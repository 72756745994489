import useSWR from "swr"
import { HotLeadsItems, RingCentralItems } from "./RingCentralItems";
import { useCallback, useEffect, useState } from "react";
import { fetchConToken, fetchConTokenDownload } from "../../../helpers/fetch";
import Swal from "sweetalert2";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

export const RingCentralScreen = () => {
  // const { data, isLoading } = useSWR(`calltools/ringcentral`);
  const [leads, setLeads] = useState([]);
  const [count, setCount] = useState(0);
  const [date, setDate] = useState(["", ""]);
  const [isLoading, setIsLoading] = useState(false);
  // const [stats, setStats] = useState(null);

  useEffect(() => {

    const getData = async () => {
      setIsLoading(true);
      const body = await fetchConToken('ringcentral')
      setLeads(body.data);
      setCount(body.dataCount);
      setIsLoading(false);
    }
    getData()

    // if (data) {
    //   console.log(data)

    //   console.log(data.data)
    //   // setStats(data.stats);
    // }
  }, [])

  const exportExcel = async () => {
    const exportE = async () => {
      Swal.fire({
        title: 'Wait ...',
        onBeforeOpen() {
          Swal.showLoading()
        },
        onAfterClose() {
          Swal.hideLoading()
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false
      })
      const body = await fetchConTokenDownload("calltools/exporthotleads")
        .then(blob => {
          // Create a URL for the blob
          const url = window.URL.createObjectURL(blob);
          // Create a temporary link element
          const a = document.createElement('a');
          // Set the href attribute to the blob URL
          a.href = url;
          // Set the download attribute to specify the filename
          a.download = 'hotleads.xlsx';
          // Append the link to the body
          document.body.appendChild(a);
          // Click the link programmatically to trigger the download
          a.click();
          // Remove the link from the body
          document.body.removeChild(a);
          // Release the object URL
          window.URL.revokeObjectURL(url);
          Swal.close();
        })
        .catch(error => {
          Swal.fire("Error", body.msg, "error");
        });
    }
    exportE();
  };

  // const [type, setType] = useState('ALL');
  const [result, setResult] = useState('ALL');
  const [filterType, setFilterType] = useState(null);
  const [filterTypeData, setFilterTypeData] = useState(null);
  // const [source, setSource] = useState('ALL');

  const filter = async (typeFilter, filterData) => {
    switch (typeFilter) {
      case 'result':
        document.querySelectorAll('.btn-type').forEach(button => {
          if (button.textContent !== filterData) {
            button.classList.remove('active');
          } else {
            button.classList.add('active');
          }
        });
        setResult(filterData);
        const filterResponse = async () => {
          if (filterType) {
            if (date[0] && date[1]) {
              setIsLoading(true);
              const body = await fetchConToken(`ringcentral/filter?${filterType}=${filterTypeData}&result=${filterData}&dateInit=${date[0]}&dateEnd=${date[1]}`);
              setLeads(body.data);
              setCount(body.dataCount);
              setIsLoading(false);
            } else {
              setIsLoading(true);
              const body = await fetchConToken(`ringcentral/filter?${filterType}=${filterTypeData}&result=${filterData}`);
              setLeads(body.data);
              setCount(body.dataCount);
              setIsLoading(false);
            }

          } else {
            if (date[0] && date[1]) {
              setIsLoading(true);
              const body = await fetchConToken(`ringcentral/filter?result=${filterData}&dateInit=${date[0]}&dateEnd=${date[1]}`);
              setLeads(body.data);
              setCount(body.dataCount);
              setIsLoading(false);
              // setStats(body.stats);
            } else {
              setIsLoading(true);
              const body = await fetchConToken(`ringcentral/filter?result=${filterData}`);
              setLeads(body.data);
              setCount(body.dataCount);
              setIsLoading(false);
              // setStats(body.stats);
            }
          }

        }
        filterResponse();
        break;
      // case 'type':
      //   document.querySelectorAll('.btn-lead').forEach(button => {
      //     if (button.textContent !== filterData) {
      //       button.classList.remove('active');
      //     } else {
      //       button.classList.add('active');
      //     }
      //   });
      //   setType(filterData);
      //   const filterType = async () => {
      //     if (date[0] && date[1]) {
      //       const body = await fetchConToken(`calltools/hotleads/filter?type=${filterData}&type_response=${response}&source=${source}&dateInit=${date[0]}&dateEnd=${date[1]}`);
      //       setLeads(body.leads);
      //       setCount(body.count);
      //       // setStats(body.stats);
      //     } else {
      //       const body = await fetchConToken(`calltools/hotleads/filter?type=${filterData}&type_response=${response}&source=${source}`);
      //       setLeads(body.leads);
      //       setCount(body.count);
      //       // setStats(body.stats);
      //     }
      //   }
      //   filterType();
      //   break;
      // case 'source':
      //   document.querySelectorAll('.btn-source').forEach(button => {
      //     if (button.textContent !== filterData) {
      //       button.classList.remove('active');
      //     } else {
      //       button.classList.add('active');
      //     }
      //   });
      //   setSource(filterData);
      //   const filterSource = async () => {
      //     if (date[0] && date[1]) {
      //       const body = await fetchConToken(`calltools/hotleads/filter?type=${type}&type_response=${response}&source=${filterData}&dateInit=${date[0]}&dateEnd=${date[1]}`);
      //       setLeads(body.leads);
      //       setCount(body.count);
      //       // setStats(body.stats);
      //     } else {
      //       const body = await fetchConToken(`calltools/hotleads/filter?type=${type}&type_response=${response}&source=${filterData}`);
      //       setLeads(body.leads);
      //       setCount(body.count);
      //       // setStats(body.stats);
      //     }

      //   }
      //   filterSource();
      //   break;

      default:
        break;
    }
  };

  const onDateChange = async (e) => {
    setDate(e)
    if (e) {
      if (filterType) {
        setIsLoading(true);
        const body = await fetchConToken(`ringcentral/filter?${filterType}=${filterTypeData}&result=${result}&dateInit=${e[0]}&dateEnd=${e[1]}`);
        setLeads(body.data);
        setCount(body.dataCount);
        setIsLoading(false);
      } else {
        setIsLoading(true);
        const body = await fetchConToken(`ringcentral/filter?result=${result}&dateInit=${e[0]}&dateEnd=${e[1]}`);
        setLeads(body.data);
        setCount(body.dataCount);
        setIsLoading(false);
      }

      // setStats(body.stats);
    }
  }



  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, wait);
    };
  };

  const debouncedFetchData = useCallback(
    debounce(async (filter, filterName) => {
      try {
        setIsLoading(true);
        setFilterType(filterName);
        setFilterTypeData(filter);
        const body = await fetchConToken(`ringcentral/filter?${filterName}=${filter}`);
        setLeads(body.data);
        setCount(body.dataCount);
        setIsLoading(false);
        // setStats(body.stats);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }, 500),
    []
  );

  const [valuesToName, setValuesToName] = useState({ cSearchToName: "" });
  const { cSearchToName } = valuesToName;
  const handleSearchToNameInputChange = ({ target }) => {
    const newValue = target.value;
    setValuesToName({
      ...valuesToName,
      [target.name]: newValue,
    });
    debouncedFetchData(newValue, 'toName');
  };

  const [valuesToNumber, setValuesToNumber] = useState({ cSearchToNumber: "" });
  const { cSearchToNumber } = valuesToNumber;
  const handleSearchToNumberInputChange = ({ target }) => {
    const newValue = target.value;
    setValuesToNumber({
      ...valuesToNumber,
      [target.name]: newValue,
    });
    debouncedFetchData(newValue, 'toNumber');
  };

  const [valuesFromName, setValuesFromName] = useState({ cSearchFromName: "" });
  const { cSearchFromName } = valuesFromName;
  const handleSearchFromNameInputChange = ({ target }) => {
    const newValue = target.value;
    setValuesFromName({
      ...valuesFromName,
      [target.name]: newValue,
    });
    debouncedFetchData(newValue, 'fromName');
  };

  const [valuesFromNumber, setValuesFromNumber] = useState({ cSearchFromNumber: "" });
  const { cSearchFromNumber } = valuesFromNumber;
  const handleSearchFromNumberInputChange = ({ target }) => {
    const newValue = target.value;
    setValuesFromNumber({
      ...valuesFromNumber,
      [target.name]: newValue,
    });
    debouncedFetchData(newValue, 'fromNumber');
  };


  return (
    <div className="m-3">
      <div className="d-flex flex-column align-items-center justify-content-center m-2">
        <h3 className="text-dark text-center">RING CENTRAL CALLS LOGS</h3>
        {/* <button onClick={() => { exportExcel() }} className="btn btn-success m-2">EXPORT <i className="fa fa-file-excel"></i></button> */}
        {/* <Link to="/reportcalling">
          <button className="btn btn-dark" title="Report" style={{ width: "250px" }}>
            HOT LEAD CALL REPORT <i className="fa fa-phone"></i>
          </button>
        </Link> */}
        {/* <div className="dropdown dropdown-nav text-decoration-none d-flex justify-content-center align-items-center p-2">
          <button className="text-light btn btn-primary me-3" title="Add deal" style={{ width: "50px" }} data-bs-toggle="collapse" data-bs-target="#statsCollapse">
            <i className="fas fa-chart-bar fa-xl"></i>
          </button>
        </div>
        <div id="statsCollapse" className="collapse navbar-collapse w-90">
          {
            stats &&
            <div className="navbar-nav d-flex flex-column justify-content-center align-items-center bg-invi rounded-3 p-1 m-2 w-100" data-bs-toggle="collapse" data-bs-target="#notificationCollapse">
              <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-primary text-light p-2 fw-bold w-100">Total Answer: {stats.amountAns}</span>
              <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-dark text-light p-2 fw-bold w-100">Total No Answer: {stats.amountNoAns}</span>
              <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-success text-light p-2 fw-bold w-100">Total Appointment: {stats.amountApp}</span>
              <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-danger text-light p-2 fw-bold w-100">Total Disqualified: {stats.amountDisq}</span>
              <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-warning text-light p-2 fw-bold w-100">Total Low Credit: {stats.amountLC}</span>
              <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-light text-dark p-2 fw-bold w-100">Total Renter: {stats.amountRent}</span>
              <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-secondary text-light p-2 fw-bold w-100">Total Only Message: {stats.amountMsj}</span>
            </div>
          }
        </div> */}
        {/* <button onClick={() => { filter() }} className="btn btn-primary">Filter <i className="fa fa-filter"></i></button> */}
        <div className="d-flex justify-content-center align-items-center row w-100">
          <div className="form-group col">
            <DateRangePicker className="form-control" onChange={(e) => { onDateChange(e) }} value={date} />
          </div>
          <div className="d-flex justify-content-center align-items-center bg-primary rounded-3 p-1 col">
            <span className="text-light text-bold">Amount: {count}</span>
          </div>
          <div className="accordion p-2 col" id="accordionFilter">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button me-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Filter
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionFilter">
                <div className="accordion-body">
                  <div className="m-1 d-flex flex-column align-items-center justify-content-center">
                    <span className="text-bold">Search by from name</span>
                    <div className="btn-group ms-2" role="group" aria-label="From Name">
                      <input className='form-control me-2 text-center' title="From Name" placeholder='From Name...' type="text" name="cSearchFromName" value={cSearchFromName} onChange={handleSearchFromNameInputChange} />
                    </div>
                  </div>
                  <div className="m-1 d-flex flex-column align-items-center justify-content-center">
                    <span className="text-bold">Search by from number</span>
                    <div className="btn-group ms-2" role="group" aria-label="From Number">
                      <input className='form-control me-2 text-center' title="From Number" placeholder='From Number...' type="text" name="cSearchFromNumber" value={cSearchFromNumber} onChange={handleSearchFromNumberInputChange} />
                    </div>
                  </div>
                  <div className="m-1 d-flex flex-column align-items-center justify-content-center">
                    <span className="text-bold">Search by to name</span>
                    <div className="btn-group ms-2" role="group" aria-label="To Name">
                      <input className='form-control me-2 text-center' title="To Name" placeholder='To Name...' type="text" name="cSearchToName" value={cSearchToName} onChange={handleSearchToNameInputChange} />
                    </div>
                  </div>
                  <div className="m-1 d-flex flex-column align-items-center justify-content-center">
                    <span className="text-bold">Search by to number</span>
                    <div className="btn-group ms-2" role="group" aria-label="To Number">
                      <input className='form-control me-2 text-center' title="To Number" placeholder='To Number...' type="text" name="cSearchToNumber" value={cSearchToNumber} onChange={handleSearchToNumberInputChange} />
                    </div>
                  </div>
                  <div className="m-1 d-flex flex-column align-items-center justify-content-center">
                    <span className="text-bold">Result</span>
                    <div className="btn-group ms-2" role="group" aria-label="Type">
                      <button type="button" className="btn-type btn btn-primary active" onClick={() => { filter('result', 'ALL') }}>ALL</button>
                      <button type="button" className="btn-type btn btn-primary" onClick={() => { filter('result', 'Accepted') }}>Accepted</button>
                      <button type="button" className="btn-type btn btn-primary" onClick={() => { filter('result', 'No Answer') }}>No Answer</button>
                      <button type="button" className="btn-type btn btn-primary" onClick={() => { filter('result', 'Call connected') }}>Call connected</button>
                      <button type="button" className="btn-type btn btn-primary" onClick={() => { filter('result', 'Voicemail') }}>Voicemail</button>
                      <button type="button" className="btn-type btn btn-primary" onClick={() => { filter('result', 'Missed') }}>Missed</button>
                      <button type="button" className="btn-type btn btn-primary" onClick={() => { filter('result', 'Hang Up') }}>Hang Up</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <RingCentralItems leads={leads} isLoading={isLoading} setLeads={setLeads} />
    </div>
  );
};
