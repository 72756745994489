import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { fetchConToken } from '../../helpers/fetch';
import { uiOpenModalBest, uiOpenModalVisionBoard, uiOpenModalll, uiOpenModallll } from '../../actions/ui';
import { StoryModal } from '../auth/StoryModal';
import { Rank } from '../ranking/Rank';
import { useNavigate } from 'react-router-dom';
import { VisionBoardModal } from './VisionBoardModal';
import { EntranceModal } from './EntranceModal';
import { BestModal } from './BestModal';

export const Home = ({ user, id, points, isAdmin }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { vision_board } = useSelector((state) => state.auth);
  const { showStory } = useSelector((state) => state.ui);
  const [newCards, setNewCards] = useState([]);
  const [bests, setBests] = useState([]);

  useEffect(() => {
    if (showStory) {
      dispatch(uiOpenModallll());
    } else {
      const getNewCards = async () => {
        const body = await fetchConToken(`usercards/id/new/${id}`);
        if (body.ok) {
          if (body.userCards.length > 0) {
            setNewCards(body.userCards);
            dispatch(uiOpenModalll());
          }

        }
      }
      getNewCards();
    }
    const getBests = async () => {
      const body = await fetchConToken(`userdata/bestoftheweek/${id}`);
      if (body.ok) {
        if (body.bests.length > 0) {
          setBests(body.bests);
          dispatch(uiOpenModalBest());
        }
      }
    }
    getBests();
  }, []);

  const goVisionBoard = () => {
    if (vision_board) {
      dispatch(uiOpenModalVisionBoard())
    } else {
      navigate('/addvisionboard')
    }
  }

  return (
    <div className='bg-primary'>
      {
        isMobile
          ?
          <div className='w-100 position-relative vh-100'>
            <img className='img-bg-mobile' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704299214/Asset_11_zhysln.png'} />
            <div className='btn-vision-mobile d-flex justify-content-center align-items-center'>
              <a className="text-decoration-none me-3" href='https://academy-be-iiek.onrender.com'><span role="button" className=""><img style={{ objectFit: "contain" }} width={75} height={75} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704827321/color1_xkbpks.png'} alt="Blue Energy Solar App" /></span></a>
              <button onClick={goVisionBoard} className='btn btn-primary text-bold ms-3'>My vision board</button>
            </div>
            <Rank id={id} />
            <img className='img-bg-logo-mobile' style={{ width: "35%" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704232784/Asset_4_jzjvwn.png'} />
            <div className='d-flex flex-column justify-content-center align-items-center text-home-bottom-mobile text-center'>
              <span className='text-3 text-light text-bold text-justified'>Congratulations</span>
              <span className='text-6 text-light text-bold text-justified'>winners!</span>
              <span className='text-3 text-light text-justified'>If you are not in the ranking.</span>
              <span className='text-3 text-light text-justified'>You still can aim the top.</span>
              <span className='text-3 text-light text-justified'>Remember <b>your are a winner!</b></span>
            </div>
            {id && newCards.length > 0 && <EntranceModal cards={newCards} id={id} points={points} />}
            <StoryModal />
            {id && bests?.length > 0 && <BestModal bests={bests} user={user} />}
            {vision_board && <VisionBoardModal />}
          </div>
          :
          <div className='w-100 position-relative vh-100'>
            <img className='img-bg' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704299214/Asset_11_zhysln.png'} />
            <div className='btn-vision-mobile d-flex justify-content-center align-items-center'>
              <a className="text-decoration-none me-3" href='https://academy-be-iiek.onrender.com'><span role="button" className=""><img style={{ objectFit: "contain" }} width={100} height={100} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704827321/color1_xkbpks.png'} alt="Blue Energy Solar App" /></span></a>
              <button onClick={goVisionBoard} className='btn btn-primary text-bold ms-3'>My vision board</button>
            </div>
            <Rank id={id} />
            <img className='img-bg-logo' style={{ width: "15%" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704232784/Asset_4_jzjvwn.png'} />
            <div className='d-flex flex-column justify-content-center align-items-center text-home-bottom text-center'>
              <span className='text-2 text-light text-bold text-justified'>Congratulations</span>
              <span className='text-4 text-light text-bold text-justified'>winners!</span>
              <span className='text-1 text-light text-justified'>If you are not in the ranking.</span>
              <span className='text-1 text-light text-justified'>You still can aim the top.</span>
              <span className='text-1 text-light text-justified'>Remember <b>your are a winner!</b></span>
            </div>
            {id && newCards.length > 0 && <EntranceModal cards={newCards} id={id} points={points} />}
            <StoryModal />
            {id && bests?.length > 0 && <BestModal bests={bests} user={user} />}
            {vision_board && <VisionBoardModal />}
          </div>
      }
    </div>
  );
};