import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import { StatsCloserTab } from './StatsCloserTab';
import { GraphCloserTab } from './GraphCloserTab';
import { GraphCloser } from './GraphCloser';
import { StatsCloser } from './StatsCloser';

export const StatsCloserScreen = ({ user, id }) => {
  const { t } = useTranslation();

  return (
    <div className={isMobile ? "w-100" : "w-100"}>
      {/* <div className='w-100 bg-primary'>
        <span className='text-2 text-bold text-light ms-5'>My Stats</span>
      </div> */}
      <img className='fixed-bottom' style={isMobile ? { width: "30vw" } : { width: "10vw" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704397910/Asset_35_m4vka5.png'} />
      {/* <img className='fixed-bottom img-decorate-logo2' style={{ width: "20vw" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704397960/Asset_36_coa2gd.png'} /> */}
      <Tabs selectedTabClassName="bg-primary-dark text-light rounded-pill">
        <TabList className={isMobile ? "d-flex justify-content-center align-items-center text-center m-2 p-0" : "d-flex justify-content-center align-items-center text-center padding-start m-2"}>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>{t('profile.stats')}</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>{t('profile.graph')}</Tab>
        </TabList>

        <TabPanel>
          {
            isMobile
              ?
              <StatsCloserTab id={id} />
              :
              <StatsCloser id={id} />
          }
        </TabPanel>
        <TabPanel>
          {
            isMobile
              ?
              <GraphCloserTab id={id} />
              :
              <GraphCloser id={id} />
          }
        </TabPanel>
      </Tabs>
    </div>
  );
};
