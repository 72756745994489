import Tilt from 'react-parallax-tilt';
import { useRef } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { uiOpenModallllll } from '../../actions/ui';
import { CardModal } from './CardModal';

export const RowCardsMobileChild = ({ card, i }) => {
  const dispatch = useDispatch();
  const cardEl = useRef(null);
  const [selectedCardModal, setSelectedCardModal] = useState(null);
  const openModal = (card) => {
    setSelectedCardModal(card);
    dispatch(uiOpenModallllll());
  }
  return (
    <div>
      {
        card.url
        &&
        <Tilt className={`card-mobile ${card.type} myText clickcard card-${i} rounded d-flex justify-content-center align-items-center m-1 test`} scale={1.2}>
          <div className='deck-mobile d-flex justify-content-center align-items-center'>
            <div ref={cardEl} className={`card-mobile ${card.type}`} onClick={() => { openModal(card) }}>
              <div className={`front-mobile face-mobile`}>
                <img src={card.url} style={{ objectFit: "cover", width: "100%", height: "100%" }} width={250} height={350} alt="Card" />
              </div>
              <div className="back-mobile face-mobile">
              </div>
            </div>
          </div>
        </Tilt>
      }
      {selectedCardModal && <CardModal card={selectedCardModal} />}
    </div>
  );
};

