import Modal from "react-modal";
import Swal from "sweetalert2";
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { uiCloseModalll } from "../../actions/ui";
import { fetchConToken } from "../../helpers/fetch";
import "./entrancemodal.css";
import { CarouselNewCards } from "./CarouselNewCards";
import { isMobile } from "react-device-detect";

const customStyles = {};
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

export const EntranceModal = ({ cards }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { modalOpennn } = useSelector((state) => state.ui);
  useEffect(() => {
    setTimeout(() => {
      slice()
    }, 1000)
  }, [])
  const slice = (e) => {
    const cards = document.getElementsByClassName('card-stacks');
    cards[1]?.classList.toggle('transition');
  }

  const closeModal = () => {
    const updateNewUserCards = async (cards) => {
      const body = await fetchConToken(
        "usercards/update",
        { cards },
        "PUT"
      );
      if (body.ok) {
      } else {
        Swal.fire("Error", body.msg, "error");
      }
    }
    updateNewUserCards(cards);
    dispatch(uiCloseModalll());
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Modal
        isOpen={modalOpennn}
        onRequestClose={closeModal}
        style={customStyles}
        closeTimeoutMS={500}
        className="entrancemodal"
        ariaHideApp={process.env.NODE_ENV === "test"}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <button onClick={closeModal} className="btn bg-invi text-light align-self-end"><i className="fa fa-close"></i></button>
          <div id="myCarouselentrance" className="d-flex flex-column justify-content-center align-items-center carousel slide mb-3 text-center animate__animated animate__zoomInDown" data-bs-pause="true" data-aos="fade-up" data-aos-duration="1000">
            <h1 className={isMobile ? "text-light bg-plate-mobile" : "text-light bg-plate"}>{t('cards.my_cards.congratulations')}</h1>
            <div className="carousel-indicators">
              {cards.length > 0 && cards.map((card, i) => {
                return (
                  <div key={card.id}>
                    {i === 0
                      ?
                      <button type="button" data-bs-target="#myCarouselentrance" data-bs-slide-to={i} className="active" aria-current="true" aria-label="Slide 1"></button>
                      :
                      <button type="button" data-bs-target="#myCarouselentrance" data-bs-slide-to={i} aria-current="true" aria-label={`Slide ${i}`}></button>
                    }
                  </div>
                )
              })
              }
            </div>
            <div className="carousel-inner">
              {
                cards.length > 0 && cards.map((card, i) => {
                  return (
                    i === 0
                      ?
                      <CarouselNewCards classy="carousel-item active" key={`stack-${i}`} card={card.card} i={i + 1} />
                      :
                      <CarouselNewCards classy="carousel-item" key={`stack-${i}`} card={card.card} i={i + 1} />
                  )
                })
              }
            </div>
            <button aria-label="Anterior" className="carousel-control-prev" type="button" data-bs-target="#myCarouselentrance" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true" />
            </button>
            <button id="apreta" aria-label="Siguiente" className="carousel-control-next" type="button" data-bs-target="#myCarouselentrance" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
