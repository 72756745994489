import { useState } from 'react';
import { fetchConToken } from '../../helpers/fetch';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Axios from "axios";
import { format } from 'date-fns';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import './uploadexcel.css';
import { useTranslation } from 'react-i18next';
import colourStyles from '../../helpers/selectStyles';
import Select from 'react-select';

export const AddUserDataCloser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const handleFile = ({ target }) => {
    setFile(target.files[0]);
  };
  const timeFrames = [
    { value: 'daily', label: `${t('cards.time_frames.daily')}` },
    { value: 'weekly', label: `${t('cards.time_frames.weekly')}` },
    { value: 'monthly', label: `${t('cards.time_frames.monthly')}` },
    { value: 'semiannual', label: `${t('cards.time_frames.semiannual')}` },
    { value: 'yearly', label: `${t('cards.time_frames.yearly')}` },
    { value: 'timeless', label: `${t('cards.time_frames.timeless')}` },
  ];
  const [timeFrame, setTimeFrame] = useState({ value: 'daily', label: `${t('cards.time_frames.daily')}` });
  const handleTimeFrame = (e) => {
    setTimeFrame(e);
  };

  const [date, setDate] = useState(format(new Date(), 'MM-dd-yyyy HH:mm'));
  const handleDateChange = (e) => {
    setDate(e);
  };

  const handleReturn = (e) => {
    navigate('/');
  };
  const handleUploadAndSave = () => {
    if (file) {
      const fileUploadCloud = async () => {
        Swal.fire({
          title: 'Wait ...',
          onBeforeOpen() {
            Swal.showLoading()
          },
          onAfterClose() {
            Swal.hideLoading()
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false
        })
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "cardsapp");
        Axios.post("https://api.cloudinary.com/v1_1/drholseqp/upload", formData
        ).then((res) => {
          const uploadUser = async (file, type) => {
            const body = await fetchConToken(`userdata/uploadusersdatacloser/${type}`, { url: file }, 'POST');
            if (body.ok) {
              Swal.close();
              Swal.fire(t('success.title'), "Users upload", "success");
              window.location.reload(false);
            } else {
              Swal.fire("Error", body.msg, "error");
            }
          }
          uploadUser(res.data.secure_url, timeFrame.value);
        });
      }
      fileUploadCloud();
    }
  }
  return (
    <div className='container d-flex flex-column justify-content-center align-items-center'>
      <h1 className='text-light text-center'>Upload users data closer</h1>
      <div className="col-md-6 login-form-2 m-2">
        <h3>{t('labels.choose_file')}</h3>

        <div className="form-group mb-3">
          <label className='text-light'>{t('labels.time_frame')}</label>
          <div className="w-100">
            <Select placeholder={t('select.placeholder')} styles={colourStyles} options={timeFrames} value={timeFrame} onChange={handleTimeFrame} />
          </div>
        </div>

        {(timeFrame.value === 'daily' || timeFrame.value === 'weekly' || timeFrame.value === 'monthly') && <div className="form-group">
          <label className='text-light'>{t('labels.date')}</label>
          <DateTimePicker
            onChange={handleDateChange}
            value={date}
            className="form-control mb-2"
            format='MM-dd-yyyy HH:mm'
          />
        </div>}

        <div className="form-group files m-2">
          <input id="excelfile" className='bg-light' type="file" onChange={(e) => handleFile(e)} />
        </div>
        <div className='container d-flex flex-column justify-content-center align-items-center'>
          <button className='btn btn-success btn-bright' onClick={handleUploadAndSave}>{t('buttons.upload')}</button>
        </div>
      </div>
      <div className='container d-flex flex-column justify-content-center align-items-center m-2'>
        <button className="btn btn-dark btn-bright" onClick={handleReturn}>{t('buttons.return')}</button>
      </div>
    </div>);
};