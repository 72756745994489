import { useEffect } from 'react';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { fetchConToken } from '../../../../helpers/fetch';
import { LoadingSpinnerWhite } from '../../../ui/LoadingSpinnerWhite';
import { CloserDealsAdminNew } from './CloserDealsAdminNew';
import { CanvasserDealsAdminNew } from './CanvasserDealsAdminNew';

export const AdminStatsDealsGeneralOffices = ({ cancels, id }) => {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const date = new Date();
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const goBack = () => {
    navigate('/statsdata');
  }
  const [totalCloser, setTotalCloser] = useState(0);
  const [offices, setOffices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(async () => {
    const todayDate = new Date();
    const year = todayDate.getFullYear();
    const month = todayDate.getMonth() + 1;
    const body = await fetchConToken(`userdata/usersmanualdata/custom/offices/true?startDate=${year}-${month}-01&endDate=${todayDate}`);
    setTotal(body.total);
    setOffices(body.offices);
    setIsLoading(false);
  }, []);

  return (
    <div className={isMobile ? 'bg-primary-dark vh-110' : 'bg-primary-dark'}>
      {
        isLoading
          ?
          <LoadingSpinnerWhite />
          :
          <>
            {
              isMobile
                ?
                <div className='position-relative'>
                  <div className='d-flex justify-content-center align-items-center pt-2'>
                    <button onClick={goBack} className='btn btn-light'><i className='fa fa-chevron-left'></i></button>
                  </div>
                  <div className='d-flex flex-column justify-content-center align-items-center text-center text-admin-stats'>
                    <span className='font-special-total-deals m-5'>{monthNames[date.getMonth()]}</span>
                    <span className='font-special-total-deals'>TOTAL SALES</span>
                    {/* <div className='bg-primary-dark rounded-3 p-4 m-3'>
                <span className='font-special-total-deals-value'>{total}</span>
              </div> */}
                  </div>
                  <img className='logo-be-admin-stats' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694440370/images-logos/001_sc2glr.png'} style={{ objectFit: "contain" }} width={200} height={100} alt="back" />
                  <img className='logo-be-admin-stats-2' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704224590/Asset_2_zo1wyw.png'} style={{ objectFit: "contain" }} width={200} height={100} alt="back" />
                </div>
                :
                <div className='w-100 text-center vh-100 position-relative pt-5 d-flex flex-column justify-content-center align-items-center'>
                  <img className='bg-offices-deals' src={'https://res.cloudinary.com/drholseqp/image/upload/v1709839195/FONDO_BLUENERGY_LOGOS_vx6tql.jpg'} style={{ objectFit: "cover" }} alt="back" />
                  <div className='position-absolute d-flex flex-column justify-content-center align-items-center'>
                    <div className='col-12 d-flex justify-content-center align-items-center pb-5'>
                      <img className='img-class-logo-offices' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694440370/images-logos/001_sc2glr.png'} style={{ objectFit: "cover" }} alt="back" />
                    </div>
                    <div className='row'>
                      {
                        offices.map((office) => {
                          return (
                            <div className='col px-5 d-flex flex-column justify-content-center align-items-center'>
                              <img className='img-logo-offices' src={office.logo} style={{ objectFit: "cover" }} alt="back" />
                              <span className='text-light text-light text-bold text-1-5'><b className='text-info-light text-bold'>{office.amount}</b> {office.amount > 1 ? 'Deals' : 'Deal'}</span>
                            </div>
                          )
                        })
                      }
                    </div>
                    <div className='col-12 d-flex justify-content-center align-items-center mt-5 pt-5'>
                      <span className='text-2 text-light text-justified me-5 text-line text-uppercase'>{monthNames[date.getMonth()]}</span>
                      <span className='text-2 text-info-light text-justified text-bold text-line'><b className='text-bold me-3'>{total}</b> DEALS</span>
                    </div>
                  </div>
                </div>
            }
          </>
      }
    </div>
  );
};