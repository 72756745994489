import { useState } from "react";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import Select from 'react-select';

import { fetchConToken } from "../../helpers/fetch";
import useSWR from "swr";
import "./adddealmodal.css";
import colourStyles from '../../helpers/selectStyles';
import { Link, useNavigate } from "react-router-dom";

export const DeleteMasiveUsers = () => {
  const { t } = useTranslation();
  const [usersToDelete, setUsersToDelete] = useState([]);
  const navigate = useNavigate();

  const { data } = useSWR("auth/users/notadmin");
  const users = [];
  if (data?.users) {
    const usersLen = data?.users.length
    for (let i = 0; i < usersLen; i++) {
      const user = {
        value: data?.users[i].id,
        label: data?.users[i].name + ' ' + data?.users[i].last_name,
        user: data?.users[i]
      }
      users.push(user);
    }
  }
  const [user, setUser] = useState(null);
  const handleUser = (e) => {
    setUser(e);
  };

  const handleRemove = async (e) => {
    e.preventDefault();
    if (!user) {
      return Swal.fire(
        "Error",
        'You must select a user',
        "error"
      );
    }
    const n = {
      name: user.user.name + " " + user.user.last_name,
      office: user.user.office,
      id: user.value,
    }
    setUsersToDelete(currentArray => [...currentArray, n])
  };

  const handleDelete = async (e) => {
    if (usersToDelete.length <= 0) {
      Swal.fire("Error", 'List empty', "error");
    } else {
      const createUserDeals = async () => {
        Swal.fire({
          title: 'Wait ...',
          onBeforeOpen() {
            Swal.showLoading()
          },
          onAfterClose() {
            Swal.hideLoading()
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false
        })
        const body = await fetchConToken(`auth/users/delete/massive`, { users: usersToDelete }, 'POST');
        if (body.ok) {
          Swal.close();
          Swal.fire(t('success.title'), "Users deleted", "success");
          navigate('/userlist');
        } else {
          Swal.fire("Error", body.msg, "error");
        }
      }
      createUserDeals();
    }
  };

  const handleDeleteRow = async (id) => {
    const userss = usersToDelete.filter((u) => {
      return u.id !== id
    })
    setUsersToDelete(userss);
  };

  return (
    <div className="d-flex flex-column justify-content-start align-items-center w-100 bg-primary vh-100">
      <div className="d-flex justify-content-center align-items-center text-light w-100">
        <div className="p-3 w-50">
          <h1 className="text-center h1">Select users for delete</h1>
          <div className="d-flex flex-column justify-content-center align-items-center w-100">
            <div className="w-100 d-flex flex-column align-items-center justify-content-center">
              <form onSubmit={handleRemove} className="w-95">
                <div className="form-group mb-1">
                  <label className='text-light'>Users</label>
                  <div className="w-100 text-dark">
                    <Select placeholder={t('select.placeholder')} styles={colourStyles} options={users} value={user} onChange={handleUser} />
                  </div>
                </div>
                <div className="form-group d-flex justify-content-center m-3">
                  <button type="submit" className="btn btn-light btn-bright">
                    <i className="fa fa-plus"></i> Add to list
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="p-3 w-50">
          <h1 className="text-center h1">Users to delete</h1>
          <table className="table table-striped">
            <thead className="bg-dark text-light">
              <tr className='align-middle'>
                <th className="text-center small text-light">Name</th>
                <th className="text-center small text-light">Office</th>
                <th className="text-center small text-light">Delete</th>
              </tr>
            </thead>
            <tbody>
              {usersToDelete.length > 0 && usersToDelete.map((user, i) => {
                return (
                  <tr key={"d" + i} className='align-middle bg-light'>
                    <td className="text-center small text-dark">{user.name}</td>
                    <td className="text-center small text-dark">{user.office}</td>
                    <td className="text-center"><button className='btn btn-danger' onClick={() => handleDeleteRow(user.id)}><i className='fa fa-trash'></i></button></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="form-group d-flex justify-content-center mb-1">
            <button onClick={() => { handleDelete() }} type="submit" className="btn btn-success btn-bright">
              <i className="fa fa-trash"></i> Delete Users
            </button>
          </div>
        </div>
      </div>
      <Link to="/" className="m-2">
        <button className="btn btn-light" title="Add deal" style={{ width: "150px" }}>
          <i className="fa fa-arrow-left"></i> Go back
        </button>
      </Link>
    </div>
  );
};
