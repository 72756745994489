import useSWR from "swr";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import axios from "axios";
import { fetchConToken } from "../../helpers/fetch";
import Swal from "sweetalert2";

const baseURL = process.env.REACT_APP_CONNECTOR_API;
const axiosReq = axios.create({
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_CONNECTOR_API_TOKEN}`
  }
});

const fetcher = (url) => axiosReq.get(url)
  .then((response) => {
    return response.data
  })
  .catch(error => console.log(error));

export const SpecialRewards = ({ id }) => {
  const { t } = useTranslation();
  const { data: deals } = useSWR(`${baseURL}leads/${id}`, fetcher);

  const review = async () => {
    const body = await fetchConToken(`rewards/review`,
      { userId: id },
      'POST'
    );
    if (body.ok) {
      Swal.fire(t('success.title'), t('success.reward_claimed'), "success");
    } else {
      Swal.fire("Error", body.msg, "error");
    }
  }

  return (
    <div className='text-center'>
      <h1 className="font-speciallblue mt-2">Special Rewards</h1>
      <div className="mt-2">
        <Link className="text-decoration-none" to="/rewards"><span className='font-speciallblue-2 bg-light text-dark rounded p-2'>Rewards</span></Link>
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center text-center text-light m-2">
        <span className="fw-bold">IMPORTANT</span>
        <span>Review is needed to be able to claim</span>
      </div>
      <div className='d-flex flex-column justify-content-center align-items-center text-center text-light'>
        <div className='d-flex justify-content-center align-items-center w-100'>
          <div className="d-flex justify-content-center align-items-center w-100 p-3">
            <div style={{ width: "60%" }}>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img style={{ objectFit: "contain" }} className="rounded-3" src={'https://res.cloudinary.com/drholseqp/image/upload/v1694748196/m126500ln-0002_modelpage_front_facing_landscape_wmfzms.png'} width={150} height={100} />
                <span className='m-1'><b>Rolex</b></span>
              </div>
              <div className="progress">
                <div className="progress-bar" role="progressbar" style={{ width: `${Math.round(deals?.deals.length * 100 / 100) >= 100 ? 100 : Math.round(deals?.deals.length * 100 / 100)}%` }} aria-valuenow={Math.round(deals?.deals.length * 100 / 100) >= 100 ? 100 : Math.round(deals?.deals.length * 100 / 100)} aria-valuemin="0" aria-valuemax="100">{`${deals?.deals.length} / 100`}</div>
              </div>
            </div>
            <button onClick={() => { review() }} disabled={deals?.deals.length >= 100 ? false : true} className={deals?.deals.length >= 100 ? "btn btn-light" : "btn btn-default"}>Review</button>
          </div>
        </div>
        <div className='d-flex justify-content-center align-items-center w-100'>
          <div className="d-flex justify-content-center align-items-center w-100 p-3">
            <div style={{ width: "60%" }}>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img style={{ objectFit: "contain" }} className="rounded-3" src={'https://res.cloudinary.com/drholseqp/image/upload/v1694811849/car-512_xmemfn.png'} width={150} height={100} />
                <span className='m-1'><b>Car</b> </span>
              </div>
              <div className="progress">
                <div className="progress-bar" role="progressbar" style={{ width: `${Math.round(deals?.deals.length * 100 / 150) >= 100 ? 100 : Math.round(deals?.deals.length * 100 / 150)}%` }} aria-valuenow={Math.round(deals?.deals.length * 100 / 150) >= 100 ? 100 : Math.round(deals?.deals.length * 100 / 150)} aria-valuemin="0" aria-valuemax="100">{`${deals?.deals.length} / 150`}</div>
              </div>
            </div>
            <button onClick={() => { review() }} disabled={deals?.deals.length >= 150 ? false : true} className={deals?.deals.length >= 150 ? "btn btn-light" : "btn btn-default"}>Review</button>
          </div>
        </div>
        <div className='d-flex justify-content-center align-items-center w-100'>
          <div className="d-flex justify-content-center align-items-center w-100 p-3">
            <div style={{ width: "60%" }}>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img style={{ objectFit: "contain" }} className="rounded-3" src={'https://res.cloudinary.com/drholseqp/image/upload/v1694811849/house-512_ilwg7r.png'} width={150} height={100} />
                <span className='m-1'><b>3% of a house</b></span>
              </div>
              <div className="progress">
                <div className="progress-bar" role="progressbar" style={{ width: `${Math.round(deals?.deals.length * 100 / 150) >= 100 ? 100 : Math.round(deals?.deals.length * 100 / 150)}%` }} aria-valuenow={Math.round(deals?.deals.length * 100 / 150) >= 100 ? 100 : Math.round(deals?.deals.length * 100 / 150)} aria-valuemin="0" aria-valuemax="100">{`${deals?.deals.length} / 150`}</div>
              </div>
            </div>
            <button onClick={() => { review() }} disabled={deals?.deals.length >= 150 ? false : true} className={deals?.deals.length >= 150 ? "btn btn-light" : "btn btn-default"}>Review</button>
          </div>
        </div>
      </div>
    </div>
  );
};
