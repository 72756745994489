import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { uiCloseModalVisionBoard } from "../../actions/ui";
import { isMobile } from "react-device-detect";

const customStyles = {};
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

export const VisionBoardModal = () => {
  const dispatch = useDispatch();
  const { vision_board } = useSelector((state) => state.auth);
  const { modalOpenVisionBoard } = useSelector((state) => state.ui);

  const closeModal = () => {
    dispatch(uiCloseModalVisionBoard());
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Modal
        isOpen={modalOpenVisionBoard}
        onRequestClose={closeModal}
        style={customStyles}
        closeTimeoutMS={500}
        className="visionmodal"
        ariaHideApp={process.env.NODE_ENV === "test"}
      >
        <div className="d-flex justify-content-center align-items-center">
          {
            isMobile
              ?
              <>
                {
                  vision_board &&
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <button className="btn btn-2xl btn-big-mobile bg-light rounded-circle text-primary m-3" onClick={() => { closeModal() }}><i className="fa fa-close"></i></button>
                    <img src={vision_board} style={{ objectFit: "cover", width: "90vw", height: "80vh" }} />
                  </div>
                }
              </>
              :
              <>
                {
                  vision_board &&
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <button className="btn btn-2xl bg-light rounded-circle text-primary m-3" onClick={() => { closeModal() }}><i className="fa fa-close"></i></button>
                    <img src={vision_board} style={{ objectFit: "contain", width: "30vw", height: "80vh" }} />
                  </div>
                }
              </>
          }
        </div>

      </Modal>
    </div>
  );
};
