import { useState } from 'react';
import { isMobile } from 'react-device-detect';

export const TableRepcardOffice = ({ title, users, totalDK, totalApp, percentage, dateS }) => {
  const [usersPercentage, setUsersPercentage] = useState(users);

  const [sortOrder, setSortOrder] = useState('ASC');
  const [sortName, setSortName] = useState('percentage');

  const sortApps = async (sortN) => {
    setSortName(sortN);
    let usersSorted = [];
    if (sortOrder === 'ASC') {
      setSortOrder('DESC');
      usersSorted = usersPercentage.sort((a, b) => {
        if (a.appointments < b.appointments) {
          return -1;
        }
        if (a.appointments > b.appointments) {
          return 1;
        }
        return 0;
      })
    } else {
      setSortOrder('ASC');
      usersSorted = usersPercentage.sort((a, b) => {
        if (b.appointments < a.appointments) {
          return -1;
        }
        if (b.appointments > a.appointments) {
          return 1;
        }
        return 0;
      })
    }
    setUsersPercentage(usersSorted);
  };
  const sortLeads = async (sortN) => {
    setSortName(sortN);
    let usersSorted = [];
    if (sortOrder === 'ASC') {
      setSortOrder('DESC');
      usersSorted = usersPercentage.sort((a, b) => {
        if (a.leads < b.leads) {
          return -1;
        }
        if (a.leads > b.leads) {
          return 1;
        }
        return 0;
      })
    } else {
      setSortOrder('ASC');
      usersSorted = usersPercentage.sort((a, b) => {
        if (b.leads < a.leads) {
          return -1;
        }
        if (b.leads > a.leads) {
          return 1;
        }
        return 0;
      })
    }
    setUsersPercentage(usersSorted);
  };
  const sortPercentage = async (sortN) => {
    setSortName(sortN);
    let usersSorted = [];
    if (sortOrder === 'ASC') {
      setSortOrder('DESC');
      usersSorted = usersPercentage.sort((a, b) => {
        if (a.percentage < b.percentage) {
          return -1;
        }
        if (a.percentage > b.percentage) {
          return 1;
        }
        return 0;
      })
    } else {
      setSortOrder('ASC');
      usersSorted = usersPercentage.sort((a, b) => {
        if (b.percentage < a.percentage) {
          return -1;
        }
        if (b.percentage > a.percentage) {
          return 1;
        }
        return 0;
      })
    }
    setUsersPercentage(usersSorted);
  };

  return (
    <div className='col-6 row mt-3 d-flex justify-content-start align-items-start'>
      <div className='col-12 row'>
        <div className='col-12 d-flex flex-column justify-content-center align-items-center'>
          {dateS && <div className='d-flex align-items-center justify-content-center w-100 p-1 rounded-3'>
            <div className='p-2 rounded-3 d-flex flex-column align-items-center justify-content-center m-2'>
              <span className='text-2 text-light text-bold text-justified'><b>{title}</b></span>
              <span className='text-1-8 text-light text-justified'><b>{totalDK}</b> Doors Knocked</span>
              <span className='text-1-8 text-light text-justified'><b>{totalApp}</b> Appointments</span>
              <span className='text-1-8 text-light text-justified'><b>{percentage}</b> % App / DK</span>
            </div>
          </div>}
        </div>
      </div>

      <div className='col-12 padding-adaptative d-flex justify-content-center align-items-center'>
        <div className='w-90 table-responsive'>
          {usersPercentage && usersPercentage.length > 0 && <table className={isMobile ? "table table-sm mt-3" : "table table-hover table-bordered table-striped table-sm overflow-hidden mt-3 rounded-3"}>
            <>
              <thead className='bg-light'>
                <tr className='bg-light'>
                  <th className={"text-1 text-center text-dark text-bold align-middle"}>#</th>
                  <th className={"text-1 text-center text-dark text-bold align-middle"}>Canvasser</th>
                  <th role='button' onClick={() => { sortApps('apps') }} className="text-center align-middle text-nowrap" scope="col">
                    {sortName === 'apps' && <i className={sortOrder === 'DESC' ? 'fa fa-arrow-up-wide-short' : 'fa fa-arrow-down-short-wide'}></i>} Apps
                  </th>
                  <th role='button' onClick={() => { sortLeads('leads') }} className="text-center align-middle text-nowrap" scope="col">
                    {sortName === 'leads' && <i className={sortOrder === 'DESC' ? 'fa fa-arrow-up-wide-short' : 'fa fa-arrow-down-short-wide'}></i>} Leads
                  </th>
                  <th role='button' onClick={() => { sortPercentage('percentage') }} className="text-center align-middle text-nowrap" scope="col">
                    {sortName === 'percentage' && <i className={sortOrder === 'DESC' ? 'fa fa-arrow-up-wide-short' : 'fa fa-arrow-down-short-wide'}></i>} % App / Lead
                  </th>
                </tr>
              </thead>
              {usersPercentage.length > 0 && usersPercentage.map((rank, i) => {
                return (
                  <tbody key={rank.id}>
                    <tr className='bg-light'>
                      <td className={"text-center text-dark fw-bold align-middle"}>{rank.position}</td>
                      <td className={"text-center text-dark fw-bold text-uppercase align-middle"}>{rank.name}</td>
                      <td className={
                        sortName !== 'apps'
                          ?
                          "text-center text-dark fw-bold text-uppercase align-middle"
                          :
                          i === 0
                            ?
                            "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-1"
                            :
                            i === 1
                              ?
                              "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-2"
                              :
                              i === 2
                                ?
                                "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-3"
                                :
                                i === 3
                                  ?
                                  "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-4"
                                  :
                                  i === 4
                                    ?
                                    "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-5"
                                    :
                                    i === 5
                                      ?
                                      "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-6"
                                      :
                                      "text-1 align-middle text-center text-dark fw-bold"
                      }>{rank.appointment}</td>
                      <td className={
                        sortName !== 'leads'
                          ?
                          "text-center text-dark fw-bold text-uppercase align-middle"
                          :
                          i === 0
                            ?
                            "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-1"
                            :
                            i === 1
                              ?
                              "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-2"
                              :
                              i === 2
                                ?
                                "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-3"
                                :
                                i === 3
                                  ?
                                  "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-4"
                                  :
                                  i === 4
                                    ?
                                    "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-5"
                                    :
                                    i === 5
                                      ?
                                      "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-6"
                                      :
                                      "text-1 align-middle text-center text-dark fw-bold"
                      }>{rank.leads}</td>
                      <td className={
                        sortName !== 'percentage'
                          ?
                          "text-center text-dark fw-bold text-uppercase align-middle"
                          :
                          i === 0
                            ?
                            "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-1"
                            :
                            i === 1
                              ?
                              "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-2"
                              :
                              i === 2
                                ?
                                "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-3"
                                :
                                i === 3
                                  ?
                                  "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-4"
                                  :
                                  i === 4
                                    ?
                                    "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-5"
                                    :
                                    i === 5
                                      ?
                                      "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-6"
                                      :
                                      "text-1 align-middle text-center text-dark fw-bold"
                      }>{rank.percentage}%</td>
                    </tr>
                  </tbody>
                );
              })}
            </>
          </table>}
        </div>
      </div>
    </div>
  );
};