import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import '../admin/uploadexcel.css';

import { fetchConToken } from '../../helpers/fetch';

export const TransferDeals = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleTransfer = async (e) => {
    const body = await fetchConToken(`auth/users/deals/transfer`);
    if (body.ok) {
      Swal.fire(t('success.title'), 'Transfers', "success");
    } else {
      Swal.fire("Error", body.msg, "error");
    }
  };

  const handleRemove = async (e) => {
    const body = await fetchConToken(`auth/users/deals/transfer/remove`);
    if (body.ok) {
      Swal.fire(t('success.title'), 'Removed', "success");
    } else {
      Swal.fire("Error", body.msg, "error");
    }
  };

  const handleReturn = () => {
    navigate('/');
  };
  return (
    <>
      {
        isMobile
          ?
          <div className='d-flex flex-column justify-content-center align-items-center mt-4' data-aos="fade-up" data-aos-duration="1000">
            <div className="col-md-6 login-form-2 m-4 w-90">
              <div className="form-group d-flex flex-row justify-content-center">
                <button className="btn btn-success mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleTransfer}>
                  <i className="fa fa-history me-1"></i> Transfer
                </button>
              </div>
              <div className="form-group d-flex flex-row justify-content-center">
                <button className="btn btn-danger mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleRemove}>
                  <i className="fa fa-trash me-1"></i> Remove
                </button>
              </div>
              <div className="form-group d-flex flex-row justify-content-center">
                <button className="btn btn-light mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleReturn}>
                  <i className="fa fa-arrow-rotate-left me-1"></i> {t('buttons.return')}
                </button>
              </div>
            </div>
          </div>
          :
          <div className='d-flex flex-column justify-content-center align-items-center mt-4' data-aos="fade-up" data-aos-duration="1000">
            <div className="col-md-6 login-form-2 m-4">
              <div className="form-group d-flex flex-row justify-content-center">
                <button className="btn btn-success mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleTransfer}>
                  <i className="fa fa-history me-1"></i> Transfer
                </button>
              </div>
              <div className="form-group d-flex flex-row justify-content-center">
                <button className="btn btn-danger mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleRemove}>
                  <i className="fa fa-trash me-1"></i> Remove
                </button>
              </div>
              <div className="form-group d-flex flex-row justify-content-center">
                <button className="btn btn-light mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleReturn}>
                  <i className="fa fa-arrow-rotate-left me-1"></i> {t('buttons.return')}
                </button>
              </div>
            </div>
          </div>
      }
    </>
  );
};
