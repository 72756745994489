import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

import { LoadingSpinnerWhite } from '../ui/LoadingSpinnerWhite';
import { fetchConToken } from '../../helpers/fetch';
import { format, isValid } from 'date-fns';
import { LoadingSpinnerBlue } from '../ui/LoadingSpinnerBlue';

export const StatsCustomTab = ({ id }) => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState(null);
  // const [date, setDate] = useState([new Date(), new Date()]);
  const [isLoading, setIsLoading] = useState(false);

  const [date, setDate] = useState(["", ""]);

  const datee = format(new Date(), 'MM-dd-yyyy HH:mm');

  useEffect(() => {
    if (isValid(datee)) {
      setDate([datee, datee])
    }
  }, []);

  const onDateChange = (e) => {
    setIsLoading(true);
    setDate(e)
    const getStats = async () => {
      const body = await fetchConToken(
        `userdata/userdatahistory/custom/${id}`,
        { date: e },
        'POST'
      );
      setUserData(body.userData);
      setIsLoading(false);
    }
    if (e !== null) {
      getStats()
    } else {
      setIsLoading(false);
    }
  }

  return (
    <div className="text-center mb-3 w-100">
      <DateRangePicker className={"bg-light rounded-3 mb-3"} onChange={(e) => { onDateChange(e) }} value={date} />
      {
        isLoading
          ?
          <LoadingSpinnerBlue disableCenter={true} />
          :
          <div>
            {
              userData &&
              <div className="d-flex flex-column justify-content-center align-items-center font-stats">
                <div className="d-flex flex-column justify-content-center align-items-center m-2 p-1 bg-primary rounded-3 w-50">
                  <label>Appointments Set</label>
                  <span>{userData.appointments_set}</span>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center m-2 p-1 bg-primary rounded-3 w-50">
                  <label>Doors knocked</label>
                  <span>{userData.doors_knocked}</span>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center m-2 p-1 bg-primary rounded-3 w-50">
                  <label>Effective doors </label>
                  <span>{userData.app_doors_percentage ? userData.app_doors_percentage?.toFixed(2) + " %" : 0 + " %"}</span>
                </div>
              </div>
            }
          </div>
      }
    </div>
  );
};
