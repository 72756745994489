import { useState } from "react";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import Select from 'react-select';

import { useForm } from "../../hooks/useForm";
import { fetchConToken } from "../../helpers/fetch";
import useSWR from "swr";
import "./adddealmodal.css";
import { format, isValid } from "date-fns";
import colourStyles from '../../helpers/selectStyles';
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const AddDeal = ({ mutate }) => {
  const { t } = useTranslation();
  const [dealsToCreate, setDealsToCreate] = useState([]);
  const navigate = useNavigate();
  const [formUserValues, handleUserInputChange] = useForm({
    rName: '',
    rLastName: '',
  });

  const { rName, rLastName } = formUserValues;


  const datee = format(new Date(), 'MM-dd-yyyy HH:mm');

  const [date, setDate] = useState('');
  const handleDateChange = (e) => {
    setDate(e);
  };

  useEffect(() => {
    if (isValid(datee)) {
      setDate(datee)
    }
  }, []);

  const { data: usersApi } = useSWR("auth/users/canvassers");
  const users = [];
  if (usersApi?.users) {
    const usersLen = usersApi?.users.length
    for (let i = 0; i < usersLen; i++) {
      const user = {
        value: usersApi?.users[i].id,
        label: usersApi?.users[i].name + ' ' + usersApi?.users[i].last_name
      }
      users.push(user);
    }
  }
  const [user, setUser] = useState(null);
  const handleUser = (e) => {
    setUser(e);
  };

  const { data: closersApi } = useSWR("auth/users/closers");
  const closers = [];
  if (closersApi?.users) {
    const usersLen = closersApi?.users.length
    for (let i = 0; i < usersLen; i++) {
      const closer = {
        value: closersApi?.users[i].id,
        label: closersApi?.users[i].name + ' ' + closersApi?.users[i].last_name
      }
      closers.push(closer);
    }
  }
  const [closer, setCloser] = useState(null);
  const handleCloser = (e) => {
    setCloser(e);
  };

  const [isSelfGen, setIsSelfGen] = useState(false);
  const handleIsSelfGen = (e) => {
    setIsSelfGen(!isSelfGen);
  };

  // const [date, setDate] = useState(format(new Date(), 'MM-dd-yyyy HH:mm'));
  // const handleDateChange = (e) => {
  //   setDate(e);
  // };

  const handleAddList = async (e) => {
    e.preventDefault();
    if (rName === "" || rLastName === "") {
      return Swal.fire(
        "Error",
        t('errors.fields_required'),
        "error"
      );
    }
    if (!isSelfGen) {
      if (!user) {
        return Swal.fire(
          "Error",
          'You must select a canvasser',
          "error"
        );
      }
    }
    if (!closer) {
      return Swal.fire(
        "Error",
        'You must select a closer',
        "error"
      );
    }
    if (!date) {
      return Swal.fire(
        "Error",
        'You must select a date',
        "error"
      );
    }
    const newDeal = {
      lead_name: rName,
      lead_last_name: rLastName,
      status: 'Closed',
      canvasser_id: isSelfGen ? closer?.value : user?.value,
      canvasser_name: isSelfGen ? closer?.label : user?.label,
      date,
      is_selfgen: isSelfGen,
      closerId: closer.value,
      closerName: closer.label,
    }
    setDealsToCreate(currentArray => [...currentArray, newDeal])
  };

  const handleCreate = async (e) => {
    if (dealsToCreate.length <= 0) {
      Swal.fire("Error", 'Deals list ', "error");
    } else {
      const createUserDeals = async () => {
        Swal.fire({
          title: 'Wait ...',
          onBeforeOpen() {
            Swal.showLoading()
          },
          onAfterClose() {
            Swal.hideLoading()
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false
        })
        const body = await fetchConToken(`auth/users/deals/create/massive`, { deals: dealsToCreate }, 'POST');
        if (body.ok) {
          Swal.close();
          Swal.fire(t('success.title'), "Deals created", "success");
          navigate('/');
        } else {
          Swal.fire("Error", body.msg, "error");
        }
      }
      createUserDeals();
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100">
      <div className="d-flex justify-content-center align-items-center text-light w-100">
        <div className="p-3 w-50">
          <h1 className="text-center h1">Add deal for create</h1>
          <div className="d-flex flex-column justify-content-center align-items-center w-100">
            <div className="w-100 d-flex flex-column align-items-center justify-content-center">
              <form onSubmit={handleAddList} className="w-95">
                <div className="form-group mb-1">
                  <label className='text-light'>Closer</label>
                  <div className="w-100 text-dark">
                    <Select placeholder={t('select.placeholder')} styles={colourStyles} options={closers} value={closer} onChange={handleCloser} />
                  </div>
                </div>
                <div className="form-group mb-1">
                  <label className='form-label text-light'>Self gen?</label>
                  <div className="mb-1 d-flex justify-content-center align-items-center form-control w-25 form-check form-switch">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value={isSelfGen}
                      checked={isSelfGen}
                      onChange={handleIsSelfGen}
                    />
                  </div>
                </div>
                {!isSelfGen &&
                  <div className="form-group mb-1">
                    <label className='text-light'>Canvasser</label>
                    <div className="w-100 text-dark">
                      <Select placeholder={t('select.placeholder')} styles={colourStyles} options={users} value={user} onChange={handleUser} />
                    </div>
                  </div>
                }
                <div className="form-group">
                  <label className='text-light'>{t('labels.date')}</label>
                  <DateTimePicker
                    onChange={handleDateChange}
                    value={date}
                    className="form-control mb-1"
                    format='MM-dd-yyyy HH:mm'
                  />
                </div>
                <div className="form-group mb-1">
                  <label>Lead Name</label>
                  <input
                    type="text"
                    className="mb-1 form-control"
                    placeholder='Name'
                    name="rName"
                    value={rName}
                    onChange={handleUserInputChange}
                  />
                </div>
                <div className="form-group mb-1">
                  <label>Lead Last Name</label>
                  <input
                    type="text"
                    className="mb-1 form-control"
                    placeholder='Last Name'
                    name="rLastName"
                    value={rLastName}
                    onChange={handleUserInputChange}
                  />
                </div>


                <div className="form-group d-flex justify-content-center mb-1">
                  <button type="submit" className="btn btn-primary btn-bright">
                    <i className="fa fa-plus"></i> Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="p-3 w-50">
          <h1 className="text-center h1">Deals to create</h1>
          <table className="table table-striped">
            <thead className="bg-dark text-light">
              <tr className='align-middle'>
                <th className="text-center small text-light">Date</th>
                <th className="text-center small text-light">Cancel Date</th>
                <th className="text-center small text-light">Lead</th>
                <th className="text-center small text-light">Canvasser</th>
                <th className="text-center small text-light">Closer</th>
                <th className="text-center small text-light">SelfGen</th>
              </tr>
            </thead>
            <tbody>
              {dealsToCreate.length > 0 && dealsToCreate.map((deal, i) => {
                return (
                  <tr key={"d" + i} className='align-middle bg-light'>
                    <td className="text-center small text-dark">{format(new Date(deal.date), 'MM-dd-yy hh:mm')}</td>
                    <td className="text-center small text-dark">{deal?.cancel_date ? format(new Date(deal?.cancel_date), 'MM-dd-yy hh:mm') : '-'}</td>
                    <td className="text-center small text-dark">{deal.lead_name} {deal.lead_last_name}</td>
                    <td className="text-center small text-dark">{deal.canvasser_name}</td>
                    <td className="text-center small text-dark">{deal.closerName}</td>
                    <td className="text-center text-dark">{deal.is_selfgen ? <i className='fa fa-check fa-2xl text-success'></i> : <i className='fa fa-minus fa-2xl text-grey'></i>}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="form-group d-flex justify-content-center mb-1">
            <button onClick={() => { handleCreate() }} type="submit" className="btn btn-success btn-bright">
              <i className="fa fa-plus"></i> Create deals
            </button>
          </div>
        </div>
      </div>
      <Link to="/" className="m-2">
        <button className="btn btn-light" title="Add deal" style={{ width: "150px" }}>
          <i className="fa fa-arrow-left"></i> Go back
        </button>
      </Link>
    </div>
  );
};
