import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { UsersSitDownsScreenByOffice } from "./UsersSitDownsScreenByOffice";
import { isMobile } from 'react-device-detect';
import { UsersSitDownsScreenAll } from './UsersSitDownsScreenAll';

export const UsersSitDownsScreen = ({ isAdmin }) => {
  return (
    <>
      <Tabs selectedTabClassName="bg-light text-dark rounded">
        <TabList className={isMobile ? 'text-center text-light font-speciallblue-tabmycards' : 'text-center text-light font-special-scroll-3'}>
          <Tab>All offices</Tab>
          <Tab>Boca Raton</Tab>
          <Tab>Bradenton</Tab>
          <Tab>Cape Coral</Tab>
          <Tab>Jacksonville</Tab>
          <Tab>Corporate Office</Tab>
          <Tab>Tampa office</Tab>
          <Tab>Strattonites</Tab>
          <Tab>Luis Felipe's Team</Tab>
          {/* <Tab>Historical</Tab> */}
        </TabList>
        <TabPanel>
          <UsersSitDownsScreenAll isAdmin={isAdmin} />
        </TabPanel>
        <TabPanel>
          <UsersSitDownsScreenByOffice isAdmin={isAdmin} office={'Boca Raton'} />
        </TabPanel>
        <TabPanel>
          <UsersSitDownsScreenByOffice isAdmin={isAdmin} office={'Bradenton'} />
        </TabPanel>
        <TabPanel>
          <UsersSitDownsScreenByOffice isAdmin={isAdmin} office={'Cape Coral'} />
        </TabPanel>
        <TabPanel>
          <UsersSitDownsScreenByOffice isAdmin={isAdmin} office={'Jacksonville'} />
        </TabPanel>
        <TabPanel>
          <UsersSitDownsScreenByOffice isAdmin={isAdmin} office={'Corporate Office'} />
        </TabPanel>
        <TabPanel>
          <UsersSitDownsScreenByOffice isAdmin={isAdmin} office={'Tampa office'} />
        </TabPanel>
        <TabPanel>
          <UsersSitDownsScreenByOffice isAdmin={isAdmin} office={'Strattonites'} />
        </TabPanel>
        <TabPanel>
          <UsersSitDownsScreenByOffice isAdmin={isAdmin} office={"Luis Felipe's Team"} />
        </TabPanel>
        {/* <TabPanel>
          <UsersDealsScreenHistoric isAdmin={isAdmin} />
        </TabPanel> */}
      </Tabs>
    </>
  );
};
