import { useSelector } from "react-redux";

export const TopLeaderboardNew = ({ date, myDeals, tierList, totalAmount }) => {
  const { role } = useSelector((state) => state.auth);
  return (
    <div>
      <div className='d-flex justify-content-center align-items-end'>
        {tierList.map((leader, index, arr) => {
          return (
            <div className="col position-relative" key={"leader" + leader.user.id}>
              {
                index === 0
                  ?
                  <div className="position-relative">
                    <div className="d-flex flex-column justify-content-end align-items-center container-silver-leader">
                      <span className="text-light text-bold text-1 mb-2"># {arr[1].position}</span>
                      <img style={{ objectFit: "cover" }} className='rounded-pill border border-info img-photo-leader' width={75} height={75} loading="lazy" src={arr[1].user.photo ? arr[1].user.photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} />
                      <img style={{ objectFit: "cover" }} className='rounded-pill img-medal' width={35} height={35} loading="lazy" src={arr[1].position === 1 ? 'https://res.cloudinary.com/drholseqp/image/upload/v1709815469/Oro_4x_qmbkw5.png' : 'https://res.cloudinary.com/drholseqp/image/upload/v1709815469/Plata_4x_yukiza.png'} />
                    </div>
                    <div className="border-2nd d-flex justify-content-center align-items-end">
                      <div className="bg-info w-40 silver-deals-letter">
                        <span className="text-primary-dark text-bold text-0-5">{arr[1].amount} {arr[1].amount > 1 ? 'DLS' : 'DL'}</span>
                      </div>
                      <div className="w-100 ps-2">
                        <span className={arr[1].user?.name.length > 6 ? "text-light fw-bold text-0-5 w-100" : "text-light fw-bold text-0-8 w-100"}>{arr[1].user?.name} {arr[1].user?.last_name}</span>
                      </div>
                    </div>
                  </div>
                  :
                  index === 1
                    ?
                    <div className="position-relative">
                      <div className="d-flex flex-column justify-content-end align-items-center container-gold-leader">
                        <span className="text-light text-bold text-1 mb-2"># {arr[0].position}</span>
                        <img style={{ objectFit: "cover" }} className='rounded-pill border border-info img-photo-leader' width={75} height={75} loading="lazy" src={arr[0].user.photo ? arr[0].user.photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} />
                        <img style={{ objectFit: "cover" }} className='rounded-pill img-medal' width={35} height={35} loading="lazy" src='https://res.cloudinary.com/drholseqp/image/upload/v1709815469/Oro_4x_qmbkw5.png' />
                      </div>
                      <div className="border-1st d-flex flex-column justify-content-end align-items-center">
                        <div className="bg-info rounded-pill w-50 mb-1">
                          <span className="text-primary-dark text-bold text-0-5">{arr[0].amount} {arr[0].amount > 1 ? 'DLS' : 'DL'}</span>
                        </div>
                        <span className={arr[0].user?.name.length > 6 ? "text-light fw-bold text-0-5 w-100" : "text-light fw-bold text-0-8 w-100"}>{arr[0].user?.name} {arr[0].user?.last_name}</span>
                      </div>
                    </div>
                    :
                    <div className="position-relative">
                      <div className="d-flex flex-column justify-content-end align-items-center container-bronze-leader">
                        <span className="text-light text-bold text-1 mb-2"># {arr[2].position}</span>
                        <img style={{ objectFit: "cover" }} className='rounded-pill border border-info img-photo-leader' width={75} height={75} loading="lazy" src={arr[2].user.photo ? arr[2].user.photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} />
                        <img style={{ objectFit: "cover" }} className='rounded-pill img-medal' width={35} height={35} loading="lazy" src={arr[2].position === 1 ? 'https://res.cloudinary.com/drholseqp/image/upload/v1709815469/Oro_4x_qmbkw5.png' : arr[2].position === 2 ? 'https://res.cloudinary.com/drholseqp/image/upload/v1709815469/Plata_4x_yukiza.png' : 'https://res.cloudinary.com/drholseqp/image/upload/v1709815469/Bronce_1_4x_orozre.png'} />
                      </div>
                      <div className="border-3rd d-flex justify-content-center align-items-end">
                        <div className="w-100 pe-2">
                          <span className={arr[2].user?.name.length > 6 ? "text-light fw-bold text-0-5 w-100" : "text-light fw-bold text-0-8 w-100"}>{leader.user?.name} {leader.user?.last_name}</span>
                        </div>
                        <div className="bg-info w-40 bronze-deals-letter">
                          <span className="text-primary-dark text-bold text-0-8">{arr[2].amount} {arr[2].amount > 1 ? 'DLS' : 'DL'}</span>
                        </div>
                      </div>
                    </div>
              }
            </div>
          )
        })}
      </div>
    </div>
  );
};
