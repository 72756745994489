import { isMobile } from 'react-device-detect';
import { LoadingSpinnerWhite } from '../../../ui/LoadingSpinnerWhite';
import { TopLeaderboardSitDown } from '../../../ranking/TopLeaderboardSitDown';

export const CloserSitDownsAdmin = ({ others, firsts, loading }) => {
  if (loading) {
    return <LoadingSpinnerWhite />
  }

  return (
    <div className='w-90 col'>
      {
        firsts.length === 3 &&
        <TopLeaderboardSitDown tierList={firsts} />
      }
      <div className={isMobile ? 'w-100 table-wrapper-leader' : 'w-100 table-wrapper-desktop-leader-2'}>
        {others && <table className={isMobile ? "table table-sm table-striped mt-3" : "table table-sm overflow-hidden mt-3"}>
          <>
            {others.length > 0 && others.map(rank => {
              return (
                <tbody key={rank.name}>
                  <tr className='rounded-3'>
                    <td className="text-1 text-center text-light fw-bold w-10">#{rank.position}</td>
                    <td className="text-1 text-center text-light w-80">{rank.name}</td>
                    <td className="text-1 text-center font-special-rank w-10">{rank.amount} SD</td>
                  </tr>
                </tbody>
              );
            })}
          </>
        </table>}
      </div>
    </div>
  );
};
