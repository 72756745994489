import { types } from "../types/types";

export const uiOpenModal = () => ({
    type: types.uiOpenModal
});
export const uiCloseModal = () => ({
    type: types.uiCloseModal
});
export const uiOpenModall = () => ({
    type: types.uiOpenModall
});
export const uiCloseModall = () => ({
    type: types.uiCloseModall
});
export const uiOpenModalll = () => ({
    type: types.uiOpenModalll
});
export const uiCloseModalll = () => ({
    type: types.uiCloseModalll
});
export const uiOpenModallll = () => ({
    type: types.uiOpenModallll
});
export const uiCloseModallll = () => ({
    type: types.uiCloseModallll
});
export const uiOpenModalllll = () => ({
    type: types.uiOpenModalllll
});
export const uiCloseModalllll = () => ({
    type: types.uiCloseModalllll
});
export const uiOpenModallllll = () => ({
    type: types.uiOpenModallllll
});
export const uiCloseModallllll = () => ({
    type: types.uiCloseModallllll
});
export const uiOpenModalBirth = () => ({
    type: types.uiOpenModalBirth
});
export const uiCloseModalBirth = () => ({
    type: types.uiCloseModalBirth
});
export const uiOpenModalBest = () => ({
    type: types.uiOpenModalBest
});
export const uiCloseModalBest = () => ({
    type: types.uiCloseModalBest
});
export const uiOpenModalResetPassword = () => ({
    type: types.uiOpenModalResetPassword
});
export const uiCloseModalResetPassword = () => ({
    type: types.uiCloseModalResetPassword
});
export const uiOpenModalAddDeal = () => ({
    type: types.uiOpenModalAddDeal
});
export const uiCloseModalAddDeal = () => ({
    type: types.uiCloseModalAddDeal
});
export const uiOpenModalAddNotes = () => ({
    type: types.uiOpenModalAddNotes
});
export const uiCloseModalAddNotes = () => ({
    type: types.uiCloseModalAddNotes
});
export const uiOpenModalEditDeal = () => ({
    type: types.uiOpenModalEditDeal
});
export const uiCloseModalEditDeal = () => ({
    type: types.uiCloseModalEditDeal
});
export const uiOpenModalAddSitDown = () => ({
    type: types.uiOpenModalAddSitDown
});
export const uiCloseModalAddSitDown = () => ({
    type: types.uiCloseModalAddSitDown
});
export const uiOpenModalTraining = () => ({
    type: types.uiOpenModalTraining
});
export const uiCloseModalTraining = () => ({
    type: types.uiCloseModalTraining
});
export const uiCloseModalPost = () => ({
    type: types.uiCloseModalPost
});
export const uiOpenModalPost = () => ({
    type: types.uiOpenModalPost
});
export const uiCloseModalMotivational = () => ({
    type: types.uiCloseModalMotivational
});
export const uiOpenModalMotivational = () => ({
    type: types.uiOpenModalMotivational
});
export const uiCloseModalVisionBoard = () => ({
    type: types.uiCloseModalVisionBoard
});
export const uiOpenModalVisionBoard = () => ({
    type: types.uiOpenModalVisionBoard
});
export const setCalendarDate = (date) => ({
    type: types.setCalendarDate,
    payload: date,
});
export const disableFixedBottom = (value) => ({
    type: types.disableFixedBottom,
    payload: value,
});
export const setSelectedCard = (card) => ({
    type: types.setSelectedCard,
    payload: card,
});
export const clearSelectedCard = () => ({
    type: types.clearSelectedCard,
});
export const showStory = (show) => ({
    type: types.showStory,
    payload: show,
});
export const setSelectedFight = (fight) => ({
    type: types.setSelectedFight,
    payload: fight
});
export const clearSelectedFight = () => ({
    type: types.clearSelectedFight
});
export const setUserDeal = (deal) => ({
    type: types.setUserDeal,
    payload: deal
});
export const clearUserDeal = () => ({
    type: types.clearUserDeal
});
export const setPost = (post) => ({
    type: types.setPost,
    payload: post
});
export const clearPost = () => ({
    type: types.clearPost
});
export const setNotes = (notes) => ({
    type: types.setNotes,
    payload: notes
});
export const setHotLeadId = (id) => ({
    type: types.setHotLeadId,
    payload: id
});