import { useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import Select from 'react-select';
import Swal from "sweetalert2";
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import { useForm } from "../../hooks/useForm";
import colourStyles from '../../helpers/selectStyles';
import Axios from "axios";
import { fetchSinToken } from "../../helpers/fetch";
import { format, isValid } from "date-fns";
import toPascalCase from "../../helpers/pascalCase";

const fetcher = (url) => fetchSinToken(url);

export const RegisterBirthdayScreen = () => {
  const { t } = useTranslation();

  const [file, setFile] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const handleFile = ({ target }) => {
    const filee = target.files[0];
    setFile(filee);
    if (filee) {
      // Crear una URL de objeto para la imagen seleccionada
      const imageUrl = URL.createObjectURL(filee);
      setImagePreview(imageUrl);
    }
  };

  // const datee = format(new Date(), 'MM-dd-yyyy HH:mm');

  // useEffect(() => {
  //   if (isValid(datee)) {
  //     // setBirthDate(datee)
  //     setStartDate(datee)
  //   }
  // }, []);

  const [userSelected, setUserSelected] = useState(false);

  const setRegisterManual = () => {
    setUserSelected(true);
  };

  const resetUser = () => {
    setUser(null);
    setUserSelected(false);
  };




  const [formRegisterValues, handleRegisterInputChange, reset] = useForm({
    rName: '',
    rLastname: '',
    rOther: ''
  });

  const { rName, rLastname, rOther } = formRegisterValues;

  const [office, setOffice] = useState(null);
  const [isOther, setIsOther] = useState(false);
  const offices = [
    { value: 'Boca Raton', label: 'Boca Raton' },
    { value: 'Bradenton', label: 'Bradenton' },
    { value: 'Cape Coral', label: 'Cape Coral' },
    { value: 'Jacksonville', label: 'Jacksonville' },
    { value: 'Backend', label: 'Backend' },
    { value: 'CallCenter', label: 'CallCenter' },
    { value: 'Other', label: 'Other' },
  ];

  const days = Array.from({ length: 31 }, (v, i) => ({ value: `${i + 1}`, label: `${i + 1}` }));
  const [dayBirth, setDayBirth] = useState(null);
  const handleDayBirth = (e) => {
    setDayBirth(e);
  };
  const months = Array.from({ length: 12 }, (v, i) => ({ value: `${i + 1}`, label: `${i + 1}` }));
  const [monthBirth, setMonthBirth] = useState(null);
  const handleMonthBirth = (e) => {
    setMonthBirth(e);
  };
  // const years = Array.from({ length: 2024 - 1900 + 1 }, (v, i) => ({ value: `${1900 + i}`, label: `${1900 + i}` }));
  const years = Array.from({ length: 2024 - 1900 + 1 }, (v, i) => ({
    value: `${2024 - i}`,
    label: `${2024 - i}`
  }));
  const [yearBirth, setYearBirth] = useState(null);
  const handleYearBirth = (e) => {
    setYearBirth(e);
  };

  const [dayJob, setDayJob] = useState(null);
  const handleDayJob = (e) => {
    setDayJob(e);
  };
  const [monthJob, setMonthJob] = useState(null);
  const handleMonthJob = (e) => {
    setMonthJob(e);
  };
  const [yearJob, setYearJob] = useState(null);
  const handleYearJob = (e) => {
    setYearJob(e);
  };

  // const [birthDate, setBirthDate] = useState("");
  // const handleBirthDateChange = (e) => {
  //   setBirthDate(e);
  // };

  // const [startDate, setStartDate] = useState("");
  // const handleStartDateChange = (e) => {
  //   setStartDate(e);
  // };

  const changePhoto = () => {
    setImagePreview(null);
    setFile(null);
  };

  const handleRegister = (e) => {
    e.preventDefault();
    setSubmitting(true);
    if (rName === '' || rLastname === '') {
      setSubmitting(false);
      return Swal.fire(
        "Error",
        t('errors.fields_required'),
        "error"
      );
    }

    if (dayBirth === null || monthBirth === null || yearBirth === null) {
      setSubmitting(false);
      return Swal.fire(
        "Error",
        "You must set your date of birth",
        "error"
      );
    }

    if (dayJob === null || monthJob === null || yearJob === null) {
      setSubmitting(false);
      return Swal.fire(
        "Error",
        "You must set your job start date",
        "error"
      );
    }

    if (office === null && rOther === '') {
      setSubmitting(false);
      return Swal.fire(
        "Error",
        "You must select an office",
        "error"
      );
    }

    const birthDate = new Date(yearBirth.value, monthBirth.value - 1, dayBirth.value);
    const startDate = new Date(yearJob.value, monthJob.value - 1, dayJob.value);
    let officeSelected = "";
    if (!isOther) {
      officeSelected = office ? office?.value : '';
    } else {
      officeSelected = rOther;
    }
    const fileUploadCloud = async () => {
      Swal.fire({
        title: 'Wait ...',
        onBeforeOpen() {
          Swal.showLoading()
        },
        onAfterClose() {
          Swal.hideLoading()
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false
      })
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "cardsapp");
      Axios.post("https://api.cloudinary.com/v1_1/drholseqp/upload", formData
      ).then((res) => {
        const startRegister = async (namee, lastnamee, office, photo, birth_date, date_started) => {
          const last_name = toPascalCase(lastnamee.trim());
          const name = toPascalCase(namee.trim());
          const body = await fetchSinToken(
            "auth/new/birthday",
            { name, last_name, office, photo, birth_date, date_started },
            "POST"
          );
          if (body.ok) {
            setSubmitting(false);
            Swal.fire("Success", body.msg, "success");
          } else {
            setSubmitting(false);
            Swal.fire("Error", body.msg, "error");
          }
        }
        startRegister(rName, rLastname, officeSelected, res.data.secure_url, birthDate, startDate);
      });
    }

    if (userSelected && imagePreview) {
      const startRegister = async (namee, lastnamee, office, photo, birth_date, date_started) => {
        const last_name = toPascalCase(lastnamee.trim());
        const name = toPascalCase(namee.trim());
        const body = await fetchSinToken(
          "auth/new/birthday",
          { name, last_name, office, photo, birth_date, date_started },
          "POST"
        );
        if (body.ok) {
          setSubmitting(false);
          Swal.fire("Success", body.msg, "success");
        } else {
          setSubmitting(false);
          Swal.fire("Error", body.msg, "error");
        }
      }
      startRegister(rName, rLastname, officeSelected, imagePreview, birthDate, startDate);
    } else {
      if (file !== null) {
        fileUploadCloud();
      } else {
        setSubmitting(false);
        Swal.fire("Error", 'You must upload a photo', "error");
      }
    }


  };
  const handleOffice = (e) => {
    setOffice(e);
    if (e.value === 'Other') {
      setIsOther(true);
    } else {
      setIsOther(false);
    }
  };

  const { data: usersApi } = useSWR("auth/users/notadmin", fetcher);
  const users = [];
  if (usersApi?.users) {
    const usersLen = usersApi?.users.length
    for (let i = 0; i < usersLen; i++) {
      const user = {
        value: usersApi?.users[i].id,
        label: usersApi?.users[i].name + ' ' + usersApi?.users[i].last_name,
        user: usersApi?.users[i]
      }
      users.push(user);
    }
  }
  const [user, setUser] = useState(null);
  const handleUser = (e) => {
    setUser(e);
    setUserSelected(true);
    reset({
      rName: e.user.name,
      rLastname: e.user.last_name,
    });
    setOffice(e.user.office ? { value: e.user.office, label: e.user.office } : null)
    setImagePreview(e.user.photo ? e.user.photo : null)
    if (e.user.date_started) {
      const getDateDay = new Date(e.user.date_started).getDate();
      const getDateMonth = new Date(e.user.date_started).getMonth() + 1;
      const getDateYear = new Date(e.user.date_started).getFullYear();
      setDayJob({ label: getDateDay, value: getDateDay });
      setMonthJob({ label: getDateMonth, value: getDateMonth });
      setYearJob({ label: getDateYear, value: getDateYear });
    }

    if (e.user.birth_date) {
      const getBirthDay = new Date(e.user.birth_date).getDate();
      const getBirthMonth = new Date(e.user.birth_date).getMonth() + 1;
      const getBirthYear = new Date(e.user.birth_date).getFullYear();
      setDayBirth({ label: getBirthDay, value: getBirthDay });
      setMonthBirth({ label: getBirthMonth, value: getBirthMonth });
      setYearBirth({ label: getBirthYear, value: getBirthYear });
    }
  };
  return (
    <>
      {
        isMobile
          ?
          <div className="container block-scroll">
            <h1 className="d-flex align-items-center justify-content-center mt-4" style={{ color: "#000" }}><img src={'https://res.cloudinary.com/drholseqp/image/upload/v1713815298/Azul_4x_xi9dte.png'} className="w-50" alt="Blue Energy Solar Logo" /></h1>
            <div className="row">
              <div className="col-md-11 col-11 login-form-1 m-3 pt-4">
                <form className="text-center" onSubmit={handleRegister}>
                  <label>Find yourself</label>
                  <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                    <div className="m-2 w-10">
                      <button type="button" onClick={resetUser} className="btn btn-dark"><i className="fa-solid fa-refresh fa-lg"></i></button>
                    </div>
                    <div className="w-100">
                      <Select placeholder={t('select.placeholder')} styles={colourStyles} options={users} value={user} onChange={handleUser} />
                    </div>
                  </div>
                  {
                    !userSelected &&
                    <div className="d-flex flex-column justify-content-center align-items-center w-100">
                      <span className="fw-bold mb-2">Can´t find you?</span>
                      <div>
                        <span className="fw-bold me-2">Register</span>
                        <button type="button" onClick={setRegisterManual} className="btn btn-dark mb-2">HERE</button>
                      </div>
                    </div>
                  }
                  {
                    userSelected &&
                    <>
                      <div>
                        <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                          <div className="m-2 w-10">
                            <i className="fa-solid fa-user fa-lg"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t('labels.first_name')}
                            name="rName"
                            value={rName}
                            onChange={handleRegisterInputChange}
                          />
                        </div>
                        <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                          <div className="m-2 w-10">
                            <i className="fa-solid fa-id-card fa-lg"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t('labels.last_name')}
                            name="rLastname"
                            value={rLastname}
                            onChange={handleRegisterInputChange}
                          />
                        </div>
                        <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                          <div className="m-2 w-10">
                            <i className="fa-solid fa-building fa-lg"></i>
                          </div>
                          <div className="w-100">
                            <Select placeholder='Select your office' styles={colourStyles} options={offices} value={office} onChange={handleOffice} />
                          </div>
                          {isOther &&
                            <input
                              type="text"
                              className="form-control"
                              placeholder='Enter your office'
                              name="rOther"
                              value={rOther}
                              onChange={handleRegisterInputChange}
                            />
                          }
                        </div>
                        <div className="form-group text-center mt-2">
                          <label className='text-dark'>Date of birth</label>
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="w-100">
                              <Select placeholder="Month" styles={colourStyles} options={months} value={monthBirth} onChange={handleMonthBirth} />
                            </div>
                            <div className="w-100">
                              <Select placeholder="Day" styles={colourStyles} options={days} value={dayBirth} onChange={handleDayBirth} />
                            </div>
                            <div className="w-100">
                              <Select placeholder="Year" styles={colourStyles} options={years} value={yearBirth} onChange={handleYearBirth} />
                            </div>
                          </div>
                          {/* <DateTimePicker
                        onChange={handleBirthDateChange}
                        value={birthDate}
                        className="form-control"
                        format='MM-dd-yyyy'
                        yearPlaceholder='YYYY'
                        monthPlaceholder='MM'
                        dayPlaceholder='DD'
                      /> */}
                        </div>
                        <div className="form-group text-center mt-2">
                          <label className='text-dark'>Job start date</label>
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="w-100">
                              <Select placeholder="Month" styles={colourStyles} options={months} value={monthJob} onChange={handleMonthJob} />
                            </div>
                            <div className="w-100">
                              <Select placeholder="Day" styles={colourStyles} options={days} value={dayJob} onChange={handleDayJob} />
                            </div>
                            <div className="w-100">
                              <Select placeholder="Year" styles={colourStyles} options={years} value={yearJob} onChange={handleYearJob} />
                            </div>
                          </div>
                          {/* <DateTimePicker
                        onChange={handleStartDateChange}
                        value={startDate}
                        className="form-control"
                        format='MM-dd-yyyy'
                        yearPlaceholder='YYYY'
                        monthPlaceholder='MM'
                        dayPlaceholder='DD'
                      /> */}
                        </div>

                        {
                          !imagePreview &&
                          <>
                            <div className="m-3 d-flex flex-column justify-content-center align-items-center">
                              <span className="text-2 text-center">Please upload a headshot photo. Use this image as a reference. Make sure your head and shoulders are clearly visible.</span>
                              <img
                                src={'https://res.cloudinary.com/drholseqp/image/upload/v1731083904/WhatsApp_Image_2024-11-08_at_13.22.32_jywipo.jpg'}
                                alt="Preview"
                                className="img-fluid img-thumbnail"
                                style={{ maxWidth: '300px', maxHeight: '300px' }} // Ajusta el tamaño de la imagen
                              />
                            </div>
                            <div className="form-group files m-2">
                              <h5 className="text-center text-bold">Select your photo</h5>
                              <input id="excelfile" className='bg-light ' type="file" onChange={(e) => handleFile(e)} accept="image/*" />
                            </div>
                          </>
                        }

                        {imagePreview && (
                          <div className="m-3 d-flex flex-column justify-content-center align-items-center">
                            <button className="btn btn-primary mb-3" onClick={changePhoto}>Change Photo</button>
                            <h5>Photo preview</h5>
                            <img
                              src={imagePreview}
                              alt="Preview"
                              className="img-fluid img-thumbnail"
                              style={{ maxWidth: '200px', maxHeight: '200px' }} // Ajusta el tamaño de la imagen
                            />
                          </div>
                        )}
                      </div>
                      <div className="form-group d-flex justify-content-center">
                        <input disabled={submitting} type="submit" className="btn btn-primary btn-bright" value={t('buttons.register')} />
                      </div>
                    </>
                  }
                </form>
              </div>

            </div>
          </div>
          :
          <div className="login-container" data-aos="fade-up" data-aos-duration="1000">
            <h1 className="d-flex align-items-center justify-content-center py-4" style={{ color: "#000" }}><img src={'https://res.cloudinary.com/drholseqp/image/upload/v1713815298/Azul_4x_xi9dte.png'} className="w-30" alt="Blue Energy Solar Logo" /></h1>
            <div className="row mt-2">
              <div className="col-md-6 col-6 me-5 login-form-3">
                <form onSubmit={handleRegister}>
                  <label>Find yourself</label>
                  <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                    <div className="m-2 w-10">
                      <button type="button" onClick={resetUser} className="btn btn-dark"><i className="fa-solid fa-refresh fa-lg"></i></button>
                    </div>
                    <div className="w-100">
                      <Select placeholder={t('select.placeholder')} styles={colourStyles} options={users} value={user} onChange={handleUser} />
                    </div>
                  </div>
                  {
                    !userSelected &&
                    <div className="d-flex flex-column justify-content-center align-items-center w-100">
                      <span className="fw-bold mb-2">Can´t find you?</span>
                      <div>
                        <span className="fw-bold me-2">Register</span>
                        <button type="button" onClick={setRegisterManual} className="btn btn-dark mb-2">HERE</button>
                      </div>
                    </div>
                  }
                  {
                    userSelected &&
                    <>
                      <div>
                        <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                          <div className="m-2 w-5">
                            <i className="fa-solid fa-user fa-lg"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t('labels.first_name')}
                            name="rName"
                            value={rName}
                            onChange={handleRegisterInputChange}
                          />
                        </div>
                        <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                          <div className="m-2 w-5">
                            <i className="fa-solid fa-id-card fa-lg"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t('labels.last_name')}
                            name="rLastname"
                            value={rLastname}
                            onChange={handleRegisterInputChange}
                          />
                        </div>
                        <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                          <div className="m-2 w-5">
                            <i className="fa-solid fa-building fa-lg"></i>
                          </div>
                          <div className="w-100">
                            <Select placeholder="Select your office" styles={colourStyles} options={offices} value={office} onChange={handleOffice} />
                          </div>
                          {isOther &&
                            <input
                              type="text"
                              className="form-control"
                              placeholder='Enter your office'
                              name="rOther"
                              value={rOther}
                              onChange={handleRegisterInputChange}
                            />
                          }
                        </div>
                        <div className="form-group">
                          <label className='text-dark'>Date of birth</label>
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="w-100">
                              <Select placeholder="Month" styles={colourStyles} options={months} value={monthBirth} onChange={handleMonthBirth} />
                            </div>
                            <div className="w-100">
                              <Select placeholder="Day" styles={colourStyles} options={days} value={dayBirth} onChange={handleDayBirth} />
                            </div>
                            <div className="w-100">
                              <Select placeholder="Year" styles={colourStyles} options={years} value={yearBirth} onChange={handleYearBirth} />
                            </div>
                          </div>
                          {/* <DateTimePicker
                        onChange={handleBirthDateChange}
                        value={birthDate}
                        className="form-control"
                        format='MM-dd-yyyy'
                        yearPlaceholder='YYYY'
                        monthPlaceholder='MM'
                        dayPlaceholder='DD'
                      /> */}
                        </div>
                        <div className="form-group">
                          <label className='text-dark'>Job start date</label>
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="w-100">
                              <Select placeholder="Month" styles={colourStyles} options={months} value={monthJob} onChange={handleMonthJob} />
                            </div>
                            <div className="w-100">
                              <Select placeholder="Day" styles={colourStyles} options={days} value={dayJob} onChange={handleDayJob} />
                            </div>
                            <div className="w-100">
                              <Select placeholder="Year" styles={colourStyles} options={years} value={yearJob} onChange={handleYearJob} />
                            </div>
                          </div>
                          {/* <DateTimePicker
                        onChange={handleStartDateChange}
                        value={startDate}
                        className="form-control"
                        format='MM-dd-yyyy'
                        yearPlaceholder='YYYY'
                        monthPlaceholder='MM'
                        dayPlaceholder='DD'
                      /> */}
                        </div>
                        {
                          !imagePreview &&
                          <>
                            <div className="m-3 d-flex flex-column justify-content-center align-items-center">
                              <span className="text-1 text-center">Please upload a headshot photo. Use this image as a reference. Make sure your head and shoulders are clearly visible.</span>
                              <img
                                src={'https://res.cloudinary.com/drholseqp/image/upload/v1731083904/WhatsApp_Image_2024-11-08_at_13.22.32_jywipo.jpg'}
                                alt="Preview"
                                className="img-fluid img-thumbnail"
                                style={{ maxWidth: '300px', maxHeight: '300px' }} // Ajusta el tamaño de la imagen
                              />
                            </div>

                            <div className="form-group files m-2">
                              <h5 className="text-center text-bold">Select your photo</h5>
                              <input id="excelfile" className='bg-light d-flex align-items-center justify-content-center' type="file" onChange={(e) => handleFile(e)} accept="image/*" />
                            </div>
                          </>
                        }
                        {imagePreview && (
                          <div className="m-3 d-flex flex-column justify-content-center align-items-center">
                            <button className="btn btn-primary mb-3" onClick={changePhoto}>Change Photo</button>
                            <h5>Photo preview</h5>
                            <img
                              src={imagePreview}
                              alt="Preview"
                              className="img-fluid img-thumbnail"
                              style={{ maxWidth: '300px', maxHeight: '300px' }} // Ajusta el tamaño de la imagen
                            />
                          </div>
                        )}
                      </div>
                      <div className="form-group d-flex justify-content-center">
                        <input disabled={submitting} type="submit" className="btn btn-primary btn-bright" value={'Finish'} />
                      </div>
                    </>
                  }
                </form>
              </div>
            </div>
          </div>
      }
    </>
  );
};
