import { useState } from 'react';
import { fetchConToken } from '../../helpers/fetch';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import './uploadexcel.css';
import { useTranslation } from 'react-i18next';
import colourStyles from '../../helpers/selectStyles';
import Select from 'react-select';

export const AssignCardCloser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const timeFrames = [
    { value: 'weekly', label: `${t('cards.time_frames.weekly')}` },
    { value: 'monthly', label: `${t('cards.time_frames.monthly')}` },
    { value: 'semiannual', label: `${t('cards.time_frames.semiannual')}` },
    { value: 'yearly', label: `${t('cards.time_frames.yearly')}` },
    { value: 'timeless', label: `${t('cards.time_frames.timeless')}` },
  ];
  const [timeFrame, setTimeFrame] = useState({ value: 'weekly', label: `${t('cards.time_frames.weekly')}` });
  const handleTimeFrame = (e) => {
    setTimeFrame(e);
  };

  const [date, setDate] = useState(format(new Date(), 'MM-dd-yyyy HH:mm'));
  const handleDateChange = (e) => {
    setDate(e);
  };

  const handleReturn = (e) => {
    navigate('/');
  };
  const handleUploadAndSave = () => {
    const uploadUser = async (type) => {
      const body = await fetchConToken(`userdata/assigncards/${type}/api/see`, { date }, 'POST');
      if (body.ok) {
        Swal.fire(t('success.title'), "Cards assigned", "success");
      } else {
        Swal.fire("Error", body.msg, "error");
      }
    }
    uploadUser(timeFrame.value);
  }
  return (
    <div className='container d-flex flex-column justify-content-center align-items-center mt-5'>
      <h1 className='text-light text-center'>Assign cards</h1>
      <div className="col-md-6 login-form-2 m-2">
        <div className="form-group mb-3">
          <label className='text-light'>{t('labels.time_frame')}</label>
          <div className="w-100">
            <Select placeholder={t('select.placeholder')} styles={colourStyles} options={timeFrames} value={timeFrame} onChange={handleTimeFrame} />
          </div>
        </div>

        {(timeFrame.value === 'daily' || timeFrame.value === 'weekly' || timeFrame.value === 'monthly') && <div className="form-group">
          <label className='text-light'>{t('labels.date')}</label>
          <DateTimePicker
            onChange={handleDateChange}
            value={date}
            className="form-control mb-2"
            format='MM-dd-yyyy HH:mm'
          />
        </div>}

        <div className='container d-flex flex-column justify-content-center align-items-center'>
          <button className='btn btn-success btn-bright' onClick={handleUploadAndSave}>{t('buttons.upload')}</button>
        </div>
      </div>
      <div className='container d-flex flex-column justify-content-center align-items-center m-2'>
        <button className="btn btn-dark btn-bright" onClick={handleReturn}>{t('buttons.return')}</button>
      </div>
    </div>);
};