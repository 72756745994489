import { LoadingSpinnerWhite } from "../ui/LoadingSpinnerWhite";
import { isMobile } from "react-device-detect";
import { format, isValid } from "date-fns";
import { useEffect, useState } from "react";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { fetchConToken } from "../../helpers/fetch";

export const CallCenterAdminScreenByDate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const [date, setDate] = useState(["", ""]);

  const datee = format(new Date(), 'MM-dd-yyyy HH:mm');
  useEffect(() => {
    if (isValid(datee)) {
      setDate([datee, datee])
    }
  }, []);

  const onDateChange = async (e) => {
    setDate(e)
    setIsLoading(true);
    if (e) {
      const body = await fetchConToken(`callcenter/bydateunique`, { date: e }, 'POST');
      setData(body.userHours);
      setIsLoading(false);
    }
  }

  const formatDateTz = (date) => {
    const dt = new Date(date);
    const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
    return format(dtDateOnly, 'eee dd-MM-yy');
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      {/* <Link to="/userscallcenter">
        <button className="btn btn-success w-100" title="Manage users callcenter" style={{ width: "150px" }}>
          <i className="fa fa-cog"></i> Manage Users
        </button>
      </Link>
      <div className="form-group mb-2">
        <label className='text-light'>Select user</label>
        <div className="w-100">
          <Select placeholder='Select...' styles={colourStyles} options={users} value={user} onChange={handleUser} />
        </div>
      </div>
      <h2 className="text-light font-special-total-deals-small">Hours register</h2>
      <h2 className="text-light font-special-total-deals-small">Total Hours: {totalHours}</h2>
      <div className="m-2">
        <Link to="/addhours">
          <button className="btn btn-success" title="Add post" style={{ width: "150px" }}>
            <i className="fas fa-calendar-plus"></i>
          </button>
        </Link>
      </div> */}
      <div className="form-group">
        <DateRangePicker className="form-control" onChange={(e) => { onDateChange(e) }} value={date} />
      </div>
      <div className='container-fluid m-2 w-100'>
        {
          isLoading
            ?
            <LoadingSpinnerWhite disableCenter={true} />
            :
            <div className="vw-100 ps-4">
              {
                data.length > 0 &&
                <div className='table-responsive vh-80'>
                  <table className={isMobile ? "d-block overflow-scroll table table-sm table-bordered table-striped bg-light" : "table table-bordered table-striped table-hover bg-light"}>
                    <thead className='bg-dark text-light align-middle'>
                      <tr>
                        <th className="text-center" scope="col">User</th>
                        <th className="text-center" scope="col">Day</th>
                        <th className="text-center" scope="col">Hours Count</th>
                        <th className="text-center" scope="col">8:00 - 8:30</th>
                        <th className="text-center" scope="col">8:30 - 9:00</th>
                        <th className="text-center" scope="col">9:00 - 9:30</th>
                        <th className="text-center" scope="col">9:30 - 10:00</th>
                        <th className="text-center" scope="col">10:00 - 10:30</th>
                        <th className="text-center" scope="col">10:30 - 11:00</th>
                        <th className="text-center" scope="col">11:00 - 11:30</th>
                        <th className="text-center" scope="col">11:30 - 12:00</th>
                        <th className="text-center" scope="col">12:00 - 12:30</th>
                        <th className="text-center" scope="col">12:30 - 13:00</th>
                        <th className="text-center" scope="col">13:00 - 13:30</th>
                        <th className="text-center" scope="col">13:30 - 14:00</th>
                        <th className="text-center" scope="col">14:00 - 14:30</th>
                        <th className="text-center" scope="col">14:30 - 15:00</th>
                        <th className="text-center" scope="col">15:00 - 15:30</th>
                        <th className="text-center" scope="col">15:30 - 16:00</th>
                        <th className="text-center" scope="col">16:00 - 16:30</th>
                        <th className="text-center" scope="col">16:30 - 17:00</th>
                        <th className="text-center" scope="col">17:00 - 17:30</th>
                        <th className="text-center" scope="col">17:30 - 18:00</th>
                        <th className="text-center" scope="col">18:00 - 18:30</th>
                        <th className="text-center" scope="col">18:30 - 19:00</th>
                        <th className="text-center" scope="col">19:00 - 19:30</th>
                        <th className="text-center" scope="col">19:30 - 20:00</th>
                        <th className="text-center" scope="col">20:00 - 20:30</th>
                        <th className="text-center" scope="col">20:30 - 21:00</th>
                        <th className="text-center" scope="col">21:00 - 21:30</th>
                      </tr>
                    </thead>
                    <>
                      {data.length > 0 && data.map(user => {
                        return (
                          <tbody key={user.id}>
                            <tr>
                              <td className="text-center text-nowrap align-middle text-bold">{user.name}</td>
                              <td className="text-center text-nowrap align-middle text-bold">{formatDateTz(user.date)}</td>
                              <td className="text-center align-middle">{user.hour?.hours_count}</td>
                              <td className={user.hour?.eight ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.eight ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.eight3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.eight3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.nine ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.nine ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.nine3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.nine3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.ten ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.ten ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.ten3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.ten3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.eleven ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.eleven ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.eleven3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.eleven3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.twelve ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.twelve ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.twelve3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.twelve3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.thirteen ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.thirteen ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.thirteen3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.thirteen3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.fourteen ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.fourteen ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.fourteen3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.fourteen3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.fifteen ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.fifteen ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.fifteen3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.fifteen3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.sixteen ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.sixteen ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.sixteen3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.sixteen3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.seventeen ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.seventeen ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.seventeen3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.seventeen3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.eighteen ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.eighteen ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.eighteen3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.eighteen3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.nineteen ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.nineteen ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.nineteen3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.nineteen3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.twenty ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.twenty ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.twenty3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.twenty3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                              <td className={user.hour?.twentyone ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.twentyone ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </>
                  </table>
                </div>
              }
            </div>
        }
      </div>
    </div>
  );
};
