import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
// import { GraphCloserAllTimeTab } from './GraphCloserAllTimeTab';
// import { GraphCloserPreviousWeekTab } from './GraphCloserPreviousWeekTab';
// import { GraphCloserActualWeekTab } from './GraphCloserActualWeekTab';
// import { GraphCloserActualMonthTab } from './GraphCloserActualMonthTab';
// import { GraphCloserCustomTab } from './GraphCloserCustomTab';
// import { GraphCloserOfficeTab } from './GraphCloserOfficeTab';
// import { GraphCloser } from './GraphCloser';
import { GraphAllTime } from './GraphAllTime';
import { GraphCustom } from './GraphCustom';
import { GraphActualMonth } from './GraphActualMonth';
import { GraphActualWeek } from './GraphActualWeek';
import { GraphPreviousWeek } from './GraphPreviousWeek';

export const GraphCloserTab = ({ id }) => {
  const { t } = useTranslation();

  return (
    <div className="text-center">
      {/* <GraphCloser /> */}
      {/* <GraphCloserAllTimeTab id={id} /> */}
      <Tabs selectedTabClassName="bg-primary-dark text-light rounded-pill">
        <TabList className={isMobile ? 'text-light font-speciallblue-tabmycards scrollable-container-mobile' : 'd-flex justify-content-center align-items-center text-light font-special-scroll scrollable-container'}>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>All time</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>Previous week</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>Actual week</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>Actual month</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>Custom</Tab>
        </TabList>

        <TabPanel>
          <GraphAllTime id={id} />
          {/* <GraphCloserAllTimeTab id={id} /> */}
        </TabPanel>
        <TabPanel>
          <GraphPreviousWeek id={id} />
          {/* <GraphCloserPreviousWeekTab id={id} /> */}
        </TabPanel>
        <TabPanel>
          <GraphActualWeek id={id} />
          {/* <GraphCloserActualWeekTab id={id} /> */}
        </TabPanel>
        <TabPanel>
          <GraphActualMonth id={id} />
          {/* <GraphCloserActualMonthTab id={id} /> */}
        </TabPanel>
        <TabPanel>
          <GraphCustom id={id} />
          {/* <GraphCloserCustomTab id={id} /> */}
        </TabPanel>
      </Tabs>
    </div>
  );
};
