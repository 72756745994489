import { useCallback } from "react";
import Modal from "react-modal";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "./addnotesmodal.css";
import { useForm } from "../../../hooks/useForm";
import { fetchConToken } from "../../../helpers/fetch";
import { uiCloseModalAddNotes } from "../../../actions/ui";
import { format } from "date-fns";

const customStyles = {};
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

export const AddNotesModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { modalOpenAddNotes, notes, hotlead_id } = useSelector((state) => state.ui);
  const [formUserValues, handleUserInputChange] = useForm({
    rNotes: '',
  });

  const { rNotes } = formUserValues;

  const closeModal = useCallback(() => {
    dispatch(uiCloseModalAddNotes());
  }, [dispatch]);

  const handleCreate = async (e) => {
    e.preventDefault();
    if (rNotes === "") {
      return Swal.fire(
        "Error",
        t('errors.fields_required'),
        "error"
      );
    }
    const addNote = async (notess) => {
      const body = await fetchConToken(`calltools/hotleads/addnotes`, { id: hotlead_id, notes: notess }, 'POST');
      if (body.ok) {
        closeModal();
        Swal.fire(t('success.title'), "Notes added", "success");
      } else {
        Swal.fire("Error", body.msg, "error");
      }
    }
    addNote(rNotes);
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Modal
        isOpen={modalOpenAddNotes}
        onRequestClose={closeModal}
        style={customStyles}
        closeTimeoutMS={200}
        className="addnotesmodal"
        ariaHideApp={process.env.NODE_ENV === "test"}
      >
        <div className="p-3">
          <div className="d-flex flex-column justify-content-center align-items-center text-center">
            <b style={{ fontSize: "30px" }}>Add Notes</b>
          </div>
          <hr className="border border-dark" />
          <div className="d-flex flex-column justify-content-center align-items-center w-100">
            <div className="w-100 d-flex flex-column align-items-center justify-content-center">
              <form onSubmit={handleCreate} className="w-95">
                <div className="form-group mb-2">
                  <label>Notes</label>
                  <textarea
                    type="text"
                    className="mb-2 form-control"
                    placeholder='Notes...'
                    name="rNotes"
                    value={rNotes}
                    onChange={handleUserInputChange}
                  />
                </div>
                <div className="form-group d-flex justify-content-center mb-1">
                  <button type="submit" className="btn btn-primary btn-bright">
                    <i className="fa fa-floppy-disk"></i> {t('buttons.save')}
                  </button>
                </div>
              </form>
            </div>
          </div>
          {notes && notes.length > 0 &&
            <>
              <hr className="border border-dark" />
              <div className="d-flex flex-column justify-content-center align-items-center text-center">
                <span className="text-dark text-bold">Notes:</span>
                <div className="w-100 d-flex flex-column justify-content-start align-items-center table-wrapper-leader">
                  {
                    notes.map((note, index) => {
                      return (
                        <div key={index} className="p-2 rounded-3 border border-dark border-2 w-100 row">
                          <span className="col-3">{format(new Date(note.date), 'dd-MM-yy hh:ss')}</span>
                          <div className="col">
                            <span className="text-wrap text-break">{note.note.notes}</span>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </>
          }
        </div>
      </Modal>
    </div>
  );
};
