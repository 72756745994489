import { useEffect } from 'react';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import useSWR from "swr";
import { fetchConToken } from '../../../../helpers/fetch';
import { LoadingSpinnerWhite } from '../../../ui/LoadingSpinnerWhite';
import { CloserDealsAdmin } from './CloserDealsAdmin';
import { CanvasserDealsAdmin } from './CanvasserDealsAdmin';
import { CanvasserDealsAdmin2 } from './CanvasserDealsAdmin2';
import { CloserDealsAdmin2 } from './CloserDealsAdmin2';
import { CloserSitDownsAdmin } from './CloserSitDownsAdmin';

export const AdminStatsSitDownsGeneral = ({ cancels, id, office }) => {
  // const { data: dataTotal } = useSWR(`userdata/usersmanualdata/month/${cancels}`);
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const date = new Date();
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  // useEffect(() => {
  //   setTotal(dataTotal?.total)
  // }, [dataTotal]);

  const goBack = () => {
    navigate('/statsdata');
  }

  const [totalCloser, setTotalCloser] = useState(0);
  const [othersCloser, setOthersCloser] = useState([]);
  const [firstsCloser, setFirstsCloser] = useState([]);
  const [totalCanva, setTotalCanva] = useState(0);
  const [othersCanva, setOthersCanva] = useState([]);
  const [firstsCanva, setFirstsCanva] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(async () => {
    // const body = await fetchConToken(`userdata/usersmanualdata/month`);
    // setTotalCloser(body.total);
    // setFirstsCloser(body.firsts);
    // setOthersCloser(body.others);
    // const bodi = await fetchConToken(`userdata/usersmanualdata/canvasser/month`);
    // setTotalCanva(bodi.total);
    // setFirstsCanva(bodi.firsts);
    // setOthersCanva(bodi.others);


    const bode = await fetchConToken(`userdata/usersmanualautomatic/sitdown/month`);
    setTotal(bode.total);
    setTotalCloser(0);
    setFirstsCloser(bode.firsts);
    setOthersCloser(bode.others);
    const bodu = await fetchConToken(`userdata/usersmanualautomatic/sitdown/canvasser/month`);
    setTotalCanva(0);
    setFirstsCanva(bodu.firsts);
    setOthersCanva(bodu.others);
    setIsLoading(false);
    // const body = await fetchConToken(`auth/user/deals/${id}`);
    // setMyDeals(body.myDeals);
  }, []);

  return (
    <div className={isMobile ? 'bg-gray vh-110' : 'bg-gray'}>
      {
        isLoading
          ?
          <LoadingSpinnerWhite />
          :
          <>
            {
              isMobile
                ?
                <div className='position-relative'>
                  <div className='d-flex justify-content-center align-items-center pt-2'>
                    <button onClick={goBack} className='btn btn-light'><i className='fa fa-chevron-left'></i></button>
                  </div>
                  <div className='d-flex flex-column justify-content-center align-items-center text-center text-admin-stats'>
                    <span className='font-special-total-deals m-5'>{monthNames[date.getMonth()]}</span>
                    <span className='font-special-total-deals'>TOTAL SALES</span>
                    {/* <div className='bg-primary-dark rounded-3 p-4 m-3'>
                <span className='font-special-total-deals-value'>{total}</span>
              </div> */}
                  </div>
                  <img className='logo-be-admin-stats' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694440370/images-logos/001_sc2glr.png'} style={{ objectFit: "contain" }} width={200} height={100} alt="back" />
                  <img className='logo-be-admin-stats-2' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704224590/Asset_2_zo1wyw.png'} style={{ objectFit: "contain" }} width={200} height={100} alt="back" />
                </div>
                :
                <div className='w-100 text-center vh-110 position-relative pt-5'>
                  <img className='logo-stats-decoration pt-5' src={'https://res.cloudinary.com/drholseqp/image/upload/v1706713981/graysun_1_jjfjyd.png'} style={{ objectFit: "contain" }} alt="back" />
                  <div className='row pt-2 vw-100 position-absolute'>
                    <div className='col-12 row'>
                      <div className='col-6 d-flex flex-column justify-content-center align-items-center rounded-3'>
                        <img className='img-stats' src={'https://res.cloudinary.com/drholseqp/image/upload/v1706712470/10_o71gp4.png'} style={{ objectFit: "contain" }} alt="back" />
                      </div>
                      <div className='col-6 d-flex flex-column justify-content-center align-items-center mb-4'>
                        <div className='d-flex flex-column align-items-center justify-content-center w-50 p-1 rounded-3'>
                          <span className='text-1-5 text-primary-dark text-justified'>{monthNames[date.getMonth()]}</span>
                          <span className='text-1-5 text-primary-dark text-justified text-bold'><b className='text-primary text-bold text-3'>{total}</b> Sit Downs</span>
                        </div>
                      </div>
                    </div>
                    <div className='col ms-5 pt-4 row bg-primary rounded-3'>
                      <span className='text-light text-2 verticaltext2 w-12'><b className='text-bold'>Top</b> Closers</span>
                      <CloserSitDownsAdmin date={date} myDeals={0} total={totalCloser} others={othersCloser} firsts={firstsCloser} loading={false} id={id} />
                    </div>
                    <div className='col mx-5 pt-4 row bg-primary rounded-3'>
                      <span className='text-light text-2 verticaltext2 w-12'><b className='text-bold'>Top</b> Canvassers</span>
                      {/* <CanvasserDealsAdmin2 date={date} myDeals={0} total={totalCanva} others={othersCanva} firsts={firstsCanva} loading={false} id={id} /> */}
                      <CloserSitDownsAdmin date={date} myDeals={0} total={totalCanva} others={othersCanva} firsts={firstsCanva} loading={false} id={id} />
                    </div>
                  </div>
                </div>
            }
          </>
      }
    </div>
  );
};