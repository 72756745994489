import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import useSWR from "swr";
import { Calendar } from "react-multi-date-picker";
import "react-multi-date-picker/styles/layouts/mobile.css"
import { LoadingSpinnerWhite } from '../ui/LoadingSpinnerWhite';
import { format, isEqual, isValid } from 'date-fns';

export const CalendarBirthdayScreen = ({ }) => {
  const { data, isLoading } = useSWR(`auth/users/birthday`);
  const [birthdays, setBirthdays] = useState([]);
  const [users, setUsers] = useState([]);
  const [userFinded, setUserFinded] = useState(null);
  useEffect(() => {
    if (data?.birthdays.length > 0) {
      setBirthdays(data?.birthdays);
      setUsers(data?.users);
    }
  }, [data]);

  const showUser = (a, date) => {
    const daySelected = date?.day;
    const monthSelected = date?.month?.number - 1;
    const yearSelected = date?.year;
    const selectedDate = new Date(yearSelected, monthSelected, daySelected)
    if (isValid(selectedDate)) {
      setUserFinded(null);

      const userFindedd = users.find((user) => {
        const userDate = new Date(user.date);
        userDate.setHours(0);
        return isEqual(userDate, selectedDate)
      })
      if (userFindedd) {
        setUserFinded(userFindedd);
      }
    }
  }

  return (
    <div>
      {
        isMobile
          ?
          <div className='w-100 d-flex flex-column justify-content-center align-items-center text-center mt-3'>
            <span className='font-special-total-deals-small m-3'>Birthday Calendar</span>
            <span className='font-special-total-deals-supersmall'>Double-tap for display birthday user</span>
            {
              isLoading ?
                <LoadingSpinnerWhite />
                :
                <Calendar multiple className={isMobile ? "rmdp-mobile mt-3" : " mt-3"} value={birthdays} onFocusedDateChange={showUser} />
            }
            {userFinded &&
              <div className='d-flex flex-column align-items-center justify-content-center m-3'>
                <img style={{ objectFit: "cover" }} className='rounded-pill' src={userFinded.photo ? userFinded.photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1694003940/images-logos/user_pyqy6e.png'} width={100} height={100} />
                <span className='font-special-total-deals-small mt-3'>{userFinded.name}</span>
              </div>
            }
          </div>
          :
          <div className='w-100 text-center d-flex flex-column justify-content-center align-items-center'>
            <div className='w-100 row d-flex justify-content-center align-items-center px-5'>
              <div className='w-100 d-flex flex-column justify-content-center align-items-center text-center mt-3 col'>
                <span className='font-special-total-deals-small m-3'>Birthday Calendar</span>
                <span className='font-special-total-deals-supersmall'>Double-tap for display birthday user</span>
                {
                  isLoading ?
                    <LoadingSpinnerWhite />
                    :
                    <Calendar multiple className={isMobile ? "rmdp-mobile mt-3" : " mt-3"} value={birthdays} onFocusedDateChange={(dateFocused, dateClicked) => { showUser(dateFocused, dateClicked) }} />
                }
              </div>
              <div className='col pt-5'>
                {userFinded &&
                  <div className='d-flex flex-column align-items-center justify-content-center m-3'>
                    <img style={{ objectFit: "cover" }} className='rounded-pill' src={userFinded.photo ? userFinded.photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1694003940/images-logos/user_pyqy6e.png'} width={100} height={100} />
                    <span className='font-special-total-deals-small mt-3'>{userFinded.name}</span>
                    <span className='fs-6 text-light mt-3'>{format(new Date(userFinded.date), 'MM-dd-yyyy')}</span>
                  </div>
                }
              </div>
            </div>
          </div>
      }
    </div>
  );
};
