import { useRef } from 'react';

export const CarouselCards = ({ card, i, classy }) => {
  const cardEl = useRef(null);
  const classDisabled = card.gotByUser ? "" : `card-disabledd`
  return (
    <div className={`${classy}`}>
      <div className={`rounded d-flex flex-row justify-content-center align-items-center m-2`}>
        {card.url
          ?
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div className='deck-progress d-flex justify-content-center align-items-center'>
              <div ref={cardEl} className={`card-progress ${card.type} ${classDisabled}`}>
                <div className={`front-progress face-progress`}>
                  {card.gotByUser ? "" : <i className="fas fa-lock text-light fa-2xl lock-icon-always"></i>}
                  <img src={card.url} style={{ objectFit: "cover", width: "100%", height: "100%" }} width={250} height={350} alt="Card" />
                </div>
                <div className="back-progress face-progress">
                </div>
              </div>
            </div>
            <span className='text-primary font-speciall'>X {card.amountGotted}</span>
            <span className='text-primary font-speciall'>{card.amountGotted * card.value} <img width={100} height={100} src={'https://res.cloudinary.com/drholseqp/image/upload/v1694460701/blucoin_front_s8z8er.png'} /></span>
          </div>
          :
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div className='deck-carousel d-flex justify-content-center align-items-center'>
              <div ref={cardEl} className={`card ${card.type} ${classDisabled}`}>
                <div className="front face">
                  <div className='card-header d-flex justify-content-between text-white rounded'>
                    <img src={'https://res.cloudinary.com/drholseqp/image/upload/v1692713939/images-logos/log_g44rdw.webp'} alt="Logo" style={{ objectFit: "contain" }} width={40} height={40} />
                    <div className={`bg-${card.type} rounded d-flex justify-content-center align-items-center p-1`}>
                      <i className='fas fa-hand-fist me-2'></i>
                      <h4 className="card-text ">{card.value}</h4>
                    </div>
                  </div>
                  <div className='d-flex justify-content-center align-items-center' style={{ height: "150px", width: "250px" }}>
                    <i className={`fas fa-bolt color-${card.type} fa-2xl fa-beat`}></i>
                    {card.gotByUser ? "" : <i className="fas fa-lock text-dark fa-2xl lock-icon-always"></i>}
                  </div>
                  <div className={`card-body bg-${card.type} text-white rounded`}>
                    <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: "50px" }}>
                      <h5 className="card-title fw-bold"> {card.description}</h5>
                    </div>
                    <hr />
                    <div className='d-flex flex-row align-items-center justify-content-evenly '>
                      <h6>{card.time_frame}</h6>
                    </div>
                  </div>
                </div>
                <div className="back face">
                </div>
              </div>
            </div>
            <span className='text-primary font-speciall'>X {card.amountGotted}</span>
            <span className='text-primary font-speciall'>{card.amountGotted * card.value} <img width={100} height={100} src={'https://res.cloudinary.com/drholseqp/image/upload/v1694460701/blucoin_front_s8z8er.png'} /></span>
          </div>
        }
      </div>
    </div>
  );
};
