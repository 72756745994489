import { useCallback, useState } from "react";
import Modal from "react-modal";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { uiCloseModal, uiOpenModalll } from "../../actions/ui";
import { useForm } from "../../hooks/useForm";
import { fetchConToken } from "../../helpers/fetch";
import { login, startGetUser } from "../../actions/auth";
import "./flmodal.css";

const customStyles = {};
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

export const FirstModalModal = ({ data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { modalOpen } = useSelector((state) => state.ui);

  const [isHidden, setIsHidden] = useState(true);
  const [formUserValues, handleUserInputChange] = useForm({
    rPassword: '',
    rPasswordConfirm: '',
  });

  const { rPassword, rPasswordConfirm } = formUserValues;

  const closeModal = useCallback(() => {
    dispatch(uiCloseModal());
    dispatch(uiOpenModalll());
  }, [dispatch]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (rPassword === "" || rPasswordConfirm === "") {
      return Swal.fire(
        "Error",
        t('errors.fields_required'),
        "error"
      );
    }
    if (rPassword !== rPasswordConfirm) {
      return Swal.fire(
        "Error",
        t('errors.password_match'),
        "error"
      );
    }
    const uploadUser = async () => {
      const body = await fetchConToken("auth/updatefirstlogin", { id: data.uid, password: rPassword }, 'POST');
      if (body.ok) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("token-init-date", new Date().getTime());
        dispatch(login({ uid: body.uid, name: body.name, role: body.role, office: body.office, photo: body.photo, points: body.points }));
        dispatch(startGetUser(body.uid));
        closeModal();
      } else {
        Swal.fire("Error", body.msg, "error");
      }
    }
    uploadUser();
  };

  const setHidden = () => {
    if (isHidden) {
      setIsHidden(false);
    } else {
      setIsHidden(true);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        closeTimeoutMS={200}
        className="flmodal"
        ariaHideApp={process.env.NODE_ENV === "test"}
      >
        <div className="d-flex flex-column justify-content-center align-items-center text-center">
          <b style={{ fontSize: "30px" }}>Please change your password</b>
        </div>
        <hr className="border border-dark" />
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
          <div className="w-100 d-flex flex-column align-items-center justify-content-center">
            <form onSubmit={handleUpdate} className="w-95">
              <div className="form-group mb-2">
                <label>{t('labels.password')}</label>
                <div className='d-flex flex-row align-items-center'>
                  <input
                    type={isHidden ? "password" : "text"}
                    className="mb-2 form-control"
                    placeholder={t('labels.password')}
                    name="rPassword"
                    value={rPassword}
                    autoComplete="new-password"
                    onChange={handleUserInputChange}
                  />
                  <button style={{ width: "15%", height: "15%" }} className={isHidden ? 'btn btn-success btn-sm mb-2 ms-1' : 'btn btn-danger btn-sm mb-2 ms-1'} onClick={setHidden} type="button">{isHidden ? <i className="fa-solid fa-eye"></i> : <i className="fa-solid fa-eye-slash"></i>}</button>
                </div>
              </div>
              <div className="form-group mb-2">
                <label>{t('labels.confirm_password')}</label>
                <div className='d-flex flex-row align-items-center'>
                  <input
                    type={isHidden ? "password" : "text"}
                    className="mb-2 form-control"
                    placeholder={t('labels.confirm_password')}
                    name="rPasswordConfirm"
                    value={rPasswordConfirm}
                    autoComplete="new-password"
                    onChange={handleUserInputChange}
                  />
                  <button style={{ width: "15%", height: "15%" }} className={isHidden ? 'btn btn-success btn-sm mb-2 ms-1' : 'btn btn-danger btn-sm mb-2 ms-1'} onClick={setHidden} type="button">{isHidden ? <i className="fa-solid fa-eye"></i> : <i className="fa-solid fa-eye-slash"></i>}</button>
                </div>
              </div>
              <div className="form-group d-flex justify-content-center mb-1">
                <button type="submit" className="btn btn-primary btn-bright">
                  <i className="fa fa-floppy-disk"></i> {t('buttons.save')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};
