import useSWR from "swr";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { LoadingSpinnerWhite } from '../ui/LoadingSpinnerWhite';
import { LoadingSpinnerBlue } from "../ui/LoadingSpinnerBlue";

export const StatsActualMonthTab = ({ id }) => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState(null);
  const { data, isLoading } = useSWR(`userdata/userdatahistory/actual/month/${id}`);

  useEffect(() => {
    setUserData(data?.userData);
  }, [data])

  return (
    <div className="text-center mb-3 w-100">
      {
        isLoading
          ?
          <LoadingSpinnerBlue disableCenter={true} />
          :
          <div>
            {
              userData &&
              <div className="d-flex flex-column justify-content-center align-items-center font-stats">
                <div className="d-flex flex-column justify-content-center align-items-center m-2 p-1 bg-primary rounded-3 w-50">
                  <label>Appointments Set</label>
                  <span>{userData.appointments_set}</span>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center m-2 p-1 bg-primary rounded-3 w-50">
                  <label>Doors knocked</label>
                  <span>{userData.doors_knocked}</span>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center m-2 p-1 bg-primary rounded-3 w-50">
                  <label>Effective doors </label>
                  <span>{userData.app_doors_percentage ? userData.app_doors_percentage?.toFixed(2) + " %" : 0 + " %"}</span>
                </div>
              </div>
            }
          </div>
      }
    </div>
  );
};
