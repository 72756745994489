
import { LoadingSpinnerBlue } from '../../ui/LoadingSpinnerBlue';
import { useState } from 'react';
import { ProjectGraphCircular } from './ProjectGraphCircular';
import { ProjectGraphBars } from './ProjectGraphBars';

export const ProjectGraph = ({ data, loading }) => {
  const [mode, setMode] = useState('circular');

  const handleMode = (mode) => {
    setMode(mode);
  }

  if (loading) {
    return <LoadingSpinnerBlue disableCenter={true} />
  }

  return (
    <div className='pb-5 mb-5'>
      <div className="d-flex flex-column justify-content-center align-items-center rounded-3 p-2">
        <div className="d-flex gap-2">
          <button className="btn btn-primary" onClick={() => { handleMode('circular') }}>Circular</button>
          <button className="btn btn-primary" onClick={() => { handleMode('bars') }}>Bars</button>
        </div>

      </div>
      {mode === 'circular' && <ProjectGraphCircular data={data} />}
      {mode === 'bars' && <ProjectGraphBars data={data} />}
    </div>
  );
};
