import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { fetchConToken } from '../../../helpers/fetch';
import { format, isValid } from 'date-fns';
import { LoadingSpinnerBlue } from '../../ui/LoadingSpinnerBlue';
import { isMobile } from 'react-device-detect';

export const GraphCustom = ({ id }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(["", ""]);

  const datee = format(new Date(), 'MM-dd-yyyy HH:mm');

  useEffect(() => {
    if (isValid(datee)) {
      setDate([datee, datee])
    }
  }, []);

  const onDateChange = (e) => {
    setIsLoading(true);
    setDate(e)
    const getGraph = async () => {
      const body = await fetchConToken(
        `userdata/userdatacloser/custom/${id}`,
        { date: e },
        'POST'
      );
      setOptions({
        chart: {
          type: 'area',
        },
        title: {
          text: 'Custom deals',
          style: {
            color: 'white'
          }
        },
        series: [{
          data: body?.hcData
        }],
        plotOptions: {
          series: {
            pointStart: body?.hc && new Date(body?.hc[0]?.date).getTime(),
            pointInterval: 24 * 3600 * 1000
          }
        },
        legend: {
          enabled: false
        },
        yAxis: [{
          className: 'highcharts-color-0',
          title: {
            text: "Deals",
            style: {
              color: 'white'
            }
          },
          labels: {
            style: {
              color: 'white'
            }
          }
        }],
        xAxis: [{
          className: 'highcharts-color-1',
          title: {
            text: "Time frame",
            style: {
              color: 'white'
            }
          },
          crosshair: {
            width: 1
          },
          type: 'datetime',
          labels: {
            format: "{value:%d %m %y}",
            style: {
              color: 'white'
            }
          },
        }]
      });
      setIsLoading(false);
    }
    if (e !== null) {
      getGraph()
    } else {
      setIsLoading(false);
    }
  }

  return (
    <div className="text-center mb-3 w-100">
      <DateRangePicker className={"bg-light rounded-3 mb-3"} onChange={(e) => { onDateChange(e) }} value={date} />
      {isLoading
        ?
        <LoadingSpinnerBlue disableCenter={true} />
        :
        <div>
          {
            options &&
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              containerProps={{ className: 'chart', style: isMobile ? { height: "50vh" } : { height: "70vh" } }}
            />
          }
        </div>
      }
    </div>
  );
};
