import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { startSetUser } from '../../actions/auth';
import { fetchConToken } from '../../helpers/fetch';
import { format } from 'date-fns';
import { useState } from 'react';
import { LoadingSpinnerWhite } from '../ui/LoadingSpinnerWhite';
import Swal from 'sweetalert2';

export const UsersItems = ({ users, loading, setUsers }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortOrder, setSortOrder] = useState('ASC');
  const [sortName, setSortName] = useState('last_name');

  if (loading) {
    return <LoadingSpinnerWhite />
  }
  const handleEdit = async (id) => {
    const body = await fetchConToken(`auth/${id}`);
    dispatch(startSetUser(body.user));
    localStorage.setItem('userId', id);
    navigate('/edituser');
  };
  const handleDelete = (id, name, last_name) => {
    Swal.fire({
      title: `Do you want to delete the user: ${name} ${last_name}?`,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const body = await fetchConToken(`auth/callcenter/${id}`, {}, 'DELETE');
        if (body.ok) {
          setUsers(body.users);
          Swal.fire("Success", 'User deleted', "success");
        } else {
          Swal.fire("Error", body.msg, "error");
        }
      } else if (result.isDenied) {
      }
    })
  };

  const sortFirstName = async (sortN) => {
    setSortName(sortN);
    let usersSorted = [];
    if (sortOrder === 'ASC') {
      setSortOrder('DESC');
      usersSorted = users.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
    } else {
      setSortOrder('ASC');
      usersSorted = users.sort((a, b) => {
        if (b.name < a.name) {
          return -1;
        }
        if (b.name > a.name) {
          return 1;
        }
        return 0;
      })
    }
    setUsers(usersSorted);
  };
  const sortLastName = async (sortN) => {
    setSortName(sortN);
    let usersSorted = [];
    if (sortOrder === 'ASC') {
      setSortOrder('DESC');
      usersSorted = users.sort((a, b) => {
        if (a.last_name < b.last_name) {
          return -1;
        }
        if (a.last_name > b.last_name) {
          return 1;
        }
        return 0;
      })
    } else {
      setSortOrder('ASC');
      usersSorted = users.sort((a, b) => {
        if (b.last_name < a.last_name) {
          return -1;
        }
        if (b.last_name > a.last_name) {
          return 1;
        }
        return 0;
      })
    }
    setUsers(usersSorted);
  };
  const sortOffice = async (sortN) => {
    setSortName(sortN);
    let usersSorted = [];
    if (sortOrder === 'ASC') {
      setSortOrder('DESC');
      usersSorted = users.sort((a, b) => {
        if (a.office < b.office) {
          return -1;
        }
        if (a.office > b.office) {
          return 1;
        }
        return 0;
      })
    } else {
      setSortOrder('ASC');
      usersSorted = users.sort((a, b) => {
        if (b.office < a.office) {
          return -1;
        }
        if (b.office > a.office) {
          return 1;
        }
        return 0;
      })
    }
    setUsers(usersSorted);
  };
  const sortRole = async (sortN) => {
    setSortName(sortN);
    let usersSorted = [];
    if (sortOrder === 'ASC') {
      setSortOrder('DESC');
      usersSorted = users.sort((a, b) => {
        if (a.role < b.role) {
          return -1;
        }
        if (a.role > b.role) {
          return 1;
        }
        return 0;
      })
    } else {
      setSortOrder('ASC');
      usersSorted = users.sort((a, b) => {
        if (b.role < a.role) {
          return -1;
        }
        if (b.role > a.role) {
          return 1;
        }
        return 0;
      })
    }
    setUsers(usersSorted);
  };

  return (
    <div className='container-fluid table-responsive mb-2'>
      {users && <table className={isMobile ? "d-block overflow-scroll table table-sm table-bordered table-striped bg-light rounded-3 overflow-hidden" : "table table-bordered table-striped table-hover bg-light rounded-3 overflow-hidden"}>
        <thead className='bg-dark text-light align-middle'>
          <tr>
            <th className="text-center" scope="col">Photo</th>
            <th className="text-center" scope="col">{t('labels.status')}</th>
            <th role='button' onClick={() => { sortFirstName('name') }} className="text-center" scope="col">
              {sortName === 'name' && <i className={sortOrder === 'DESC' ? 'fa fa-arrow-up-wide-short' : 'fa fa-arrow-down-short-wide'}></i>} First Name
            </th>
            <th role='button' onClick={() => { sortLastName('last_name') }} className="text-center" scope="col">
              {sortName === 'last_name' && <i className={sortOrder === 'DESC' ? 'fa fa-arrow-up-wide-short' : 'fa fa-arrow-down-short-wide'}></i>} Last Name
            </th>
            <th className="text-center" scope="col">{t('labels.email')}</th>
            <th role='button' onClick={() => { sortOffice('office') }} className="text-center" scope="col">
              {sortName === 'office' && <i className={sortOrder === 'DESC' ? 'fa fa-arrow-up-wide-short' : 'fa fa-arrow-down-short-wide'}></i>} Office
            </th>
            <th role='button' onClick={() => { sortRole('role') }} className="text-center" scope="col">
              {sortName === 'role' && <i className={sortOrder === 'DESC' ? 'fa fa-arrow-up-wide-short' : 'fa fa-arrow-down-short-wide'}></i>} Role
            </th>
            <th className="text-center" scope="col">Start Date</th>
            <th className="text-center" scope="col">Birth Date</th>
            <th className="text-center" scope="col">{t('labels.edit')}</th>
            <th className="text-center" scope="col">Delete</th>
          </tr>
        </thead>
        <>
          {users.length > 0 && users.map(user => {
            return (
              <tbody key={user.id}>
                <tr>
                  <td className="text-center">{user.photo ? <img src={user.photo} className='rounded-circle' width={100} height={100} /> : '-'}</td>
                  <td className="text-center">{user.status ? t('labels.active') : t('labels.inactive')}</td>
                  <td className="text-center">{user.name}</td>
                  <td className="text-center">{user.last_name}</td>
                  <td className="text-center">{user.email}</td>
                  <td className="text-center">{user.office}</td>
                  <td className="text-center">{user.role}</td>
                  <td className="text-center">{user.date_started ? format(new Date(user.date_started), 'MM-dd-yy hh:mm') : '-'}</td>
                  <td className="text-center">{user.birth_date ? format(new Date(user.birth_date), 'MM-dd-yy hh:mm') : '-'}</td>
                  <td className="text-center"><button className='btn btn-primary' onClick={() => handleEdit(user.id)}><i className='fa fa-edit'></i></button></td>
                  <td className="text-center"><button className='btn btn-danger' onClick={() => handleDelete(user.id, user.name, user.last_name)}><i className='fa fa-trash'></i></button></td>
                </tr>
              </tbody>
            );
          })}
        </>
      </table>}
    </div>);
};
