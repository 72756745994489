const enUS = {
    admin: {
        title: 'Sit Downs Tracker',
        total: 'Total',
    },
    office_manager: {
        amount: 'Amount',
        total: 'Total',
    },
    sit_downs: {
        title: 'Sit Downs',
        sit_down: 'Simple Sit Down Register',
        fail_credits: 'Fail Credits',
        simple_register: 'Simple Sit Down Register Table',
        empty: 'No simple sit downs',
    },
    detailed_sit_downs: {
        title: 'Detailed Sit Downs',
        empty: 'No detailed sit downs',
        register: {
            title: 'Add A Detailed Sit Down',
            table: 'Detailed Sit Downs Added Table',
            empty: 'No detailed sit downs added',
        },
        edit: {
            title: 'Edit A Detailed Sit Down',
        },
    },
    auth: {
        login: {
            title: 'LOGIN',
            go_to_register: 'Not have an account?',
            first_login: 'Please change your password and upload a photo'
        },
        register: {
            title: 'REGISTER',
            go_to_login: 'Already register?'
        }
    },
    labels: {
        edit: 'Edit',
        first_name: 'First Name',
        last_name: 'Last Name',
        email: 'Email',
        office: 'Office',
        role: 'Role',
        status: 'Status',
        active: 'Active',
        inactive: 'Inactive',
        password: 'Password',
        confirm_password: 'Confirm Password',
        incomplete: 'Incomplete',
        processed: 'Processed',
        fail_credit: 'Fail Credit',
        sit_down: 'Sit Down',
        fail_credits: 'Fail Credits',
        sit_downs: 'Sit Downs',
        payed: 'Payed',
        date: 'Date',
        address: 'Address',
        phone_number: 'Phone Number',
        reason: 'Reason',
        closer: 'Closer',
        canvasser: 'Canvasser',
        name: 'Name',
        add: 'Add',
        user: 'User',
        last_update: 'Last update',
        choose_file: 'Choose file',
        level: 'Level',
        time_frame: 'Time Frame',
        type: 'Type',
        description: 'Description',
        value: 'Value',
        card: 'Card',
        rank: 'Rank',
        tokens: 'Tokens',
        place: 'Place',
        location: 'Location',
        individual: 'Individual goal',
        company: 'Company goal',
        price: 'Price',
        title: 'Title'
    },
    cards: {
        register: {
            title: 'Add card'
        },
        give: {
            title: 'Give card'
        },
        list: {
            title: 'Blue Cards',
        },
        my_cards: {
            title: 'My cards',
            points: 'Current tokens:',
            congratulations: 'Congratulations!'
        },
        time_frames: {
            daily: 'Daily',
            weekly: 'Weekly',
            monthly: 'Monthly',
            semiannual: 'Semi-annual',
            yearly: 'Yearly',
            timeless: 'Timeless',
            yesterday: 'Yesterday'
        },
        types: {
            basic: 'Basic',
            pro: 'Pro',
            master: 'Master',
            grandmaster: 'Grand Master',
            legendary: 'Legendary',
            goat: 'GOAT',
        }
    },
    upload: {
        user: 'Upload Users',
        user_data: 'Upload Users Data'
    },
    loading: {
        text: 'Loading...'
    },
    errors: {
        fields_required: 'All fields must be completed',
        password_match: 'Passwords have to match',
        google_login: 'Failed to login with Google',
        page_not_found: 'There is no page here',
        file_null: 'Please, select a file'
    },
    success: {
        title: 'Success',
        amount_update: 'Amount updated sucessfully',
        sit_down_created: 'Detailed Sit Down Created',
        sit_down_updated: 'Detailed Sit Down Updated',
        closer_created: 'Closer created',
        canvasser_created: 'Canvasser created',
        card_created: 'Card created',
        card_assign: 'Card assigned to the user',
        event_created: 'Event created',
        reward_claimed: 'Reward claimed',
        post_created: 'Post created'
    },
    buttons: {
        save: 'Save',
        return: 'Return',
        login: 'Login',
        logout: 'Logout',
        profile: 'Profile',
        register: 'Register',
        upload: 'Upload'
    },
    filters: {
        search_placeholder: 'Search a sit down by name',
        search_title: 'Search by name',
        remove_filter: 'Remove filter',
    },
    users: {
        title: 'Users',
        list: {
            empty: 'No users'
        },
        edit: {
            title: 'Edit User',
        },
        roles: {
            admin: 'Admin',
            office_manager: 'Office Manager'
        }
    },
    office: {
        select_office: 'Select your office'
    },
    profile: {
        title: 'Profile',
        password: 'Password',
        change_password: 'Change password',
        general: 'General',
        photo: 'Photo',
        stats: 'Stats',
        graph: 'Graph'
    },
    select: {
        placeholder: 'Select...'
    },
    closers: {
        create: 'Add closer'
    },
    canvassers: {
        create: 'Add canvasser'
    },
    user_cards: {
        assign: {
            title: 'Assign card to an user'
        }
    },
    progress: {
        title: 'Progress'
    },
    ranking: {
        title: 'Ranking',
        empty: 'No rank stats'
    },
    events: {
        register: {
            title: 'Add event'
        },
        list: {
            title: 'Events',
        },
    },
};

export default enUS;