import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { UsersDealsScreenByOffice } from "./UsersDealsScreenByOffice";
import { isMobile } from 'react-device-detect';
import { UsersDealsScreenAll } from './UsersDealsScreenAll';
import { UsersDealsScreenHistoric } from './UsersDealsScreenHistoric';

export const UsersDealsScreen = ({ isAdmin }) => {
  return (
    <>
      <Tabs selectedTabClassName="bg-light text-dark rounded">
        <TabList className={isMobile ? 'text-center text-light font-speciallblue-tabmycards' : 'text-center text-light font-special-scroll-3'}>
          <Tab>All offices</Tab>
          <Tab>Boca Raton</Tab>
          <Tab>Bradenton</Tab>
          <Tab>Cape Coral</Tab>
          <Tab>Jacksonville</Tab>
          <Tab>Corporate Office</Tab>
          <Tab>Tampa office</Tab>
          <Tab>Strattonites</Tab>
          <Tab>Luis Felipe's Team</Tab>
          <Tab>Historical</Tab>
        </TabList>
        <TabPanel>
          <UsersDealsScreenAll isAdmin={isAdmin} />
        </TabPanel>
        <TabPanel>
          <UsersDealsScreenByOffice isAdmin={isAdmin} office={'Boca Raton'} />
        </TabPanel>
        <TabPanel>
          <UsersDealsScreenByOffice isAdmin={isAdmin} office={'Bradenton'} />
        </TabPanel>
        <TabPanel>
          <UsersDealsScreenByOffice isAdmin={isAdmin} office={'Cape Coral'} />
        </TabPanel>
        <TabPanel>
          <UsersDealsScreenByOffice isAdmin={isAdmin} office={'Jacksonville'} />
        </TabPanel>
        <TabPanel>
          <UsersDealsScreenByOffice isAdmin={isAdmin} office={'Corporate Office'} />
        </TabPanel>
        <TabPanel>
          <UsersDealsScreenByOffice isAdmin={isAdmin} office={'Tampa office'} />
        </TabPanel>
        <TabPanel>
          <UsersDealsScreenByOffice isAdmin={isAdmin} office={'Strattonites'} />
        </TabPanel>
        <TabPanel>
          <UsersDealsScreenByOffice isAdmin={isAdmin} office={"Luis Felipe's Team"} />
        </TabPanel>
        <TabPanel>
          <UsersDealsScreenHistoric isAdmin={isAdmin} />
        </TabPanel>
      </Tabs>
    </>
  );
};
