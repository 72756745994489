import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useTranslation } from 'react-i18next';
import 'react-tabs/style/react-tabs.css';
import useSWR from "swr";

import { AllCardsMobile } from './AllCardsMobile';
import { isMobile } from 'react-device-detect';
import { CardsTimeFrameMobile } from './CardsTimeFrameMobile';

export const CardsMobile = ({ id, points }) => {
  const { t } = useTranslation();
  const { data: cardsWeek, errorW, isLoadingW } = useSWR(`usercards/cardsfilter/single/weekly/${id}`);
  const { data: cardsMonth, errorM, isLoadingM } = useSWR(`usercards/cardsfilter/single/monthly/${id}`);
  // const { data: cardsSemi, errorS, isLoadingS } = useSWR(`usercards/cardsfilter/single/semiannual/${id}`);
  // const { data: cardsYear, errorY, isLoadingY } = useSWR(`usercards/cardsfilter/single/yearly/${id}`);
  // const { data: cardsTime, errorT, isLoadingT } = useSWR(`usercards/cardsfilter/single/timeless/${id}`);
  return (
    <div className='text-center'>
      <div className='w-100 bg-primary d-flex justify-content-start'>
        <span className='text-4 text-bold text-light ms-5'>My wallet</span>
      </div>
      <h3 className="m-2 text-primary text-center font-speciallblue">Tokens: <span className='text-primary-dark text-bold'>{points}</span></h3>

      <Tabs selectedTabClassName="bg-primary-dark text-light rounded-pill">
        <TabList className={isMobile ? 'text-light font-speciallblue-tabmycards scrollable-container-mobile' : 'text-light font-speciallblue-3 scrollable-container'}>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>ALL</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>{t('cards.time_frames.weekly')}</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>{t('cards.time_frames.monthly')}</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>{t('cards.time_frames.semiannual')}</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>{t('cards.time_frames.yearly')}</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>{t('cards.time_frames.timeless')}</Tab>
        </TabList>

        <TabPanel>
          <AllCardsMobile id={id} />
        </TabPanel>
        <TabPanel>
          <CardsTimeFrameMobile id={id} data={cardsWeek} isLoading={isLoadingW} />
        </TabPanel>
        <TabPanel>
          <CardsTimeFrameMobile id={id} data={cardsMonth} isLoading={isLoadingM} />
        </TabPanel>
        <TabPanel>
          <h1 className='text-primary'>Working...</h1>
          {/* <CardsTimeFrameMobile id={id} data={cardsSemi} isLoading={isLoadingS} /> */}
        </TabPanel>
        <TabPanel>
          <h1 className='text-primary'>Working...</h1>
          {/* <CardsTimeFrameMobile id={id} data={cardsYear} isLoading={isLoadingY} /> */}
        </TabPanel>
        <TabPanel>
          <h1 className='text-primary'>Working...</h1>
          {/* <CardsTimeFrameMobile id={id} data={cardsTime} isLoading={isLoadingT} /> */}
        </TabPanel>
      </Tabs>
    </div>
  );
};
