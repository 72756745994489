import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
// import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
// import 'react-calendar/dist/Calendar.css';
import Select from 'react-select';
import { format, isValid, set, setDay } from 'date-fns';

import colourStyles from '../../helpers/selectStyles';
import { fetchConToken } from '../../helpers/fetch';
import { MotivationModal } from './MotivationModal';
import { useDispatch } from 'react-redux';
import { uiOpenModalMotivational } from '../../actions/ui';

export const AddCallCenter = ({ id, isAdmin, isNewCall }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hours, setHours] = useState([]);
  const [months, setMonths] = useState([]);
  const [days, setDays] = useState([]);
  const [generals, setGenerals] = useState(
    isNewCall
      ?
      [
        '11:00 to 19:00',
        '10:00 to 18:00',
        '12:00 to 20:00',
        '9:00 to 13:00 / 15:00 to 19:00',
        '9:00 to 13:00 / 16:00 to 20:00',
      ]
      :
      [
        '11:00 to 19:00',
        '10:00 to 18:00',
        '12:00 to 20:00',
        '9:00 to 13:00 / 15:00 to 19:00',
        '9:00 to 13:00 / 16:00 to 20:00',
        'Custom'
      ]
  );

  const [generalSelected, setGeneralSelected] = useState(null);

  const [date, setDate] = useState(["", ""]);

  const datee = format(new Date(), 'MM-dd-yyyy HH:mm');

  const [mode, setMode] = useState({ value: 'General', label: 'General' });
  const modes = [
    { value: 'General', label: 'General' },
    { value: 'Routine', label: 'Routine' },
    { value: 'Custom', label: 'Custom' },
  ];

  useEffect(() => {
    // if (mode.value === 'General') {
    setTimeout(() => {
      dispatch(uiOpenModalMotivational());
    }, 500)
    // }

    if (isValid(datee)) {
      setDate([datee, datee])
    }
  }, []);

  const handleGeneralSelected = (item) => {
    setGeneralSelected(item);

    switch (item) {
      case '11:00 to 19:00':
        setHours([11, 113, 12, 123, 13, 133, 14, 143, 15, 153, 16, 163, 17, 173, 18, 183])
        break;

      case '10:00 to 18:00':
        setHours([10, 103, 11, 113, 12, 123, 13, 133, 14, 143, 15, 153, 16, 163, 17, 173])
        break;
      case '12:00 to 20:00':
        setHours([12, 123, 13, 133, 14, 143, 15, 153, 16, 163, 17, 173, 18, 183, 19, 193])
        break;
      case '9:00 to 13:00 / 15:00 to 19:00':
        setHours([9, 93, 10, 103, 11, 113, 12, 123, 15, 153, 16, 163, 17, 173, 18, 183])
        break;
      case '9:00 to 13:00 / 16:00 to 20:00':
        setHours([9, 93, 10, 103, 11, 113, 12, 123, 16, 163, 17, 173, 18, 183, 19, 193])
        break;
      case 'Custom':
        setHours([])
        break;
      default:
        setHours([])
        break;
    }

    document.querySelectorAll('.cards-hours').forEach(button => {
      if (button.textContent !== item) {
        button.classList.remove('card-hour-selected');
        button.classList.add('card-hour');
      } else {
        button.classList.add('card-hour-selected');
        button.classList.remove('card-hour');
      }
    });
  };


  const handleDateChange = (e) => {
    setDate(e);
  };



  const handleMode = (e) => {
    setMode(e);
    setMonths([]);
    setDays([]);
    setHours([]);
    setC8(false);
    setC9(false);
    setC10(false);
    setC11(false);
    setC12(false);
    setC13(false);
    setC14(false);
    setC15(false);
    setC16(false);
    setC17(false);
    setC18(false);
    setC19(false);
    setC20(false);
    setC21(false);
    setC83(false);
    setC93(false);
    setC103(false);
    setC113(false);
    setC123(false);
    setC133(false);
    setC143(false);
    setC153(false);
    setC163(false);
    setC173(false);
    setC183(false);
    setC193(false);
    setC203(false);
  };



  //MONTHS
  const [january, setJanuary] = useState(false);
  const [february, setFebruary] = useState(false);
  const [march, setMarch] = useState(false);
  const [april, setApril] = useState(false);
  const [may, setMay] = useState(false);
  const [june, setJune] = useState(false);
  const [july, setJuly] = useState(false);
  const [august, setAugust] = useState(false);
  const [september, setSeptember] = useState(false);
  const [october, setOctober] = useState(false);
  const [november, setNovember] = useState(false);
  const [december, setDecember] = useState(false);

  const handleJanuary = () => {
    setJanuary(!january);
    if (!january === true) {
      setMonths(prev => [...prev, 1])
    } else {
      const oldPrev = [...months];
      const newPrev = oldPrev.filter((v) => {
        return v !== 1
      })
      setMonths(newPrev)
    }
  };
  const handleFebruary = () => {
    setFebruary(!february);
    if (!february === true) {
      setMonths(prev => [...prev, 2])
    } else {
      const oldPrev = [...months];
      const newPrev = oldPrev.filter((v) => {
        return v !== 2
      })
      setMonths(newPrev)
    }
  };
  const handleMarch = () => {
    setMarch(!march);
    if (!march === true) {
      setMonths(prev => [...prev, 3])
    } else {
      const oldPrev = [...months];
      const newPrev = oldPrev.filter((v) => {
        return v !== 3
      })
      setMonths(newPrev)
    }
  };
  const handleApril = () => {
    setApril(!april);
    if (!april === true) {
      setMonths(prev => [...prev, 4])
    } else {
      const oldPrev = [...months];
      const newPrev = oldPrev.filter((v) => {
        return v !== 4
      })
      setMonths(newPrev)
    }
  };
  const handleMay = () => {
    setMay(!may);
    if (!may === true) {
      setMonths(prev => [...prev, 5])
    } else {
      const oldPrev = [...months];
      const newPrev = oldPrev.filter((v) => {
        return v !== 5
      })
      setMonths(newPrev)
    }
  };
  const handleJune = () => {
    setJune(!june);
    if (!june === true) {
      setMonths(prev => [...prev, 6])
    } else {
      const oldPrev = [...months];
      const newPrev = oldPrev.filter((v) => {
        return v !== 6
      })
      setMonths(newPrev)
    }
  };
  const handleJuly = () => {
    setJuly(!july);
    if (!july === true) {
      setMonths(prev => [...prev, 7])
    } else {
      const oldPrev = [...months];
      const newPrev = oldPrev.filter((v) => {
        return v !== 7
      })
      setMonths(newPrev)
    }
  };
  const handleAugust = () => {
    setAugust(!august);
    if (!august === true) {
      setMonths(prev => [...prev, 8])
    } else {
      const oldPrev = [...months];
      const newPrev = oldPrev.filter((v) => {
        return v !== 8
      })
      setMonths(newPrev)
    }
  };
  const handleSeptember = () => {
    setSeptember(!september);
    if (!september === true) {
      setMonths(prev => [...prev, 9])
    } else {
      const oldPrev = [...months];
      const newPrev = oldPrev.filter((v) => {
        return v !== 9
      })
      setMonths(newPrev)
    }
  };
  const handleOctober = () => {
    setOctober(!october);
    if (!october === true) {
      setMonths(prev => [...prev, 10])
    } else {
      const oldPrev = [...months];
      const newPrev = oldPrev.filter((v) => {
        return v !== 10
      })
      setMonths(newPrev)
    }
  };
  const handleNovember = () => {
    setNovember(!november);
    if (!november === true) {
      setMonths(prev => [...prev, 11])
    } else {
      const oldPrev = [...months];
      const newPrev = oldPrev.filter((v) => {
        return v !== 11
      })
      setMonths(newPrev)
    }
  };
  const handleDecember = () => {
    setDecember(!december);
    if (!december === true) {
      setMonths(prev => [...prev, 12])
    } else {
      const oldPrev = [...months];
      const newPrev = oldPrev.filter((v) => {
        return v !== 12
      })
      setMonths(newPrev)
    }
  };


  //DAYS
  const [sunday, setSunday] = useState(false);
  const [monday, setMonday] = useState(false);
  const [tuesday, setTuesday] = useState(false);
  const [wednesday, setWednesday] = useState(false);
  const [thursday, setThursday] = useState(false);
  const [friday, setFriday] = useState(false);
  const [saturday, setSaturday] = useState(false);

  const handleSunday = () => {
    setSunday(!sunday);
    if (!sunday === true) {
      setDays(prev => [...prev, 1])
    } else {
      const oldPrev = [...days];
      const newPrev = oldPrev.filter((v) => {
        return v !== 1
      })
      setDays(newPrev)
    }
  };
  const handleMonday = () => {
    setMonday(!monday);
    if (!monday === true) {
      setDays(prev => [...prev, 2])
    } else {
      const oldPrev = [...days];
      const newPrev = oldPrev.filter((v) => {
        return v !== 2
      })
      setDays(newPrev)
    }
  };
  const handleTuesday = () => {
    setTuesday(!tuesday);
    if (!tuesday === true) {
      setDays(prev => [...prev, 3])
    } else {
      const oldPrev = [...days];
      const newPrev = oldPrev.filter((v) => {
        return v !== 3
      })
      setDays(newPrev)
    }
  };
  const handleWednesday = () => {
    setWednesday(!wednesday);
    if (!wednesday === true) {
      setDays(prev => [...prev, 4])
    } else {
      const oldPrev = [...days];
      const newPrev = oldPrev.filter((v) => {
        return v !== 4
      })
      setDays(newPrev)
    }
  };
  const handleThursday = () => {
    setThursday(!thursday);
    if (!thursday === true) {
      setDays(prev => [...prev, 5])
    } else {
      const oldPrev = [...days];
      const newPrev = oldPrev.filter((v) => {
        return v !== 5
      })
      setDays(newPrev)
    }
  };
  const handleFriday = () => {
    setFriday(!friday);
    if (!friday === true) {
      setDays(prev => [...prev, 6])
    } else {
      const oldPrev = [...days];
      const newPrev = oldPrev.filter((v) => {
        return v !== 6
      })
      setDays(newPrev)
    }
  };
  const handleSaturday = () => {
    setSaturday(!saturday);
    if (!saturday === true) {
      setDays(prev => [...prev, 7])
    } else {
      const oldPrev = [...days];
      const newPrev = oldPrev.filter((v) => {
        return v !== 7
      })
      setDays(newPrev)
    }
  };


  //HOURS
  const [c8, setC8] = useState(false);
  const [c9, setC9] = useState(false);
  const [c10, setC10] = useState(false);
  const [c11, setC11] = useState(false);
  const [c12, setC12] = useState(false);
  const [c13, setC13] = useState(false);
  const [c14, setC14] = useState(false);
  const [c15, setC15] = useState(false);
  const [c16, setC16] = useState(false);
  const [c17, setC17] = useState(false);
  const [c18, setC18] = useState(false);
  const [c19, setC19] = useState(false);
  const [c20, setC20] = useState(false);
  const [c21, setC21] = useState(false);
  const [c83, setC83] = useState(false);
  const [c93, setC93] = useState(false);
  const [c103, setC103] = useState(false);
  const [c113, setC113] = useState(false);
  const [c123, setC123] = useState(false);
  const [c133, setC133] = useState(false);
  const [c143, setC143] = useState(false);
  const [c153, setC153] = useState(false);
  const [c163, setC163] = useState(false);
  const [c173, setC173] = useState(false);
  const [c183, setC183] = useState(false);
  const [c193, setC193] = useState(false);
  const [c203, setC203] = useState(false);

  const handleC8 = () => {
    setC8(!c8);
    if (!c8 === true) {
      setHours(prev => [...prev, 8])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 8
      })
      setHours(newPrev)
    }
  };
  const handleC9 = () => {
    setC9(!c9);
    if (!c9 === true) {
      setHours(prev => [...prev, 9])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 9
      })
      setHours(newPrev)
    }
  };
  const handleC10 = () => {
    setC10(!c10);
    if (!c10 === true) {
      setHours(prev => [...prev, 10])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 10
      })
      setHours(newPrev)
    }
  };
  const handleC11 = () => {
    setC11(!c11);
    if (!c11 === true) {
      setHours(prev => [...prev, 11])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 11
      })
      setHours(newPrev)
    }
  };
  const handleC12 = () => {
    setC12(!c12);
    if (!c12 === true) {
      setHours(prev => [...prev, 12])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 12
      })
      setHours(newPrev)
    }
  };
  const handleC13 = () => {
    setC13(!c13);
    if (!c13 === true) {
      setHours(prev => [...prev, 13])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 13
      })
      setHours(newPrev)
    }
  };
  const handleC14 = () => {
    setC14(!c14);
    if (!c14 === true) {
      setHours(prev => [...prev, 14])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 14
      })
      setHours(newPrev)
    }
  };
  const handleC15 = () => {
    setC15(!c15);
    if (!c15 === true) {
      setHours(prev => [...prev, 15])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 15
      })
      setHours(newPrev)
    }
  };
  const handleC16 = () => {
    setC16(!c16);
    if (!c16 === true) {
      setHours(prev => [...prev, 16])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 16
      })
      setHours(newPrev)
    }
  };
  const handleC17 = () => {
    setC17(!c17);
    if (!c17 === true) {
      setHours(prev => [...prev, 17])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 17
      })
      setHours(newPrev)
    }
  };
  const handleC18 = () => {
    setC18(!c18);
    if (!c18 === true) {
      setHours(prev => [...prev, 18])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 18
      })
      setHours(newPrev)
    }
  };
  const handleC19 = () => {
    setC19(!c19);
    if (!c19 === true) {
      setHours(prev => [...prev, 19])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 19
      })
      setHours(newPrev)
    }
  };
  const handleC20 = () => {
    setC20(!c20);
    if (!c20 === true) {
      setHours(prev => [...prev, 20])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 20
      })
      setHours(newPrev)
    }
  };
  const handleC21 = () => {
    setC21(!c21);
    if (!c21 === true) {
      setHours(prev => [...prev, 21])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 21
      })
      setHours(newPrev)
    }
  };
  const handleC83 = () => {
    setC83(!c83);
    if (!c83 === true) {
      setHours(prev => [...prev, 83])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 83
      })
      setHours(newPrev)
    }
  };
  const handleC93 = () => {
    setC93(!c93);
    if (!c93 === true) {
      setHours(prev => [...prev, 93])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 93
      })
      setHours(newPrev)
    }
  };
  const handleC103 = () => {
    setC103(!c103);
    if (!c103 === true) {
      setHours(prev => [...prev, 103])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 103
      })
      setHours(newPrev)
    }
  };
  const handleC113 = () => {
    setC113(!c113);
    if (!c113 === true) {
      setHours(prev => [...prev, 113])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 113
      })
      setHours(newPrev)
    }
  };
  const handleC123 = () => {
    setC123(!c123);
    if (!c123 === true) {
      setHours(prev => [...prev, 123])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 123
      })
      setHours(newPrev)
    }
  };
  const handleC133 = () => {
    setC133(!c133);
    if (!c133 === true) {
      setHours(prev => [...prev, 133])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 133
      })
      setHours(newPrev)
    }
  };
  const handleC143 = () => {
    setC143(!c143);
    if (!c143 === true) {
      setHours(prev => [...prev, 143])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 143
      })
      setHours(newPrev)
    }
  };
  const handleC153 = () => {
    setC153(!c153);
    if (!c153 === true) {
      setHours(prev => [...prev, 153])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 153
      })
      setHours(newPrev)
    }
  };
  const handleC163 = () => {
    setC163(!c163);
    if (!c163 === true) {
      setHours(prev => [...prev, 163])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 163
      })
      setHours(newPrev)
    }
  };
  const handleC173 = () => {
    setC173(!c173);
    if (!c173 === true) {
      setHours(prev => [...prev, 173])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 173
      })
      setHours(newPrev)
    }
  };
  const handleC183 = () => {
    setC183(!c183);
    if (!c183 === true) {
      setHours(prev => [...prev, 183])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 183
      })
      setHours(newPrev)
    }
  };
  const handleC193 = () => {
    setC193(!c193);
    if (!c193 === true) {
      setHours(prev => [...prev, 193])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 193
      })
      setHours(newPrev)
    }
  };
  const handleC203 = () => {
    setC203(!c203);
    if (!c203 === true) {
      setHours(prev => [...prev, 203])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 203
      })
      setHours(newPrev)
    }
  };

  const handleAdd = (e) => {
    e.preventDefault();

    Swal.fire({
      title: 'Wait ...',
      onBeforeOpen() {
        Swal.showLoading()
      },
      onAfterClose() {
        Swal.hideLoading()
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false
    })

    if (date === null || date.length != 2 || date[0] === "" || date[1] === "") {
      return Swal.fire("Error", 'You must select a date', "error");
    }

    const startAdd = async (hours, date) => {
      const body = await fetchConToken(
        "callcenter",
        { hours, date, userId: id },
        "POST"
      );
      if (body.ok) {
        Swal.close();
        Swal.fire(t('success.title'), 'Hours registered', "success");
      } else {
        Swal.close();
        Swal.fire("Error", body.msg, "error");
      }
    };

    const startAddRoutine = async (hours, months, days) => {
      const body = await fetchConToken(
        "callcenter/routine",
        { hours, months, days, userId: id },
        "POST"
      );
      if (body.ok) {
        Swal.close();
        Swal.fire(t('success.title'), 'Hours registered', "success");
      } else {
        Swal.close();
        Swal.fire("Error", body.msg, "error");
      }
    };

    if (hours.length >= 1) {
      if (mode?.value === 'Routine') {
        startAddRoutine(hours, months, days);
      } else {
        startAdd(hours, date);
      }

    } else {
      Swal.close();
      Swal.fire("Error", 'You must select at least 1 hour', "error");
    }
  };
  const handleReturn = () => {
    navigate(isAdmin ? '/callcenter' : '/');
  };
  return (
    <>
      {
        isMobile
          ?
          <div className='d-flex flex-column justify-content-center align-items-center' data-aos="fade-up" data-aos-duration="1000">
            <span className='text-primary text-center text-uppercase fs-1 mt-3'>Register hours</span>
            <div className="col-md-6 rounded-3 border border-light p-3 m-3">
              <form onSubmit={handleAdd}>
                <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                  <div className="w-100">
                    <Select placeholder={t('select.placeholder')} styles={colourStyles} options={modes} value={mode} onChange={handleMode} />
                  </div>
                </div>
                <div>
                  {
                    mode &&
                    <div>
                      {
                        mode?.value === 'Routine'
                          ?
                          <>
                            <div className='d-flex justify-content-center align-items-center'>
                              <button className="btn btn-primary m-2" type="button" data-bs-toggle="collapse" data-bs-target="#monthCol" aria-expanded="false" aria-controls="collapseExample">
                                Months
                              </button>
                              <button className="btn btn-primary m-2" type="button" data-bs-toggle="collapse" data-bs-target="#dayCol" aria-expanded="false" aria-controls="collapseExample">
                                Days
                              </button>
                              <button className="btn btn-primary m-2" type="button" data-bs-toggle="collapse" data-bs-target="#hourCol" aria-expanded="false" aria-controls="collapseExample">
                                Hours
                              </button>
                            </div>
                            <div className="collapse" id="monthCol">
                              <div className='row m-2 text-center'>
                                <label className='text-primary fw-bold'>Select the months</label>
                                <div className='col-6 d-flex flex-column justify-content-start align-items-start'>
                                  <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='january'
                                      value={january}
                                      onChange={handleJanuary}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='january'>January</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='february'
                                      value={february}
                                      onChange={handleFebruary}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='february'>February</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='march'
                                      value={march}
                                      onChange={handleMarch}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='march'>March</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='april'
                                      value={april}
                                      onChange={handleApril}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='april'>April</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='may'
                                      value={may}
                                      onChange={handleMay}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='may'>May</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='june'
                                      value={june}
                                      onChange={handleJune}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='june'>June</label>
                                  </div>
                                </div>
                                <div className='col-6 d-flex flex-column justify-content-start align-items-start'>
                                  <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='july'
                                      value={july}
                                      onChange={handleJuly}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='july'>July</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='august'
                                      value={august}
                                      onChange={handleAugust}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='august'>August</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='september'
                                      value={september}
                                      onChange={handleSeptember}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='september'>September</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='october'
                                      value={october}
                                      onChange={handleOctober}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='october'>October</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='november'
                                      value={november}
                                      onChange={handleNovember}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='november'>November</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='december'
                                      value={december}
                                      onChange={handleDecember}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='december'>December</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="collapse" id="dayCol">
                              <div className='row m-2 text-center'>
                                <label className='text-primary fw-bold'>Select the days</label>
                                <div className='col-6 d-flex flex-column justify-content-start align-items-start'>
                                  <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='sunday'
                                      value={sunday}
                                      onChange={handleSunday}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='sunday'>Sunday</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='monday'
                                      value={monday}
                                      onChange={handleMonday}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='monday'>Monday</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='tuesday'
                                      value={tuesday}
                                      onChange={handleTuesday}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='tuesday'>Tuesday</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='wednesday'
                                      value={wednesday}
                                      onChange={handleWednesday}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='wednesday'>Wednesday</label>
                                  </div>
                                </div>
                                <div className='col-6 d-flex flex-column justify-content-start align-items-start'>
                                  <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='thursday'
                                      value={thursday}
                                      onChange={handleThursday}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='thursday'>Thursday</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='friday'
                                      value={friday}
                                      onChange={handleFriday}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='friday'>Friday</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='saturday'
                                      value={saturday}
                                      onChange={handleSaturday}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='saturday'>Saturday</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="collapse" id="hourCol">
                              <div className='row m-2 text-center'>
                                <label className='text-primary fw-bold'>Select the hours</label>
                                <div className='col-6 d-flex flex-column justify-content-center align-items-center'>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c8'
                                      value={c8}
                                      onChange={handleC8}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c8'>8:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c83'
                                      value={c83}
                                      onChange={handleC83}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c83'>8:30</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c9'
                                      value={c9}
                                      onChange={handleC9}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c9'>9:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c93'
                                      value={c93}
                                      onChange={handleC93}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c93'>9:30</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c10'
                                      value={c10}
                                      onChange={handleC10}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c10'>10:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c103'
                                      value={c103}
                                      onChange={handleC103}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c103'>10:30</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c11'
                                      value={c11}
                                      onChange={handleC11}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c11'>11:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c113'
                                      value={c113}
                                      onChange={handleC113}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c113'>11:30</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c12'
                                      value={c12}
                                      onChange={handleC12}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c12'>12:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c123'
                                      value={c123}
                                      onChange={handleC123}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c123'>12:30</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c13'
                                      value={c13}
                                      onChange={handleC13}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c13'>13:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c133'
                                      value={c133}
                                      onChange={handleC133}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c133'>13:30</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c14'
                                      value={c14}
                                      onChange={handleC14}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c14'>14:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c143'
                                      value={c143}
                                      onChange={handleC143}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c143'>14:30</label>
                                  </div>
                                </div>
                                <div className='col-6 d-flex flex-column justify-content-center align-items-center'>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c15'
                                      value={c15}
                                      onChange={handleC15}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c15'>15:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c153'
                                      value={c153}
                                      onChange={handleC153}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c153'>15:30</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c16'
                                      value={c16}
                                      onChange={handleC16}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c16'>16:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c163'
                                      value={c163}
                                      onChange={handleC163}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c163'>16:30</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c17'
                                      value={c17}
                                      onChange={handleC17}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c17'>17:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c173'
                                      value={c173}
                                      onChange={handleC173}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c173'>17:30</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c18'
                                      value={c18}
                                      onChange={handleC18}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c18'>18:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c183'
                                      value={c183}
                                      onChange={handleC183}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c183'>18:30</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c19'
                                      value={c19}
                                      onChange={handleC19}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c19'>19:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c193'
                                      value={c193}
                                      onChange={handleC193}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c193'>19:30</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c20'
                                      value={c20}
                                      onChange={handleC20}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c20'>20:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c203'
                                      value={c203}
                                      onChange={handleC203}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c203'>20:30</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c21'
                                      value={c21}
                                      onChange={handleC21}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c21'>21:00</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                          :
                          mode?.value === 'Custom'
                            ?
                            <>
                              <div className="d-flex flex-column justify-content-center align-items-center text-center">
                                <label className='text-primary fw-bold'>Select a day</label>
                                <DateRangePicker className={"bg-light rounded-3 mb-1"} onChange={(e) => { handleDateChange(e) }} value={date} />
                              </div>
                              <div className='row m-2 text-center'>
                                <label className='text-primary fw-bold'>Select the hours</label>
                                <div className='col-6 d-flex flex-column justify-content-center align-items-center'>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c8'
                                      value={c8}
                                      onChange={handleC8}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c8'>8:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c83'
                                      value={c83}
                                      onChange={handleC83}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c83'>8:30</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c9'
                                      value={c9}
                                      onChange={handleC9}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c9'>9:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c93'
                                      value={c93}
                                      onChange={handleC93}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c93'>9:30</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c10'
                                      value={c10}
                                      onChange={handleC10}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c10'>10:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c103'
                                      value={c103}
                                      onChange={handleC103}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c103'>10:30</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c11'
                                      value={c11}
                                      onChange={handleC11}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c11'>11:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c113'
                                      value={c113}
                                      onChange={handleC113}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c113'>11:30</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c12'
                                      value={c12}
                                      onChange={handleC12}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c12'>12:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c123'
                                      value={c123}
                                      onChange={handleC123}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c123'>12:30</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c13'
                                      value={c13}
                                      onChange={handleC13}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c13'>13:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c133'
                                      value={c133}
                                      onChange={handleC133}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c133'>13:30</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c14'
                                      value={c14}
                                      onChange={handleC14}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c14'>14:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c143'
                                      value={c143}
                                      onChange={handleC143}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c143'>14:30</label>
                                  </div>
                                </div>
                                <div className='col-6 d-flex flex-column justify-content-center align-items-center'>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c15'
                                      value={c15}
                                      onChange={handleC15}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c15'>15:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c153'
                                      value={c153}
                                      onChange={handleC153}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c153'>15:30</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c16'
                                      value={c16}
                                      onChange={handleC16}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c16'>16:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c163'
                                      value={c163}
                                      onChange={handleC163}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c163'>16:30</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c17'
                                      value={c17}
                                      onChange={handleC17}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c17'>17:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c173'
                                      value={c173}
                                      onChange={handleC173}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c173'>17:30</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c18'
                                      value={c18}
                                      onChange={handleC18}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c18'>18:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c183'
                                      value={c183}
                                      onChange={handleC183}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c183'>18:30</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c19'
                                      value={c19}
                                      onChange={handleC19}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c19'>19:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c193'
                                      value={c193}
                                      onChange={handleC193}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c193'>19:30</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c20'
                                      value={c20}
                                      onChange={handleC20}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c20'>20:00</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c203'
                                      value={c203}
                                      onChange={handleC203}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c203'>20:30</label>
                                  </div>
                                  <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                    <input
                                      type="checkbox"
                                      className="me-1 form-check-input p-3"
                                      id='c21'
                                      value={c21}
                                      onChange={handleC21}
                                    />
                                    <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c21'>21:00</label>
                                  </div>
                                </div>
                              </div>
                            </>
                            :
                            <></>
                      }
                    </div>
                  }
                </div>
                <div className="form-group d-flex justify-content-center mb-1 mt-4">
                  <button type="submit" className="btn btn-success btn-bright">
                    <i className="fa fa-floppy-disk"></i> {t('buttons.save')}
                  </button>
                </div>
              </form>
              <div className="form-group d-flex flex-row justify-content-center">
                <button className="btn btn-primary mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleReturn}>
                  <i className="fa fa-arrow-rotate-left me-1"></i> {t('buttons.return')}
                </button>
              </div>
            </div>
          </div>
          :
          <div className='d-flex flex-column justify-content-center align-items-center' data-aos="fade-up" data-aos-duration="1000">

            <span className='text-primary-strong text-center text-uppercase text-bold text-2 mt-3'>Register hours</span>
            <div className="d-flex flex-column justify-content-center align-items-center rounded-3 border border-light p-3 w-75">
              <form onSubmit={handleAdd} className='mb-5 d-flex flex-column justify-content-center align-items-center'>

                <div className="form-group d-flex justify-content-center align-items-center mb-3 w-25">
                  <button type="submit" className="btn btn-success btn-bright">
                    <i className="fa fa-floppy-disk"></i> {t('buttons.save')}
                  </button>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center text-center mb-2">
                  <label className='text-primary-strong text-bold'>Select a day /s</label>
                  <DateRangePicker className={"bg-light rounded-3 mb-1 border border-light border-0"} onChange={(e) => { handleDateChange(e) }} value={date} />
                </div>

                {
                  date &&
                  <div className='w-100'>
                    {
                      mode &&
                      <>
                        <div className='w-100'>
                          <div className='d-flex justify-content-center align-items-center row'>
                            {
                              generals.map((item, index) => {
                                return (
                                  <div key={index} className='bg-primary-strong rounded-3 p-3 text-center col card-hour m-2 cards-hours item-size-card' onClick={() => { handleGeneralSelected(item) }}>
                                    <span className='text-light text-bold'>{item}</span>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                        {
                          generalSelected === 'Custom' &&
                          <div className='w-100'>
                            <>
                              <div className="form-group d-flex flex-row justify-content-center align-items-center my-3">
                                <div className="w-100">
                                  <Select placeholder={t('select.placeholder')} styles={colourStyles} options={modes} value={mode} onChange={handleMode} />
                                </div>
                              </div>
                            </>
                            {
                              mode?.value === 'Routine'
                                ?
                                <>
                                  <div className='d-flex justify-content-center align-items-center'>
                                    <button className="btn btn-primary m-2" type="button" data-bs-toggle="collapse" data-bs-target="#monthCol" aria-expanded="false" aria-controls="collapseExample">
                                      Months
                                    </button>
                                    <button className="btn btn-primary m-2" type="button" data-bs-toggle="collapse" data-bs-target="#dayCol" aria-expanded="false" aria-controls="collapseExample">
                                      Days
                                    </button>
                                    <button className="btn btn-primary m-2" type="button" data-bs-toggle="collapse" data-bs-target="#hourCol" aria-expanded="false" aria-controls="collapseExample">
                                      Hours
                                    </button>
                                  </div>
                                  <div className="collapse d-flex justify-content-center align-items-center" id="monthCol">
                                    <div className='row m-2 text-center w-50 d-flex justify-content-center align-items-center'>
                                      <label className='text-primary fw-bold'>Select the months</label>
                                      <div className='col-6 d-flex flex-column justify-content-start align-items-start'>
                                        <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='january'
                                            value={january}
                                            onChange={handleJanuary}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='january'>January</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='february'
                                            value={february}
                                            onChange={handleFebruary}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='february'>February</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='march'
                                            value={march}
                                            onChange={handleMarch}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='march'>March</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='april'
                                            value={april}
                                            onChange={handleApril}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='april'>April</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='may'
                                            value={may}
                                            onChange={handleMay}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='may'>May</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='june'
                                            value={june}
                                            onChange={handleJune}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='june'>June</label>
                                        </div>
                                      </div>
                                      <div className='col-6 d-flex flex-column justify-content-start align-items-start'>
                                        <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='july'
                                            value={july}
                                            onChange={handleJuly}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='july'>July</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='august'
                                            value={august}
                                            onChange={handleAugust}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='august'>August</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='september'
                                            value={september}
                                            onChange={handleSeptember}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='september'>September</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='october'
                                            value={october}
                                            onChange={handleOctober}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='october'>October</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='november'
                                            value={november}
                                            onChange={handleNovember}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='november'>November</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='december'
                                            value={december}
                                            onChange={handleDecember}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='december'>December</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="collapse d-flex justify-content-center align-items-center" id="dayCol">
                                    <div className='row m-2 text-center w-50 d-flex justify-content-center align-items-center'>
                                      <label className='text-primary fw-bold'>Select the days</label>
                                      <div className='col-6 d-flex flex-column justify-content-start align-items-start'>
                                        <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='sunday'
                                            value={sunday}
                                            onChange={handleSunday}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='sunday'>Sunday</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='monday'
                                            value={monday}
                                            onChange={handleMonday}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='monday'>Monday</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='tuesday'
                                            value={tuesday}
                                            onChange={handleTuesday}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='tuesday'>Tuesday</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='wednesday'
                                            value={wednesday}
                                            onChange={handleWednesday}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='wednesday'>Wednesday</label>
                                        </div>
                                      </div>
                                      <div className='col-6 d-flex flex-column justify-content-start align-items-start'>
                                        <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='thursday'
                                            value={thursday}
                                            onChange={handleThursday}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='thursday'>Thursday</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='friday'
                                            value={friday}
                                            onChange={handleFriday}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='friday'>Friday</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom-month d-flex justify-content-start align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='saturday'
                                            value={saturday}
                                            onChange={handleSaturday}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-ultrasmall' htmlFor='saturday'>Saturday</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="collapse d-flex justify-content-center align-items-center" id="hourCol">
                                    <div className='row m-2 text-center w-50 d-flex justify-content-center align-items-center'>
                                      <label className='text-primary fw-bold'>Select the hours</label>
                                      <div className='col-6 d-flex flex-column justify-content-start align-items-start'>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c8'
                                            value={c8}
                                            onChange={handleC8}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c8'>8:00</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c83'
                                            value={c83}
                                            onChange={handleC83}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c83'>8:30</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c9'
                                            value={c9}
                                            onChange={handleC9}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c9'>9:00</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c93'
                                            value={c93}
                                            onChange={handleC93}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c93'>9:30</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c10'
                                            value={c10}
                                            onChange={handleC10}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c10'>10:00</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c103'
                                            value={c103}
                                            onChange={handleC103}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c103'>10:30</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c11'
                                            value={c11}
                                            onChange={handleC11}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c11'>11:00</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c113'
                                            value={c113}
                                            onChange={handleC113}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c113'>11:30</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c12'
                                            value={c12}
                                            onChange={handleC12}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c12'>12:00</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c123'
                                            value={c123}
                                            onChange={handleC123}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c123'>12:30</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c13'
                                            value={c13}
                                            onChange={handleC13}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c13'>13:00</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c133'
                                            value={c133}
                                            onChange={handleC133}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c133'>13:30</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c14'
                                            value={c14}
                                            onChange={handleC14}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c14'>14:00</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c143'
                                            value={c143}
                                            onChange={handleC143}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c143'>14:30</label>
                                        </div>
                                      </div>
                                      <div className='col-6 d-flex flex-column justify-content-start align-items-start'>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c15'
                                            value={c15}
                                            onChange={handleC15}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c15'>15:00</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c153'
                                            value={c153}
                                            onChange={handleC153}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c153'>15:30</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c16'
                                            value={c16}
                                            onChange={handleC16}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c16'>16:00</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c163'
                                            value={c163}
                                            onChange={handleC163}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c163'>16:30</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c17'
                                            value={c17}
                                            onChange={handleC17}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c17'>17:00</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c173'
                                            value={c173}
                                            onChange={handleC173}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c173'>17:30</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c18'
                                            value={c18}
                                            onChange={handleC18}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c18'>18:00</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c183'
                                            value={c183}
                                            onChange={handleC183}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c183'>18:30</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c19'
                                            value={c19}
                                            onChange={handleC19}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c19'>19:00</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c193'
                                            value={c193}
                                            onChange={handleC193}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c193'>19:30</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c20'
                                            value={c20}
                                            onChange={handleC20}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c20'>20:00</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c203'
                                            value={c203}
                                            onChange={handleC203}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c203'>20:30</label>
                                        </div>
                                        <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                          <input
                                            type="checkbox"
                                            className="me-1 form-check-input p-3"
                                            id='c21'
                                            value={c21}
                                            onChange={handleC21}
                                          />
                                          <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c21'>21:00</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                                :
                                mode?.value === 'Custom'
                                  ?
                                  <>
                                    {/* <div className="d-flex flex-column justify-content-center align-items-center text-center">
                                      <label className='text-primary fw-bold'>Select a day /s</label>
                                      <DateRangePicker className={"bg-light rounded-3 mb-1"} onChange={(e) => { handleDateChange(e) }} value={date} />
                                    </div> */}
                                    <div className='d-flex justify-content-center align-items-center'>
                                      <div className='row m-2 text-center w-50 d-flex justify-content-center align-items-center'>
                                        <label className='text-primary fw-bold'>Select the hours</label>
                                        <div className='col-6 d-flex flex-column justify-content-start align-items-start'>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c8'
                                              value={c8}
                                              onChange={handleC8}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c8'>8:00</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c83'
                                              value={c83}
                                              onChange={handleC83}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c83'>8:30</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c9'
                                              value={c9}
                                              onChange={handleC9}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c9'>9:00</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c93'
                                              value={c93}
                                              onChange={handleC93}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c93'>9:30</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c10'
                                              value={c10}
                                              onChange={handleC10}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c10'>10:00</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c103'
                                              value={c103}
                                              onChange={handleC103}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c103'>10:30</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c11'
                                              value={c11}
                                              onChange={handleC11}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c11'>11:00</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c113'
                                              value={c113}
                                              onChange={handleC113}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c113'>11:30</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c12'
                                              value={c12}
                                              onChange={handleC12}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c12'>12:00</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c123'
                                              value={c123}
                                              onChange={handleC123}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c123'>12:30</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c13'
                                              value={c13}
                                              onChange={handleC13}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c13'>13:00</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c133'
                                              value={c133}
                                              onChange={handleC133}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c133'>13:30</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c14'
                                              value={c14}
                                              onChange={handleC14}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c14'>14:00</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c143'
                                              value={c143}
                                              onChange={handleC143}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c143'>14:30</label>
                                          </div>
                                        </div>
                                        <div className='col-6 d-flex flex-column justify-content-start align-items-start'>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c15'
                                              value={c15}
                                              onChange={handleC15}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c15'>15:00</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c153'
                                              value={c153}
                                              onChange={handleC153}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c153'>15:30</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c16'
                                              value={c16}
                                              onChange={handleC16}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c16'>16:00</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c163'
                                              value={c163}
                                              onChange={handleC163}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c163'>16:30</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c17'
                                              value={c17}
                                              onChange={handleC17}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c17'>17:00</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c173'
                                              value={c173}
                                              onChange={handleC173}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c173'>17:30</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c18'
                                              value={c18}
                                              onChange={handleC18}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c18'>18:00</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c183'
                                              value={c183}
                                              onChange={handleC183}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c183'>18:30</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c19'
                                              value={c19}
                                              onChange={handleC19}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c19'>19:00</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c193'
                                              value={c193}
                                              onChange={handleC193}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c193'>19:30</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c20'
                                              value={c20}
                                              onChange={handleC20}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c20'>20:00</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c203'
                                              value={c203}
                                              onChange={handleC203}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c203'>20:30</label>
                                          </div>
                                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                                            <input
                                              type="checkbox"
                                              className="me-1 form-check-input p-3"
                                              id='c21'
                                              value={c21}
                                              onChange={handleC21}
                                            />
                                            <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c21'>21:00</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                  :
                                  <></>
                            }
                          </div>
                        }
                      </>
                    }
                  </div>
                }

              </form>
              <div className="form-group d-flex flex-row justify-content-center align-items-center mb-5 pb-5 w-25">
                <button className="btn btn-primary mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleReturn}>
                  <i className="fa fa-arrow-rotate-left me-1"></i> {t('buttons.return')}
                </button>
              </div>
            </div>
          </div>
      }
      <MotivationModal />
    </>
  );
};
