import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import { GraphTab } from './GraphTab';
import { StatsTab } from './StatsTab';
import { GraphCanvasser } from './GraphCanvasser';
import { StatsCanvasser } from './StatsCanvasser';

export const StatsScreen = ({ user, id }) => {
  const { t } = useTranslation();
  return (
    <div className={isMobile ? "w-100" : "w-100"}>
      <img className='fixed-bottom' style={isMobile ? { width: "30vw" } : { width: "10vw" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704397910/Asset_35_m4vka5.png'} />
      <Tabs selectedTabClassName="bg-primary-dark text-light rounded-pill">
        <TabList className={isMobile ? "d-flex justify-content-center align-items-center text-center m-2 p-0" : "d-flex justify-content-center align-items-center text-center padding-start m-2"}>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>{t('profile.stats')}</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>{t('profile.graph')}</Tab>
        </TabList>

        <TabPanel>
          {
            isMobile
              ?
              <StatsTab id={id} />
              :
              <StatsCanvasser id={id} />
          }
        </TabPanel>
        <TabPanel>
          {
            isMobile
              ?
              <GraphTab id={id} />
              :
              <GraphCanvasser id={id} />
          }
        </TabPanel>
      </Tabs>
    </div>
  );
};
