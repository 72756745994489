import { useEffect } from 'react';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import useSWR from "swr";
import { fetchConToken } from '../../../../helpers/fetch';
import { LoadingSpinnerWhite } from '../../../ui/LoadingSpinnerWhite';
import { CloserDealsAdmin } from './CloserDealsAdmin';
import { CanvasserDealsAdmin } from './CanvasserDealsAdmin';
import { CloserDealsAdminNew } from './CloserDealsAdminNew';
import { CanvasserDealsAdminNew } from './CanvasserDealsAdminNew';

export const AdminStatsDealsGeneralNew = ({ cancels, id }) => {
  // const { data: dataCloser } = useSWR(`userdata/usersdata/adminstats/${cancels}`);
  // const { data, isLoading } = useSWR(`userdata/usersdata/adminstats/canvasser/${cancels}`);
  // const { data: dataTotal } = useSWR(`userdata/usersmanualdata/month/${cancels}`);
  const navigate = useNavigate();
  // const [usersCloser, setUsersCloser] = useState(0);
  // const [users, setUsers] = useState(0);
  const [total, setTotal] = useState(0);
  const date = new Date();
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  // useEffect(() => {
  //   // setUsersCloser(dataCloser?.users)
  //   // setUsers(data?.users)
  //   setTotal(dataTotal?.total)
  // }, [dataTotal]);

  const goBack = () => {
    navigate('/statsdata');
  }

  // const { dataa } = useSWR("userdata/usersmanualdata/month");

  // const [myDeals, setMyDeals] = useState(0);
  const [totalCloser, setTotalCloser] = useState(0);
  const [othersCloser, setOthersCloser] = useState([]);
  const [firstsCloser, setFirstsCloser] = useState([]);
  const [totalCanva, setTotalCanva] = useState(0);
  const [othersCanva, setOthersCanva] = useState([]);
  const [firstsCanva, setFirstsCanva] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(async () => {
    const todayDate = new Date();
    const year = todayDate.getFullYear();
    const month = todayDate.getMonth() + 1;
    const body = await fetchConToken(`userdata/usersmanualdata/custom/true?startDate=${year}-${month}-01&endDate=${todayDate}`);
    setTotal(body.total);
    setTotalCloser(body.total);
    setFirstsCloser(body.firsts);
    setOthersCloser(body.others);
    const bodi = await fetchConToken(`userdata/usersmanualdata/custom/canvasser/true?startDate=${year}-${month}-01&endDate=${todayDate}`);
    setTotalCanva(bodi.total);
    setFirstsCanva(bodi.firsts);
    setOthersCanva(bodi.others);


    // const bode = await fetchConToken(`userdata/usersmanualautomatic/month`);
    // // setTotal(bode.total);
    // setTotalCloser(0);
    // setFirstsCloser(bode.firsts);
    // setOthersCloser(bode.others);
    // const bodu = await fetchConToken(`userdata/usersmanualautomatic/canvasser/month`);
    // setTotalCanva(0);
    // setFirstsCanva(bodu.firsts);
    // setOthersCanva(bodu.others);
    setIsLoading(false);
    // const body = await fetchConToken(`auth/user/deals/${id}`);
    // setMyDeals(body.myDeals);
  }, []);

  return (
    <div className={isMobile ? 'bg-primary-dark vh-110' : 'bg-primary-dark'}>
      {
        isLoading
          ?
          <LoadingSpinnerWhite />
          :
          <>
            {
              isMobile
                ?
                <div className='position-relative'>
                  <div className='d-flex justify-content-center align-items-center pt-2'>
                    <button onClick={goBack} className='btn btn-light'><i className='fa fa-chevron-left'></i></button>
                  </div>
                  <div className='d-flex flex-column justify-content-center align-items-center text-center text-admin-stats'>
                    <span className='font-special-total-deals m-5'>{monthNames[date.getMonth()]}</span>
                    <span className='font-special-total-deals'>TOTAL SALES</span>
                    {/* <div className='bg-primary-dark rounded-3 p-4 m-3'>
                <span className='font-special-total-deals-value'>{total}</span>
              </div> */}
                  </div>
                  <img className='logo-be-admin-stats' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694440370/images-logos/001_sc2glr.png'} style={{ objectFit: "contain" }} width={200} height={100} alt="back" />
                  <img className='logo-be-admin-stats-2' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704224590/Asset_2_zo1wyw.png'} style={{ objectFit: "contain" }} width={200} height={100} alt="back" />
                </div>
                :
                <div className='w-100 text-center vh-110 position-relative pt-5'>
                  {/* <img className='logo-stats-decoration pt-5' src={'https://res.cloudinary.com/drholseqp/image/upload/v1705439657/Asset_1_1_qxqhqj.png'} style={{ objectFit: "contain" }} alt="back" /> */}


                  {/* <div className='col-6 d-flex flex-column justify-content-center align-items-center rounded-3 mb-4'> */}
                  <img className='img-class' src={'https://res.cloudinary.com/drholseqp/image/upload/v1709815470/BANNER_BLUENERGY_TOP-VENTAS_V1_otec5a.jpg'} style={{ objectFit: "cover" }} alt="back" />
                  {/* </div> */}
                  {/* <div className='col-6 d-flex flex-column justify-content-center align-items-center mb-4'>
                        <div className='d-flex flex-column align-items-center justify-content-center w-50 p-1 rounded-3'>
                          <span className='text-1-5 text-light text-justified'>{monthNames[date.getMonth()]}</span>
                          <span className='text-2 text-light text-justified text-bold'><b className='text-primary text-bold text-3'>{total}</b> deals</span>
                        </div>
                      </div> */}
                  {/* <img className='img-class-banner' src={'https://res.cloudinary.com/drholseqp/image/upload/v1721073514/banner_july_incentives_woiu3o2_eb7rkn.png'} style={{ objectFit: "cover" }} alt="back" /> */}
                  <div className='col-12 bg-lighter-table px-5 py-4 d-flex justify-content-center align-items-center'>
                    {/* <span className='text-light text-2 verticaltext2'><b className='text-bold'>Top</b> Closers</span> */}
                    <CloserDealsAdminNew date={date} myDeals={0} total={totalCloser} others={othersCloser} firsts={firstsCloser} loading={false} id={id} />
                  </div>
                  <div className='col-12 bg-canva-table px-5 py-4 d-flex justify-content-center align-items-center'>
                    {/* <span className='text-light text-2 verticaltext2'><b className='text-bold'>Top</b> Closers</span> */}
                    <CanvasserDealsAdminNew date={date} myDeals={0} total={totalCanva} others={othersCanva} firsts={firstsCanva} loading={false} id={id} />
                  </div>
                  <div className='col-12 bg-canva-table d-flex justify-content-center align-items-center'>
                    <span className='text-2 text-light text-justified text-bold'><b className='text-primary text-bold text-3'>{total}</b> deals</span>
                  </div>
                  <div className='col-12 bg-canva-table px-5 py-4 d-flex justify-content-center align-items-center'>
                    <img className='img-class-logo' src={'https://res.cloudinary.com/drholseqp/image/upload/v1707425511/output-onlinepngtools_i0seme.png'} style={{ objectFit: "contain" }} alt="back" />
                  </div>
                  {/* <div className='col-12 px-5 d-flex justify-content-center align-items-center'>
                      <span className='text-light text-2 verticaltext2'><b className='text-bold'>Top</b> Canvassers</span>
                      <CanvasserDealsAdmin date={date} myDeals={0} total={totalCanva} others={othersCanva} firsts={firstsCanva} loading={false} id={id} />
                    </div> */}
                </div>
            }
          </>
      }
    </div>
  );
};