import useSWR from "swr";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { fetchConToken } from "../../helpers/fetch";
import { login, startGetUser } from "../../actions/auth";
import { useDispatch } from "react-redux";
import axios from "axios";

const baseURL = process.env.REACT_APP_CONNECTOR_API;
const axiosReq = axios.create({
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_CONNECTOR_API_TOKEN}`
  }
});

const fetcher = (url) => axiosReq.get(url)
  .then((response) => {
    return response.data
  })
  .catch(error => console.log(error));

export const RewardScreen = ({ id, points }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: rewards, error, isLoading } = useSWR(`rewards`);
  const { data: deals } = useSWR(`${baseURL}leads/date/weekly/${id}`, fetcher);

  const claim = async (rewardId, price) => {
    if (points >= price) {
      const body = await fetchConToken(`rewards/claim`,
        { rewardId, userId: id },
        'POST'
      );
      if (body.ok) {
        Swal.fire(t('success.title'), t('success.reward_claimed'), "success");
        dispatch(login({ uid: body.uid, name: body.name, role: body.role, office: body.office, photo: body.photo, points: body.points }));
        dispatch(startGetUser(body.uid));
      } else {
        Swal.fire("Error", body.msg, "error");
      }
    } else {
      Swal.fire("Error", "More tokens required", "error");
    }
  }
  return (
    <div className='text-center'>
      {
        isMobile
          ?
          <>
            <h1 className="font-speciall mt-2">Rewards</h1>
            <div className="m-2 row">
              <Link className="text-decoration-none col" to="/claimed"><span className='font-special-rewards-2 bg-light text-dark rounded p-2'>Rewards Claimed</span></Link>
              <Link className="text-decoration-none col" to="/special"><span className='font-special-rewards-2 bg-light text-dark rounded p-2'>Special Rewards</span></Link>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center text-center text-light'>
              <h1 className="mt-2 h4">Automatic rewards</h1>
              <div className="border border-light rounded-3 w-90 m-2">
                <div className='d-flex justify-content-center align-items-center w-100'>
                  <div className="d-flex justify-content-center align-items-center w-100 p-3">
                    <div style={{ width: "60%" }}>
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <img style={{ objectFit: "contain" }} className="rounded-3" src={'https://res.cloudinary.com/drholseqp/image/upload/v1694743038/byx2ujglxosuylg7w7yc.png'} width={150} height={100} />
                        <span className='m-1'><b>Amazon gift card ($150)</b> </span>
                      </div>
                      {deals?.deals &&
                        <div className="progress">
                          <div className="progress-bar" role="progressbar" style={{ width: `${Math.round(deals?.deals.length * 100 / 3) >= 100 ? 100 : Math.round(deals?.deals.length * 100 / 3)}%` }} aria-valuenow={Math.round(deals?.deals.length * 100 / 3) >= 100 ? 100 : Math.round(deals?.deals.length * 100 / 3)} aria-valuemin="0" aria-valuemax="100">{`${deals?.deals.length} / 3`}</div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-center align-items-center w-100'>
                  <div className="d-flex justify-content-center align-items-center w-100 p-3">
                    <div style={{ width: "60%" }}>
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <img style={{ objectFit: "contain" }} className="rounded-3" src={'https://res.cloudinary.com/drholseqp/image/upload/v1694747721/250_ry1jkp.jpg'} width={150} height={100} />
                        <span className='m-1'><b>Amazon gift card ($250)</b> </span>
                      </div>
                      {deals?.deals &&
                        <div className="progress">
                          <div className="progress-bar" role="progressbar" style={{ width: `${Math.round(deals?.deals.length * 100 / 5) >= 100 ? 100 : Math.round(deals?.deals.length * 100 / 5)}%` }} aria-valuenow={Math.round(deals?.deals.length * 100 / 5) >= 100 ? 100 : Math.round(deals?.deals.length * 100 / 5)} aria-valuemin="0" aria-valuemax="100">{`${deals?.deals.length} / 5`}</div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center text-center text-light'>
              <h1 className="mt-2 h4">Claimable Rewards</h1>
              {
                rewards?.rewards?.length > 0 && rewards?.rewards?.map((reward, j) => {
                  return (
                    <div key={`key-reward-${j}`} className='d-flex justify-content-center align-items-center w-100'>
                      <div className="d-flex justify-content-start align-items-center w-100 p-3">
                        <div style={{ width: "60%" }}>
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <img style={{ objectFit: "contain" }} className="rounded-3" src={reward.url} width={75} height={75} />
                            <span className='m-1'><b>{reward.name}</b> </span>
                          </div>
                          <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{ width: `${Math.round(points * 100 / reward.price) >= 100 ? 100 : Math.round(points * 100 / reward.price)}%` }} aria-valuenow={Math.round(points * 100 / reward.price) >= 100 ? 100 : Math.round(points * 100 / reward.price)} aria-valuemin="0" aria-valuemax="100">{`${Math.round(points * 100 / reward.price) >= 100 ? 100 : Math.round(points * 100 / reward.price)}%`}</div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center m-3">
                          <span className='m-1'><b>{reward.price}</b> </span>
                          <img src={'https://res.cloudinary.com/drholseqp/image/upload/v1694460701/blucoin_front_s8z8er.png'} width={50} height={50} />
                        </div>
                        <button onClick={() => { claim(reward.id, reward.price) }} disabled={points >= reward.price ? false : true} className={points >= reward.price ? "btn btn-light" : "btn btn-default"}>Claim</button>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </>
          :
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h1 className="mt-2 h4">Claimable Rewards</h1>
            <Link className="text-decoration-none" to="/claimed"><span className='font-speciallblue-2 bg-light text-dark rounded p-2'>Rewards claimed</span></Link>
            <div className='d-flex flex-column justify-content-center align-items-center text-center text-light w-50'>
              {
                rewards?.rewards?.length > 0 && rewards?.rewards?.map((reward, j) => {
                  return (
                    <div key={`key-reward-${j}`} className='d-flex justify-content-center align-items-center w-100'>
                      <div className="d-flex justify-content-start align-items-center w-100 p-3">
                        <div style={{ width: "60%" }}>
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <img style={{ objectFit: "contain" }} className="rounded-3" src={reward.url} width={75} height={75} />
                            <span className='m-1'><b>{reward.name}</b> </span>
                          </div>
                          <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{ width: `${Math.round(points * 100 / reward.price) >= 100 ? 100 : Math.round(points * 100 / reward.price)}%` }} aria-valuenow={Math.round(points * 100 / reward.price) >= 100 ? 100 : Math.round(points * 100 / reward.price)} aria-valuemin="0" aria-valuemax="100">{`${Math.round(points * 100 / reward.price) >= 100 ? 100 : Math.round(points * 100 / reward.price)}%`}</div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center m-3">
                          <span className='m-1'><b>{reward.price}</b> </span>
                          <img src={'https://res.cloudinary.com/drholseqp/image/upload/v1694460701/blucoin_front_s8z8er.png'} width={50} height={50} />
                        </div>
                        <button onClick={() => { claim(reward.id, reward.price) }} disabled={points >= reward.price ? false : true} className={points >= reward.price ? "btn btn-light" : "btn btn-default"}>Claim</button>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
      }
    </div >
  );
};
