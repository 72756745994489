import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useTranslation } from 'react-i18next';
import 'react-tabs/style/react-tabs.css';
import { isMobile } from 'react-device-detect';

// import { StatsCloserPreviousWeekTab } from './StatsCloserPreviousWeekTab';
// import { StatsCloserActualWeekTab } from './StatsCloserActualWeekTab';
// import { StatsCloserActualMonthTab } from './StatsCloserActualMonthTab';
// import { StatsCloserCustomTab } from './StatsCloserCustomTab';
// import { StatsCloserYesterdayTab } from './StatsCloserYesterdayTab';
// import { StatsCloserAllTimeTab } from './StatsCloserAllTimeTab';
// import { StatsCloserOfficeTab } from './StatsCloserOfficeTab';
import { StatsAllTime } from './StatsAllTime';
import { StatsCustom } from './StatsCustom';
import { StatsActualMonth } from './StatsActualMonth';
import { StatsActualWeek } from './StatsActualWeek';
import { StatsPreviousWeek } from './StatsPreviousWeek';

export const StatsCloserTab = ({ id }) => {
  const { t } = useTranslation();

  return (
    <div className="text-center border border-light rounded-3 mb-3 w-100 p-2">
      <Tabs selectedTabClassName="bg-primary-dark text-light rounded-pill">
        <TabList className={isMobile ? 'text-light font-speciallblue-tabmycards scrollable-container-mobile' : 'd-flex justify-content-center align-items-center text-light font-special-scroll scrollable-container'}>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>All time</Tab>
          {/* <Tab>Yesterday</Tab> */}
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>Previous week</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>Actual week</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>Actual month</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>Custom</Tab>
          {/* <Tab>Office</Tab> */}
        </TabList>

        <TabPanel>
          <StatsAllTime id={id} />
          {/* <StatsCloserAllTimeTab id={id} /> */}
        </TabPanel>
        {/* <TabPanel>
          <StatsCloserYesterdayTab id={id} />
        </TabPanel> */}
        <TabPanel>
          <StatsPreviousWeek id={id} />
          {/* <StatsCloserPreviousWeekTab id={id} /> */}
        </TabPanel>
        <TabPanel>
          <StatsActualWeek id={id} />
          {/* <StatsCloserActualWeekTab id={id} /> */}
        </TabPanel>
        <TabPanel>
          <StatsActualMonth id={id} />
          {/* <StatsCloserActualMonthTab id={id} /> */}
        </TabPanel>
        <TabPanel>
          <StatsCustom id={id} />
          {/* <StatsCloserCustomTab id={id} /> */}
        </TabPanel>
        {/* <TabPanel>
          <StatsCloserOfficeTab id={id} />
        </TabPanel> */}
      </Tabs>
    </div>
  );
};
