import { isMobile } from "react-device-detect";

export const CloserDealsAdminNewGroup2Design2 = ({ tops, othersList, title }) => {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center pt-5 position-relative w-80'>
      {/* <div className='border border-3 border-info rounded-custom bg-canva-table w-60 position-absolute top-cl-pos'>
        <span className='text-1-8 text-light text-line ps-5'><b className='text-bold'>TOP</b> {title}</span>
      </div> */}
      <div className='w-100 d-flex flex-column justify-content-center align-items-center'>
        {
          tops.length > 0 &&
          tops.map((top, index) => {
            return (
              index === 0
                ?
                <div className='d-flex flex-column justify-content-center align-items-center row w-50 border border-2 border-info rounded-custom-2 position-relative p-4'>
                  <div className='d-flex justify-content-center align-items-center w-auto bg-canva-table rounded-3 p-1 px-3 position-absolute top-cartel'>
                    <img style={{ objectFit: "cover" }} className='rounded-pill me-2' width={35} height={35} loading="lazy" src={'https://res.cloudinary.com/drholseqp/image/upload/v1709815469/Oro_4x_qmbkw5.png'} />
                    <span className='text-info-light text-bold text-1-6 text-design-new'>#1 | {top[0]} dls</span>
                  </div>
                  <div className='d-flex justify-content-center align-items-center col row rounded-3 px-5 py-2'>
                    {
                      top[1].map((topInside) => {
                        return (
                          <div className='d-flex justify-content-center align-items-center col rounded-3 p-1'>
                            <div className='d-flex justify-content-center align-items-center'>
                              <div>
                                <img style={{ objectFit: "cover" }} className='rounded-pill me-2' width={75} height={75} loading="lazy" src={topInside.user.photo ? topInside.user.photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} />
                              </div>
                              <div className='d-flex flex-column justify-content-start align-items-start w-100'>
                                <span className='text-light text-bold text-1 w-100 text-start'>{topInside.user.name} {topInside.user.last_name}</span>
                                <span className='text-info w-100 text-start text-bold text-0-8'>{topInside.user.office}</span>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                :
                index === 1
                  ?
                  <div className='d-flex flex-column justify-content-center align-items-center row w-75  border border-2 border-info rounded-custom-2 position-relative p-4'>
                    {/* <div className='d-flex flex-column justify-content-center align-items-center main-rect-top'> */}
                    <div className='d-flex justify-content-center align-items-center w-auto bg-canva-table rounded-3 p-1 px-3 position-absolute top-cartel'>
                      <img style={{ objectFit: "cover" }} className='rounded-pill me-2' width={35} height={35} loading="lazy" src={'https://res.cloudinary.com/drholseqp/image/upload/v1709815469/Plata_4x_yukiza.png'} />
                      <span className='text-info-light text-bold text-1-6 text-design-new'>#2 | {top[0]} dls</span>
                    </div>
                    {/* </div> */}
                    <div className='d-flex justify-content-center align-items-center col row rounded-3 px-5 py-2'>
                      {
                        top[1].map((topInside) => {
                          return (
                            <div className='d-flex justify-content-center align-items-center col rounded-3 p-1'>
                              <div className='d-flex justify-content-center align-items-center'>
                                <div>
                                  <img style={{ objectFit: "cover" }} className='rounded-pill me-2' width={75} height={75} loading="lazy" src={topInside.user.photo ? topInside.user.photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} />
                                </div>
                                <div className='d-flex flex-column justify-content-start align-items-start w-100'>
                                  <span className='text-light text-bold text-1 w-100 text-start'>{topInside.user.name} {topInside.user.last_name}</span>
                                  <span className='text-info w-100 text-start text-bold text-0-8'>{topInside.user.office}</span>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                  :
                  index === 2
                    ?
                    <div className='d-flex flex-column justify-content-center align-items-center row w-100  border border-2 border-info rounded-custom-2 position-relative p-4'>
                      {/* <div className='d-flex flex-column justify-content-center align-items-center main-rect-top'> */}
                      <div className='d-flex justify-content-center align-items-center w-auto bg-canva-table rounded-3 p-1 px-3 position-absolute top-cartel'>
                        <img style={{ objectFit: "cover" }} className='rounded-pill me-2' width={35} height={35} loading="lazy" src={'https://res.cloudinary.com/drholseqp/image/upload/v1709815469/Bronce_1_4x_orozre.png'} />
                        <span className='text-info-light text-bold text-1-6 text-design-new'>#3 | {top[0]} dls</span>
                      </div>
                      {/* </div> */}
                      <div className='d-flex justify-content-center align-items-center col row rounded-3 px-5 py-2'>
                        {
                          top[1].map((topInside) => {
                            return (
                              <div className='d-flex justify-content-center align-items-center col rounded-3 p-1'>
                                <div className='d-flex justify-content-center align-items-center'>
                                  <div>
                                    <img style={{ objectFit: "cover" }} className='rounded-pill me-2' width={75} height={75} loading="lazy" src={topInside.user.photo ? topInside.user.photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} />
                                  </div>
                                  <div className='d-flex flex-column justify-content-center align-items-center w-100'>
                                    <span className='text-light text-bold text-1 w-100 text-start'>{topInside.user.name} {topInside.user.last_name}</span>
                                    <span className='text-info w-100 text-start text-bold text-0-8'>{topInside.user.office}</span>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                    :
                    <div className='d-flex flex-column justify-content-center align-items-center row m-2 w-100'>
                      <div className='d-flex justify-content-center align-items-center bg-canva-table w-auto rounded-3 p-2'>
                        <img style={{ objectFit: "cover" }} className='rounded-pill me-2' width={35} height={35} loading="lazy" src={'https://res.cloudinary.com/drholseqp/image/upload/v1709815469/Oro_4x_qmbkw5.png'} />
                        <span className='text-info-light text-bold text-1-6 text-design-new'>#1 | {top[0]} dls</span>
                      </div>
                      <div className='d-flex justify-content-start align-items-center col row rounded-3 p-3'>
                        {
                          top[1].map((topInside) => {
                            return (
                              <div className='d-flex justify-content-center align-items-center col-3 rounded-3 p-1'>
                                <img style={{ objectFit: "cover" }} className='rounded-pill me-4' width={75} height={75} loading="lazy" src={topInside.user.photo ? topInside.user.photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} />
                                <div className='d-flex flex-column justify-content-start align-items-start'>
                                  <span className='text-light text-bold text-1 w-100 text-start'>{topInside.user.name} {topInside.user.last_name}</span>
                                  <span className='text-light w-100 text-start'>{topInside.user.office}</span>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
            )
          })
        }
        {/* {
          othersList.length > 0 &&
          <div className={isMobile ? 'w-100 table-wrapper-desktop-leader-new-new mb-3' : 'w-80 mb-3'}>
            {othersList && <table className={isMobile ? "table table-sm table-striped mt-3" : "table table-sm overflow-hidden mt-3"}>
              <>
                {othersList.length > 0 && othersList.map((rank, i) => {
                  return (
                    <tbody key={rank.user?.id}>
                      <tr className={i % 2 === 0 ? 'rounded-3 bg-darker-table' : 'rounded-3 bg-lighter-table'}>
                        <td className={"text-1 text-start text-light fw-bold w-10"}>#{rank.position}</td>
                        <td className={"text-1 text-start text-light w-80"}><b>{rank.user?.name} {rank.user?.last_name}</b> <span className='text-0-8'>{rank.user?.office}</span></td>
                        <td className={"text-1 text-center text-light text-bold w-10"}>{rank.amount} {rank.amount > 1 ? 'DLS' : 'DL'}</td>
                      </tr>
                    </tbody>
                  );
                })}
              </>
            </table>}
          </div>
        } */}
      </div>

    </div>
  );
};
