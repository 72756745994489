import { Outlet, useLocation } from "react-router-dom";
import { isMobile } from 'react-device-detect';

import { Navbar } from "../components/ui/Navbar";

export const Layout = ({ isCallCenter, isNewCall }) => {
    const path = useLocation().pathname;
    const location = path.split("/")[1];

    switch (location) {
        case '':
            if (isCallCenter || isNewCall) {
                document.body.style.overflow = "auto"
            } else {
                document.body.style.overflowY = "auto"
                document.body.style.overflowX = "hidden"
            }
            break;
        case 'stats':
            document.body.style.overflow = "hidden"
            break;
        case 'events':
            document.body.style.overflow = "hidden"
            break;
        case 'calendar':
            document.body.style.overflow = "hidden"
            break;
        case 'adminstats':
            document.body.style.overflowY = "scroll"
            document.body.style.overflowX = "hidden"
            break;
        default:
            document.body.style.overflow = "auto"
            break;
    }

    return (
        <div className={isCallCenter ? "header-bg-callcenterbg" : isMobile ? "header-bg-mobile-" + location : "header-bg-" + location}>
            <Navbar />
            <Outlet />
        </div>
    );
}