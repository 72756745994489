import { LoadingSpinnerBlue } from '../ui/LoadingSpinnerBlue';
import { CarouselCards } from './CarouselCards';

export const CardsTimeFrameMobile = ({ id, data, isLoading }) => {
  return (
    <>
      {
        isLoading
          ?
          <LoadingSpinnerBlue />
          :
          <div id="myCarousel" className="d-flex flex-column justify-content-center align-items-center carousel slide text-center animate__animated animate__fadeIn" data-bs-pause="true">
            <div className="carousel-inner carousel-inner-card">
              {
                data?.cards?.length > 0 && data?.cards?.map((card, i) => {
                  return (
                    i === 0
                      ?
                      <CarouselCards classy="carousel-item active" key={`stack-${i}`} card={card} i={i + 1} />
                      :
                      <CarouselCards classy="carousel-item" key={`stack-${i}`} card={card} i={i + 1} />
                  )
                })
              }
            </div>
            <button aria-label="Anterior" className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
              <span className="fas fa-circle-arrow-left fa-2xl text-primary" aria-hidden="true" />
            </button>
            <button id="apreta" aria-label="Siguiente" className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
              <span className="fas fa-circle-arrow-right fa-2xl text-primary" aria-hidden="true" />
            </button>
          </div>
      }
    </>
  );
};
