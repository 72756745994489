import { useEffect } from 'react';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import useSWR from "swr";

export const AdminStatsCanvasser = ({ cancels }) => {
  const { data, isLoading } = useSWR(`userdata/usersdata/adminstats/canvasser/${cancels}`);
  const navigate = useNavigate();
  const [users, setUsers] = useState(0);
  const date = new Date();
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  useEffect(() => {
    setUsers(data?.users)
  }, [data]);

  const goBack = () => {
    navigate('/statsdata');
  }

  return (
    <div className='bg-primary vh-110'>
      {/* <img className='position-absolute' src={'https://res.cloudinary.com/drholseqp/image/upload/v1697207405/blue_za5f17.jpg'} style={{ objectFit: "cover", width: "100vw", height: "110vh" }} alt="back" /> */}
      {
        isMobile
          ?
          <div className='position-relative'>
            <div className='d-flex justify-content-center align-items-center pt-2'>
              <button onClick={goBack} className='btn btn-light'><i className='fa fa-chevron-left'></i></button>
            </div>
            <img className='logo-be-admin-stats-table' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694440370/images-logos/001_sc2glr.png'} style={{ objectFit: "contain" }} width={150} height={75} alt="back" />
            <img className='logo-be-admin-stats-2' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704224590/Asset_2_zo1wyw.png'} style={{ objectFit: "contain" }} width={200} height={100} alt="back" />
            <div className='d-flex flex-column justify-content-center align-items-center text-center top-admin-stats w-95'>
              <span className='font-special-total-deals-month mb-3'>{monthNames[date.getMonth()]}</span>
              <span className='font-special-total-deals-small'>TOP CANVASSERS</span>
              {/* <span className='font-special-total-deals-supersmall'>Data cut-off time: 9:30 A.M. AND 6 P.M.</span> */}
              <div className='w-100'>
                {users && <table className={isMobile ? "table table-sm table-striped mt-3" : "table table-striped table-hover table-borderless table-sm overflow-hidden mt-3"}>
                  <>
                    {users.length > 0 && users.map(rank => {
                      return (
                        <tbody key={rank.user?.id}>
                          <tr className='rounded-3'>
                            <td className={"text-center text-light fw-bold align-middle"}>#{rank.position}</td>
                            <td className={"text-center text-light fw-bold align-middle"}><img style={{ objectFit: "cover" }} className='rounded-pill' src={rank.user.photo ? rank.user.photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1694003940/images-logos/user_pyqy6e.png'} width={35} height={35} /></td>
                            <td className={"text-center text-light fw-bold text-uppercase align-middle"}>{rank.user?.name}</td>
                            <td className={"text-center text-light align-middle"}>{
                              rank.user?.office === 'Boca Raton' ?
                                <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462423/offices_logos/boca100x100_wzjla8.png'} width={35} height={35} />
                                :
                                rank.user?.office === 'Jacksonville' ?
                                  <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462421/offices_logos/jax100x100_oqyqj7.png'} width={35} height={35} />
                                  :
                                  rank.user?.office === 'Bradenton' ?
                                    <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462425/offices_logos/brad100x100_g0cxrl.png'} width={35} height={35} />
                                    :
                                    <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462427/offices_logos/cape100x100_zsjjn4.png'} width={35} height={35} />
                            }</td>
                            <td className={"align-middle text-center font-special-rank-stats bg-light rounded-3 fw-bold"}>{rank.amount} {rank.amount > 1 ? 'DLS' : 'DL'}</td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </>
                </table>}
              </div>
              {/* <div>
                <button onClick={goBack} className='btn btn-light'><i className='fa fa-chevron-left'></i></button>
              </div> */}
            </div>
          </div>
          :
          <div className='w-100 text-center position-absolute'>
            <div className='d-flex flex-column justify-content-center align-items-center vh-100'>
              <img className='position-absolute top-0' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694440370/images-logos/001_sc2glr.png'} style={{ objectFit: "contain" }} width={150} height={75} alt="back" />
              <div className='d-flex flex-column justify-content-center align-items-center position-absolute top-10 w-100'>
                <span className='font-special-total-deals-month mb-3'>{monthNames[date.getMonth()]}</span>
                <span className='font-special-total-deals-small'>TOP CANVASSERS</span>
                <span className='font-special-total-deals-supersmall'>Data cut-off time: 9:30 A.M. AND 6 P.M.</span>
                <div className='w-100'>
                  {users && <table className={isMobile ? "table table-sm table-striped mt-3" : "table table-striped table-hover table-borderless table-sm overflow-hidden mt-3"}>
                    <>
                      {users.length > 0 && users.map(rank => {
                        return (
                          <tbody key={rank.user?.id}>
                            <tr className='rounded-3'>
                              <td className={"text-center text-light fw-bold align-middle"}>#{rank.position}</td>
                              <td className={"text-center text-light fw-bold align-middle"}><img style={{ objectFit: "cover" }} className='rounded-pill' src={rank.user.photo ? rank.user.photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1694003940/images-logos/user_pyqy6e.png'} width={35} height={35} /></td>
                              <td className={"text-center text-light fw-bold text-uppercase align-middle"}>{rank.user?.name}</td>
                              <td className={"text-center text-light align-middle"}>{
                                rank.user?.office === 'Boca Raton' ?
                                  <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462423/offices_logos/boca100x100_wzjla8.png'} width={35} height={35} />
                                  :
                                  rank.user?.office === 'Jacksonville' ?
                                    <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462421/offices_logos/jax100x100_oqyqj7.png'} width={35} height={35} />
                                    :
                                    rank.user?.office === 'Bradenton' ?
                                      <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462425/offices_logos/brad100x100_g0cxrl.png'} width={35} height={35} />
                                      :
                                      <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462427/offices_logos/cape100x100_zsjjn4.png'} width={35} height={35} />
                              }</td>
                              <td className={"align-middle text-center font-special-rank-stats bg-light rounded-3 fw-bold"}>{rank.amount} {rank.amount > 1 ? 'DLS' : 'DL'}</td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </>
                  </table>}
                </div>
                <div>
                  <button onClick={goBack} className='btn btn-light'><i className='fa fa-chevron-left'></i></button>
                </div>
              </div>
            </div>
          </div>
      }
    </div >
  );
};
