import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { showStory, uiCloseModallll } from "../../actions/ui";
import { useState } from "react";
import "./storymodal.css";
import { isMobile } from "react-device-detect";
import ReactTyped from "react-typed";

const customStyles = {};
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

export const StoryModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [typed, setTyped] = useState(false);
  const [ready, setReady] = useState(false);
  const { modalOpennnn } = useSelector((state) => state.ui);
  const initialSlides = [
    {
      id: "1",
      url: 'https://res.cloudinary.com/drholseqp/image/upload/v1692714631/images-logos/1_f0bk56.webp',
      text: "Welcome to the Blue World",
    },
    {
      id: "2",
      url: 'https://res.cloudinary.com/drholseqp/image/upload/v1692714631/images-logos/2_r2fq3a.webp',
      text: "",
    },
    {
      id: "3",
      url: isMobile ? 'https://res.cloudinary.com/drholseqp/image/upload/v1692714632/images-logos/maintitle_mobile_kclgp2.webp' : 'https://res.cloudinary.com/drholseqp/image/upload/v1692714632/images-logos/maintitle_horizontal_a1fyiq.webp',
      text: "",
    },
  ];
  const [slides, setSlides] = useState(initialSlides);

  const closeModal = () => {
    setReady(false);
    dispatch(uiCloseModallll());
    dispatch(showStory(false));
  };

  const last = () => {
    setReady(true);
    const lastSlide = document.getElementById(`carousel-slide-${slides.length - 1}`);
    if (lastSlide.classList.contains('active')) {
      closeModal();
    }
  }

  const complete = () => {
    setTimeout(() => {
      if (document.getElementById('apreta')) {
        document.getElementById('apreta').click();
        setReady(true)
      }
    }, 300)
  }
  return (
    <div className="d-flex justify-content-center align-items-center">
      <Modal
        isOpen={modalOpennnn}
        onRequestClose={closeModal}
        style={customStyles}
        closeTimeoutMS={500}
        className="storymodal"
        ariaHideApp={process.env.NODE_ENV === "test"}
      >
        {
          isMobile
            ?
            <div className="d-flex flex-column justify-content-center align-items-center">
              <button onClick={closeModal} className="btn bg-invi text-light align-self-end"><i className="fa fa-close"></i></button>
              <div id="myCarousel" className="h-100 d-flex flex-column justify-content-center align-items-center carousel slide carousel-fade mb-3 text-center animate__animated animate__fadeIn" data-bs-pause="true">
                <div className="carousel-indicators">
                  {slides.length > 0 && slides.map((slide, i) => {
                    return (
                      <div key={slide.id + i}>
                        {i === 0
                          ?
                          <button type="button" data-bs-target="#myCarousel" data-bs-slide-to={i} className="active" aria-current="true" aria-label="Slide 1"></button>
                          :
                          <button type="button" data-bs-target="#myCarousel" data-bs-slide-to={i} aria-current="true" aria-label={`Slide ${i}`}></button>
                        }
                      </div>
                    )
                  })
                  }
                </div>

                <div className="carousel-inner">
                  {
                    slides.length > 0 && slides.map((slide, i) => {
                      return (
                        i === 0
                          ?
                          <div className="carousel-item active" key={slide.text + i} >
                            <div className="bg-slide-mobile text-center">
                              <ReactTyped className="color-dark p-5" strings={[slide.text]} typeSpeed={100} onComplete={() => { complete(i) }} />
                            </div>
                          </div>
                          :
                          i !== slides.length - 1
                            ?
                            <div id={`carousel-slide-${i}`} className="carousel-item" key={slide.text + i}>
                              <div className="bg-slide-mobile text-center">
                                {ready && <ReactTyped className="color-dark p-5" strings={[slide.text]} typeSpeed={100} onComplete={() => { complete(i) }} />}
                              </div>
                            </div>
                            :
                            <div id={`carousel-slide-${i}`} className="carousel-item" key={slide.text + i}>
                              <div className="img-slide-mobile">
                                <img src={slide.url} style={{ objectFit: "cover", width: "100vw", height: "85vh" }} alt="evento" />
                              </div>
                            </div>
                      )
                    })
                  }
                </div>
                <button aria-label="Anterior" className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true" />
                </button>
                <button onClick={last} id="apreta" aria-label="Siguiente" className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true" />
                </button>
              </div>
            </div>
            :
            <div id="myCarousel" className="d-flex flex-column justify-content-center align-items-center carousel slide carousel-fade mb-3 text-center animate__animated animate__fadeIn" data-bs-pause="true">
              <div className="carousel-indicators">
                {slides.length > 0 && slides.map((slide, i) => {
                  return (
                    <div key={slide.id + i}>
                      {i === 0
                        ?
                        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to={i} className="active" aria-current="true" aria-label="Slide 1"></button>
                        :
                        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to={i} aria-current="true" aria-label={`Slide ${i}`}></button>
                      }
                    </div>
                  )
                })
                }
              </div>

              <div className="carousel-inner">
                {
                  slides.length > 0 && slides.map((slide, i) => {
                    return (
                      i === 0
                        ?
                        <div className="carousel-item active" key={slide.text + i} >
                          <div className="bg-slide text-center">
                            <ReactTyped className="color-dark p-5" strings={[slide.text]} typeSpeed={100} onComplete={() => { complete(i) }} />
                          </div>
                        </div>
                        :
                        i !== slides.length - 1
                          ?
                          <div id={`carousel-slide-${i}`} className="carousel-item" key={slide.text + i}>
                            <div className="bg-slide text-center">
                              {ready && <ReactTyped className="color-dark p-5" strings={[slide.text]} typeSpeed={100} onComplete={() => { complete(i) }} />}
                            </div>
                          </div>
                          :
                          <div id={`carousel-slide-${i}`} className="carousel-item" key={slide.text + i}>
                            <div className="img-slide">
                              <img src={slide.url} style={{ objectFit: "cover", width: "100%", height: "85vh" }} alt="evento" />
                            </div>
                          </div>
                    )
                  })
                }
              </div>
              <button aria-label="Anterior" className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true" />
              </button>
              <button onClick={last} id="apreta" aria-label="Siguiente" className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true" />
              </button>
            </div>
        }
      </Modal>
    </div>
  );
};
