import { RowsDesktopChild } from './RowsDesktopChild';

export const RowsDesktop = ({ cardRow, i }) => {
  return (
    <div className={`stack4 stack-${i}`}>
      <ul className='cards-down4 row'>
        {
          cardRow.length > 0 && cardRow.map((card, j) => {
            return (
              <RowsDesktopChild key={card.id} card={card} i={j + 1} j={i} />
            )
          })
        }
      </ul>
    </div>
  );
};
