import { useCallback, useState } from "react";
import Modal from "react-modal";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Axios from "axios";

import { uiCloseModal } from "../../actions/ui";
import { fetchConToken } from "../../helpers/fetch";
import "./uploadmodal.css";
import { login, startGetUser } from "../../actions/auth";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

export const PhotoModal = ({ user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { modalOpen } = useSelector((state) => state.ui);

  const [file, setFile] = useState(null);
  const handleFile = ({ target }) => {
    setFile(target.files[0]);
  };
  const closeModal = useCallback(() => {
    dispatch(uiCloseModal());
  }, [dispatch]);

  const changePhoto = (e) => {
    if (file) {
      const fileUploadCloud = async () => {
        Swal.fire({
          title: 'Wait ...',
          onBeforeOpen() {
            Swal.showLoading()
          },
          onAfterClose() {
            Swal.hideLoading()
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false
        })
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "cardsapp");
        Axios.post("https://api.cloudinary.com/v1_1/drholseqp/upload", formData
        ).then((res) => {
          const uploadUser = async (file) => {
            const body = await fetchConToken("auth/updatephoto", { id: user.id, url: file }, 'POST');
            if (body.ok) {
              dispatch(login({ uid: body.uid, name: body.name, role: body.role, office: body.office, photo: body.photo }));
              dispatch(startGetUser(body.uid));
              Swal.close();
              closeModal();
              Swal.fire(t('success.title'), "Photo updated", "success");
            } else {
              Swal.fire("Error", body.msg, "error");
            }
          }
          uploadUser(res.data.secure_url);
        });
      }
      fileUploadCloud();
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        closeTimeoutMS={200}
        className="modal"
        ariaHideApp={process.env.NODE_ENV === "test"}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <b style={{ fontSize: "25px" }}>{t('labels.choose_file')}</b>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
          <div className="w-100">
            <div className="form-group files m-2">
              <input id="excelfile" className='bg-light' type="file" onChange={(e) => handleFile(e)} />
            </div>
            <div className='container d-flex flex-column justify-content-center align-items-center'>
              <button className='btn btn-success btn-bright' onClick={changePhoto}>{t('buttons.upload')}</button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
