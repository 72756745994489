import { useTranslation } from 'react-i18next';
import Tilt from 'react-parallax-tilt';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCard, uiOpenModallllll } from '../../actions/ui';
import { CardModal } from '../cards_mobile/CardModal';

export const RowsDesktopChild = ({ card, i, j }) => {
  const { t } = useTranslation();
  const cardEl = useRef(null);
  const dispatch = useDispatch();
  const { selectedCard } = useSelector((state) => state.ui);
  const openModal = (card) => {
    dispatch(setSelectedCard(card));
    dispatch(uiOpenModallllll());
  }
  const classDisabled = card.gotByUser ? "" : "card-disabledd"

  return (
    <div>
      {
        card.url
          ?
          <Tilt className={`card ${card.type} myText clickcard card-${i} rounded d-flex justify-content-center align-items-center m-1 test`} scale={1.2}>
            <div className='deck-progress d-flex justify-content-center align-items-center'>
              <div ref={cardEl} className={`card-progress ${card.type} ${classDisabled}`} onClick={() => { openModal(card) }}>
                <div className={`front-progress face-progress`}>
                  <i className="fas fa-lock text-light fa-2xl lock-icon"></i>
                  <img src={card.url} style={{ objectFit: "cover", width: "100%", height: "100%" }} width={250} height={350} alt="Card" />
                </div>
                <div className="back-progress face-progress">
                </div>
              </div>
            </div>
          </Tilt>
          :
          <Tilt ref={cardEl} className={`${card.id} card ${card.type} myText clickcard card-${i} rounded d-flex justify-content-center align-items-center m-1 test ${classDisabled}`} scale={1.2}>
            <div className="front face" onClick={() => { openModal(card) }}>
              <div className='card-header d-flex justify-content-between text-white rounded'>
                <img src={'https://res.cloudinary.com/drholseqp/image/upload/v1692713939/images-logos/log_g44rdw.webp'} alt="Logo" style={{ objectFit: "contain" }} width={40} height={40} />
                <div className={`bg-${card.type} rounded d-flex justify-content-center align-items-center p-1`}>
                  <i className='fas fa-hand-fist me-2'></i>
                  <h4 className="card-text ">{card.value}</h4>
                </div>
              </div>
              <div className='d-flex justify-content-center align-items-center' style={{ height: "150px", width: "250px" }}>
                <i className="fas fa-lock fa-2xl lock-icon"></i>
                <i className={`fas fa-bolt color-${card.type} fa-2xl fa-beat`}></i>
              </div>
              <div className={`card-body bg-${card.type} text-white rounded`}>
                <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: "50px" }}>
                  <h5 className="card-title fw-bold"> {card.description}</h5>
                </div>
                <hr />
                <div className='d-flex flex-row align-items-center justify-content-evenly'>
                  <h6>{t(`cards.time_frames.${card.time_frame}`)}</h6>
                </div>
              </div>
            </div>
            <div className="back face">
            </div>
          </Tilt>
      }
      {selectedCard && <CardModal card={selectedCard} />}
    </div>
  );
};
