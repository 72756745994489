import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useTranslation } from 'react-i18next';
import { AllCardsDesktop } from './AllCardsDesktop';
import { CardsTimeFrameDesktop } from './CardsTimeFrameDesktop';

export const CardsDesktop = ({ id, points }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className='w-100 bg-primary d-flex justify-content-start'>
        <span className='text-2 text-bold text-light ms-5'>My wallet</span>
      </div>
      <h3 className="m-2 text-primary text-center font-speciallblue">Tokens: <span className='text-primary-dark text-bold'>{points}</span></h3>
      <img className='img-decorate-cards' style={{ width: "10vw" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704487364/Asset_63_b3dxks.png'} />
      <Tabs selectedTabClassName="bg-primary-dark text-light rounded-pill">
        <TabList className="d-flex justify-content-center align-items-center text-center m-2">
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>ALL</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>{t('cards.time_frames.weekly')}</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>{t('cards.time_frames.monthly')}</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>{t('cards.time_frames.semiannual')}</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>{t('cards.time_frames.yearly')}</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>{t('cards.time_frames.timeless')}</Tab>
        </TabList>

        <TabPanel>
          <AllCardsDesktop id={id} />
        </TabPanel>
        <TabPanel>
          <CardsTimeFrameDesktop id={id} time_frame={'weekly'} points={points} />
        </TabPanel>
        <TabPanel>
          <CardsTimeFrameDesktop id={id} time_frame={'monthly'} points={points} />
        </TabPanel>
        <TabPanel>
          <CardsTimeFrameDesktop id={id} time_frame={'semiannual'} points={points} />
        </TabPanel>
        <TabPanel>
          <CardsTimeFrameDesktop id={id} time_frame={'yearly'} points={points} />
        </TabPanel>
        <TabPanel>
          <CardsTimeFrameDesktop id={id} time_frame={'timeless'} points={points} />
        </TabPanel>
      </Tabs>
    </div>
  );
};
