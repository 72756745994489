import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";

export const ShowWelcome = ({ user, id }) => {
  const path = useLocation().pathname;
  const location = path.split("/")[1];
  if (location === '' && isMobile) {
    document.body.style.overflow = "hidden"
  } else {
    document.body.style.overflow = "auto"
  }
  return (
    <div className={isMobile ? "d-flex flex-column justify-content-center align-items-center text-light header-bg-show" : "d-flex flex-column justify-content-center align-items-center text-light header-bg-show"}>
      <div data-aos="fade-up" data-aos-duration="1000">
        <div className="m-1 d-flex flex-column justify-content-center align-items-center">
          <img className="rounded-pill" alt="user" src={user?.photo ? user?.photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1694470108/afvxd7hodymiampbnzfo.webp'} style={{ objectFit: "cover" }} width={115} height={115} />
        </div>
        <div className='d-flex flex-column justify-content-center align-items-center' style={{ fontSize: "20px" }}>
          <span>Welcome, <b>{user?.name + ' ' + user?.last_name}</b></span>
        </div>
      </div>
    </div>
  );
};