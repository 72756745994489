import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import useSWR from "swr";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { isMobile } from 'react-device-detect';

import { LoadingSpinnerWhite } from '../ui/LoadingSpinnerWhite';
import { LoadingSpinnerBlue } from '../ui/LoadingSpinnerBlue';

export const GraphPreviousWeekTab = ({ id }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState({});
  const [options2, setOptions2] = useState({});
  const [pointStartDate, setPointStartDate] = useState(new Date(null));
  const [pointStartDate2, setPointStartDate2] = useState(new Date(null));
  const { data, isLoading } = useSWR(`userdata/userdatahistory/previous/week/${id}`);

  useEffect(() => {
    setPointStartDate(new Date(data?.hc[0]?.date));
    setPointStartDate2(new Date(data?.hcApp[0]?.date));
    setOptions({
      chart: {
        type: 'area',
      },
      title: {
        text: 'Previous week doors knocked',
        style: {
          color: '#0d6dfd'
        }
      },
      series: [{
        data: data?.hcData
      }],
      plotOptions: {
        series: {
          pointStart: data?.hc && pointStartDate.getTime(),
          pointInterval: 24 * 3600 * 1000
        }
      },
      legend: {
        enabled: false
      },
      yAxis: [{
        className: 'highcharts-color-0',
        title: {
          text: "Doors knocked",
          style: {
            color: 'black'
          }
        },
        labels: {
          style: {
            color: 'black'
          }
        }
      }],
      xAxis: [{
        className: 'highcharts-color-1',
        title: {
          text: "Time frame",
          style: {
            color: 'black'
          }
        },
        crosshair: {
          width: 1
        },
        type: 'datetime',
        labels: {
          format: "{value:%d %m }",
          style: {
            color: 'black'
          }
        },
      }]
    })
    setOptions2({
      chart: {
        type: 'area',
      },
      title: {
        text: 'Previous week appointments',
        style: {
          color: '#0d6dfd'
        }
      },
      series: [{
        data: data?.hcDataApp
      }],
      plotOptions: {
        series: {
          pointStart: data?.hcApp && pointStartDate2.getTime(),
          pointInterval: 24 * 3600 * 1000
        }
      },
      legend: {
        enabled: false
      },
      yAxis: [{
        className: 'highcharts-color-0',
        title: {
          text: "Appointments",
          style: {
            color: 'black'
          }
        },
        labels: {
          style: {
            color: 'black'
          }
        }
      }],
      xAxis: [{
        className: 'highcharts-color-1',
        title: {
          text: "Time frame",
          style: {
            color: 'black'
          }
        },
        crosshair: {
          width: 1
        },
        type: 'datetime',
        labels: {
          format: "{value:%d %m }",
          style: {
            color: 'black'
          }
        },
      }]
    })
  }, [data])

  return (
    <Tabs selectedTabClassName="bg-primary-dark text-light rounded-pill">
      <TabList className={isMobile ? 'text-light font-speciallblue-tabmycards scrollable-container-mobile-2' : 'd-flex justify-content-center align-items-center text-light font-special-scroll scrollable-container-2'}>
        <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>Doors Knocked</Tab>
        <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>Appointments</Tab>
      </TabList>
      <TabPanel>
        <div className="text-center mb-3 w-100">
          {isLoading
            ?
            <LoadingSpinnerBlue disableCenter={true} />
            :
            <div>
              <HighchartsReact
                highcharts={Highcharts}
                options={options}
                containerProps={{ className: 'chart', style: { height: "330px" } }}
              />
            </div>
          }
        </div>
      </TabPanel>
      <TabPanel>
        <div className="text-center mb-3 w-100">
          {isLoading
            ?
            <LoadingSpinnerBlue disableCenter={true} />
            :
            <div>
              <HighchartsReact
                highcharts={Highcharts}
                options={options2}
                containerProps={{ className: 'chart', style: { height: "330px" } }}
              />
            </div>
          }
        </div>
      </TabPanel>
    </Tabs>
  );
};
