import useSWR from "swr";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { LoadingSpinnerBlue } from "../../ui/LoadingSpinnerBlue";

export const StatsPreviousWeek = ({ id }) => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState(null);
  const { data, isLoading } = useSWR(`userdata/userdatacloser/previous/week/${id}`);

  useEffect(() => {
    setUserData(data?.userData);
  }, [data])

  return (
    <div className="text-center mb-3 w-100 pt-5">
      {
        isLoading
          ?
          <LoadingSpinnerBlue disableCenter={true} />
          :
          <div>
            {
              userData &&
              <div className="d-flex flex-column justify-content-center align-items-center font-stats">
                <div className="d-flex flex-column justify-content-center align-items-center m-2 p-1 bg-primary rounded-3 w-50">
                  <label>Deals</label>
                  <span>{userData.deals}</span>
                </div>
              </div>
            }
          </div>
      }
    </div>
  );
};
