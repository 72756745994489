import { Link, useNavigate } from "react-router-dom";
import useSWR from "swr"
import { useTranslation } from "react-i18next";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

import { useState } from "react";
import { fetchConToken } from "../../helpers/fetch";
import { useEffect } from "react";
import { format, isValid } from "date-fns";
import { UsersDealsItems } from "./UsersDealsItems";
import { isMobile } from "react-device-detect";

export const UsersDealsScreenHistoric = ({ isAdmin }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const { data, isLoading, mutate } = useSWR(`auth/users/deals/historic`, { revalidateOnFocus: false });
  const [deals, setDeals] = useState([]);
  const [stats, setStats] = useState(null);
  const [date, setDate] = useState(["", ""]);

  const datee = format(new Date(), 'MM-dd-yyyy HH:mm');
  useEffect(() => {
    if (isValid(datee)) {
      setDate([datee, datee])
    }
  }, []);

  useEffect(() => {
    if (data && !isLoading) {
      setDeals(data?.deals);
      setStats(data?.stats);
    }
  }, [data]);

  const handleReturn = (e) => {
    navigate('/');
  };
  const [values, setValues] = useState({ cSearch: "" });

  const { cSearch } = values;

  const handleSearchInputChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setLoading(true);
    if (cSearch.length > 0) {
      const search = async () => {
        const body = await fetchConToken(`auth/users/deals/historic/search/${cSearch}`);
        setDeals(body.deals);
        // setStats(body.stats);
        setLoading(false);
      }
      search();
    } else {
      const search = async () => {
        const body = await fetchConToken(`auth/users/deals/historic`);
        setDeals(body.deals);
        setStats(body.stats);
        setLoading(false);
      }
      search();
    }
  };
  const onDateChange = async (e) => {
    setDate(e)
    setLoading(true);
    if (e) {
      const body = await fetchConToken(`auth/users/deals/historic/date`, { date: e }, 'POST');
      setDeals(body.deals);
      setStats(body.stats);
      setLoading(false);
    } else {
      const body = await fetchConToken(`auth/users/deals/historic`);
      setDeals(body.deals);
      setStats(body.stats);
      setLoading(false);
    }
  }
  return (
    <>
      {
        isMobile
          ?
          <div className="d-flex flex-column justify-content-center align-items-center text-center">
            <h1 className="text-light h1 mb-2 mt-2">Deals tracker historical</h1>
            <div className="d-flex flex-column justify-content-center align-items-center mb-3 w-90">
              <form className='form-group d-flex justify-content-center align-items-center w-100 m-2' onSubmit={handleSearch}>
                <input className='form-control me-2 text-center' title={t('filters.search_title')} placeholder='Search by name' type="text" name="cSearch" value={cSearch} onChange={handleSearchInputChange} />
                <button type="submit" className='btn btn-primary'><i className="fas fa-search"></i></button>
              </form>
              <div className="form-group">
                <DateRangePicker className="form-control" onChange={(e) => { onDateChange(e) }} value={date} />
              </div>
              <div className="d-flex flex-row justify-content-center align-items-center">
                <div className="dropdown dropdown-nav text-decoration-none d-flex justify-content-center align-items-center p-2">
                  <button className="text-light btn btn-primary me-3" title="Add deal" style={{ width: "50px" }} data-bs-toggle="collapse" data-bs-target="#statsCollapse">
                    <i className="fas fa-chart-bar fa-xl"></i>
                  </button>
                </div>
                <Link to="/adddeals">
                  <button className="btn btn-success me-3" title="Add deal" style={{ width: "50px" }}>
                    <i className="fas fa-plus-square fa-xl"></i>
                  </button>
                </Link>
              </div>
            </div>
            <div id="statsCollapse" className="collapse navbar-collapse w-90">
              {
                stats &&
                <div className="navbar-nav d-flex flex-column justify-content-center align-items-center bg-invi rounded-3 p-1 m-2 w-100" data-bs-toggle="collapse" data-bs-target="#notificationCollapse">
                  <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-primary text-light p-2 fw-bold w-100">Total Deals: {stats.sumTotalDeals}</span>
                  <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-dark text-light p-2 fw-bold w-100">Total SelfGens: {stats.sumTotalSelfGens}</span>
                  <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-success text-light p-2 fw-bold w-100">Total Closed: {stats.sumTotalDealsClosed}</span>
                  <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-danger text-light p-2 fw-bold w-100">Total Cancelled: {stats.sumTotalDealsCancelled}</span>
                  <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-warning text-light p-2 fw-bold w-100">Total On Hold: {stats.sumTotalDealsOnHold}</span>
                </div>
              }
            </div>
            <UsersDealsItems setLoading={setLoading} setDeals={setDeals} users={deals} page={page} loading={loading} isLoading={isLoading} mutate={mutate} />
            {
              isAdmin
              &&
              <>
                {
                  !isLoading &&
                  <div className="form-group d-flex flex-row justify-content-center">
                    <button className="btn btn-light mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleReturn}>
                      <i className="fa fa-arrow-rotate-left me-1"></i> {t('buttons.return')}
                    </button>
                  </div>}
              </>
            }
          </div>
          :
          <div className="d-flex flex-column justify-content-center align-items-center text-center">
            <h1 className="text-light h1 mb-2 mt-2">Deals tracker</h1>
            <div className="d-flex justify-content-between align-items-center mb-3 w-100">
              <form className='form-group d-flex justify-content-center align-items-center w-30 m-2' onSubmit={handleSearch}>
                <input className='form-control me-2 text-center' title={t('filters.search_title')} placeholder='Search by name' type="text" name="cSearch" value={cSearch} onChange={handleSearchInputChange} />
                <button type="submit" className='btn btn-primary'><i className="fas fa-search"></i></button>
              </form>
              <div className="form-group">
                <DateRangePicker className="form-control" onChange={(e) => { onDateChange(e) }} value={date} />
              </div>
              <div className="d-flex flex-row justify-content-center align-items-center">
                <div className="dropdown dropdown-nav text-decoration-none d-flex justify-content-center align-items-center p-2">
                  <button className="text-light btn btn-primary me-3" title="Add deal" style={{ width: "50px" }} data-bs-toggle="collapse" data-bs-target="#statsCollapse">
                    <i className="fas fa-chart-bar fa-xl"></i>
                  </button>
                </div>
                <Link to="/adddeals">
                  <button className="btn btn-success me-3" title="Add deal" style={{ width: "50px" }}>
                    <i className="fas fa-plus-square fa-xl"></i>
                  </button>
                </Link>
              </div>
            </div>
            <div id="statsCollapse" className="collapse navbar-collapse w-90">
              {
                stats &&
                <div className="navbar-nav d-flex flex-row justify-content-center align-items-center bg-invi rounded-3 p-1 m-2 w-100" data-bs-toggle="collapse" data-bs-target="#notificationCollapse">
                  <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-primary text-light p-2 fw-bold w-100">Total Deals: {stats.sumTotalDeals}</span>
                  <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-dark text-light p-2 fw-bold w-100">Total SelfGens: {stats.sumTotalSelfGens}</span>
                  <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-success text-light p-2 fw-bold w-100">Total Closed: {stats.sumTotalDealsClosed}</span>
                  <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-danger text-light p-2 fw-bold w-100">Total Cancelled: {stats.sumTotalDealsCancelled}</span>
                  <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-warning text-light p-2 fw-bold w-100">Total On Hold: {stats.sumTotalDealsOnHold}</span>
                </div>
              }
            </div>
            <UsersDealsItems setLoading={setLoading} setDeals={setDeals} users={deals} page={page} loading={loading} isLoading={isLoading} mutate={mutate} />
            {
              isAdmin
              &&
              <>
                {
                  !isLoading &&
                  <div className="form-group d-flex flex-row justify-content-center">
                    <button className="btn btn-light mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleReturn}>
                      <i className="fa fa-arrow-rotate-left me-1"></i> {t('buttons.return')}
                    </button>
                  </div>}
              </>
            }
          </div>
      }

    </>
  );
};
