import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useTranslation } from 'react-i18next';
import 'react-tabs/style/react-tabs.css';
import { isMobile } from 'react-device-detect';

import { StatsAllTimeTab } from './StatsAllTimeTab';
import { StatsYesterdayTab } from './StatsYesterdayTab';
import { StatsPreviousWeekTab } from './StatsPreviousWeekTab';
import { StatsActualWeekTab } from './StatsActualWeekTab';
import { StatsActualMonthTab } from './StatsActualMonthTab';
import { StatsCustomTab } from './StatsCustomTab';
import { StatsOfficeTab } from './StatsOfficeTab';

export const StatsTab = ({ id }) => {
  const { t } = useTranslation();

  return (
    <div className="text-center border border-light rounded-3 mb-3 w-100 p-2">
      <Tabs selectedTabClassName="bg-primary-dark text-light rounded-pill">
        <TabList className={isMobile ? 'text-light font-speciallblue-tabmycards scrollable-container-mobile' : 'd-flex justify-content-center align-items-center text-light font-special-scroll scrollable-container'}>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>All time</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>Yesterday</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>Previous week</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>Actual week</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>Actual month</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>Custom</Tab>
          {/* <Tab>Office</Tab> */}
        </TabList>

        <TabPanel>
          <StatsAllTimeTab id={id} />
        </TabPanel>
        <TabPanel>
          <StatsYesterdayTab id={id} />
        </TabPanel>
        <TabPanel>
          <StatsPreviousWeekTab id={id} />
        </TabPanel>
        <TabPanel>
          <StatsActualWeekTab id={id} />
        </TabPanel>
        <TabPanel>
          <StatsActualMonthTab id={id} />
        </TabPanel>
        <TabPanel>
          <StatsCustomTab id={id} />
        </TabPanel>
        {/* <TabPanel>
          <StatsOfficeTab id={id} />
        </TabPanel> */}
      </Tabs>
    </div>
  );
};
