import { types } from "../types/types";

const initialState = {
  modalOpen: false,
  modalOpenn: false,
  modalOpennn: false,
  modalOpennnn: false,
  modalOpennnnn: false,
  modalOpennnnnn: false,
  modalOpenBirth: false,
  modalOpenBest: false,
  modalOpenTraining: false,
  modalOpenResetPassword: false,
  modalOpenAddDeal: false,
  modalOpenAddNotes: false,
  modalOpenEditDeal: false,
  modalOpenAddSitDown: false,
  modalOpenPost: false,
  modalOpenVisionBoard: false,
  modalOpenMotivational: false,
  showStory: false,
  calendarDate: null,
  disableFixedBottom: false,
  selectedCard: null,
  selectedFight: null,
  userDeal: null,
  post: null,
  notes: null,
  hotlead_id: null
};

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.uiOpenModal:
      return {
        ...state,
        modalOpen: true,
      };
    case types.uiCloseModal:
      return {
        ...state,
        modalOpen: false,
      };
    case types.uiOpenModall:
      return {
        ...state,
        modalOpenn: true,
      };
    case types.uiCloseModall:
      return {
        ...state,
        modalOpenn: false,
      };
    case types.uiOpenModalll:
      return {
        ...state,
        modalOpennn: true,
      };
    case types.uiCloseModalll:
      return {
        ...state,
        modalOpennn: false,
      };
    case types.uiOpenModallll:
      return {
        ...state,
        modalOpennnn: true,
      };
    case types.uiCloseModallll:
      return {
        ...state,
        modalOpennnn: false,
      };
    case types.uiOpenModalllll:
      return {
        ...state,
        modalOpennnnn: true,
      };
    case types.uiCloseModalllll:
      return {
        ...state,
        modalOpennnnn: false,
      };
    case types.uiOpenModallllll:
      return {
        ...state,
        modalOpennnnnn: true,
      };
    case types.uiCloseModallllll:
      return {
        ...state,
        modalOpennnnnn: false,
      };
    case types.uiCloseModalBirth:
      return {
        ...state,
        modalOpenBirth: false,
      };
    case types.uiOpenModalBirth:
      return {
        ...state,
        modalOpenBirth: true,
      };
    case types.uiCloseModalBest:
      return {
        ...state,
        modalOpenBest: false,
      };
    case types.uiOpenModalBest:
      return {
        ...state,
        modalOpenBest: true,
      };
    case types.uiCloseModalResetPassword:
      return {
        ...state,
        modalOpenResetPassword: false,
      };
    case types.uiOpenModalResetPassword:
      return {
        ...state,
        modalOpenResetPassword: true,
      };
    case types.uiCloseModalAddDeal:
      return {
        ...state,
        modalOpenAddDeal: false,
      };
    case types.uiOpenModalAddDeal:
      return {
        ...state,
        modalOpenAddDeal: true,
      };
    case types.uiCloseModalAddNotes:
      return {
        ...state,
        modalOpenAddNotes: false,
      };
    case types.uiOpenModalAddNotes:
      return {
        ...state,
        modalOpenAddNotes: true,
      };
    case types.uiCloseModalEditDeal:
      return {
        ...state,
        modalOpenEditDeal: false,
      };
    case types.uiOpenModalEditDeal:
      return {
        ...state,
        modalOpenEditDeal: true,
      };
    case types.uiCloseModalAddSitDown:
      return {
        ...state,
        modalOpenAddSitDown: false,
      };
    case types.uiOpenModalAddSitDown:
      return {
        ...state,
        modalOpenAddSitDown: true,
      };
    case types.uiCloseModalTraining:
      return {
        ...state,
        modalOpenTraining: false,
      };
    case types.uiOpenModalTraining:
      return {
        ...state,
        modalOpenTraining: true,
      };
    case types.uiOpenModalPost:
      return {
        ...state,
        modalOpenPost: true,
      };
    case types.uiCloseModalPost:
      return {
        ...state,
        modalOpenPost: false,
      };
    case types.uiOpenModalMotivational:
      return {
        ...state,
        modalOpenMotivational: true,
      };
    case types.uiCloseModalMotivational:
      return {
        ...state,
        modalOpenMotivational: false,
      };
    case types.uiOpenModalVisionBoard:
      return {
        ...state,
        modalOpenVisionBoard: true,
      };
    case types.uiCloseModalVisionBoard:
      return {
        ...state,
        modalOpenVisionBoard: false,
      };
    case types.setCalendarDate:
      return {
        ...state,
        calendarDate: action.payload,
      };
    case types.disableFixedBottom:
      return {
        ...state,
        disableFixedBottom: action.payload,
      };
    case types.setSelectedCard:
      return {
        ...state,
        selectedCard: action.payload
      };
    case types.clearSelectedCard:
      return {
        ...state,
        selectedCard: null
      };
    case types.setSelectedFight:
      return {
        ...state,
        selectedFight: action.payload
      };
    case types.clearSelectedFight:
      return {
        ...state,
        selectedFight: null
      };
    case types.setUserDeal:
      return {
        ...state,
        userDeal: action.payload
      };
    case types.clearUserDeal:
      return {
        ...state,
        userDeal: null
      };
    case types.showStory:
      return {
        ...state,
        showStory: action.payload,
      };
    case types.setPost:
      return {
        ...state,
        post: action.payload
      };
    case types.clearPost:
      return {
        ...state,
        post: null
      };
    case types.setNotes:
      return {
        ...state,
        notes: action.payload
      };
    case types.setHotLeadId:
      return {
        ...state,
        hotlead_id: action.payload
      };


    default:
      return state;
  }
};
