import { useState } from 'react';
import { fetchConToken, fetchConTokenDownload } from '../../helpers/fetch';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Axios from "axios";
import './uploadexcel.css';
import { useTranslation } from 'react-i18next';

export const ConvertXlsx = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const handleFile = ({ target }) => {
    setFile(target.files[0]);
  };

  const handleReturn = (e) => {
    navigate('/');
  };
  const handleUploadAndSave = () => {
    if (file) {
      const fileUploadCloud = async () => {
        Swal.fire({
          title: 'Wait ...',
          onBeforeOpen() {
            Swal.showLoading()
          },
          onAfterClose() {
            Swal.hideLoading()
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false
        })
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "cardsapp");
        Axios.post("https://api.cloudinary.com/v1_1/drholseqp/upload", formData
        ).then((res) => {
          const convert = async (file) => {
            const body = await fetchConTokenDownload("auth/convert/xlsx", { url: file }, 'POST')
              .then(blob => {
                // Create a URL for the blob
                const url = window.URL.createObjectURL(blob);
                // Create a temporary link element
                const a = document.createElement('a');
                // Set the href attribute to the blob URL
                a.href = url;
                // Set the download attribute to specify the filename
                a.download = 'converted.xlsx';
                // Append the link to the body
                document.body.appendChild(a);
                // Click the link programmatically to trigger the download
                a.click();
                // Remove the link from the body
                document.body.removeChild(a);
                // Release the object URL
                window.URL.revokeObjectURL(url);
                Swal.close();
              })
              .catch(error => {
                Swal.fire("Error", body.msg, "error");
              });
          }
          convert(res.data.secure_url);
        });
      }
      fileUploadCloud();
    }
  }
  return (
    <div className='container d-flex flex-column justify-content-center align-items-center'>
      <h1 className='text-primary text-center'>Convert excel</h1>
      <div className="col-md-6 login-form-2 m-2">
        <h3>{t('labels.choose_file')}</h3>
        <div className="form-group files m-2">
          <input id="excelfile" className='bg-light' type="file" onChange={(e) => handleFile(e)} />
        </div>
        <div className='container d-flex flex-column justify-content-center align-items-center'>
          <button className='btn btn-success btn-bright' onClick={handleUploadAndSave}>{t('buttons.upload')}</button>
        </div>
      </div>
      <div className='container d-flex flex-column justify-content-center align-items-center m-2'>
        <button className="btn btn-dark btn-bright" onClick={handleReturn}>{t('buttons.return')}</button>
      </div>
    </div>);
};
