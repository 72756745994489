import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useTranslation } from 'react-i18next';
import { GraphAllTimeTab } from './GraphAllTimeTab';
import { GraphPreviousWeekTab } from './GraphPreviousWeekTab';
import { isMobile } from 'react-device-detect';
import { GraphActualWeekTab } from './GraphActualWeekTab';
import { GraphActualMonthTab } from './GraphActualMonthTab';
import { GraphCustomTab } from './GraphCustomTab';
import { GraphOfficeTab } from './GraphOfficeTab';

export const GraphTab = ({ id }) => {
  const { t } = useTranslation();

  return (
    <div className="text-center">
      <Tabs selectedTabClassName="bg-primary-dark text-light rounded-pill">
        <TabList className={isMobile ? 'text-light font-speciallblue-tabmycards scrollable-container-mobile' : 'd-flex justify-content-center align-items-center text-light font-special-scroll scrollable-container'}>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>All time</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>Previous week</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>Actual week</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>Actual month</Tab>
          <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>Custom</Tab>
          {/* <Tab>Office</Tab> */}
        </TabList>

        <TabPanel>
          <GraphAllTimeTab id={id} />
        </TabPanel>
        <TabPanel>
          <GraphPreviousWeekTab id={id} />
        </TabPanel>
        <TabPanel>
          <GraphActualWeekTab id={id} />
        </TabPanel>
        <TabPanel>
          <GraphActualMonthTab id={id} />
        </TabPanel>
        <TabPanel>
          <GraphCustomTab id={id} />
        </TabPanel>
        {/* <TabPanel>
          <GraphOfficeTab id={id} />
        </TabPanel> */}
      </Tabs>
    </div>
  );
};
