import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useTranslation } from 'react-i18next';

import { ProfileGeneralScreen } from "./ProfileGeneralScreen";
import { ChangePasswordScreen } from "./ChangePasswordScreen";

export const ProfileScreen = ({ user, id }) => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <h1 className="mb-2 text-light text-center">{t('profile.title')}</h1>
      <Tabs selectedTabClassName="bg-light text-dark rounded">
        <TabList className="text-light text-center">
          <Tab>{t('profile.general')}</Tab>
          <Tab>{t('profile.password')}</Tab>
        </TabList>

        <TabPanel>
          {user &&
            <ProfileGeneralScreen user={user} />
          }
        </TabPanel>
        <TabPanel>
          <ChangePasswordScreen />
        </TabPanel>
      </Tabs>
    </div>
  );
};
