import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { isMobile } from 'react-device-detect';
import { AdminStatsRepcardOffice } from './AdminStatsRepcardOffice';
import { AdminStatsRepcardGeneral } from './AdminStatsRepcardGeneral';
import { AdminStatsRepcardOfficeSimple } from './AdminStatsRepcardOfficeSimple';
import { AdminStatsRepcardOfficeSimpleAll } from './AdminStatsRepcardOfficeSimpleAll';

export const AdminStatsRepcard = ({ cancels, id, office, isAdmin }) => {

  return (
    <>
      {
        isAdmin
          ?
          <Tabs selectedTabClassName="bg-light text-dark rounded m-3">
            <TabList className={isMobile ? 'text-center text-light font-speciallblue-tabmycards' : 'text-center text-light font-special-scroll-3'}>
              <Tab>General</Tab>
              <Tab>By Office Simple</Tab>
              <Tab>By Office Advanced</Tab>
              <Tab>All offices simple</Tab>
            </TabList>
            <TabPanel>
              <AdminStatsRepcardGeneral cancels={cancels} id={id} />
            </TabPanel>
            <TabPanel>
              <AdminStatsRepcardOfficeSimple cancels={cancels} id={id} officee={office} isAdmin={isAdmin} />
            </TabPanel>
            <TabPanel>
              <AdminStatsRepcardOffice cancels={cancels} id={id} officee={office} isAdmin={isAdmin} />
            </TabPanel>
            <TabPanel>
              <AdminStatsRepcardOfficeSimpleAll cancels={cancels} id={id} officee={office} isAdmin={isAdmin} />
            </TabPanel>
          </Tabs>
          :
          <AdminStatsRepcardOffice cancels={cancels} id={id} officee={office} isAdmin={isAdmin} />
      }
    </>
  );
};