import { isMobile } from 'react-device-detect';
import Swal from 'sweetalert2';
import { fetchConToken } from '../../../helpers/fetch';
import { format } from 'date-fns';
import { LoadingSpinnerBlue } from '../../ui/LoadingSpinnerBlue';
import { useDispatch } from 'react-redux';
import { setHotLeadId, setNotes, uiOpenModalAddNotes } from '../../../actions/ui';


export const RingCentralItems = ({ leads, isLoading, setLeads }) => {
  const dispatch = useDispatch();
  if (isLoading) {
    return <LoadingSpinnerBlue disableCenter={true} />
  }
  const updateType = async (id, type_response, id_calltools) => {
    const update = async () => {
      const body = await fetchConToken(`calltools/updatehotlead`, { id, type_response, id_calltools }, 'PUT');
      if (body.ok) {
        Swal.fire('Success', "Type changed", "success");
        setLeads(body.leads);
      } else {
        Swal.fire("Error", body.msg, "error");
      }
    }
    update();
  };
  const seeNotes = async (id, notes) => {

    dispatch(setHotLeadId(id));
    const getNotes = async () => {
      const body = await fetchConToken(`calltools/hotleads/notes/${id}`);
      dispatch(setNotes(body.data));
      dispatch(uiOpenModalAddNotes());
    }
    getNotes();
  };
  return (
    <div className='container-fluid table-responsive mb-2'>
      <>
        {leads && <table className={isMobile ? "d-block overflow-scroll table table-sm table-bordered bg-light table-striped rounded rounded-3 overflow-hidden" : "table table-striped bg-light table-hover overflow-hidden"}>
          <thead className='bg-dark text-light align-middle'>
            <tr>
              <th className="text-center" scope="col">Date</th>
              <th className="text-center" scope="col">From Name</th>
              <th className="text-center" scope="col">From Phone Number</th>
              <th className="text-center" scope="col">To Name</th>
              <th className="text-center" scope="col">To Phone Number</th>
              {/* <th className="text-center" scope="col">Utility provider</th> */}
              {/* <th className="text-center" scope="col">Utility bill</th> */}
              {/* <th className="text-center" scope="col">Credit score</th> */}
              {/* <th className="text-center" scope="col">Call hour</th> */}
              <th className="text-center" scope="col">Result</th>
            </tr>
          </thead>

          {leads.length > 0 && leads.map(lead => {
            return (
              <tbody key={lead.id}>
                <tr>
                  <td className="text-center text-dark text-nowrap">{lead.startTime ? format(new Date(lead.startTime), 'dd-MM-yyyy hh:mm') : '-'}</td>
                  <td className="text-center text-dark text-uppercase">{lead.from?.name}</td>
                  <td className="text-center text-dark text-uppercase">{lead.from?.phoneNumber}</td>
                  <td className="text-center text-dark">{lead.to?.name}</td>
                  <td className="text-center text-dark">{lead.to?.phoneNumber}</td>
                  <td className="text-center text-dark">{lead.result}</td>
                </tr>
              </tbody>
            );
          })}
        </table>
        }
      </>
    </div>);
};
