import { isMobile } from 'react-device-detect';
import { LoadingSpinnerWhite } from '../../../ui/LoadingSpinnerWhite';
import { TopLeaderboard } from '../../../ranking/TopLeaderboard';
import { TopLeaderboardNew } from '../../../ranking/TopLeaderboardNew';

export const CanvasserDealsAdminNew = ({ date, myDeals, total, others, firsts, loading, id }) => {
  if (loading) {
    return <LoadingSpinnerWhite />
  }

  return (
    <div className='w-80 d-flex flex-column justify-content-center align-items-center'>
      <div className='w-60'>
        {
          firsts.length === 3 &&
          <TopLeaderboardNew date={date} myDeals={myDeals} totalAmount={total} tierList={firsts} />
        }
      </div>
      <div className='border border-3 border-info rounded-custom bg-canva-letter w-80'>
        <span className='text-2 text-light'><b className='text-bold'>TOP</b> CANVASSERS</span>
      </div>
      <div className={isMobile ? 'w-100 table-wrapper-desktop-leader-new-canva' : 'w-80'}>
        {others && <table className={isMobile ? "table table-sm table-striped mt-3" : "table table-sm overflow-hidden mt-3"}>
          <>
            {others.length > 0 && others.map((rank, i) => {
              return (
                <tbody key={rank.user?.id} className={rank.user?.id === id ? '' : ''}>
                  <tr className={i % 2 === 0 ? 'rounded-3 bg-canva-lighter-table' : 'rounded-3  bg-canva-table'}>
                    <td className={rank.user?.id === id ? "text-1 text-center text-light fw-bold w-10" : "text-1 text-center text-light fw-bold w-10"}>#{rank.position}</td>
                    <td className={rank.user?.id === id ? "text-1 text-center text-light w-80" : "text-1 text-center text-light w-80"}>{rank.user?.name} {rank.user?.last_name}</td>
                    <td className={rank.user?.id === id ? "text-1 text-center text-light text-bold w-10" : "text-1 text-center text-light text-bold w-10"}>{rank.amount} {rank.amount > 1 ? 'DLS' : 'DL'}</td>
                  </tr>
                </tbody>
              );
            })}
          </>
        </table>}
      </div>
    </div>
  );
};
