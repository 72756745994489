import useSWR from "swr";

import { LoadingSpinnerWhite } from '../ui/LoadingSpinnerWhite';
import { useEffect } from "react";
import { useState } from "react";
import { CanvasserDealsItems } from "./CanvasserDealsItems";
import { fetchConToken } from "../../helpers/fetch";

export const RankCanvasser = ({ id }) => {
  const { data, isLoading } = useSWR("userdata/usersmanualdata/canvasser/month");
  const [myDeals, setMyDeals] = useState(0);
  const date = new Date();
  useEffect(async () => {
    const body = await fetchConToken(`auth/user/deals/${id}`);
    setMyDeals(body.myDeals);
  }, [data]);
  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100">
      {
        isLoading ?
          <div className="text-center d-flex flex-column justify-content-center align-items-center w-100 m-5" style={{ height: "4.5rem" }} data-aos="fade-up" data-aos-duration="1000">
            <LoadingSpinnerWhite />
          </div>
          :
          <div className="text-center d-flex flex-column justify-content-center align-items-center w-100">
            {
              data?.firsts?.length > 0 ?
                <CanvasserDealsItems date={date} myDeals={myDeals} total={data?.total} others={data?.others} firsts={data?.firsts} loading={isLoading} id={id} />
                :
                <span className="h3 text-light m-3">No data</span>
            }
          </div >
      }
    </div>
  );
};
