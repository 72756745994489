import { RowCardsMobileChild } from './RowCardsMobileChild';

export const RowCardsMobile = ({ cardRow, i }) => {
  return (
    <div className={`stack4-mobile stack-${i}`}>
      <ul className='cards-down4-mobile row'>
        {
          cardRow.length > 0 && cardRow.map((card, j) => {
            return (
              <RowCardsMobileChild key={card.id} card={card} i={j + 1} j={i} />
            )
          })
        }
      </ul>
    </div>
  );
};
