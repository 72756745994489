import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { fetchConToken } from '../../../../helpers/fetch';
import { LoadingSpinnerWhite } from '../../../ui/LoadingSpinnerWhite';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { format } from 'date-fns';
import colourStyles from '../../../../helpers/selectStyles';
import Select from 'react-select';

export const AdminStatsDealsList = ({ cancels, id, officee, isAdmin }) => {
  const navigate = useNavigate();
  const [usersPercentage, setUsersPercentage] = useState([]);
  const [canvaPercentage, setCanvaPercentage] = useState([]);
  const [totalDeals, setTotalDeals] = useState(0);
  const [dateS, setDateS] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const date = new Date();
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const goBack = () => {
    navigate('/statsdata');
  }

  const handleDateChange = async (e) => {
    setDateS(e);
    if (office && status) {
      setIsLoading(true);
      const bodu = await fetchConToken('userdata/sdanddeal/custom/byofficecanva/states/alldata', { date: e, office: office.value, status: status.value }, 'POST')
      setUsersPercentage(bodu.deals);
      setCanvaPercentage(bodu.dealsCanva);
      setTotalDeals(bodu.totalDeals);
      setIsLoading(false);
    }
  };

  const [office, setOffice] = useState(null);
  const offices = isAdmin
    ?
    [
      { value: 'All', label: 'All' },
      { value: 'Boca Raton', label: 'Boca Raton' },
      { value: 'Bradenton', label: 'Bradenton' },
      { value: 'Cape Coral', label: 'Cape Coral' },
      { value: 'Jacksonville', label: 'Jacksonville' },
    ]
    :
    [
      { value: officee, label: officee },
    ];
  const handleOffice = async (e) => {
    setOffice(e);
    if (dateS && status) {
      setIsLoading(true);
      const bodu = await fetchConToken('userdata/sdanddeal/custom/byofficecanva/states/alldata', { date: dateS, office: e.value, status: status.value }, 'POST')
      setUsersPercentage(bodu.deals);
      setCanvaPercentage(bodu.dealsCanva);
      setTotalDeals(bodu.totalDeals);
      setIsLoading(false);
    }
  };

  const [status, setStatus] = useState(null);
  const statuses = isAdmin
    ?
    [
      { value: 'All', label: 'All' },
      { value: 'Closed', label: 'Closed' },
      { value: 'On Hold', label: 'On Hold' },
      { value: 'Cancelled', label: 'Cancelled' },
      { value: 'Closed And On Hold', label: 'Closed And On Hold' },
    ]
    :
    [
      { value: 'Closed', label: 'Closed' },
    ];
  const handleStatus = async (e) => {
    setStatus(e);
    if (dateS && office) {
      setIsLoading(true);
      const bodu = await fetchConToken('userdata/sdanddeal/custom/byofficecanva/states/alldata', { date: dateS, office: office.value, status: e.value }, 'POST')
      setUsersPercentage(bodu.deals);
      setCanvaPercentage(bodu.dealsCanva);
      console.log(bodu)
      setTotalDeals(bodu.totalDeals);
      setIsLoading(false);
    }
  };

  const [sortOrder, setSortOrder] = useState('ASC');
  const [sortName, setSortName] = useState('deals');

  const sortDeals = async (sortN) => {
    setSortName(sortN);
    let usersSorted = [];
    if (sortOrder === 'ASC') {
      setSortOrder('DESC');
      usersSorted = usersPercentage.sort((a, b) => {
        if (a.deals < b.deals) {
          return -1;
        }
        if (a.deals > b.deals) {
          return 1;
        }
        return 0;
      })
    } else {
      setSortOrder('ASC');
      usersSorted = usersPercentage.sort((a, b) => {
        if (b.deals < a.deals) {
          return -1;
        }
        if (b.deals > a.deals) {
          return 1;
        }
        return 0;
      })
    }
    setUsersPercentage(usersSorted);
  };

  return (
    <div className={isMobile ? 'bg-primary-dark vh-110' : 'bg-primary-dark'}>
      {
        isLoading
          ?
          <LoadingSpinnerWhite />
          :
          <>
            {
              isMobile
                ?
                <div className='position-relative'>
                  <div className='d-flex justify-content-center align-items-center pt-2'>
                    <button onClick={goBack} className='btn btn-light'><i className='fa fa-chevron-left'></i></button>
                  </div>
                  <div className='d-flex flex-column justify-content-center align-items-center text-center text-admin-stats'>
                    <span className='font-special-total-deals m-5'>{monthNames[date.getMonth()]}</span>
                    <span className='font-special-total-deals'>TOTAL SALES</span>
                  </div>
                  <img className='logo-be-admin-stats' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694440370/images-logos/001_sc2glr.png'} style={{ objectFit: "contain" }} width={200} height={100} alt="back" />
                  <img className='logo-be-admin-stats-2' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704224590/Asset_2_zo1wyw.png'} style={{ objectFit: "contain" }} width={200} height={100} alt="back" />
                </div>
                :
                <div className='w-100 text-center vh-random position-relative'>

                  <img className='logo-stats-decoration-2' src={'https://res.cloudinary.com/drholseqp/image/upload/v1705439657/Asset_1_1_qxqhqj.png'} style={{ objectFit: "contain" }} alt="back" />
                  <DateRangePicker className={"bg-light rounded-3 mb-1"} onChange={(e) => { handleDateChange(e) }} value={dateS} />
                  <div className="form-group d-flex flex-row justify-content-center align-items-center">
                    <div className="w-20">
                      <Select placeholder={'Select office'} styles={colourStyles} options={offices} value={office} onChange={handleOffice} />
                    </div>
                  </div>
                  <div className="form-group d-flex flex-row justify-content-center align-items-center mt-2">
                    <div className="w-20">
                      <Select placeholder={'Select status'} styles={colourStyles} options={statuses} value={status} onChange={handleStatus} />
                    </div>
                  </div>
                  <div className='row pt-5 vw-100 position-absolute'>
                    <div className='col-12 row pt-5'>
                      <div className='col-6 d-flex flex-column justify-content-center align-items-center rounded-3 mb-4'>
                        <img className='img-stats' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694440370/images-logos/001_sc2glr.png'} style={{ objectFit: "contain" }} alt="back" />
                      </div>
                      <div className='col-6 d-flex flex-column justify-content-center align-items-center mb-4'>
                        {dateS && <div className='d-flex align-items-center justify-content-center w-100 p-1 rounded-3'>
                          <div className='p-2 rounded-3 d-flex flex-column align-items-center justify-content-center m-2'>
                            <span className='text-1-8 text-light text-justified'><b>{totalDeals}</b> Deals</span>
                          </div>
                          <div className='bg-light p-2 rounded-3 d-flex flex-column align-items-center justify-content-center m-2'>
                            <span className='text-1-5 text-primary text-justified'>{monthNames[dateS[1].getMonth()]}</span>
                            <span className='text-1-5 text-primary text-justified'><b>{format(new Date(dateS[0]), 'yyyy-dd-MM')}</b> - <b>{format(new Date(dateS[1]), 'yyyy-dd-MM')}</b></span>
                          </div>
                        </div>}
                      </div>
                    </div>
                    <div className='col-6 padding-adaptative d-flex justify-content-center align-items-start'>
                      <div className='w-90'>
                        {usersPercentage && usersPercentage.length > 0 && <table className={isMobile ? "table table-sm mt-3" : "table table-hover table-sm table-bordered overflow-hidden mt-3 rounded-3"}>
                          <>
                            <thead className='bg-light'>
                              <tr className='bg-light'>
                                <th className={"text-1 text-center text-light text-bold align-middle bg-dark"}>Closer</th>
                                <th className={"text-1 text-center text-light text-bold align-middle bg-dark"}>Office</th>
                                <th className={"text-1 text-center text-light text-bold align-middle bg-dark"}>Deals</th>
                                <th className={"text-1 text-center text-light text-bold align-middle bg-success"}>Closed</th>
                                <th className={"text-1 text-center text-light text-bold align-middle bg-danger"}>Cancelled</th>
                                <th className={"text-1 text-center text-light text-bold align-middle bg-warning"}>On hold</th>
                              </tr>
                            </thead>
                            {usersPercentage.length > 0 && usersPercentage.map((rank, i) => {
                              return (
                                <tbody key={rank.id}>
                                  <tr className='bg-light'>
                                    {/* <td className={"text-center text-dark fw-bold align-middle"}>{rank.position}</td> */}
                                    <td className={"text-center text-dark fw-bold text-uppercase align-middle"}>{rank.user.name} {rank.user.last_name}</td>
                                    <td className={"text-center text-dark fw-bold text-uppercase align-middle"}>{rank.user.office}</td>
                                    <td className={"text-center text-dark fw-bold text-uppercase align-middle"}>{rank.amount}</td>
                                    <td className={"text-center text-dark fw-bold text-uppercase align-middle"}>{rank.amountClosed}</td>
                                    <td className={"text-center text-dark fw-bold text-uppercase align-middle"}>{rank.amountCancelled}</td>
                                    <td className={"text-center text-dark fw-bold text-uppercase align-middle"}>{rank.amountOnHold}</td>
                                  </tr>
                                </tbody>
                              );
                            })}
                          </>
                        </table>}
                      </div>
                    </div>
                    <div className='col-6 padding-adaptative d-flex justify-content-center align-items-start'>
                      <div className='w-90'>
                        {canvaPercentage && canvaPercentage.length > 0 && <table className={isMobile ? "table table-sm mt-3" : "table table-hover table-sm table-bordered overflow-hidden mt-3 rounded-3"}>
                          <>
                            <thead className='bg-light'>
                              <tr className='bg-light'>
                                <th className={"text-1 text-center text-light text-bold align-middle bg-dark"}>Closer</th>
                                <th className={"text-1 text-center text-light text-bold align-middle bg-dark"}>Office</th>
                                <th className={"text-1 text-center text-light text-bold align-middle bg-dark"}>Deals</th>
                                <th className={"text-1 text-center text-light text-bold align-middle bg-success"}>Closed</th>
                                <th className={"text-1 text-center text-light text-bold align-middle bg-danger"}>Cancelled</th>
                                <th className={"text-1 text-center text-light text-bold align-middle bg-warning"}>On hold</th>
                              </tr>
                            </thead>
                            {canvaPercentage.length > 0 && canvaPercentage.map((rank, i) => {
                              return (
                                <tbody key={rank.user.id}>
                                  <tr className='bg-light'>
                                    {/* <td className={"text-center text-dark fw-bold align-middle"}>{rank.position}</td> */}
                                    <td className={"text-center text-dark fw-bold text-uppercase align-middle"}>{rank.user.name} {rank.user.last_name}</td>
                                    <td className={"text-center text-dark fw-bold text-uppercase align-middle"}>{rank.user.office}</td>
                                    <td className={"text-center text-dark fw-bold text-uppercase align-middle"}>{rank.amount}</td>
                                    <td className={"text-center text-dark fw-bold text-uppercase align-middle"}>{rank.amountClosed}</td>
                                    <td className={"text-center text-dark fw-bold text-uppercase align-middle"}>{rank.amountCancelled}</td>
                                    <td className={"text-center text-dark fw-bold text-uppercase align-middle"}>{rank.amountOnHold}</td>
                                  </tr>
                                </tbody>
                              );
                            })}
                          </>
                        </table>}
                      </div>
                    </div>
                  </div>
                </div>
            }
          </>
      }
    </div>
  );
};