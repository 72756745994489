
export const TopLeaderboardSitDown = ({ tierList }) => {
  return (
    <div>
      <div className='d-flex flex-column justify-content-center align-items-start'>
        {tierList.map((leader, index, arr) => {
          return (
            <div className="" key={"leader" + leader.name}>
              {
                index === 0
                  ?
                  <div className="d-flex justify-content-center align-items-center position-relative p-3">
                    <div className="d-flex justify-content-center align-items-center rounded-pill bg-primary-dark w-70 row">
                      <span style={{ wordSpacing: "99999px" }} className="text-light text-wrap fw-bold mx-3 col-5">{arr[0].name}</span>
                      <div className="col">
                        <span className="text-light text-bold me-1">{arr[0].amount}</span>
                        <span className="text-light text-bold me-5">SD</span>
                      </div>
                    </div>
                    <img style={{ objectFit: "cover" }} className={'imgg-2 rounded-pill'} width={75} height={75} loading="lazy" src={arr[0].photo ? arr[0].photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} />
                    <span className={'position-logo-1st-2'}>
                      {
                        arr[0].office === 'Boca Raton' ?
                          <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462423/offices_logos/boca100x100_wzjla8.png'} width={35} height={35} />
                          :
                          arr[0].office === 'Jacksonville' ?
                            <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462421/offices_logos/jax100x100_oqyqj7.png'} width={35} height={35} />
                            :
                            arr[0].office === 'Bradenton' ?
                              <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462425/offices_logos/brad100x100_g0cxrl.png'} width={35} height={35} />
                              :
                              <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462427/offices_logos/cape100x100_zsjjn4.png'} width={35} height={35} />
                      }
                    </span>
                  </div>
                  :
                  index === 1
                    ?
                    <div className="d-flex justify-content-center align-items-center position-relative p-3 me-2nd">
                      <div className="d-flex justify-content-center align-items-center rounded-pill bg-primary-dark w-80 row">
                        <span style={{ wordSpacing: "99999px" }} className="text-light text-wrap fw-bold mx-3 col-4">{arr[1].name}</span>
                        <div className="col">
                          <span className="text-light text-bold me-1">{arr[1].amount}</span>
                          <span className="text-light text-bold me-5">SD</span>
                        </div>
                      </div>
                      <img style={{ objectFit: "cover" }} className={arr[1].position === 1 ? 'imggs-2 rounded-pill' : 'imgs-2 rounded-pill'} width={75} height={75} loading="lazy" src={arr[1].photo ? arr[1].photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} />
                      <span className={'position-logo-2nd-2'}>
                        {
                          arr[1].office === 'Boca Raton' ?
                            <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462423/offices_logos/boca100x100_wzjla8.png'} width={35} height={35} />
                            :
                            arr[1].office === 'Jacksonville' ?
                              <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462421/offices_logos/jax100x100_oqyqj7.png'} width={35} height={35} />
                              :
                              arr[1].office === 'Bradenton' ?
                                <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462425/offices_logos/brad100x100_g0cxrl.png'} width={35} height={35} />
                                :
                                <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462427/offices_logos/cape100x100_zsjjn4.png'} width={35} height={35} />
                        }
                      </span>
                    </div>
                    :
                    <div className="d-flex justify-content-center align-items-center position-relative p-3 me-3rd">
                      <div className="d-flex justify-content-center align-items-center rounded-pill bg-primary-dark w-90 row">
                        <span style={{ wordSpacing: "99999px" }} className="text-light text-wrap fw-bold mx-3 col-4">{arr[2].name}</span>
                        <div className="col">
                          <span className="text-light text-bold me-1">{arr[2].amount}</span>
                          <span className="text-light text-bold me-5">SD</span>
                        </div>
                      </div>
                      <img style={{ objectFit: "cover" }} className={arr[2].position === 1 ? 'imggc-2 rounded-pill' : arr[2].position === 2 ? 'imgss-2 rounded-pill' : 'imgc-2 rounded-pill'} width={75} height={75} loading="lazy" src={leader.photo ? leader.photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} />
                      <span className={'position-logo-3rd-2'}>
                        {
                          arr[2].office === 'Boca Raton' ?
                            <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462423/offices_logos/boca100x100_wzjla8.png'} width={35} height={35} />
                            :
                            arr[2].office === 'Jacksonville' ?
                              <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462421/offices_logos/jax100x100_oqyqj7.png'} width={35} height={35} />
                              :
                              arr[2].office === 'Bradenton' ?
                                <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462425/offices_logos/brad100x100_g0cxrl.png'} width={35} height={35} />
                                :
                                <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462427/offices_logos/cape100x100_zsjjn4.png'} width={35} height={35} />
                        }
                      </span>
                    </div>
              }
            </div>
          )
        })}
      </div>
    </div>
  );
};
