import { isMobile } from 'react-device-detect';
import { LoadingSpinnerBlue } from '../../ui/LoadingSpinnerBlue';
import { format } from 'date-fns';
import { useState } from 'react';

export const ProjectItem = ({ data, loading, setData }) => {

  const [sortName, setSortName] = useState('date');
  const [sortOrder, setSortOrder] = useState('ASC');

  const sortDate = async (sortN) => {
    setSortName(sortN);
    let usersSorted = [];
    if (sortOrder === 'ASC') {
      setSortOrder('DESC');
      usersSorted = data.sort((a, b) => {
        if (new Date(a.project_date) < new Date(b.project_date)) {
          return -1;
        }
        if (new Date(a.project_date) > new Date(b.project_date)) {
          return 1;
        }
        return 0;
      })
    } else {
      setSortOrder('ASC');
      usersSorted = data.sort((a, b) => {
        if (new Date(b.project_date) < new Date(a.project_date)) {
          return -1;
        }
        if (new Date(b.project_date) > new Date(a.project_date)) {
          return 1;
        }
        return 0;
      })
    }
    setData(usersSorted);
  };

  if (loading) {
    return <LoadingSpinnerBlue disableCenter={true} />
  }
  return (
    <div className='container-fluid table-responsive mb-2'>
      <>
        <>
          {data && <table className={isMobile ? "d-block overflow-scroll table table-sm table-bordered bg-light table-striped rounded rounded-3 overflow-hidden" : "table table-striped bg-light table-hover overflow-hidden"}>
            <thead className='bg-dark text-light align-middle'>
              <tr>
                <th className="text-center" scope="col">Lead Name</th>
                <th className="text-center" scope="col">Closer</th>
                <th className="text-center" scope="col">Canvasser</th>
                <th className="text-center" scope="col">Office</th>
                <th className="text-center" scope="col">Status</th>
                <th role='button' onClick={() => { sortDate('date') }} className="text-center" scope="col">
                  {sortName === 'date' && <i className={sortOrder === 'DESC' ? 'fa fa-arrow-up-wide-short' : 'fa fa-arrow-down-short-wide'}></i>} Project Date
                </th>
                <th className="text-center" scope="col">Percentage</th>
              </tr>
            </thead>

            {data.length > 0 && data.map(item => {
              return (
                <tbody key={item.id}>
                  <tr data-bs-toggle="collapse" id={'fu' + item.id} href={'#' + 'u' + item.id} role="button" aria-expanded="false" aria-controls={'u' + item.id}>
                    <td className="text-center text-dark text-uppercase">{item.name} {item.last_name}</td>
                    <td className="text-center text-dark">{item.closer}</td>
                    <td className="text-center text-dark">{item.canvasser}</td>
                    <td className="text-center text-dark">{item.office}</td>
                    <td className="text-center text-dark">{item.type}</td>
                    <td className="text-center text-dark">{format(new Date(item.project_date), 'dd-MM-yyyy')}</td>
                    <td className="text-center text-dark">{item.project_percentage === '' ? 'Empty' : item.project_percentage + ' %'}</td>
                  </tr>
                  <tr>
                    <td colSpan="12" className="p-0 bg-dark">
                      <div className="collapse " id={'u' + item.id}>
                        <div className="overflowing vw-98">
                          <table className="table table-striped">
                            <thead className="bg-default text-light">
                              <tr className='align-middle'>
                                {item.project_details.length > 0 && item.project_details.map(detail => {
                                  return (
                                    <th className="text-center small text-light">{detail[0]}</th>
                                  );
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              <tr className='align-middle bg-light'>
                                {item.project_details.length > 0 && item.project_details.map(detail => {
                                  return (
                                    <td className="text-center small text-dark col-1">{detail[1].finished ? <span className='d-flex flex-column justify-content-center align-items-center'><i className='fa fa-check-square text-success'></i> {format(new Date(detail[1].date), 'dd-MM-yyyy')} </span> : <i className='fa fa-cancel text-danger'></i>}</td>
                                  );
                                })}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>}
        </>
      </>
    </div>);
};
