import useSWR from "swr";
import { RowsMobile } from './RowsMobile';
import { LoadingSpinnerBlue } from '../ui/LoadingSpinnerBlue';

export const AllCardsMobile = ({ id }) => {
  const { data: cards, error, isLoading } = useSWR(`usercards/filter2/${id}`);
  return (
    <>
      {
        isLoading
          ?
          <LoadingSpinnerBlue />
          :
          <div>
            {cards?.cards?.length > 0 && <RowsMobile cards={cards?.cards} />}
          </div>
      }
    </>
  );
};
