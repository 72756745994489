import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import useSWR from "swr";

import { useState } from "react";
import { useEffect } from "react";
import { format, isValid } from "date-fns";
import { isMobile } from "react-device-detect";
import { fetchConToken } from "../../../helpers/fetch";
import { ProjectItem } from "./ProjectItem";
import { ProjectGraph } from "./ProjectGraph";
// import { MondayItems } from "./MondayItems";

export const ProjectsScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [percentages, setPercentages] = useState([]);
  const [date, setDate] = useState(["", ""]);
  const [mode, setMode] = useState('detail');
  const [type, setType] = useState('ALL');
  const [office, setOffice] = useState('ALL');
  const [state, setState] = useState('ALL');
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const datee = format(new Date(), 'MM-dd-yyyy HH:mm');

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const body = await fetchConToken(`userdata/projectdata?type=${type}&office=${office}&state=${state}&status=${selectedCheckboxes}`);
      setData(body.data);
      setPercentages(body.percentages);
      setLoading(false);
    }
    init();
    if (isValid(datee)) {
      setDate([datee, datee])
    }
  }, [])


  const handleType = async (typee) => {
    setType(typee);
    setLoading(true);

    document.querySelectorAll('.btn-type').forEach(button => {
      if (button.textContent !== typee) {
        button.classList.remove('active');
      } else {
        button.classList.add('active');
      }
    });
    if (date[0] === "" && date[1] === "") {
      const body = await fetchConToken(`userdata/projectdata?type=${typee}&office=${office}&state=${state}&status=${selectedCheckboxes}`);
      setData(body.data);
      setPercentages(body.percentages);
      setLoading(false);
    } else {
      const body = await fetchConToken(`userdata/projectdata/bydate?type=${typee}&office=${office}&state=${state}&status=${selectedCheckboxes}`, { date }, 'POST');
      setData(body.data);
      setPercentages(body.percentages);
      setLoading(false);
    }
  }

  const handleOffice = async (officee) => {
    let officeCasted = "";
    switch (officee) {
      case "Boca Raton":
        officeCasted = "Boca"
        break;
      case "Bradenton":
        officeCasted = "Bradenton New"
        break;

      default:
        officeCasted = officee
        break;
    }
    setOffice(officeCasted);
    setLoading(true);
    document.querySelectorAll('.btn-office').forEach(button => {
      if (button.textContent !== officee) {
        button.classList.remove('active');
      } else {
        button.classList.add('active');
      }
    });
    if (date[0] === "" && date[1] === "") {
      const body = await fetchConToken(`userdata/projectdata?type=${type}&office=${officeCasted}&state=${state}&status=${selectedCheckboxes}`);
      setData(body.data);
      setPercentages(body.percentages);
      setLoading(false);
    } else {
      const body = await fetchConToken(`userdata/projectdata/bydate?type=${type}&office=${officeCasted}&state=${state}&status=${selectedCheckboxes}`, { date }, 'POST');
      setData(body.data);
      setPercentages(body.percentages);
      setLoading(false);
    }
  }

  const handleState = async (statee) => {
    let stateCasted = "";
    switch (statee) {
      case "Empty":
        stateCasted = ""
        break;
      default:
        stateCasted = statee
        break;
    }

    setState(stateCasted);
    setLoading(true);
    document.querySelectorAll('.btn-state').forEach(button => {
      if (button.textContent !== statee) {
        button.classList.remove('active');
      } else {
        button.classList.add('active');
      }
    });
    if (date[0] === "" && date[1] === "") {
      const body = await fetchConToken(`userdata/projectdata?type=${type}&office=${office}&state=${stateCasted}&status=${selectedCheckboxes}`);
      setData(body.data);
      setPercentages(body.percentages);
      setLoading(false);
    } else {
      const body = await fetchConToken(`userdata/projectdata/bydate?type=${type}&office=${office}&state=${stateCasted}&status=${selectedCheckboxes}`, { date }, 'POST');
      setData(body.data);
      setPercentages(body.percentages);
      setLoading(false);
    }
  }

  const handleMode = (mode) => {
    setMode(mode);
  }

  const handleCheckboxChange = async (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedCheckboxes((prevState) => [...prevState, value]);
    } else {
      setSelectedCheckboxes((prevState) => prevState.filter(item => item !== value));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (date[0] === "" && date[1] === "") {
        const body = await fetchConToken(`userdata/projectdata?type=${type}&office=${office}&state=${state}&status=${selectedCheckboxes}`);
        setData(body.data);
        setPercentages(body.percentages);
        setLoading(false);
      } else {
        const body = await fetchConToken(`userdata/projectdata/bydate?type=${type}&office=${office}&state=${state}&status=${selectedCheckboxes}`, { date }, 'POST');
        setData(body.data);
        setPercentages(body.percentages);
        setLoading(false);
      }
    }
    if (selectedCheckboxes.length > 0) {
      fetchData();
    }

  }, [selectedCheckboxes]);

  const handleReturn = (e) => {
    navigate('/');
  };

  const onDateChange = async (e) => {
    setDate(e)
    setLoading(true);
    if (e) {
      const body = await fetchConToken(`userdata/projectdata/bydate?type=${type}&office=${office}&state=${state}&status=${selectedCheckboxes}`, { date: e }, 'POST');
      setData(body.data);
      setPercentages(body.percentages);
      setLoading(false);
    } else {
      const body = await fetchConToken(`userdata/projectdata?type=${type}&office=${office}&state=${state}&status=${selectedCheckboxes}`);
      setData(body.data);
      setPercentages(body.percentages);
      setLoading(false);
    }
  }
  return (
    <>
      {
        isMobile
          ?
          <div className="d-flex flex-column justify-content-center align-items-center text-center">
            {/* <h1 className="text-light h1 mb-2 mt-2">Sit Downs tracker</h1>
            <div className="d-flex flex-column justify-content-center align-items-center mb-3 w-90">
              <form className='form-group d-flex justify-content-center align-items-center w-100 m-2' onSubmit={handleSearch}>
                <input className='form-control me-2 text-center' title={t('filters.search_title')} placeholder='Search by name' type="text" name="cSearch" value={cSearch} onChange={handleSearchInputChange} />
                <button type="submit" className='btn btn-primary'><i className="fas fa-search"></i></button>
              </form>
              <div className="form-group">
                <DateRangePicker className="form-control" onChange={(e) => { onDateChange(e) }} value={date} />
              </div>
              <div className="d-flex flex-row justify-content-center align-items-center">
                <div className="dropdown dropdown-nav text-decoration-none d-flex justify-content-center align-items-center p-2">
                  <button className="text-light btn btn-primary me-3" title="Add deal" style={{ width: "50px" }} data-bs-toggle="collapse" data-bs-target="#statsCollapse">
                    <i className="fas fa-chart-bar fa-xl"></i>
                  </button>
                </div>
                <Link to="/adddeals">
                  <button className="btn btn-success me-3" title="Add deal" style={{ width: "50px" }}>
                    <i className="fas fa-plus-square fa-xl"></i>
                  </button>
                </Link>
              </div>
            </div>
            <div id="statsCollapse" className="collapse navbar-collapse w-90">
              {
                stats &&
                <div className="navbar-nav d-flex flex-column justify-content-center align-items-center bg-invi rounded-3 p-1 m-2 w-100" data-bs-toggle="collapse" data-bs-target="#notificationCollapse">
                  <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-primary text-light p-2 fw-bold w-100">Total Deals: {stats.sumTotalDeals}</span>
                  <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-dark text-light p-2 fw-bold w-100">Total SelfGens: {stats.sumTotalSelfGens}</span>
                  <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-success text-light p-2 fw-bold w-100">Total Closed: {stats.sumTotalDealsClosed}</span>
                  <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-danger text-light p-2 fw-bold w-100">Total Cancelled: {stats.sumTotalDealsCancelled}</span>
                  <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-warning text-light p-2 fw-bold w-100">Total On Hold: {stats.sumTotalDealsOnHold}</span>
                </div>
              }
            </div>
            <UsersSitDownsItems setLoading={setLoading} setDeals={setDeals} users={deals} page={page} loading={loading} isLoading={isLoading} mutate={mutate} />
            {
              isAdmin
              &&
              <>
                {
                  !isLoading &&
                  <div className="form-group d-flex flex-row justify-content-center">
                    <button className="btn btn-light mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleReturn}>
                      <i className="fa fa-arrow-rotate-left me-1"></i> {t('buttons.return')}
                    </button>
                  </div>}
              </>
            } */}
          </div>
          :
          <div className="d-flex flex-column justify-content-center align-items-center text-center bg-light">
            <h1 className="text-primary h1 mb-2 mt-2">Projects</h1>
            <div className="d-flex justify-content-between align-items-center mb-3 px-4 w-100">
              {/* <form className='form-group d-flex justify-content-center align-items-center w-30 m-2' onSubmit={handleSearch}>
                <label className="me-2">Name</label>
                <input className='form-control me-2 text-center' title={t('filters.search_title')} placeholder='Search by name' type="text" name="cSearch" value={cSearch} onChange={handleSearchInputChange} />
                <button type="submit" className='btn btn-primary'><i className="fas fa-search"></i></button>
              </form>
              <form className='form-group d-flex justify-content-center align-items-center w-30 m-2' onSubmit={handleSearchBoard}>
                <label className="me-2">Board</label>
                <input className='form-control me-2 text-center' title={t('filters.search_title')} placeholder='Search by board' type="text" name="cSearchBoard" value={cSearchBoard} onChange={handleSearchInputChange} />
                <button type="submit" className='btn btn-primary'><i className="fas fa-search"></i></button>
              </form> */}
              <div className="d-flex flex-column justify-content-center align-items-center bg-dark rounded-3 p-2">
                <span className="text-light text-bold">Display</span>
                <div className="d-flex gap-2 pt-2">
                  <button className="btn btn-primary" onClick={() => { handleMode('detail') }}>Detail</button>
                  <button className="btn btn-primary" onClick={() => { handleMode('graphic') }}>Graph</button>
                </div>
              </div>
              <div className="accordion" id="accordionFilter">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button me-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Filter
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionFilter">
                    <div className="accordion-body">
                      <div className="m-1">
                        <span>Lead status</span>
                        <div className="btn-group ms-2" role="group" aria-label="Status">
                          <button type="button" className="btn-type btn btn-primary active" onClick={() => { handleType('ALL') }}>ALL</button>
                          <button type="button" className="btn-type btn btn-primary" onClick={() => { handleType('DEAL-CLOSED') }}>DEAL-CLOSED</button>
                          <button type="button" className="btn-type btn btn-primary" onClick={() => { handleType('APPOINTMENT') }}>APPOINTMENT</button>
                          <button type="button" className="btn-type btn btn-primary" onClick={() => { handleType('CANCELLED') }}>CANCELLED</button>
                        </div>
                      </div>
                      <div className="m-1">
                        <span>Office</span>
                        <div className="btn-group ms-2" role="group" aria-label="Office">
                          <button type="button" className="btn-office btn btn-primary active" onClick={() => { handleOffice('ALL') }}>ALL</button>
                          <button type="button" className="btn-office btn btn-primary" onClick={() => { handleOffice('Bradenton') }}>Bradenton</button>
                          <button type="button" className="btn-office btn btn-primary" onClick={() => { handleOffice('Jacksonville') }}>Jacksonville</button>
                          <button type="button" className="btn-office btn btn-primary" onClick={() => { handleOffice('Boca Raton') }}>Boca Raton</button>
                          <button type="button" className="btn-office btn btn-primary" onClick={() => { handleOffice('Cape Coral') }}>Cape Coral</button>
                          <button type="button" className="btn-office btn btn-primary" onClick={() => { handleOffice('Bluenergy HQ') }}>Bluenergy HQ</button>
                          <button type="button" className="btn-office btn btn-primary" onClick={() => { handleOffice('New Jersey') }}>New Jersey</button>
                        </div>
                      </div>
                      <div className="m-1">
                        <span>Global State</span>
                        <div className="btn-group ms-2" role="group" aria-label="State">
                          <button type="button" className="btn-state btn btn-primary active" onClick={() => { handleState('ALL') }}>ALL</button>
                          <button type="button" className="btn-state btn btn-primary" onClick={() => { handleState('Empty') }}>Empty</button>
                          <button type="button" className="btn-state btn btn-primary" onClick={() => { handleState('10') }}>10</button>
                          <button type="button" className="btn-state btn btn-primary" onClick={() => { handleState('20') }}>20</button>
                          <button type="button" className="btn-state btn btn-primary" onClick={() => { handleState('30') }}>30</button>
                          <button type="button" className="btn-state btn btn-primary" onClick={() => { handleState('40') }}>40</button>
                          <button type="button" className="btn-state btn btn-primary" onClick={() => { handleState('50') }}>50</button>
                          <button type="button" className="btn-state btn btn-primary" onClick={() => { handleState('60') }}>60</button>
                          <button type="button" className="btn-state btn btn-primary" onClick={() => { handleState('70') }}>70</button>
                          <button type="button" className="btn-state btn btn-primary" onClick={() => { handleState('80') }}>80</button>
                          <button type="button" className="btn-state btn btn-primary" onClick={() => { handleState('90') }}>90</button>
                          <button type="button" className="btn-state btn btn-primary" onClick={() => { handleState('100') }}>100</button>
                        </div>
                      </div>
                      <div className="m-1 d-flex flex-column justify-content-center align-items-center">
                        <span>Find by state</span>
                        <div className="btn-group ms-2 w-100">
                          <div className="d-flex flex-column justify-content-center align-items-start w-100">
                            <label className="text-start">
                              <input
                                type="checkbox"
                                value="Sign Agreement"
                                className=""
                                onChange={handleCheckboxChange}
                                checked={selectedCheckboxes.includes('Sign Agreement')}
                              />
                              <span className="ms-2">Sign Agreement</span>
                            </label>
                            <label className="text-start">
                              <input
                                type="checkbox"
                                value="SOW"
                                className=""
                                onChange={handleCheckboxChange}
                                checked={selectedCheckboxes.includes('SOW')}
                              />
                              <span className="ms-2">SOW</span>
                            </label>
                            <label className="text-start">
                              <input
                                type="checkbox"
                                value="Engineering Submitted"
                                className=""
                                onChange={handleCheckboxChange}
                                checked={selectedCheckboxes.includes('Engineering Submitted')}
                              />
                              <span className="ms-2">Engineering Submitted</span>
                            </label>
                            <label className="text-start">
                              <input
                                type="checkbox"
                                value="Permit Submitted"
                                className=""
                                onChange={handleCheckboxChange}
                                checked={selectedCheckboxes.includes('Permit Submitted')}
                              />
                              <span className="ms-2">Permit Submitted</span>
                            </label>
                            <label className="text-start">
                              <input
                                type="checkbox"
                                value="Engineering Approved"
                                className=""
                                onChange={handleCheckboxChange}
                                checked={selectedCheckboxes.includes('Engineering Approved')}
                              />
                              <span className="ms-2">Engineering Approved</span>
                            </label>
                          </div>

                          <div className="d-flex flex-column justify-content-center align-items-start w-100">
                            <label className="text-start">
                              <input
                                type="checkbox"
                                value="Permit Approved"
                                className=""
                                onChange={handleCheckboxChange}
                                checked={selectedCheckboxes.includes('Permit Approved')}
                              />
                              <span className="ms-2">Permit Approved</span>
                            </label>
                            <label className="text-start">
                              <input
                                type="checkbox"
                                value="Installation Scheduled"
                                className=""
                                onChange={handleCheckboxChange}
                                checked={selectedCheckboxes.includes('Installation Scheduled')}
                              />
                              <span className="ms-2">Installation Scheduled</span>
                            </label>
                            <label className="text-start">
                              <input
                                type="checkbox"
                                value="Building Inspection Complete"
                                className=""
                                onChange={handleCheckboxChange}
                                checked={selectedCheckboxes.includes('Building Inspection Complete')}
                              />
                              <span className="ms-2">Building Inspection Complete</span>
                            </label>
                            <label className="text-start">
                              <input
                                type="checkbox"
                                value="Net Metering Application Applied"
                                className=""
                                onChange={handleCheckboxChange}
                                checked={selectedCheckboxes.includes('Net Metering Application Applied')}
                              />
                              <span className="ms-2">Net Metering Application Applied</span>
                            </label>
                            <label className="text-start">
                              <input
                                type="checkbox"
                                value="In Service"
                                className=""
                                onChange={handleCheckboxChange}
                                checked={selectedCheckboxes.includes('In Service')}
                              />
                              <span className="ms-2">In Service</span>
                            </label>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="d-flex flex-column justify-content-center align-items-center bg-dark rounded-3 p-2">
                <span className="text-light text-bold">Filter</span>
                <div className="d-flex gap-2 pt-2">
                  <button className="btn btn-primary" onClick={() => { handleMode('detail') }}>Detail</button>
                  <button className="btn btn-primary" onClick={() => { handleMode('graphic') }}>Graph</button>
                </div>
              </div> */}
              <div className="form-group">
                <DateRangePicker className="form-control" onChange={(e) => { onDateChange(e) }} value={date} />
              </div>
            </div>
            {mode === 'detail' && <ProjectItem data={data} loading={loading} setData={setData} />}
            {mode === 'graphic' && <ProjectGraph data={percentages} loading={loading} />}
          </div>
      }

    </>
  );
};
