import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import '../admin/uploadexcel.css';

import { useForm } from '../../hooks/useForm';
import { fetchConToken } from '../../helpers/fetch';

export const AddZoom = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formPostValues, handlePostInputChange] = useForm({
    sURL: ''
  });
  const { sURL } = formPostValues;

  const handleAddZoom = (e) => {
    e.preventDefault();
    if (sURL === '') {
      return Swal.fire(
        "Error",
        t('errors.fields_required'),
        "error"
      );
    }

    const startAddZoom = async (url) => {
      const body = await fetchConToken(
        `notifications/zoom`,
        { url},
        "POST"
      );
      if (body.ok) {
        Swal.fire(t('success.title'), 'Zoom link sent to users', "success");
      } else {
        Swal.fire("Error", body.msg, "error");
      }
    };
    startAddZoom(sURL);
  };
  const handleReturn = () => {
    navigate('/');
  };
  return (
    <>
      {
        isMobile
          ?
          <div className='d-flex flex-column justify-content-center align-items-center mt-4' data-aos="fade-up" data-aos-duration="1000">
            <h1 className='text-light h1 text-center'>Sent Zoom link to users</h1>
            <div className="col-md-6 login-form-2 m-4 w-90">
              <form onSubmit={handleAddZoom}>
                <div className="form-group">
                  <label className='text-light'>URL</label>
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder='Copy Zoom URL'
                    name="sURL"
                    value={sURL}
                    onChange={handlePostInputChange}
                  />
                </div>
                <div className="form-group d-flex justify-content-center mb-1">
                  <button type="submit" className="btn btn-success btn-bright">
                    <i className="fa fa-floppy-disk"></i> {t('buttons.save')}
                  </button>
                </div>
              </form>
              <div className="form-group d-flex flex-row justify-content-center">
                <button className="btn btn-light mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleReturn}>
                  <i className="fa fa-arrow-rotate-left me-1"></i> {t('buttons.return')}
                </button>
              </div>
            </div>
          </div>
          :
          <div className='d-flex flex-column justify-content-center align-items-center mt-4' data-aos="fade-up" data-aos-duration="1000">
            <h1 className='text-light'>Sent Zoom link to users</h1>
            <div className="col-md-6 login-form-2 m-4">
              <form onSubmit={handleAddZoom}>
                <div className="form-group">
                  <label className='text-light'>URL</label>
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder='Copy Zoom URL'
                    name="sURL"
                    value={sURL}
                    onChange={handlePostInputChange}
                  />
                </div>
                <div className="form-group d-flex justify-content-center mb-1">
                  <button type="submit" className="btn btn-success btn-bright">
                    <i className="fa fa-floppy-disk"></i> {t('buttons.save')}
                  </button>
                </div>
              </form>
              <div className="form-group d-flex flex-row justify-content-center">
                <button className="btn btn-light mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleReturn}>
                  <i className="fa fa-arrow-rotate-left me-1"></i> {t('buttons.return')}
                </button>
              </div>
            </div>
          </div>
      }
    </>
  );
};
