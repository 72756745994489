import { useEffect } from 'react';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import useSWR from "swr";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { format, isValid } from 'date-fns';
import { fetchConToken } from '../../../helpers/fetch';
import { LoadingSpinnerWhite } from '../../ui/LoadingSpinnerWhite';

export const AdminAppointments = () => {
  // const { data, isLoading } = useSWR(`userdata/usersdata/leads`);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(0);
  const date2 = new Date();

  const [date, setDate] = useState(["", ""]);

  const datee = format(new Date(), 'MM-dd-yyyy HH:mm');

  useEffect(() => {
    if (isValid(datee)) {
      setDate([datee, datee])
    }
  }, []);

  const handleDateChange = async (e) => {
    setLoading(true);
    setDate(e);
    const body = await fetchConToken('userdata/usersdata/appointments', { date: e }, 'POST')
    setUsers(body.leads);
    setLoading(false);
  };

  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  // useEffect(() => {
  //   setUsers(data?.leads)
  // }, [data]);

  const goBack = () => {
    navigate('/statsdata');
  }

  return (
    <div className='bg-primary vh-110'>
      {/* <img className='position-absolute' src={'https://res.cloudinary.com/drholseqp/image/upload/v1697207405/blue_za5f17.jpg'} style={{ objectFit: "cover", width: "100vw", height: "110vh" }} alt="back" /> */}
      <div className="d-flex flex-column justify-content-center align-items-center text-center">
        <label className='text-light fw-bold'>Select a day</label>
        <DateRangePicker className={"bg-light rounded-3 mb-1"} onChange={(e) => { handleDateChange(e) }} value={date} />
      </div>
      {
        isMobile
          ?
          <div className='position-relative'>
            <div className='d-flex justify-content-center align-items-center pt-2'>
              <button onClick={goBack} className='btn btn-light'><i className='fa fa-chevron-left'></i></button>
            </div>
            <img className='logo-be-admin-stats' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694440370/images-logos/001_sc2glr.png'} style={{ objectFit: "contain" }} width={200} height={100} alt="back" />
            <img className='logo-be-admin-stats-2' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704224590/Asset_2_zo1wyw.png'} style={{ objectFit: "contain" }} width={200} height={100} alt="back" />
            <div className='d-flex flex-column justify-content-center align-items-center text-center top-admin-stats w-95'>
              <span className='font-special-total-deals-small'>FROM {date[0] && format(new Date(date[0]), 'MM-dd-yyyy')}</span>
              <span className='font-special-total-deals-small mb-3'>TO {date[1] && format(new Date(date[1]), 'MM-dd-yyyy')}</span>
              {/* <span className='font-special-total-deals-month mb-3'>{date && monthNames[date[0].getMonth()]}</span> */}
              <span className='font-special-total-deals-small'>TOP APPOINTMENTS</span>
              <div className='w-100'>
                {loading
                  ?
                  <LoadingSpinnerWhite disableCenter={true} />
                  :
                  <table className={isMobile ? "table table-sm table-striped mt-3" : "table table-striped table-hover table-borderless table-sm overflow-hidden mt-3"}>
                    <>
                      {users.length > 0 && users.map(rank => {
                        return (
                          <tbody key={rank.user?.id}>
                            <tr className='rounded-3'>
                              <td className={"text-center text-light fw-bold align-middle"}>#{rank.position}</td>
                              {/* <td className={"text-center text-light fw-bold align-middle"}><img style={{ objectFit: "cover" }} className='rounded-pill' src={rank.user.photo ? rank.user.photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1694003940/images-logos/user_pyqy6e.png'} width={35} height={35} /></td> */}
                              <td className={"text-center text-light fw-bold text-uppercase align-middle"}>{rank.name}</td>
                              {/* <td className={"text-center text-light align-middle"}>{
                                rank.user?.office === 'Boca Raton' ?
                                  <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462423/offices_logos/boca100x100_wzjla8.png'} width={35} height={35} />
                                  :
                                  rank.user?.office === 'Jacksonville' ?
                                    <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462421/offices_logos/jax100x100_oqyqj7.png'} width={35} height={35} />
                                    :
                                    rank.user?.office === 'Bradenton' ?
                                      <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462425/offices_logos/brad100x100_g0cxrl.png'} width={35} height={35} />
                                      :
                                      <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462427/offices_logos/cape100x100_zsjjn4.png'} width={35} height={35} />
                              }</td> */}
                              <td className={"align-middle text-center font-special-rank-stats bg-light rounded-3 fw-bold"}>{rank.appointments}</td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </>
                  </table>}
              </div>
              {/* <div>
                  <button onClick={goBack} className='btn btn-light'><i className='fa fa-chevron-left'></i></button>
                </div> */}
            </div>
          </div>
          :
          <div className='w-100 text-center position-absolute'>
            <div className='d-flex flex-column justify-content-center align-items-center vh-100'>
              <img className='position-absolute top-0' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694440370/images-logos/001_sc2glr.png'} style={{ objectFit: "contain" }} width={150} height={75} alt="back" />
              <div className='d-flex flex-column justify-content-center align-items-center position-absolute top-10 w-100'>
                <span className='font-special-total-deals-small'>FROM {date[0] && format(new Date(date[0]), 'MM-dd-yyyy')}</span>
                <span className='font-special-total-deals-small mb-3'>TO {date[1] && format(new Date(date[1]), 'MM-dd-yyyy')}</span>
                {/* <span className='font-special-total-deals-month mb-3'>{date && monthNames[date[0].getMonth()]}</span> */}
                <span className='font-special-total-deals-small'>TOP APPOINTMENTS</span>
                <div className='w-100'>
                  {loading
                    ?
                    <LoadingSpinnerWhite disableCenter={true} />
                    :
                    <table className={isMobile ? "table table-sm table-striped mt-3" : "table table-striped table-hover table-borderless table-sm overflow-hidden mt-3"}>
                      <>
                        {users.length > 0 && users.map(rank => {
                          return (
                            <tbody key={rank.user?.id}>
                              <tr className='rounded-3'>
                                <td className={"text-center text-light fw-bold align-middle"}>#{rank.position}</td>
                                {/* <td className={"text-center text-light fw-bold align-middle"}><img style={{ objectFit: "cover" }} className='rounded-pill' src={rank.user.photo ? rank.user.photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1694003940/images-logos/user_pyqy6e.png'} width={35} height={35} /></td> */}
                                <td className={"text-center text-light fw-bold text-uppercase align-middle"}>{rank.name}</td>
                                {/* <td className={"text-center text-light align-middle"}>{
                                rank.user?.office === 'Boca Raton' ?
                                  <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462423/offices_logos/boca100x100_wzjla8.png'} width={35} height={35} />
                                  :
                                  rank.user?.office === 'Jacksonville' ?
                                    <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462421/offices_logos/jax100x100_oqyqj7.png'} width={35} height={35} />
                                    :
                                    rank.user?.office === 'Bradenton' ?
                                      <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462425/offices_logos/brad100x100_g0cxrl.png'} width={35} height={35} />
                                      :
                                      <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462427/offices_logos/cape100x100_zsjjn4.png'} width={35} height={35} />
                              }</td> */}
                                <td className={"align-middle text-center font-special-rank-stats bg-light rounded-3 fw-bold"}>{rank.appointments}</td>
                              </tr>
                            </tbody>
                          );
                        })}
                      </>
                    </table>}
                </div>
                <div>
                  <button onClick={goBack} className='btn btn-light'><i className='fa fa-chevron-left'></i></button>
                </div>
              </div>
            </div>
          </div>
      }
    </div>
  );
};
