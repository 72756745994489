import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import useSWR from "swr";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { LoadingSpinnerBlue } from '../../ui/LoadingSpinnerBlue';
import { isMobile } from 'react-device-detect';

export const GraphActualWeek = ({ id }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState({});
  const [pointStartDate, setPointStartDate] = useState(new Date(null));
  const { data, isLoading } = useSWR(`userdata/userdatacloser/actual/week/${id}`);

  useEffect(() => {
    setPointStartDate(new Date(data?.hc[0]?.date));
    setOptions({
      chart: {
        type: 'area',
      },
      title: {
        text: 'Actual week deals',
        style: {
          color: '#0d6dfd'
        }
      },
      series: [{
        data: data?.hcData
      }],
      plotOptions: {
        series: {
          pointStart: data?.hc && pointStartDate.getTime(),
          pointInterval: 24 * 3600 * 1000
        }
      },
      legend: {
        enabled: false
      },
      yAxis: [{
        className: 'highcharts-color-0',
        title: {
          text: "Deals",
          style: {
            color: 'black'
          }
        },
        labels: {
          style: {
            color: 'black'
          }
        }
      }],
      xAxis: [{
        className: 'highcharts-color-1',
        title: {
          text: "Time frame",
          style: {
            color: 'black'
          }
        },
        crosshair: {
          width: 1
        },
        type: 'datetime',
        labels: {
          format: "{value:%d %m }",
          style: {
            color: 'black'
          }
        },
      }]
    })
  }, [data])

  return (
    <div className="text-center mb-3 w-100">
      {isLoading
        ?
        <LoadingSpinnerBlue disableCenter={true} />
        :
        <div>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            containerProps={{ className: 'chart', style: isMobile ? { height: "50vh" } : { height: "70vh" } }}
          />
        </div>
      }
    </div>
  );
};
