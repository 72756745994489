import { useCallback, useState } from "react";
import Modal from "react-modal";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { uiCloseModalResetPassword } from "../../actions/ui";
import { useForm } from "../../hooks/useForm";
import { fetchConToken } from "../../helpers/fetch";
import { login, startGetUser } from "../../actions/auth";
import "./flmodal.css";

const customStyles = {};
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

export const ModalPasswordReset = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { modalOpenResetPassword } = useSelector((state) => state.ui);
  const [isForgot, setIsForgot] = useState(true);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [code, setCode] = useState(false);

  const [isHidden, setIsHidden] = useState(true);
  const [formUserValues, handleUserInputChange] = useForm({
    rPassword: '',
    rPasswordConfirm: '',
    rEmail: '',
    rCode: ''
  });

  const { rPassword, rPasswordConfirm, rEmail, rCode } = formUserValues;

  const closeModal = useCallback(() => {
    dispatch(uiCloseModalResetPassword());
  }, [dispatch]);

  const handleForgot = async (e) => {
    e.preventDefault();
    const forgotPassword = async (email) => {
      const body = await fetchConToken("auth/forgotpassword", { email }, 'POST');
      if (body.ok) {
        setIsCodeSent(true);
        setIsForgot(false);
        setCode(body.code);
        Swal.fire(t('success.title'), "Code sent to your email.", "success");
      } else {
        Swal.fire("Error", body.msg, "error");
      }
    }
    forgotPassword(rEmail);
  };

  const handleCode = async (e) => {
    e.preventDefault();
    if (code === rCode) {
      setIsReset(true);
      setIsCodeSent(false);
    } else {
      Swal.fire("Error", 'Code not match', "error");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (rPassword === "" || rPasswordConfirm === "") {
      return Swal.fire(
        "Error",
        t('errors.fields_required'),
        "error"
      );
    }
    if (rPassword !== rPasswordConfirm) {
      return Swal.fire(
        "Error",
        t('errors.password_match'),
        "error"
      );
    }
    const resetPassword = async (email, password) => {
      const body = await fetchConToken("auth/resetpassword", { email, password }, 'POST');
      if (body.ok) {
        localStorage.setItem("token", body.token);
        localStorage.setItem("token-init-date", new Date().getTime());
        dispatch(login({ uid: body.uid, name: body.name, role: body.role, office: body.office, photo: body.photo, points: body.points }));
        dispatch(startGetUser(body.uid));
        closeModal();
        Swal.fire(t('success.title'), "Password reset", "success");
      } else {
        Swal.fire("Error", body.msg, "error");
      }
    }
    resetPassword(rEmail, rPassword);
  };

  const setHidden = () => {
    if (isHidden) {
      setIsHidden(false);
    } else {
      setIsHidden(true);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Modal
        isOpen={modalOpenResetPassword}
        onRequestClose={closeModal}
        style={customStyles}
        closeTimeoutMS={200}
        className="flmodal"
        ariaHideApp={process.env.NODE_ENV === "test"}
      >
        <div className="d-flex justify-content-center align-items-center">
          {isForgot &&
            <div className="d-flex flex-column justify-content-center align-items-center p-4">
              <div className="d-flex flex-column justify-content-center align-items-center text-center">
                <b style={{ fontSize: "30px" }}>Enter your email</b>
              </div>
              <hr className="border border-dark" />
              <div className="d-flex flex-column justify-content-center align-items-center w-100">
                <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                  <form onSubmit={handleForgot} className="w-95">
                    <div className="form-group mb-2">
                      <label>Email</label>
                      <input
                        type="text"
                        className="mb-2 form-control"
                        placeholder="Email"
                        name="rEmail"
                        value={rEmail}
                        onChange={handleUserInputChange}
                      />
                    </div>
                    <div className="form-group d-flex justify-content-center mb-1">
                      <button type="submit" className="btn btn-primary btn-bright">
                        <i className="fa fa-paper-plane"></i> Send Code
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          }

          {isCodeSent &&
            <div className="d-flex flex-column justify-content-center align-items-center p-2">
              <div className="d-flex flex-column justify-content-center align-items-center text-center">
                <b style={{ fontSize: "30px" }}>Enter the code sent to your email</b>
              </div>
              <hr className="border border-dark" />
              <div className="d-flex flex-column justify-content-center align-items-center w-100">
                <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                  <form onSubmit={handleCode} className="w-95">
                    <div className="form-group mb-2">
                      <label>Code</label>
                      <div className='d-flex flex-row align-items-center'>
                        <input
                          type="text"
                          className="mb-2 form-control"
                          placeholder="Code"
                          name="rCode"
                          value={rCode}
                          onChange={handleUserInputChange}
                        />
                      </div>
                    </div>
                    <div className="form-group d-flex justify-content-center mb-1">
                      <button type="submit" className="btn btn-primary btn-bright"><i className="fa fa-check"></i> Verify</button>
                    </div>
                    <div className="form-group d-flex justify-content-center mb-1">
                      <button type="button" onClick={handleForgot} className="btn btn-primary btn-bright"><i className="fa fa-paper-plane"></i> Resend Code</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          }

          {isReset &&
            <div className="d-flex flex-column justify-content-center align-items-center p-2">
              <div className="d-flex flex-column justify-content-center align-items-center text-center">
                <b style={{ fontSize: "30px" }}>Enter your new password</b>
              </div>
              <hr className="border border-dark" />
              <div className="d-flex flex-column justify-content-center align-items-center w-100">
                <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                  <form onSubmit={handleUpdate} className="w-95">
                    <div className="form-group mb-2">
                      <label>{t('labels.password')}</label>
                      <div className='d-flex flex-row align-items-center'>
                        <input
                          type={isHidden ? "password" : "text"}
                          className="mb-2 form-control"
                          placeholder={t('labels.password')}
                          name="rPassword"
                          value={rPassword}
                          autoComplete="new-password"
                          onChange={handleUserInputChange}
                        />
                        <button style={{ width: "15%", height: "15%" }} className={isHidden ? 'btn btn-success btn-sm mb-2 ms-1' : 'btn btn-danger btn-sm mb-2 ms-1'} onClick={setHidden} type="button">{isHidden ? <i className="fa-solid fa-eye"></i> : <i className="fa-solid fa-eye-slash"></i>}</button>
                      </div>
                    </div>
                    <div className="form-group mb-2">
                      <label>{t('labels.confirm_password')}</label>
                      <div className='d-flex flex-row align-items-center'>
                        <input
                          type={isHidden ? "password" : "text"}
                          className="mb-2 form-control"
                          placeholder={t('labels.confirm_password')}
                          name="rPasswordConfirm"
                          value={rPasswordConfirm}
                          autoComplete="new-password"
                          onChange={handleUserInputChange}
                        />
                        <button style={{ width: "15%", height: "15%" }} className={isHidden ? 'btn btn-success btn-sm mb-2 ms-1' : 'btn btn-danger btn-sm mb-2 ms-1'} onClick={setHidden} type="button">{isHidden ? <i className="fa-solid fa-eye"></i> : <i className="fa-solid fa-eye-slash"></i>}</button>
                      </div>
                    </div>
                    <div className="form-group d-flex justify-content-center mb-1">
                      <button type="submit" className="btn btn-primary btn-bright">
                        <i className="fa fa-floppy-disk"></i> {t('buttons.save')}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          }
        </div>
      </Modal>
    </div>
  );
};
