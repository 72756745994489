import { useState } from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { LoadingSpinner } from '../ui/LoadingSpinner';

export const OptionScreen = () => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      {
        loading
          ?
          <LoadingSpinner />
          :
          <div>
            {
              isMobile
                ?
                <div className='container d-flex flex-column justify-content-center align-items-center text-center' data-aos="fade-up" data-aos-duration="1000">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="dropdown-center">
                      <button className="btn btn-light dropdown-toggle m-2" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fas fa-cog"></i> Actions
                      </button>
                      <ul className="dropdown-menu bg-invi p-0 m-0 border-0" aria-labelledby="dropdownMenuButton1">
                        <div className='gap-1 d-flex flex-column justify-content-center align-items-center'>
                          <Link to="/convertexcel">
                            <button className="btn btn-light bg-invi text-light" title="List users" style={{ width: "250px" }}>
                              <i className="fas fa-file-excel"></i> Convert excel
                            </button>
                          </Link>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
                :
                <div className='container-fluid text-center' data-aos="fade-up" data-aos-duration="1000">
                  <div className="d-flex flex-column justify-content-center align-items-center pt-3">
                    <div className="dropdown-center">
                      <button className="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fas fa-cog"></i> Actions
                      </button>
                      <ul className="dropdown-menu bg-invi p-0 m-0 border-0" aria-labelledby="dropdownMenuButton1">
                        <div className='gap-1 d-flex flex-column justify-content-center align-items-center'>
                          <Link to="/convertexcel">
                            <button className="btn btn-light bg-invi text-light" title="List users" style={{ width: "250px" }}>
                              <i className="fas fa-file-excel"></i> Convert excel
                            </button>
                          </Link>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
            }
          </div>
      }
    </>
  );
};
