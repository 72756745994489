import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import Select from 'react-select';
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import useSWR from "swr";

import { useForm } from "../../hooks/useForm";
import colourStyles from '../../helpers/selectStyles';
import Axios from "axios";
import { fetchSinToken } from "../../helpers/fetch";
import { format, isValid } from "date-fns";
import toPascalCase from "../../helpers/pascalCase";

const fetcher = (url) => fetchSinToken(url);

export const RegisterScreen = () => {
  const { t } = useTranslation();

  const [userSelected, setUserSelected] = useState(false);
  const [notPassword, setNotPassword] = useState(false);
  const [file, setFile] = useState(null);
  const handleFile = ({ target }) => {
    setFile(target.files[0]);
  };

  const datee = format(new Date(), 'MM-dd-yyyy HH:mm');

  useEffect(() => {
    if (isValid(datee)) {
      setBirthDate(datee)
    }
  }, []);


  const [formRegisterValues, handleRegisterInputChange, reset] = useForm({
    rName: '',
    rLastname: '',
    rEmail: '',
    rPassword: '',
    rPasswordConf: ''
  });

  const { rEmail, rPassword, rName, rLastname, rPasswordConf } = formRegisterValues;

  const [role, setRole] = useState(null);
  const roles = [
    { value: 'Closer', label: 'Closer' },
    { value: 'Canvasser', label: 'Canvasser' },
    { value: 'CallCenter New', label: 'CallCenter' },
  ];
  const handleRole = (e) => {
    setRole(e);
  };

  const [office, setOffice] = useState(null);
  const offices = [
    { value: 'Boca Raton', label: 'Boca Raton' },
    { value: 'Bradenton', label: 'Bradenton' },
    { value: 'Cape Coral', label: 'Cape Coral' },
    { value: 'Jacksonville', label: 'Jacksonville' },
    { value: 'Argentina', label: 'Argentina' },
  ];

  const [birthDate, setBirthDate] = useState("");
  const handleBirthDateChange = (e) => {
    setBirthDate(e);
  };

  const { data: usersApi } = useSWR("auth/users/notadmin", fetcher);
  const users = [];
  if (usersApi?.users) {
    const usersLen = usersApi?.users.length
    for (let i = 0; i < usersLen; i++) {
      const user = {
        value: usersApi?.users[i].id,
        label: usersApi?.users[i].name + ' ' + usersApi?.users[i].last_name,
        user: usersApi?.users[i]
      }
      users.push(user);
    }
  }
  const [user, setUser] = useState(null);
  const handleUser = (e) => {
    setUser(e);
    setUserSelected(true);
    setNotPassword(true);
    reset({
      rName: e.user.name,
      rLastname: e.user.last_name,
      rEmail: e.user.email,
      rPassword: '',
      rPasswordConf: ''
    });
    setOffice(e.user.office ? { value: e.user.office, label: e.user.office } : null)
    setRole(e.user.role ? { value: e.user.role, label: e.user.role } : null)
  };


  const handleRegister = (e) => {
    e.preventDefault();
    if (rName === '' || rLastname === '' || rEmail === '') {
      return Swal.fire(
        "Error",
        t('errors.fields_required'),
        "error"
      );
    }

    if (!notPassword) {
      if (rPassword === '' || rPasswordConf === '') {
        return Swal.fire(
          "Error",
          t('errors.fields_required'),
          "error"
        );
      }
      if (rPassword !== rPasswordConf) {
        return Swal.fire(
          "Error",
          t('errors.password_match'),
          "error"
        );
      }
    }

    const fileUploadCloud = async () => {
      Swal.fire({
        title: 'Wait ...',
        onBeforeOpen() {
          Swal.showLoading()
        },
        onAfterClose() {
          Swal.hideLoading()
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false
      })
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "cardsapp");
      Axios.post("https://api.cloudinary.com/v1_1/drholseqp/upload", formData
      ).then((res) => {
        const startRegister = async (emaill, password = "123456", namee, lastnamee, office, photo, birth_date, role) => {
          const email = emaill.toLowerCase().trim();
          const last_name = toPascalCase(lastnamee.trim());
          const name = toPascalCase(namee.trim());
          const body = await fetchSinToken(
            "auth/new",
            { email, password, name, last_name, office, photo, birth_date, role },
            "POST"
          );
          if (body.ok) {
            Swal.fire("Success", body.msg, "success");
          } else {
            Swal.fire("Error", body.msg, "error");
          }
        }
        startRegister(rEmail, rPassword, rName, rLastname, office.value, res.data.secure_url, birthDate, role.value);
      });
    }
    if (file !== null && office !== null && role !== null) {
      fileUploadCloud();
    } else {
      Swal.fire("Error", t('errors.fields_required'), "error");
    }
  };
  const handleOffice = (e) => {
    setOffice(e);
  };
  const resetUser = () => {
    setUser(null);
    setUserSelected(false);
  };
  const setRegisterManual = () => {
    setUserSelected(true);
  };
  return (
    <>
      {isMobile ?
        <div className="container block-scroll">
          <h1 className="d-flex align-items-center justify-content-center mt-2" style={{ color: "#000" }}><img src={'https://res.cloudinary.com/drholseqp/image/upload/v1694200007/images-logos/logocolor_whitebg_small_rhhmat.png'} className="w-50" alt="Blue Energy Solar Logo" /></h1>
          <div className="row">
            <div className="col-md-11 col-11 login-form-1 m-3">
              <h3>{t('auth.register.title')}</h3>
              <form className="text-center" onSubmit={handleRegister}>
                <label>Find yourself</label>
                <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                  <div className="m-2 w-10">
                    <button type="button" onClick={resetUser} className="btn btn-dark"><i className="fa-solid fa-refresh fa-lg"></i></button>
                  </div>
                  <div className="w-100">
                    <Select placeholder={t('select.placeholder')} styles={colourStyles} options={users} value={user} onChange={handleUser} />
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center w-100">
                  <span className="fw-bold">Can´t find you?</span>
                  <div>
                    <span className="fw-bold me-2">Register</span>
                    <button type="button" onClick={setRegisterManual} className="btn btn-dark mb-2">HERE</button>
                  </div>
                </div>
                <div>
                  {
                    userSelected &&
                    <>
                      <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                        <div className="m-2 w-10">
                          <i className="fa-solid fa-envelope fa-lg"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t('labels.email')}
                          name="rEmail"
                          autoComplete="off"
                          value={rEmail}
                          onChange={handleRegisterInputChange}
                        />
                      </div>
                      {
                        !notPassword &&
                        <>
                          <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                            <div className="m-2 w-10">
                              <i className="fa-solid fa-lock fa-lg"></i>
                            </div>
                            <input
                              type="password"
                              className="form-control"
                              placeholder={t('labels.password')}
                              name="rPassword"
                              autoComplete="new-password"
                              value={rPassword}
                              onChange={handleRegisterInputChange}
                            />
                          </div>
                          <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                            <div className="m-2 w-10">
                              <i className="fa-solid fa-arrows-rotate fa-lg"></i>
                            </div>
                            <input
                              type="password"
                              className="form-control"
                              placeholder={t('labels.confirm_password')}
                              name="rPasswordConf"
                              autoComplete="new-password"
                              value={rPasswordConf}
                              onChange={handleRegisterInputChange}
                            />
                          </div>
                        </>
                      }
                      <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                        <div className="m-2 w-10">
                          <i className="fa-solid fa-user fa-lg"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t('labels.first_name')}
                          name="rName"
                          value={rName}
                          onChange={handleRegisterInputChange}
                        />
                      </div>
                      <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                        <div className="m-2 w-10">
                          <i className="fa-solid fa-id-card fa-lg"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t('labels.last_name')}
                          name="rLastname"
                          value={rLastname}
                          onChange={handleRegisterInputChange}
                        />
                      </div>
                      <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                        <div className="m-2 w-10">
                          <i className="fa-solid fa-building fa-lg"></i>
                        </div>
                        <div className="w-100">
                          <Select placeholder={t('select.placeholder')} styles={colourStyles} options={offices} value={office} onChange={handleOffice} />
                        </div>
                      </div>
                      <div className="form-group d-flex flex-row justify-content-center align-items-center">
                        <div className="m-2 w-10">
                          <i className="fa-solid fa-users-gear fa-lg"></i>
                        </div>
                        <div className="w-100">
                          <Select placeholder={t('select.placeholder')} styles={colourStyles} options={roles} value={role} onChange={handleRole} />
                        </div>
                      </div>
                      <div className="form-group text-center mt-2">
                        <label className='text-dark'>Birth date</label>
                        <DateTimePicker
                          onChange={handleBirthDateChange}
                          value={birthDate}
                          className="form-control"
                          format='MM-dd-yyyy HH:mm'
                        />
                      </div>
                      <div className="form-group files m-2">
                        <input id="excelfile" className='bg-light' type="file" onChange={(e) => handleFile(e)} />
                      </div>
                    </>
                  }
                </div>
                {
                  userSelected &&
                  <div className="form-group d-flex justify-content-center">
                    <input type="submit" className="btn btn-primary btn-bright" value={t('buttons.register')} />
                  </div>
                }
              </form>
              <div className="form-group d-flex justify-content-center mt-3">
                <h5>{t('auth.register.go_to_login')} <Link className='text-decoration-none text-primary' to={`/login`}>{t('buttons.login')}</Link></h5>
              </div>
            </div>

          </div>
        </div>
        :
        <div className="login-container" data-aos="fade-up" data-aos-duration="1000">
          <h1 className="d-flex align-items-center justify-content-center" style={{ color: "#000" }}><img src={'https://res.cloudinary.com/drholseqp/image/upload/v1694200007/images-logos/logocolor_whitebg_small_rhhmat.png'} className="w-30" alt="Blue Energy Solar Logo" /></h1>
          <div className="row mt-2">
            <div className="col-md-6 col-6 me-5 login-form-3">
              <h3>{t('auth.register.title')}</h3>
              <form onSubmit={handleRegister}>
                <label>Find yourself</label>
                <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                  <div className="m-2 w-10">
                    <button type="button" onClick={resetUser} className="btn btn-dark"><i className="fa-solid fa-refresh fa-lg"></i></button>
                  </div>
                  <div className="w-100">
                    <Select placeholder={t('select.placeholder')} styles={colourStyles} options={users} value={user} onChange={handleUser} />
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center w-100">
                  <span className="fw-bold">Can´t find you?</span>
                  <div>
                    <span className="fw-bold me-2">Register</span>
                    <button type="button" onClick={setRegisterManual} className="btn btn-dark mb-2">HERE</button>
                  </div>
                </div>
                <div>
                  {
                    userSelected &&
                    <>
                      <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                        <div className="m-2 w-5">
                          <i className="fa-solid fa-envelope fa-lg"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t('labels.email')}
                          name="rEmail"
                          autoComplete="off"
                          value={rEmail}
                          onChange={handleRegisterInputChange}
                        />
                      </div>
                      {
                        !notPassword &&
                        <>
                          <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                            <div className="m-2 w-10">
                              <i className="fa-solid fa-lock fa-lg"></i>
                            </div>
                            <input
                              type="password"
                              className="form-control"
                              placeholder={t('labels.password')}
                              name="rPassword"
                              autoComplete="new-password"
                              value={rPassword}
                              onChange={handleRegisterInputChange}
                            />
                          </div>
                          <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                            <div className="m-2 w-10">
                              <i className="fa-solid fa-arrows-rotate fa-lg"></i>
                            </div>
                            <input
                              type="password"
                              className="form-control"
                              placeholder={t('labels.confirm_password')}
                              name="rPasswordConf"
                              autoComplete="new-password"
                              value={rPasswordConf}
                              onChange={handleRegisterInputChange}
                            />
                          </div>
                        </>
                      }
                      <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                        <div className="m-2 w-5">
                          <i className="fa-solid fa-user fa-lg"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t('labels.first_name')}
                          name="rName"
                          value={rName}
                          onChange={handleRegisterInputChange}
                        />
                      </div>
                      <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                        <div className="m-2 w-5">
                          <i className="fa-solid fa-id-card fa-lg"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t('labels.last_name')}
                          name="rLastname"
                          value={rLastname}
                          onChange={handleRegisterInputChange}
                        />
                      </div>
                      <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                        <div className="m-2 w-5">
                          <i className="fa-solid fa-building fa-lg"></i>
                        </div>
                        <div className="w-100">
                          <Select placeholder={t('select.placeholder')} styles={colourStyles} options={offices} value={office} onChange={handleOffice} />
                        </div>
                      </div>
                      <div className="form-group d-flex flex-row justify-content-center align-items-center">
                        <div className="m-2 w-10">
                          <i className="fa-solid fa-users-gear fa-lg"></i>
                        </div>
                        <div className="w-100">
                          <Select placeholder={t('select.placeholder')} styles={colourStyles} options={roles} value={role} onChange={handleRole} />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className='text-light'>Birth date</label>
                        <DateTimePicker
                          onChange={handleBirthDateChange}
                          value={birthDate}
                          className="form-control"
                          format='MM-dd-yyyy HH:mm'
                        />
                      </div>
                      <div className="form-group files m-2">
                        <input id="excelfile" className='bg-light' type="file" onChange={(e) => handleFile(e)} />
                      </div>
                    </>
                  }
                </div>
                {
                  userSelected &&
                  <div className="form-group d-flex justify-content-center">
                    <input type="submit" className="btn btn-primary btn-bright" value={t('buttons.register')} />
                  </div>
                }
              </form>
              <div className="form-group d-flex justify-content-center">
                <h5>{t('auth.register.go_to_login')} <Link className='text-decoration-none text-primary' to={`/login`}>{t('buttons.login')}</Link></h5>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};
