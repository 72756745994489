import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { TopLeaderboard } from './TopLeaderboard';

export const CanvasserDealsItems = ({ date, myDeals, total, others, firsts, loading, id }) => {
  const { t } = useTranslation();

  if (loading) {
    return <h2>{t('loading.title')}</h2>
  }

  return (
    <div className='w-100'>
      {
        firsts.length === 3 &&
        <TopLeaderboard date={date} myDeals={myDeals} totalAmount={total} tierList={firsts} />
      }
      <div className={isMobile ? 'w-100 table-wrapper-leader' : 'w-100 table-wrapper-desktop-leader'}>
        {others && <table className={isMobile ? "table table-sm table-striped mt-3" : "table table-striped table-hover table-borderless table-sm overflow-hidden mt-3"}>
          <>
            {others.length > 0 && others.map(rank => {
              return (
                <tbody key={rank.user?.id} className={rank.user?.id === id ? '' : ''}>
                  <tr className='rounded-3'>
                    <td className={rank.user?.id === id ? "text-center text-light fw-bold" : "text-center text-light fw-bold"}>#{rank.position}</td>
                    <td className={rank.user?.id === id ? "text-center text-light" : "text-center text-light"}>{rank.user?.name} {rank.user?.last_name}</td>
                    <td className={rank.user?.id === id ? "text-center text-light" : "text-center text-light"}>{
                      rank.user?.office === 'Boca Raton' ?
                        <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462423/offices_logos/boca100x100_wzjla8.png'} width={35} height={35} />
                        :
                        rank.user?.office === 'Jacksonville' ?
                          <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462421/offices_logos/jax100x100_oqyqj7.png'} width={35} height={35} />
                          :
                          rank.user?.office === 'Bradenton' ?
                            <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462425/offices_logos/brad100x100_g0cxrl.png'} width={35} height={35} />
                            :
                            <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462427/offices_logos/cape100x100_zsjjn4.png'} width={35} height={35} />
                    }</td>
                    <td className={rank.user?.id === id ? "text-center font-special-rank" : "text-center font-special-rank"}>{rank.amount} DLS</td>
                  </tr>
                </tbody>
              );
            })}
          </>
        </table>}
      </div>
    </div>
  );
};
