import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchConToken } from '../../helpers/fetch';
import { setUserDeal, uiOpenModalAddDeal, uiOpenModalDeleteDeal, uiOpenModalEditDeal } from '../../actions/ui';
import { AddDealModal } from './AddDealModal';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { LoadingSpinnerWhite } from '../ui/LoadingSpinnerWhite';
import { useState } from 'react';
import { EditDealModal } from './EditDealModal';
import { useNavigate } from 'react-router-dom';

export const UsersDealsItems = ({ users, loading, page, setDeals, isLoading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sortOrder, setSortOrder] = useState('ASC');
  const [sortName, setSortName] = useState('deals');
  const [blockButtons, setBlockButtons] = useState(false);
  const { userDeal } = useSelector((state) => state.ui);
  if (loading || isLoading) {
    return <LoadingSpinnerWhite />
  }
  const handlePlus = async (user) => {
    dispatch(uiOpenModalAddDeal())
    dispatch(setUserDeal(user.user));
  };
  const handleChangeState = async (id, status, userId) => {
    setBlockButtons(true);
    const createUserDeal = async () => {
      const body = await fetchConToken(`auth/users/deals/change`, { id, status, page }, 'PUT');
      if (body.ok) {
        Swal.fire(t('success.title'), "Deal state changed", "success");
        //BUSCANDO
        const objectFinded = users.find(x => x.user.id === userId);

        const dealsFiltered = objectFinded.deals.filter(y => y.id !== id);

        const dealsFinded = objectFinded.deals.filter(y => y.id === id);

        dealsFiltered.push(body.userDeal);

        objectFinded.deals = dealsFiltered;

        if (status === 'Closed') {
          if (dealsFinded[0].status === 'Cancelled') {
            if (body.userDeal.is_selfgen) {
              objectFinded.user.deals = objectFinded.user.deals + 1;
              objectFinded.user.selfgens = objectFinded.user.selfgens + 1;
            } else {
              objectFinded.user.deals = objectFinded.user.deals + 1;
            }
          }
        } else if (status === 'Cancelled') {
          if (dealsFinded[0].status === 'Closed') {
            if (body.userDeal.is_selfgen) {
              objectFinded.user.deals = objectFinded.user.deals - 1;
              objectFinded.user.selfgens = objectFinded.user.selfgens - 1;
            } else {
              objectFinded.user.deals = objectFinded.user.deals - 1;
            }
          }
        }

        //CREATING NEW ARRAY
        const newDeals = users.map((obj) => {
          return obj.user.id === userId ? objectFinded : obj;
        });
        //REPLACING
        setDeals(newDeals);
        setBlockButtons(false);
      } else {
        Swal.fire("Error", body.msg, "error");
        setBlockButtons(false);
      }
    }
    createUserDeal();
  };
  const setColor = async (id) => {
    const element = document.getElementById('fu' + id);
    if (!element.classList.contains('bg-dark')) {
      element.classList.add('bg-dark')
      for (let i = 0; i < element.children.length; i++) {
        element.children[i].classList.add('text-light')
        element.children[i].classList.remove('text-dark')
      }
    } else {
      element.classList.remove('bg-dark')
      for (let i = 0; i < element.children.length; i++) {
        element.children[i].classList.remove('text-light')
        element.children[i].classList.add('text-dark')
      }
    }
  };

  const handleDelete = async (id) => {
    const deleteUserDeal = async () => {
      const body = await fetchConToken(`auth/users/deals/delete`, { id }, 'DELETE');
      if (body.ok) {
        Swal.fire(t('success.title'), "Deal deleted", "success");
        navigate(0);
      } else {
        Swal.fire("Error", body.msg, "error");
      }
    }
    deleteUserDeal();
  };

  const handleEdit = async (id, user) => {
    const dealFinded = user.deals.filter(d => d.id === id);
    let data = {
      deal: {},
      user: user.user
    }
    if (dealFinded.length > 0) {
      data.deal = dealFinded[0];
    }

    dispatch(uiOpenModalEditDeal())
    dispatch(setUserDeal(data));
  };

  const sortFirstName = async (sortN) => {
    setSortName(sortN);
    let usersSorted = [];
    if (sortOrder === 'ASC') {
      setSortOrder('DESC');
      usersSorted = users.sort((a, b) => {
        if (a.user.name < b.user.name) {
          return -1;
        }
        if (a.user.name > b.user.name) {
          return 1;
        }
        return 0;
      })
    } else {
      setSortOrder('ASC');
      usersSorted = users.sort((a, b) => {
        if (b.user.name < a.user.name) {
          return -1;
        }
        if (b.user.name > a.user.name) {
          return 1;
        }
        return 0;
      })
    }
    setDeals(usersSorted);
  };
  const sortLastName = async (sortN) => {
    setSortName(sortN);
    let usersSorted = [];
    if (sortOrder === 'ASC') {
      setSortOrder('DESC');
      usersSorted = users.sort((a, b) => {
        if (a.user.last_name < b.user.last_name) {
          return -1;
        }
        if (a.user.last_name > b.user.last_name) {
          return 1;
        }
        return 0;
      })
    } else {
      setSortOrder('ASC');
      usersSorted = users.sort((a, b) => {
        if (b.user.last_name < a.user.last_name) {
          return -1;
        }
        if (b.user.last_name > a.user.last_name) {
          return 1;
        }
        return 0;
      })
    }
    setDeals(usersSorted);
  };
  const sortDeals = async (sortN) => {
    setSortName(sortN);
    let usersSorted = [];
    if (sortOrder === 'ASC') {
      setSortOrder('DESC');
      usersSorted = users.sort((a, b) => {
        if (a.user.deals < b.user.deals) {
          return -1;
        }
        if (a.user.deals > b.user.deals) {
          return 1;
        }
        return 0;
      })
    } else {
      setSortOrder('ASC');
      usersSorted = users.sort((a, b) => {
        if (b.user.deals < a.user.deals) {
          return -1;
        }
        if (b.user.deals > a.user.deals) {
          return 1;
        }
        return 0;
      })
    }
    setDeals(usersSorted);
  };
  const sortSelfGens = async (sortN) => {
    setSortName(sortN);
    let usersSorted = [];
    if (sortOrder === 'ASC') {
      setSortOrder('DESC');
      usersSorted = users.sort((a, b) => {
        if (a.user.selfgens < b.user.selfgens) {
          return -1;
        }
        if (a.user.selfgens > b.user.selfgens) {
          return 1;
        }
        return 0;
      })
    } else {
      setSortOrder('ASC');
      usersSorted = users.sort((a, b) => {
        if (b.user.selfgens < a.user.selfgens) {
          return -1;
        }
        if (b.user.selfgens > a.user.selfgens) {
          return 1;
        }
        return 0;
      })
    }
    setDeals(usersSorted);
  };
  return (
    <div className='container-fluid table-responsive mb-2'>
      <>
        <>
          {users && <table className={isMobile ? "d-block overflow-scroll table table-sm table-bordered bg-light table-striped rounded rounded-3 overflow-hidden" : "table table-striped bg-light table-hover overflow-hidden"}>
            <thead className='bg-dark text-light align-middle'>
              <tr>
                <th role='button' onClick={() => { sortFirstName('name') }} className="text-center" scope="col">
                  {sortName === 'name' && <i className={sortOrder === 'DESC' ? 'fa fa-arrow-up-wide-short' : 'fa fa-arrow-down-short-wide'}></i>} First Name
                </th>
                <th role='button' onClick={() => { sortLastName('last_name') }} className="text-center" scope="col">
                  {sortName === 'last_name' && <i className={sortOrder === 'DESC' ? 'fa fa-arrow-up-wide-short' : 'fa fa-arrow-down-short-wide'}></i>} Last Name
                </th>
                <th className="text-center" scope="col">{t('labels.office')}</th>
                <th className="text-center" scope="col">{t('labels.role')}</th>
                <th role='button' onClick={() => { sortDeals('deals') }} className="text-center" scope="col">
                  {sortName === 'deals' && <i className={sortOrder === 'DESC' ? 'fa fa-arrow-up-wide-short' : 'fa fa-arrow-down-short-wide'}></i>} Total Deals
                </th>
                <th role='button' onClick={() => { sortSelfGens('selfgens') }} className="text-center" scope="col">
                  {sortName === 'selfgens' && <i className={sortOrder === 'DESC' ? 'fa fa-arrow-up-wide-short' : 'fa fa-arrow-down-short-wide'}></i>} Self Gens
                </th>
                <th className="text-center" scope="col">Add Deal</th>
              </tr>
            </thead>

            {users.length > 0 && users.map(user => {
              return (
                <tbody key={user.user.id}>
                  <tr data-bs-toggle="collapse" onClick={() => { setColor(user.user.id) }} id={'fu' + user.user.id} href={'#' + 'u' + user.user.id} role="button" aria-expanded="false" aria-controls={'u' + user.user.id}>
                    <td className="text-center text-dark">{user.user.name}</td>
                    <td className="text-center text-dark">{user.user.last_name}</td>
                    <td className="text-center text-dark">{user.user.office}</td>
                    <td className="text-center text-dark">{user.user.role}</td>
                    <td className="text-center text-dark">{user.user.deals}</td>
                    <td className="text-center text-dark">{user.user.selfgens}</td>
                    <td className="text-center text-dark"><button className='btn btn-success' disabled={blockButtons ? true : false} onClick={() => handlePlus(user)}><i className='fa fa-plus'></i></button></td>
                  </tr>
                  <tr>
                    <td colSpan="12" className="p-0 bg-dark">
                      <div className="collapse" id={'u' + user.user.id}>
                        <table className="table table-striped">
                          <thead className="bg-default text-light">
                            <tr className='align-middle'>
                              <th className="text-center small text-light">Edit</th>
                              <th className="text-center small text-light">Delete</th>
                              <th className="text-center small text-light">Date</th>
                              <th className="text-center small text-light">Cancel Date</th>
                              <th className="text-center small text-light">Lead</th>
                              <th className="text-center small text-light">Canvasser</th>
                              <th className="text-center small text-light">SelfGen</th>
                              <th className="text-center small text-light">Status</th>
                              <th className="text-center small text-light" colSpan={3}>Change status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {user.deals.length > 0 && user.deals.map(deal => {
                              return (
                                <tr key={deal.id} className='align-middle bg-light'>
                                  <td className="text-center small text-dark"><button className='btn btn-warning btn-sm' onClick={() => handleEdit(deal.id, user)}><i className='fa fa-edit'></i></button></td>
                                  <td className="text-center small text-dark"><button className='btn btn-danger btn-sm' onClick={() => handleDelete(deal.id)}><i className='fa fa-trash'></i></button></td>
                                  <td className="text-center small text-dark">{format(new Date(deal.date), 'MM-dd-yy hh:mm')}</td>
                                  <td className="text-center small text-dark">{deal?.cancel_date ? format(new Date(deal?.cancel_date), 'MM-dd-yy hh:mm') : '-'}</td>
                                  <td className="text-center small text-dark">{deal.lead_name} {deal.lead_last_name}</td>
                                  <td className="text-center small text-dark">{deal.canvasser_name}</td>
                                  <td className="text-center text-dark">{deal.is_selfgen ? <i className='fa fa-check fa-2xl text-success'></i> : <i className='fa fa-minus fa-2xl text-grey'></i>}</td>
                                  <td className={deal.status === 'Closed' ? "text-center small text-light bg-success" : deal.status === 'On hold' ? "text-center small text-light bg-warning" : "text-center small text-light bg-danger"}>{deal.status}</td>
                                  <td className="text-center small text-dark"><button className='btn btn-success btn-sm' disabled={blockButtons ? true : deal.status === 'Closed' ? true : false} onClick={() => handleChangeState(deal.id, 'Closed', user.user.id)}><i className='fa fa-handshake'></i></button></td>
                                  <td className="text-center small text-dark"><button className='btn btn-warning btn-sm' disabled={blockButtons ? true : deal.status === 'On hold' ? true : false} onClick={() => handleChangeState(deal.id, 'On hold', user.user.id)}><i className='fa fa-clock'></i></button></td>
                                  <td className="text-center small text-dark"><button className='btn btn-danger btn-sm' disabled={blockButtons ? true : deal.status === 'Cancelled' ? true : false} onClick={() => handleChangeState(deal.id, 'Cancelled', user.user.id)}><i className='fa fa-times'></i></button></td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>}
          <AddDealModal setDeals={setDeals} deals={users} />
          {userDeal && <EditDealModal setDeals={setDeals} deals={users} />}
        </>
      </>
    </div>);
};
