import { isMobile } from 'react-device-detect';
import { LoadingSpinnerBlue } from '../../ui/LoadingSpinnerBlue';
import { format } from 'date-fns';

export const MondayItems = ({ data, loading }) => {
  if (loading) {
    return <LoadingSpinnerBlue disableCenter={true} />
  }
  return (
    <div className='container-fluid table-responsive mb-2'>
      <>
        <>
          {data && <table className={isMobile ? "d-block overflow-scroll table table-sm table-bordered bg-light table-striped rounded rounded-3 overflow-hidden" : "table table-striped bg-light table-hover overflow-hidden"}>
            <thead className='bg-dark text-light align-middle'>
              <tr>
                <th className="text-center" scope="col">Board</th>
                <th className="text-center" scope="col">Name</th>
                <th className="text-center" scope="col">Group</th>
              </tr>
            </thead>

            {data.length > 0 && data.map(item => {
              return (
                <tbody key={item.id}>
                  <tr data-bs-toggle="collapse" id={'fu' + item.id} href={'#' + 'u' + item.id} role="button" aria-expanded="false" aria-controls={'u' + item.id}>
                    <td className="text-center text-dark">{item.board}</td>
                    <td className="text-center text-dark">{item.name}</td>
                    <td className="text-center text-dark">{item.group}</td>
                  </tr>
                  <tr>
                    <td colSpan="12" className="p-0 bg-dark">
                      <div className="collapse " id={'u' + item.id}>
                        <div className="overflowing vw-98">
                          <table className="table table-striped">
                            <thead className="bg-default text-light">
                              <tr className='align-middle'>
                                {item.columns.length > 0 && item.columns.map(column => {
                                  return (
                                    <th className="text-center small text-light">{column.column.title}</th>
                                  );
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              <tr className='align-middle bg-light'>
                                {item.columns.length > 0 && item.columns.map(value => {
                                  return (
                                    <td className="text-center small text-dark">{value.text === '' ? '-' : value.text}</td>
                                  );
                                })}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>}
        </>
      </>
    </div>);
};
