import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import useSWR from "swr";
import Select from 'react-select';
import { LoadingSpinner } from '../ui/LoadingSpinner';
import colourStyles from '../../helpers/selectStyles';
import { Chip } from '../ui/Chip';
import { useForm } from '../../hooks/useForm';
import { fetchConToken } from '../../helpers/fetch';

export const NotificationsSender = () => {
  const [loading, setLoading] = useState(false);

  const [formValues, handleInputChange] = useForm({
    sTitle: '',
    sDescription: '',
  });
  const { sDescription, sTitle } = formValues;

  const types = [
    { value: 'Roles', label: `Roles` },
    { value: 'Individual', label: `Individual` },
  ];
  const [type, setType] = useState(null);
  const handleType = (e) => {
    setType(e);
  };

  const roles = [
    { value: 'All', label: `All` },
    { value: 'Closer', label: `Closer` },
    { value: 'Canvasser', label: `Canvasser` },
    { value: 'CallCenter', label: `CallCenter` },
    { value: 'Closer and Canvasser', label: `Closer and Canvasser` },
  ];
  const [role, setRole] = useState(null);


  const { data: usersApi } = useSWR("auth/users");
  const users = [];
  if (usersApi?.users) {
    const usersLen = usersApi?.users.length
    for (let i = 0; i < usersLen; i++) {
      const user = {
        value: usersApi?.users[i].id,
        label: usersApi?.users[i].name + ' ' + usersApi?.users[i].last_name,
        role: usersApi?.users[i].role,
        fcm_token: usersApi?.users[i].fcm_token,
      }
      users.push(user);
    }
  }
  const [user, setUser] = useState(null);
  const handleUser = (e) => {
    let isRepeated = false;
    for (let i = 0; i < selectedUsers.length; i++) {
      if (selectedUsers[i].value === e.value) {
        isRepeated = true;
      }
    }
    if (!isRepeated) {
      setSelectedUsers(currentArray => [...currentArray, e]);
    }

  };

  const handleRole = (e) => {
    let userss = users.filter((u) => {
      switch (e.value) {
        case 'All':
          return u;
        case 'Closer and Canvasser':
          return u.role === 'Closer' || u.role === 'Canvasser'
        default:
          return u.role === e.value
      }
    })
    setSelectedUsers(currentArray => userss);
  };

  const [selectedUsers, setSelectedUsers] = useState([]);

  const removeUser = (id) => {
    const filtered = selectedUsers.filter(user => user.value !== id);
    setSelectedUsers(filtered);
  };

  const resetReceivers = () => {
    setSelectedUsers([]);
  };

  const sendMessage = async () => {
    await fetchConToken(
      "notifications/send",
      { users: selectedUsers, title: sTitle, description: sDescription },
      "POST"
    );
  };

  return (
    <>
      {
        loading
          ?
          <LoadingSpinner />
          :
          <div>
            {
              isMobile
                ?
                <div className='container d-flex flex-column justify-content-center align-items-center text-center' data-aos="fade-up" data-aos-duration="1000">
                </div>
                :
                <div className='container-fluid text-center' data-aos="fade-up" data-aos-duration="1000">
                  <div className="d-flex flex-column justify-content-center align-items-center pt-3">
                    <h1 className='text-light'>SEND NOTIFICATIONS</h1>
                    <div className='d-flex justify-content-center align-items-center w-100'>
                      <div className="form-group mb-3 w-25 me-2">
                        <label className='text-light'>Select receivers</label>
                        <div className="w-100">
                          <Select placeholder='Select send type...' styles={colourStyles} options={types} value={type} onChange={handleType} />
                        </div>
                      </div>
                      {type && type.value === 'Roles' &&
                        <div className="form-group mb-3 w-25 me-2">
                          <label className='text-light'>Role</label>
                          <div className="w-100">
                            <Select placeholder='Select role...' styles={colourStyles} options={roles} value={role} onChange={handleRole} />
                          </div>
                        </div>
                      }
                      {type && type.value === 'Individual' &&
                        <div className="form-group mb-3 w-25 me-2">
                          <label className='text-light'>Select an user</label>
                          <div className="w-100">
                            <Select placeholder='Find user...' styles={colourStyles} options={users} value={user} onChange={handleUser} />
                          </div>
                        </div>
                      }
                    </div>
                    <div className="form-group">
                      <button onClick={resetReceivers} className='btn btn-danger'>Reset receivers</button>
                    </div>
                    <hr />
                    <span className='text-light text-bold'>MESSAGE</span>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                      <div className="form-group">
                        <label className='text-light'>Title</label>
                        <input
                          type="text"
                          className="form-control mb-2"
                          placeholder="Title"
                          name="sTitle"
                          value={sTitle}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className='text-light'>Description</label>
                        <textarea
                          type="text"
                          className="form-control mb-2"
                          placeholder='Description'
                          name="sDescription"
                          value={sDescription}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <button onClick={sendMessage} className='btn btn-outline-light'><i className='fa fa-paper-plane'></i> Send</button>
                    </div>
                    <hr />
                    {selectedUsers.length > 0 &&
                      <>
                        <span className='text-light text-bold'>RECEIVERS</span>
                        <div className='row'>
                          {
                            selectedUsers.map(u => {
                              return <Chip key={u.value} label={u.label} onDelete={() => { removeUser(u.value) }} />
                            })
                          }
                        </div>
                      </>
                    }
                  </div>
                </div>
            }
          </div>
      }
    </>
  );
};
