import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import { googleLogout } from '@react-oauth/google';
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import { startClearUserAsoc, startLogout } from "../../actions/auth";
import { fetchConToken } from "../../helpers/fetch";
import { useState } from "react";
import { useEffect } from "react";

export const Navbar = () => {
  const { t } = useTranslation();
  const { uid, name, photo, points, role } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { data, error, isLoading, mutate } = useSWR(`notifications/${uid}`);
  const [isAdmin, setisAdmin] = useState(false);
  const [isAdminUser, setisAdminUsers] = useState(false);
  const [isCallCenter, setIsCallCenter] = useState(false);
  const [isAdminDeals, setIsAdminDeals] = useState(false);
  const [isAshten, setIsAshten] = useState(false);
  const [isRobertoA, setIsRobertoA] = useState(false);
  const [isLuisF, setIsLuisF] = useState(false);
  const [hotleadsAdmin, setHotleadsAdmin] = useState(false);
  const [isNewCall, setIsNewCall] = useState(false);

  useEffect(() => {
    if (role === 'Admin') {
      setisAdmin(true);
    }
    if (role === 'Admin Users') {
      setisAdminUsers(true);
    }
    if (role === 'CallCenter') {
      setIsCallCenter(true);
    }
    if (role === 'Admin Deals') {
      setIsAdminDeals(true);
    }
    if (role === 'CallCenter New') {
      setIsNewCall(true);
    }
    if (role === 'Hotleads') {
      setHotleadsAdmin(true);
    }

    if (name === 'Ashten' && role === 'Closer') {
      setIsAshten(true);
    }

    if (name === 'Roberto Armando' && role === 'Closer') {
      setIsRobertoA(true);
    }

    if (name === 'Luis Felipe' || role === 'Closer') {
      setIsLuisF(true);
    }
  }, [role]);

  const handleLogout = () => {
    googleLogout();
    dispatch(startLogout());
    dispatch(startClearUserAsoc());
  };

  const sawNotification = async (id) => {
    const body = await fetchConToken(`notifications/saw/${id}`, { state: false }, "PUT");
    if (body.ok) {
      mutate();
    }
  }

  const clearNotifications = async (id) => {
    const body = await fetchConToken(`notifications/clear/${uid}`, { state: false }, "PUT");
    if (body.ok) {
      mutate();
    }
  }

  return (
    <div className="bg-primary">
      {isMobile ?
        <div className="navbar navbar-dark navbar-expand-lg d-flex justify-content-between align-items-center w-100" style={{ zIndex: 999 }}>
          <Link className="text-decoration-none" to="/"><span role="button" className="text-decoration-none"><img style={{ objectFit: "contain" }} width={100} height={75} src={'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/title_white_bqk7kq.webp'} alt="Blue Energy Solar App" /></span></Link>
          <div className="dropdown dropdown-nav text-decoration-none d-flex justify-content-center align-items-center p-2">
            <button type="button" className="btn bg-invi text-light position-relative" data-bs-toggle="collapse" data-bs-target="#notificationCollapse">
              <i className="fas fa-bell"></i>
              {data?.notifications?.length > 0 && <span style={{ fontSize: "10px" }} className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-danger">{data?.notifications?.length}</span>}
            </button>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center text-light">
            <span className="text-light">{points}
              <img width={35} height={35} src={'https://res.cloudinary.com/drholseqp/image/upload/v1694460701/blucoin_front_s8z8er.png'} />
            </span>
            <span>{name}</span>
          </div>
          <span><img className="rounded-pill" style={{ objectFit: "cover" }} width={50} height={50} src={photo ? photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user_z1c8yd.webp'} alt="Logo" /></span>
          <button type="button" className="navbar-toggler me-1" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span className="navbar-toggler-icon"></span>
          </button>
          {
            data?.notifications?.length > 0 &&
            <div id="notificationCollapse" className="collapse navbar-collapse">
              <div className="d-flex justify-content-center align-items-center">
                <button className="btn btn-danger" onClick={clearNotifications}><b>Clear Notifications</b> <i className="fa fa-bell-slash"></i></button>
              </div>
              <div className="navbar-nav d-flex justify-content-center align-items-center text-dark bg-invi rounded-3 table-wrapper" data-bs-toggle="collapse" data-bs-target="#notificationCollapse">
                {
                  data?.notifications?.length > 0 && data?.notifications?.map((notification) => {
                    return (
                      <div className=" btn-bright p-2 m-2 bg-light rounded-3 w-90" key={"notification" + notification.id} onClick={() => { sawNotification(notification.id) }}>
                        <Link className="text-decoration-none text-dark row" to={notification.url}>
                          <span className="col-2"><img className="rounded-pill me-1" style={{ objectFit: "cover" }} width={45} height={45} src={notification.image} alt="URL" /></span>
                          <span className="col"><b>{notification.title}</b></span>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          }
          <div id="navbarCollapse" className="collapse navbar-collapse">
            <div className="navbar-nav d-flex justify-content-center align-items-center" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
              {/* <Link className="text-decoration-none" to="/story"><span className='font-speciallblue-2'>Story</span></Link> */}
              {(isRobertoA) && <Link className="text-decoration-none" to="/rankings"><span className='font-speciallblue-2'><i className="fa fa-cog"></i></span></Link>}
              {(isAdmin || isAdminUser || isAdminDeals) && <Link className="text-decoration-none" to="/rankings"><span className='font-speciallblue-2'>Rankings</span></Link>}
              {(isAdmin || isAdminDeals) && <Link className="text-decoration-none" to="/userdeals"><span className='font-speciallblue-2'>Deals tracker</span></Link>}
              {(isAdmin || isAdminDeals) && <Link className="text-decoration-none" to="/statsdata"><span className='font-speciallblue-2'>Stats Data</span></Link>}
              {(isAshten) && <Link className="text-decoration-none" to="/adminstats"><span className='font-speciallblue-2'>Stats Data</span></Link>}
              {!isCallCenter && !isNewCall && <Link className="text-decoration-none" to="/stats"><span className='font-speciallblue-2'>My Stats</span></Link>}
              {!isCallCenter && !isNewCall && <Link className="text-decoration-none" to="/announcements"><span className='font-speciallblue-2'>Announcements</span></Link>}
              {!isCallCenter && !isNewCall && <Link className="text-decoration-none" to="/events"><span className='font-speciallblue-2'>Events</span></Link>}
              {!isCallCenter && !isNewCall && <Link className="text-decoration-none" to="/cards"><span className='font-speciallblue-2'>My wallet</span></Link>}
              {!isCallCenter && !isNewCall && <Link className="text-decoration-none" to="/calendar/birthday"><span className='font-speciallblue-2'>Birthday Calendar</span></Link>}
              {isAdmin && <Link className="text-decoration-none" to="/callcenter"><span className='font-speciallblue-2'>Call Center</span></Link>}
              {/* {isAdmin && <Link className="text-decoration-none" to="/votes"><span className='font-speciallblue-2'>BE AWARDS</span></Link>} */}
              {/* <Link className="text-decoration-none" to="/rewards"><span className='font-speciallblue-2'>Rewards</span></Link> */}
              <Link className="text-decoration-none" to="/profile"><span className='font-speciallblue-2'>Account</span></Link>
            </div>
          </div>
        </div>
        :
        <div className="navbar navbar-dark navbar-expand-lg d-flex justify-content-between align-items-center w-100" style={{ zIndex: 999 }}>
          <Link className="text-decoration-none" to="/"><span role="button" className="navbar-brand ms-5 text-decoration-none"><img style={{ objectFit: "contain" }} width={150} height={75} src={'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/title_white_bqk7kq.webp'} alt="Blue Energy Solar App" /></span></Link>
          {/* <Link className="text-decoration-none" to="/story"><span className='font-special-navbar-small me-4'>Story</span></Link> */}
          {(isAdmin || isAdminUser || isAdminDeals) && <Link className="text-decoration-none" to="/rankings"><span className='font-special-navbar-small me-4'>Rankings</span></Link>}
          {(isAdmin || isAdminDeals) && <Link className="text-decoration-none" to="/userdeals"><span className='font-special-navbar-small me-4'>Deals tracker</span></Link>}
          {(isAdmin || isAdminDeals) && <Link className="text-decoration-none" to="/statsdata"><span className='font-special-navbar-small me-4'>Stats Data</span></Link>}
          {(isAshten || isRobertoA || isLuisF) && <Link className="text-decoration-none" to="/adminstats"><span className='font-special-navbar-small me-4'>Stats Data</span></Link>}
          {!isCallCenter && !isNewCall && !hotleadsAdmin && <Link className="text-decoration-none" to="/stats"><span className='font-special-navbar-small me-4'>My Stats</span></Link>}
          {!isCallCenter && !isNewCall && !hotleadsAdmin && <Link className="text-decoration-none" to="/announcements"><span className='font-special-navbar-small me-4'>Announcements</span></Link>}
          {!isCallCenter && !isNewCall && !hotleadsAdmin && <Link className="text-decoration-none" to="/events"><span className='font-special-navbar-small me-4'>Events</span></Link>}
          {!isCallCenter && !isNewCall && !hotleadsAdmin && <Link className="text-decoration-none" to="/cards"><span className='font-special-navbar-small me-4'>My wallet</span></Link>}
          {!isCallCenter && !isNewCall && !hotleadsAdmin && <Link className="text-decoration-none" to="/calendar/birthday"><span className='font-special-navbar-small me-4'>Birthday Calendar</span></Link>}
          {isAdmin && <Link className="text-decoration-none" to="/callcenter"><span className='font-special-navbar-small me-4'>Call Center</span></Link>}
          {(isRobertoA) && <Link className="text-decoration-none" to="/optionscreen"><span className='text-light'><i className="fa fa-cog "></i></span></Link>}
          {/* <Link className="text-decoration-none" to="/rewards"><span className='font-special-navbar me-4'>Rewards</span></Link> */}

          <button type="button" className="navbar-toggler me-3" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div id="navbarCollapse" className="me-5 collapse navbar-collapse">
            <div className="navbar-nav ms-auto d-flex justify-content-center align-items-center">
              <div className="dropdown dropdown-nav ms-4 text-decoration-none d-flex justify-content-center align-items-center">
                <div className="dropdown dropdown-nav text-decoration-none d-flex justify-content-center align-items-center">
                  {
                    data?.notifications?.length > 0
                      ?
                      <button type="button" className="btn bg-invi text-light position-relative" id="notificationDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fas fa-bell fa-xl"></i>
                        {data?.notifications?.length > 0 && <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{data?.notifications?.length}</span>}
                      </button>
                      :
                      <button type="button" className="btn bg-invi text-light position-relative">
                        <i className="fas fa-bell fa-xl"></i>
                      </button>
                  }
                  {
                    data?.notifications?.length > 0 &&
                    <div className="dropdown-menu dropdown-menu-end dropdown-menu-lg-end bg-invi border-0" aria-labelledby="notificationDropdown">

                      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-end rounded text-light bg-dark p-0 table-wrapper-navbar" style={{ width: "300px" }} aria-labelledby="notificationDropdown">
                        <div className="w-100">
                          <button className="btn text-light w-100" onClick={clearNotifications}><b>Clear Notifications</b> <i className="fa fa-bell-slash"></i></button>
                        </div>
                        {
                          data?.notifications?.length > 0 && data?.notifications?.map((notification) => {
                            return (
                              <li className="pt-2 btn-bright p-3 m-3" key={"notification" + notification.id} onClick={() => { sawNotification(notification.id) }}>
                                <Link className="text-decoration-none link-unstyled row" to={notification.url}>
                                  <span className="col-3"><img className="rounded-pill p-1" style={{ objectFit: "cover", minWidth: "100%" }} width={45} height={45} src={notification.image} alt="URL" /></span>
                                  <span className="col fw-bold text-light">{notification.title} </span>
                                </Link>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </div>
                  }
                </div>
                <img className="ms-3 me-3 rounded-circle" alt="user" src={photo ? photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user_z1c8yd.webp'} style={{ objectFit: "cover" }} width={60} height={60} />
                <span className="color-text-nav dropdown-toggle" href="!#" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"><strong style={{ fontSize: "20px", fontWeight: "600" }} className=" text-decoration-none">{name}</strong></span>
                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-end rounded text-light bg-dark p-0 border-light" aria-labelledby="userDropdown">
                  <li><Link className="text-decoration-none text-light dropdown-item dropdown-item-default rounded-1" to="/profile">{t('buttons.profile')}</Link></li>
                  <li><button onClick={handleLogout} className="dropdown-item rounded-1 text-light">{t('buttons.logout')}</button></li>
                </ul>
              </div>
            </div>
          </div>
        </div >
      }
    </div>
  );
};
