import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { isMobile } from 'react-device-detect';

import { RankCloser } from './RankCloser';
import { RankCanvasser } from './RankCanvasser';

export const Rank = ({ id }) => {
  const date = new Date();
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  return (
    <div className={isMobile ? "home-leader-mobile text-center w-100" : "home-leader text-center"}>
      <div className='d-flex justify-content-center align-items-center'>
        <span className={isMobile ? 'fs-3 text-light text-bold' : 'font-special-dash-deals'}>Month Deals</span>
        <span className='bg-primary-light rounded-pill p-2 ms-3 text-bold text-primary-dark'>{monthNames[date.getMonth()]}</span>
      </div>
      <Tabs selectedTabClassName="bg-primary text-light rounded">
        <TabList className={isMobile ? 'text-center text-light font-speciallblue-tabmycards m-2' : 'text-center text-light font-special-scroll ps-2 pb-4'}>
          <Tab>Closers</Tab>
          <Tab>Canvassers</Tab>
        </TabList>

        <TabPanel>
          <RankCloser id={id} />
        </TabPanel>
        <TabPanel>
          <RankCanvasser id={id} />
        </TabPanel>
      </Tabs>
    </div>
  );
};
