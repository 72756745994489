import useSWR from "swr";

import { useEffect } from 'react';
import { LoadingSpinnerBlue } from '../ui/LoadingSpinnerBlue';
import { RowsDesktop } from "./RowsDesktop";

export const CardsTimeFrameDesktop = ({ id, time_frame }) => {
  const { data: cards, error, isLoading } = useSWR(`usercards/cardsfilter/${time_frame}/${id}`);
  useEffect(() => {
    if (cards) {
      setTimeout(() => {
        slice()
      }, 1000)
    }
  }, [cards])
  const slice = (e) => {
    const cards = document.getElementsByClassName('card-stacks4');
    cards[0]?.classList?.toggle('transition');
  }
  return (
    <div className='text-center'>
      {
        isLoading
          ?
          <LoadingSpinnerBlue disableCenter={true} />
          :
          <div className='vw-100 d-flex flex-column justify-content-center align-items-center position-absolute  '>
            <ul className='card-stacks4 animate__animated animate__backInLeft'>
              {
                cards?.cards.length > 0 && cards?.cards.map((card, i) => {
                  return (
                    <RowsDesktop key={i} cardRow={card} i={i + 1} />
                  )
                })
              }
            </ul>
          </div>
      }
    </div>
  );
};
