import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import useSWR from "swr";
import { useEffect, useState } from 'react';

import { LoadingSpinnerBlue } from '../../ui/LoadingSpinnerBlue';
import { isMobile } from 'react-device-detect';

export const GraphAllTime = ({ id }) => {
  const [options, setOptions] = useState({});
  const [pointStartDate, setPointStartDate] = useState(new Date(null));
  const { data, isLoading } = useSWR(`userdata/userdatacloser/${id}`);
  // const isLoading = false;
  // const fakeHcData = [
  //   [new Date(2024, 0, 1).getTime(), 1],
  //   [new Date(2024, 0, 2).getTime(), 1],
  //   [new Date(2024, 0, 3).getTime(), 5],
  //   [new Date(2024, 0, 4).getTime(), 1],
  //   [new Date(2024, 0, 5).getTime(), 1],
  //   [new Date(2024, 0, 6).getTime(), 4],
  //   [new Date(2024, 0, 9).getTime(), 1],
  //   [new Date(2024, 0, 10).getTime(), 3],
  //   [new Date(2024, 0, 14).getTime(), 1],
  //   [new Date(2024, 0, 18).getTime(), 2],
  //   [new Date(2024, 0, 19).getTime(), 1],
  //   [new Date(2024, 0, 20).getTime(), 1],
  // ];
  // const fakeHc = [
  //   { date: new Date(2024, 0, 1).getTime(), deals: 1 },
  //   { date: new Date(2024, 0, 2).getTime(), deals: 1 },
  //   { date: new Date(2024, 0, 3).getTime(), deals: 5 },
  //   { date: new Date(2024, 0, 4).getTime(), deals: 1 },
  //   { date: new Date(2024, 0, 5).getTime(), deals: 1 },
  //   { date: new Date(2024, 0, 6).getTime(), deals: 4 },
  //   { date: new Date(2024, 0, 9).getTime(), deals: 1 },
  //   { date: new Date(2024, 0, 10).getTime(), deals: 3 },
  //   { date: new Date(2024, 0, 14).getTime(), deals: 1 },
  //   { date: new Date(2024, 0, 18).getTime(), deals: 2 },
  //   { date: new Date(2024, 0, 19).getTime(), deals: 1 },
  //   { date: new Date(2024, 0, 20).getTime(), deals: 1 },
  // ]

  // useEffect(() => {
  //   setPointStartDate(new Date(fakeHc[0]?.date));
  //   setOptions({
  //     chart: {
  //       type: 'area',
  //     },
  //     title: {
  //       text: 'All time deals',
  //       style: {
  //         color: '#0d6dfd'
  //       }
  //     },
  //     series: [{
  //       data: fakeHcData
  //     }],
  //     plotOptions: {
  //       series: {
  //         pointStart: fakeHc && pointStartDate.getTime(),
  //         pointInterval: 24 * 3600 * 1000
  //       }
  //     },
  //     legend: {
  //       enabled: false
  //     },
  //     yAxis: [{
  //       className: 'highcharts-color-0',
  //       title: {
  //         text: "Deals",
  //         style: {
  //           color: 'black'
  //         }
  //       },
  //       labels: {
  //         style: {
  //           color: 'black'
  //         }
  //       }
  //     }],
  //     xAxis: [{
  //       className: 'highcharts-color-1',
  //       title: {
  //         text: "Time frame",
  //         style: {
  //           color: 'black'
  //         }
  //       },
  //       crosshair: {
  //         width: 1
  //       },
  //       type: 'datetime',
  //       labels: {
  //         format: "{value:%d %m %y }",
  //         style: {
  //           color: 'black'
  //         }
  //       },
  //     }]
  //   })
  // }, [])


  useEffect(() => {
    setPointStartDate(new Date(data?.hc[0]?.date));
    setOptions({
      chart: {
        type: 'area',
      },
      title: {
        text: 'All time deals',
        style: {
          color: '#0d6dfd'
        }
      },
      series: [{
        data: data?.hcData
      }],
      plotOptions: {
        series: {
          pointStart: data?.hc && pointStartDate.getTime(),
          pointInterval: 24 * 3600 * 1000
        }
      },
      legend: {
        enabled: false
      },
      yAxis: [{
        className: 'highcharts-color-0',
        title: {
          text: "Deals",
          style: {
            color: 'black'
          }
        },
        labels: {
          style: {
            color: 'black'
          }
        }
      }],
      xAxis: [{
        className: 'highcharts-color-1',
        title: {
          text: "Time frame",
          style: {
            color: 'black'
          }
        },
        crosshair: {
          width: 1
        },
        type: 'datetime',
        labels: {
          format: "{value:%d %m %y }",
          style: {
            color: 'black'
          }
        },
      }]
    })
  }, [data])

  return (
    <div className="">
      {isLoading
        ?
        <LoadingSpinnerBlue disableCenter={true} />
        :
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          containerProps={{ className: 'chart', style: isMobile ? { height: "50vh" } : { height: "70vh" } }}
        />
      }
    </div>
  );
};
