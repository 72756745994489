import useSWR from "swr"
import { HotLeadsItems } from "./HotLeadsItems";
import { useCallback, useEffect, useState } from "react";
import { fetchConToken, fetchConTokenDownload } from "../../../helpers/fetch";
import Swal from "sweetalert2";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Link } from "react-router-dom";

export const HotLeadsScreen = () => {
  const { data, isLoading } = useSWR(`calltools/hotleads`);
  const [leads, setLeads] = useState([]);
  const [count, setCount] = useState(0);
  const [date, setDate] = useState(["", ""]);
  const [stats, setStats] = useState(null);

  useEffect(() => {
    if (data) {
      setLeads(data.data);
      setCount(data.data.length);
      setStats(data.stats);
    }
  }, [data])

  const exportExcel = async () => {
    const exportE = async () => {
      Swal.fire({
        title: 'Wait ...',
        onBeforeOpen() {
          Swal.showLoading()
        },
        onAfterClose() {
          Swal.hideLoading()
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false
      })
      const body = await fetchConTokenDownload("calltools/exporthotleads")
        .then(blob => {
          // Create a URL for the blob
          const url = window.URL.createObjectURL(blob);
          // Create a temporary link element
          const a = document.createElement('a');
          // Set the href attribute to the blob URL
          a.href = url;
          // Set the download attribute to specify the filename
          a.download = 'hotleads.xlsx';
          // Append the link to the body
          document.body.appendChild(a);
          // Click the link programmatically to trigger the download
          a.click();
          // Remove the link from the body
          document.body.removeChild(a);
          // Release the object URL
          window.URL.revokeObjectURL(url);
          Swal.close();
        })
        .catch(error => {
          Swal.fire("Error", body.msg, "error");
        });
    }
    exportE();
  };

  const exportExcel2 = async () => {
    const exportE = async () => {
      Swal.fire({
        title: 'Wait ...',
        onBeforeOpen() {
          Swal.showLoading()
        },
        onAfterClose() {
          Swal.hideLoading()
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false
      })
      const body = await fetchConTokenDownload("calltools/exporthotleads2")
        .then(blob => {
          // Create a URL for the blob
          const url = window.URL.createObjectURL(blob);
          // Create a temporary link element
          const a = document.createElement('a');
          // Set the href attribute to the blob URL
          a.href = url;
          // Set the download attribute to specify the filename
          a.download = 'hotleads.xlsx';
          // Append the link to the body
          document.body.appendChild(a);
          // Click the link programmatically to trigger the download
          a.click();
          // Remove the link from the body
          document.body.removeChild(a);
          // Release the object URL
          window.URL.revokeObjectURL(url);
          Swal.close();
        })
        .catch(error => {
          Swal.fire("Error", body.msg, "error");
        });
    }
    exportE();
  };

  const exportExcel3 = async () => {
    const exportE = async () => {
      Swal.fire({
        title: 'Wait ...',
        onBeforeOpen() {
          Swal.showLoading()
        },
        onAfterClose() {
          Swal.hideLoading()
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false
      })
      const body = await fetchConTokenDownload("calltools/exporthotleads3")
        .then(blob => {
          // Create a URL for the blob
          const url = window.URL.createObjectURL(blob);
          // Create a temporary link element
          const a = document.createElement('a');
          // Set the href attribute to the blob URL
          a.href = url;
          // Set the download attribute to specify the filename
          a.download = 'hotleads.xlsx';
          // Append the link to the body
          document.body.appendChild(a);
          // Click the link programmatically to trigger the download
          a.click();
          // Remove the link from the body
          document.body.removeChild(a);
          // Release the object URL
          window.URL.revokeObjectURL(url);
          Swal.close();
        })
        .catch(error => {
          Swal.fire("Error", body.msg, "error");
        });
    }
    exportE();
  };

  const [type, setType] = useState('ALL');
  const [response, setResponse] = useState('ALL');
  const [source, setSource] = useState('ALL');

  const filter = async (typeFilter, filterData) => {
    switch (typeFilter) {
      case 'type_response':
        document.querySelectorAll('.btn-type').forEach(button => {
          if (button.textContent !== filterData) {
            button.classList.remove('active');
          } else {
            button.classList.add('active');
          }
        });
        setResponse(filterData);
        const filterResponse = async () => {
          if (date[0] && date[1]) {
            const body = await fetchConToken(`calltools/hotleads/filter?type=${type}&type_response=${filterData}&source=${source}&dateInit=${date[0]}&dateEnd=${date[1]}`);
            setLeads(body.leads);
            setCount(body.count);
            setStats(body.stats);
          } else {
            const body = await fetchConToken(`calltools/hotleads/filter?type=${type}&type_response=${filterData}&source=${source}`);
            setLeads(body.leads);
            setCount(body.count);
            setStats(body.stats);
          }

        }
        filterResponse();
        break;
      case 'type':
        document.querySelectorAll('.btn-lead').forEach(button => {
          if (button.textContent !== filterData) {
            button.classList.remove('active');
          } else {
            button.classList.add('active');
          }
        });
        setType(filterData);
        const filterType = async () => {
          if (date[0] && date[1]) {
            const body = await fetchConToken(`calltools/hotleads/filter?type=${filterData}&type_response=${response}&source=${source}&dateInit=${date[0]}&dateEnd=${date[1]}`);
            setLeads(body.leads);
            setCount(body.count);
            setStats(body.stats);
          } else {
            const body = await fetchConToken(`calltools/hotleads/filter?type=${filterData}&type_response=${response}&source=${source}`);
            setLeads(body.leads);
            setCount(body.count);
            setStats(body.stats);
          }
        }
        filterType();
        break;
      case 'source':
        document.querySelectorAll('.btn-source').forEach(button => {
          if (button.textContent !== filterData) {
            button.classList.remove('active');
          } else {
            button.classList.add('active');
          }
        });
        setSource(filterData);
        const filterSource = async () => {
          if (date[0] && date[1]) {
            const body = await fetchConToken(`calltools/hotleads/filter?type=${type}&type_response=${response}&source=${filterData}&dateInit=${date[0]}&dateEnd=${date[1]}`);
            setLeads(body.leads);
            setCount(body.count);
            setStats(body.stats);
          } else {
            const body = await fetchConToken(`calltools/hotleads/filter?type=${type}&type_response=${response}&source=${filterData}`);
            setLeads(body.leads);
            setCount(body.count);
            setStats(body.stats);
          }

        }
        filterSource();
        break;

      default:
        break;
    }
  };

  const onDateChange = async (e) => {
    setDate(e)
    if (e) {
      const body = await fetchConToken(`calltools/hotleads/filter?type=${type}&type_response=${response}&source=${source}&dateInit=${e[0]}&dateEnd=${e[1]}`);
      setLeads(body.leads);
      setCount(body.count);
      setStats(body.stats);
    }
  }

  const [values, setValues] = useState({ cSearch: "" });

  const { cSearch } = values;

  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, wait);
    };
  };

  const debouncedFetchData = useCallback(
    debounce(async (filter) => {
      try {
        const body = await fetchConToken(`calltools/hotleads/filter?name=${filter}`);
        setLeads(body.leads);
        setCount(body.count);
        setStats(body.stats);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }, 500),
    []
  );

  const handleSearchInputChange = ({ target }) => {
    const newValue = target.value;
    setValues({
      ...values,
      [target.name]: newValue,
    });
    debouncedFetchData(newValue);
  };


  return (
    <div className="m-3">
      <div className="d-flex flex-column align-items-center justify-content-center m-2">
        <h3 className="text-dark text-center">HOT LEADS</h3>
        <button onClick={() => { exportExcel() }} className="btn btn-success m-2">EXPORT <i className="fa fa-file-excel"></i></button>
        <Link to="/reportcalling">
          <button className="btn btn-dark" title="Report" style={{ width: "250px" }}>
            HOT LEAD CALL REPORT <i className="fa fa-phone"></i>
          </button>
        </Link>
        <div className="dropdown dropdown-nav text-decoration-none d-flex justify-content-center align-items-center p-2">
          <button className="text-light btn btn-primary me-3" title="Add deal" style={{ width: "50px" }} data-bs-toggle="collapse" data-bs-target="#statsCollapse">
            <i className="fas fa-chart-bar fa-xl"></i>
          </button>
        </div>
        <div id="statsCollapse" className="collapse navbar-collapse w-90">
          {
            stats &&
            <div className="navbar-nav d-flex flex-column justify-content-center align-items-center bg-invi rounded-3 p-1 m-2 w-100" data-bs-toggle="collapse" data-bs-target="#notificationCollapse">
              <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-primary text-light p-2 fw-bold w-100">Total Answer: {stats.amountAns}</span>
              <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-dark text-light p-2 fw-bold w-100">Total No Answer: {stats.amountNoAns}</span>
              <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-success text-light p-2 fw-bold w-100">Total Appointment: {stats.amountApp}</span>
              <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-danger text-light p-2 fw-bold w-100">Total Disqualified: {stats.amountDisq}</span>
              <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-warning text-light p-2 fw-bold w-100">Total Low Credit: {stats.amountLC}</span>
              <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-light text-dark p-2 fw-bold w-100">Total Renter: {stats.amountRent}</span>
              <span style={{ fontSize: "15px" }} className="bg-gradient rounded-3 bg-secondary text-light p-2 fw-bold w-100">Total Only Message: {stats.amountMsj}</span>
            </div>
          }
        </div>
        {/* <button onClick={() => { filter() }} className="btn btn-primary">Filter <i className="fa fa-filter"></i></button> */}
        <div className="d-flex justify-content-center align-items-center row w-100">
          <div className="form-group col">
            <DateRangePicker className="form-control" onChange={(e) => { onDateChange(e) }} value={date} />
          </div>
          <div className="d-flex justify-content-center align-items-center bg-primary rounded-3 p-1 col">
            <span className="text-light text-bold">Amount: {count}</span>
          </div>
          <div className="accordion p-2 col" id="accordionFilter">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button me-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Filter
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionFilter">
                <div className="accordion-body">
                  <div className="m-1 d-flex flex-column align-items-center justify-content-center">
                    <span className="text-bold">Search by name</span>
                    <div className="btn-group ms-2" role="group" aria-label="Name">
                      <input className='form-control me-2 text-center' title="Name" placeholder='Name...' type="text" name="cSearch" value={cSearch} onChange={handleSearchInputChange} />
                    </div>
                  </div>
                  <div className="m-1 d-flex flex-column align-items-center justify-content-center">
                    <span className="text-bold">Response</span>
                    <div className="btn-group ms-2" role="group" aria-label="Type">
                      <button type="button" className="btn-type btn btn-primary active" onClick={() => { filter('type_response', 'ALL') }}>ALL</button>
                      <button type="button" className="btn-type btn btn-primary" onClick={() => { filter('type_response', 'Ans') }}>Ans</button>
                      <button type="button" className="btn-type btn btn-primary" onClick={() => { filter('type_response', 'No Ans') }}>No Ans</button>
                      <button type="button" className="btn-type btn btn-primary" onClick={() => { filter('type_response', 'App') }}>App</button>
                      <button type="button" className="btn-type btn btn-primary" onClick={() => { filter('type_response', 'Rent') }}>Rent</button>
                      <button type="button" className="btn-type btn btn-primary" onClick={() => { filter('type_response', 'LC') }}>LC</button>
                      <button type="button" className="btn-type btn btn-primary" onClick={() => { filter('type_response', 'Disq') }}>Disq</button>
                      <button type="button" className="btn-type btn btn-primary" onClick={() => { filter('type_response', 'Msj') }}>Msj</button>
                    </div>
                  </div>
                  <div className="m-1 d-flex flex-column align-items-center justify-content-center">
                    <span className="text-bold">Type</span>
                    <div className="btn-group ms-2" role="group" aria-label="Lead">
                      <button type="button" className="btn-lead btn btn-primary active" onClick={() => { filter('type', 'ALL') }}>ALL</button>
                      <button type="button" className="btn-lead btn btn-primary" onClick={() => { filter('type', 'Owner') }}>Owner</button>
                      <button type="button" className="btn-lead btn btn-primary" onClick={() => { filter('type', 'Renter') }}>Renter</button>
                    </div>
                  </div>
                  <div className="m-1 d-flex flex-column align-items-center justify-content-center">
                    <span className="text-bold">Source</span>
                    <div className="btn-group ms-2" role="group" aria-label="Source">
                      <button type="button" className="btn-source btn btn-primary active" onClick={() => { filter('source', 'ALL') }}>ALL</button>
                      <button type="button" className="btn-source btn btn-primary" onClick={() => { filter('source', 'Own') }}>Own</button>
                      <button type="button" className="btn-source btn btn-primary" onClick={() => { filter('source', 'IG Form') }}>IG Form</button>
                      <button type="button" className="btn-source btn btn-primary" onClick={() => { filter('source', 'Landing') }}>Landing</button>
                      <button type="button" className="btn-source btn btn-primary" onClick={() => { filter('source', 'Other') }}>Other</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <HotLeadsItems leads={leads} isLoading={isLoading} setLeads={setLeads} />
    </div>
  );
};
