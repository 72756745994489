import { format } from 'date-fns';
import useSWR from "swr";
import { isMobile } from 'react-device-detect';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const EventsScreen = ({ id, isCloser }) => {
  const { data: events, error, isLoading } = useSWR(`events/${id}`);
  const [individualGoal, setIndividualGoal] = useState(null);
  const [companyGoal, setCompanyGoal] = useState(null);
  const date = new Date();
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  useEffect(() => {
    if (events) {
      setIndividualGoal(events.individualGoal);
      setCompanyGoal(events.companyGoal);
    }
  }, [events])

  return (
    <div className='text-center'>
      {
        isMobile
          ?
          <>
            <div className='w-100 bg-primary d-flex justify-content-start'>
              <span className='text-4 text-bold text-light ms-5'>Events</span>
            </div>
            <img className='fixed-bottom' style={{ width: "30vw" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704470527/Asset_42_qdamfo.png'} />
            <div id="myCarousel" className="d-flex flex-column justify-content-center align-items-center carousel slide text-center animate__animated animate__fadeIn" data-bs-pause="true">
              <div className="carousel-indicators">
                {events?.events?.length > 0 && events?.events?.map((event, i) => {
                  return (
                    <div key={event.id}>
                      {i === 0
                        ?
                        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to={i} className="active" aria-current="true" aria-label="Slide 1"></button>
                        :
                        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to={i} aria-current="true" aria-label={`Slide ${i}`}></button>
                      }
                    </div>
                  )
                })
                }
              </div>
              <div className="carousel-inner carousel-inner-card">
                {
                  events?.events?.length > 0 && events?.events?.map((event, i) => {
                    return (
                      i === 0
                        ?
                        <div key={`eventA-${i}`} className="carousel-item active">
                          <div className='d-flex justify-content-center align-items-center'>
                            <Link to={`/eventsdetail/${event.id}`}>
                              <img className='rounded-3' src={event.url} style={{ objectFit: "contain", width: "65vw", height: "40vh" }} width={250} height={250} alt="event" />
                            </Link>
                          </div>
                          <div className='d-flex flex-column justify-content-center align-items-center text-center text-light'>
                            <div>
                              <div className='m-2' style={{ width: "300px" }}>
                                <span><b>Individual goal</b></span>
                                <div className="progress">
                                  <div className="progress-bar" role="progressbar" style={{ width: `${Math.round(individualGoal * 100 / (isCloser ? event.individual_goal : event.individual_goal_canvasser))}%` }} aria-valuenow={Math.round(individualGoal * 100 / (isCloser ? event.individual_goal : event.individual_goal_canvasser))} aria-valuemin="0" aria-valuemax="100">{`${individualGoal} / ${isCloser ? event.individual_goal : event.individual_goal_canvasser}`}</div>
                                </div>
                              </div>
                              <div className='m-2' style={{ width: "300px" }}>
                                <span><b>Company goal</b></span>
                                <div className="progress">
                                  <div className="progress-bar" role="progressbar" style={{ width: `${Math.round(companyGoal * 100 / (isCloser ? event.company_goal : event.company_goal_canvasser))}%` }} aria-valuenow={Math.round(companyGoal * 100 / (isCloser ? event.company_goal : event.company_goal_canvasser))} aria-valuemin="0" aria-valuemax="100">{`${companyGoal} / ${isCloser ? event.company_goal : event.company_goal_canvasser}`}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        <div key={`eventB-${i}`} >
                          {
                            event.upcoming === false
                              ?
                              <div className="carousel-item">
                                <div className='d-flex justify-content-center align-items-center'>
                                  <Link to={`/eventsdetail/${event.id}`}>
                                    <img className='rounded-3 img-event' src={event.url} style={{ objectFit: "cover", width: "65vw", height: "40vh" }} width={250} height={250} alt="event" />
                                  </Link>
                                </div>
                                <div className='d-flex flex-column justify-content-center align-items-center text-center text-light'>
                                  <div className='m-2' style={{ width: "300px" }}>
                                    <span><b>Individual goal</b></span>
                                    <div className="progress">
                                      <div className="progress-bar" role="progressbar" style={{ width: `${Math.round(individualGoal * 100 / (isCloser ? event.individual_goal : event.individual_goal_canvasser))}%` }} aria-valuenow={Math.round(individualGoal * 100 / (isCloser ? event.individual_goal : event.individual_goal_canvasser))} aria-valuemin="0" aria-valuemax="100">{`${individualGoal} / ${isCloser ? event.individual_goal : event.individual_goal_canvasser}`}</div>
                                    </div>
                                  </div>
                                  <div className='m-2' style={{ width: "300px" }}>
                                    <span><b>Company goal</b></span>
                                    <div className="progress">
                                      <div className="progress-bar" role="progressbar" style={{ width: `${Math.round(companyGoal * 100 / (isCloser ? event.company_goal : event.company_goal_canvasser))}%` }} aria-valuenow={Math.round(companyGoal * 100 / (isCloser ? event.company_goal : event.company_goal_canvasser))} aria-valuemin="0" aria-valuemax="100">{`${companyGoal} / ${isCloser ? event.company_goal : event.company_goal_canvasser}`}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              :
                              <div className="carousel-item">
                                <div className='d-flex justify-content-center align-items-center'>
                                  <img className='rounded-3' src={event.url} style={{ objectFit: "contain", width: "100vw", height: "47vh" }} width={250} height={250} alt="event" />
                                </div>
                                <div className='d-flex flex-column justify-content-center align-items-center text-center text-light m-2'>
                                  <span className='font-speciallblue-event'>COMING SOON</span>
                                </div>
                              </div>
                          }
                        </div>
                    )
                  })
                }
              </div>
              <button aria-label="Anterior" className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true" />
              </button>
              <button id="apreta" aria-label="Siguiente" className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true" />
              </button>
            </div>
          </>
          :
          <>
            <div className='w-100 bg-primary d-flex justify-content-start'>
              <span className='text-2 text-bold text-light ms-5'>Events</span>
            </div>
            <div className='w-100 d-flex justify-content-start mt-3'>
              <span className='text-1 text-bold text-light ms-5'>{monthNames[date.getMonth()]} / {new Date().getFullYear()}</span>
            </div>
            <img className='fixed-bottom' style={{ width: "10vw" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704470527/Asset_42_qdamfo.png'} />
            <div id="myCarousel" className="d-flex flex-column justify-content-center align-items-center carousel slide text-center animate__animated animate__fadeIn" data-bs-pause="true">

              <div className="carousel-indicators">
                {events?.events?.length > 0 && events?.events?.map((event, i) => {
                  return (
                    <div key={event.id}>
                      {i === 0
                        ?
                        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to={i} className="active" aria-current="true" aria-label="Slide 1"></button>
                        :
                        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to={i} aria-current="true" aria-label={`Slide ${i}`}></button>
                      }
                    </div>
                  )
                })
                }
              </div>
              <div className="carousel-inner">

                {
                  events?.events?.length > 0 && events?.events?.map((event, i) => {
                    return (
                      i === 0
                        ?
                        <div key={`eventA-${i}`} className="carousel-item active">
                          <div className='d-flex justify-content-center align-items-center'>
                            <img className='rounded-3 img-event' src={event.url} style={{ objectFit: "contain", width: "65vw", height: "35vh" }} width={250} height={250} alt="event" />
                          </div>
                          <div className='d-flex flex-column justify-content-center align-items-center text-center text-light pb-5'>
                            <div className='m-2' style={{ width: "300px" }}>
                              <span><b>Individual goal</b></span>
                              <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{ width: `${Math.round(individualGoal * 100 / (isCloser ? event.individual_goal : event.individual_goal_canvasser))}%` }} aria-valuenow={Math.round(individualGoal * 100 / (isCloser ? event.individual_goal : event.individual_goal_canvasser))} aria-valuemin="0" aria-valuemax="100">{`${individualGoal} / ${isCloser ? event.individual_goal : event.individual_goal_canvasser}`}</div>
                              </div>
                            </div>
                            <div className='m-2' style={{ width: "300px" }}>
                              <span><b>Company goal</b></span>
                              <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{ width: `${Math.round(companyGoal * 100 / (isCloser ? event.company_goal : event.company_goal_canvasser))}%` }} aria-valuenow={Math.round(companyGoal * 100 / (isCloser ? event.company_goal : event.company_goal_canvasser))} aria-valuemin="0" aria-valuemax="100">{`${companyGoal} / ${isCloser ? event.company_goal : event.company_goal_canvasser}`}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        <div key={`eventB-${i}`}>
                          {
                            event.upcoming === false
                              ?
                              <div className="carousel-item">
                                <div className='d-flex justify-content-center align-items-center'>
                                  <Link to={`/eventsdetail/${event.id}`}>
                                    <img className='rounded-3 img-event' src={event.url} style={{ objectFit: "contain", width: "65vw", height: "35vh" }} width={250} height={250} alt="event" />
                                  </Link>
                                </div>
                                <div className='d-flex flex-column justify-content-center align-items-center text-center text-light'>
                                  <span className='m-2'>{format(new Date(event.date), 'MM-dd-yyyy')}</span>
                                  <div className='m-2' style={{ width: "300px" }}>
                                    <span><b>Individual goal</b></span>
                                    <div className="progress">
                                      <div className="progress-bar" role="progressbar" style={{ width: `${Math.round(individualGoal * 100 / (isCloser ? event.individual_goal : event.individual_goal_canvasser))}%` }} aria-valuenow={Math.round(individualGoal * 100 / (isCloser ? event.individual_goal : event.individual_goal_canvasser))} aria-valuemin="0" aria-valuemax="100">{`${individualGoal} / ${isCloser ? event.individual_goal : event.individual_goal_canvasser}`}</div>
                                    </div>
                                  </div>
                                  <div className='m-2' style={{ width: "300px" }}>
                                    <span><b>Company goal</b></span>
                                    <div className="progress">
                                      <div className="progress-bar" role="progressbar" style={{ width: `${Math.round(companyGoal * 100 / (isCloser ? event.company_goal : event.company_goal_canvasser))}%` }} aria-valuenow={Math.round(companyGoal * 100 / (isCloser ? event.company_goal : event.company_goal_canvasser))} aria-valuemin="0" aria-valuemax="100">{`${companyGoal} / ${isCloser ? event.company_goal : event.company_goal_canvasser}`}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              :
                              <div className="carousel-item">
                                <div className='d-flex justify-content-center align-items-center'>
                                  <img className='rounded-3' src={event.url} style={{ objectFit: "contain", width: "100vw", height: "35vh" }} width={250} height={250} alt="event" />
                                </div>
                                <div className='d-flex flex-column justify-content-center align-items-center text-center text-light m-2'>
                                  <span className='font-speciallblue-event'>COMING SOON</span>
                                </div>
                              </div>
                          }
                        </div>
                    )
                  })
                }
              </div>
              <button aria-label="Anterior" className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true" />
              </button>
              <button id="apreta" aria-label="Siguiente" className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true" />
              </button>
            </div>
          </>
      }

    </div>
  );
};
