import useSWR from "swr";
import { LoadingSpinnerWhite } from "../ui/LoadingSpinnerWhite";
import { isMobile } from "react-device-detect";
import { format, isValid } from "date-fns";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { fetchConToken } from "../../helpers/fetch";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

export const CallCenterScreen = ({ id, isNewCall }) => {
  const { data, error, isLoading } = useSWR(`callcenter/${id}`);
  const [totalHours, setTotalHours] = useState(0);
  const [userHours, setUserHours] = useState(0);
  const [isFilteredByDate, setIsFilteredByDate] = useState(false);
  const navigate = useNavigate();

  const [date, setDate] = useState(["", ""]);

  const datee = format(new Date(), 'MM-dd-yyyy HH:mm');
  useEffect(() => {
    if (isValid(datee)) {
      setDate([datee, datee])
    }
  }, []);

  const onDateChange = async (e) => {
    setDate(e)
    if (e) {
      const body = await fetchConToken(`callcenter/${id}/date`, { date: e }, 'POST');
      setTotalHours(body.total);
      setUserHours(body.userHours);
      setIsFilteredByDate(true);
    } else {
      const body = await fetchConToken(`callcenter/${id}`);
      setTotalHours(body.total);
      setUserHours(body.userHours);
      setIsFilteredByDate(false);
    }
  }

  useEffect(() => {
    if (data?.total) {
      setTotalHours(data?.total);
      setUserHours(data?.userHours);
    }
  }, [data]);
  const handleDelete = (idHour) => {
    Swal.fire({
      title: `Sure to delete this register?`,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const body = await fetchConToken(`callcenter/${id}/${idHour}`, {}, 'DELETE');
        if (body.ok) {
          setUserHours(body.userHours);
          setTotalHours(body.total);
          Swal.fire("Success", 'Register deleted', "success");
        } else {
          Swal.fire("Error", body.msg, "error");
        }
      } else if (result.isDenied) {
      }
    })
  };

  const handleEdit = (idHour) => {
    navigate(`/edithours/${idHour}`);
  };

  const formatDateTz = (date) => {
    const dt = new Date(date);
    const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
    return format(dtDateOnly, 'eee dd-MM-yy');
  };

  const editHourSelected = async (hours, hour) => {
    if (!isNewCall) {
      if (isFilteredByDate) {
        const body = await fetchConToken(`callcenter/userhour/${hours.id}/date`, { key: hour, value: hours[hour], userId: id, date }, 'PUT');
        setTotalHours(body.total);
        setUserHours(body.userHours);
      } else {
        const body = await fetchConToken(`callcenter/userhour/${hours.id}`, { key: hour, value: hours[hour], userId: id }, 'PUT');
        setTotalHours(body.total);
        setUserHours(body.userHours);
      }
    }
  };

  const clearHours = async () => {
    Swal.fire({
      title: "Do you want to clear your hours?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`
    }).then(async (result) => {
      if (result.isConfirmed) {
        const body = await fetchConToken(`callcenter/clear`, { userId: id }, 'POST');
        if (body.ok) {
          setTotalHours(0);
          setUserHours([]);
          Swal.fire('Success', 'Hours cleared', 'success');
        } else {
          Swal.fire('Error', 'Hours can`t be cleared', 'error');
        }
      } else if (result.isDenied) {
      }
    });

  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center bg-light">
      <h2 className="text-primary font-special-total-deals-small mt-3">Hours register</h2>
      <h2 className="text-primary font-special-total-deals-small">Total Hours: {totalHours}</h2>
      <div className="d-flex justify-content-center align-items-center row">
        <div className="col">
          <Link to="/addhours">
            <button className="btn btn-success" title="Add post" style={{ width: "110px" }}>
              <i className="fas fa-calendar-plus"></i>
            </button>
          </Link>
        </div>
        <div className="col">
          <DateRangePicker className="form-control" onChange={(e) => { onDateChange(e) }} value={date} />
        </div>
        <div className="col">
          <button className="btn btn-danger" title="Clear all my hours" onClick={clearHours}><i className="fa fa-trash"></i> All Hours</button>
        </div>
      </div>

      <div className='container-fluid m-2 w-100'>
        {
          isLoading
            ?
            <LoadingSpinnerWhite />
            :
            <div className=" table-responsive vh-80">
              {
                userHours.length > 0 &&
                <table className={isMobile ? "d-block overflow-scroll table table-sm table-bordered table-striped bg-light" : "table table-bordered table-striped table-hover bg-light"}>
                  <thead className='bg-dark text-light align-middle sticky-top'>
                    <tr>
                      <th className="text-center th-lg-3" scope="col">Delete</th>
                      {!isNewCall && <th className="text-center th-lg-3" scope="col">Edit</th>}
                      <th className="text-center th-lg-3" scope="col">Day</th>
                      <th className="text-center" scope="col">Hours Count</th>
                      {/* <th className="text-center" scope="col">6:00</th> */}
                      {/* <th className="text-center" scope="col">7:00</th> */}
                      <th className="text-center" scope="col">8:00 - 8:30</th>
                      <th className="text-center" scope="col">8:30 - 9:00</th>
                      <th className="text-center" scope="col">9:00 - 9:30</th>
                      <th className="text-center" scope="col">9:30 - 10:00</th>
                      <th className="text-center" scope="col">10:00 - 10:30</th>
                      <th className="text-center" scope="col">10:30 - 11:00</th>
                      <th className="text-center" scope="col">11:00 - 11:30</th>
                      <th className="text-center" scope="col">11:30 - 12:00</th>
                      <th className="text-center" scope="col">12:00 - 12:30</th>
                      <th className="text-center" scope="col">12:30 - 13:00</th>
                      <th className="text-center" scope="col">13:00 - 13:30</th>
                      <th className="text-center" scope="col">13:30 - 14:00</th>
                      <th className="text-center" scope="col">14:00 - 14:30</th>
                      <th className="text-center" scope="col">14:30 - 15:00</th>
                      <th className="text-center" scope="col">15:00 - 15:30</th>
                      <th className="text-center" scope="col">15:30 - 16:00</th>
                      <th className="text-center" scope="col">16:00 - 16:30</th>
                      <th className="text-center" scope="col">16:30 - 17:00</th>
                      <th className="text-center" scope="col">17:00 - 17:30</th>
                      <th className="text-center" scope="col">17:30 - 18:00</th>
                      <th className="text-center" scope="col">18:00 - 18:30</th>
                      <th className="text-center" scope="col">18:30 - 19:00</th>
                      <th className="text-center" scope="col">19:00 - 19:30</th>
                      <th className="text-center" scope="col">19:30 - 20:00</th>
                      <th className="text-center" scope="col">20:00 - 20:30</th>
                      <th className="text-center" scope="col">20:30 - 21:00</th>
                      <th className="text-center" scope="col">21:00 - 21:30</th>
                      {/* <th className="text-center" scope="col">22:00</th>
                      <th className="text-center" scope="col">23:00</th> */}
                    </tr>
                  </thead>
                  <>
                    {userHours.length > 0 && userHours.map(user => {
                      return (
                        <tbody key={user.id}>
                          <tr>
                            <td className="text-center"><button className='btn btn-danger' onClick={() => handleDelete(user.id)}><i className='fa fa-trash'></i></button></td>
                            {!isNewCall && <td className="text-center"><button className='btn btn-warning' onClick={() => handleEdit(user.id)}><i className='fa fa-edit'></i></button></td>}
                            <td className="text-center text-nowrap align-middle text-bold">{formatDateTz(user.date)}</td>
                            <td className="text-center align-middle">{user.hour?.hours_count}</td>
                            {/* <td className={user.hour?.six ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.six ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td> */}
                            {/* <td className={user.hour?.seven ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.seven ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td> */}
                            <td onClick={() => { editHourSelected(user.hour, 'eight') }} className={user.hour?.eight ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.eight ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'eight3') }} className={user.hour?.eight3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.eight3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'nine') }} className={user.hour?.nine ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.nine ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'nine3') }} className={user.hour?.nine3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.nine3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'ten') }} className={user.hour?.ten ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.ten ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'ten3') }} className={user.hour?.ten3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.ten3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'eleven') }} className={user.hour?.eleven ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.eleven ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'eleven3') }} className={user.hour?.eleven3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.eleven3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'twelve') }} className={user.hour?.twelve ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.twelve ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'twelve3') }} className={user.hour?.twelve3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.twelve3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'thirteen') }} className={user.hour?.thirteen ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.thirteen ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'thirteen3') }} className={user.hour?.thirteen3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.thirteen3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'fourteen') }} className={user.hour?.fourteen ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.fourteen ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'fourteen3') }} className={user.hour?.fourteen3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.fourteen3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'fifteen') }} className={user.hour?.fifteen ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.fifteen ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'fifteen3') }} className={user.hour?.fifteen3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.fifteen3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'sixteen') }} className={user.hour?.sixteen ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.sixteen ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'sixteen3') }} className={user.hour?.sixteen3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.sixteen3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'seventeen') }} className={user.hour?.seventeen ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.seventeen ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'seventeen3') }} className={user.hour?.seventeen3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.seventeen3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'eighteen') }} className={user.hour?.eighteen ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.eighteen ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'eighteen3') }} className={user.hour?.eighteen3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.eighteen3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'nineteen') }} className={user.hour?.nineteen ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.nineteen ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'nineteen3') }} className={user.hour?.nineteen3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.nineteen3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'twenty') }} className={user.hour?.twenty ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.twenty ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'twenty3') }} className={user.hour?.twenty3 ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.twenty3 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td onClick={() => { editHourSelected(user.hour, 'twentyone') }} className={user.hour?.twentyone ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.twentyone ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            {/* <td className={user.hour?.twentytwo ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.twentytwo ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td> */}
                            {/* <td className={user.hour?.twentythree ? 'text-center align-middle text-white bg-success' : 'text-center align-middle text-white bg-danger'}>{user.hour?.twentythree ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td> */}
                          </tr>
                        </tbody>
                      );
                    })}
                  </>
                </table>
              }
            </div>
        }
      </div>
    </div>
  );
};
