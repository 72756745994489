import { useState } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Axios from "axios";
import '../uploadexcel.css';
import { fetchConToken, fetchConTokenDownload } from '../../../helpers/fetch';
import { isMobile } from 'react-device-detect';
import { format } from 'date-fns';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

export const GetReportCalling = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [totalCountDuration, setTotalCountDuration] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [dateRange, setDateRange] = useState(["", ""]);
  const handleFile = ({ target }) => {
    setFile(target.files[0]);
  };

  const handleReturn = (e) => {
    navigate('/');
  };
  const handleUploadAndSave = () => {
    if (file) {
      const fileUploadCloud = async () => {
        Swal.fire({
          title: 'Wait ...',
          onBeforeOpen() {
            Swal.showLoading()
          },
          onAfterClose() {
            Swal.hideLoading()
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false
        })
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "cardsapp");
        Axios.post("https://api.cloudinary.com/v1_1/drholseqp/upload", formData
        ).then((res) => {
          const uploadUser = async (file) => {
            const body = await fetchConToken("calltools/getreportcall", { url: file, dateRange }, 'POST');
            if (body.ok) {
              Swal.close();
              setData(body.data);
              setTotalCountDuration(body.totalCountSeconds);
              setTotalCount(body.totalCountCalls);
            } else {
              Swal.fire("Error", body.msg, "error");
            }
          }
          uploadUser(res.data.secure_url);
        });
      }
      fileUploadCloud();
    }
  }
  const handleUploadAndExport = () => {
    if (file) {
      const fileUploadCloud = async () => {
        Swal.fire({
          title: 'Wait ...',
          onBeforeOpen() {
            Swal.showLoading()
          },
          onAfterClose() {
            Swal.hideLoading()
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false
        })
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "cardsapp");
        Axios.post("https://api.cloudinary.com/v1_1/drholseqp/upload", formData
        ).then((res) => {
          const uploadUser = async (file) => {
            // const body = await fetchConToken("calltools/exportreportcall", { url: file, dateRange }, 'POST');
            const body = await fetchConTokenDownload("calltools/exportreportcall", { url: file, dateRange }, 'POST')
              .then(blob => {
                // Create a URL for the blob
                const url = window.URL.createObjectURL(blob);
                // Create a temporary link element
                const a = document.createElement('a');
                // Set the href attribute to the blob URL
                a.href = url;
                // Set the download attribute to specify the filename
                a.download = 'hotleads_calling.pdf';
                // Append the link to the body
                document.body.appendChild(a);
                // Click the link programmatically to trigger the download
                a.click();
                // Remove the link from the body
                document.body.removeChild(a);
                // Release the object URL
                window.URL.revokeObjectURL(url);
                Swal.close();
              })
              .catch(error => {
                Swal.fire("Error", body.msg, "error");
              });
            // if (body.ok) {
            //   Swal.close();
            //   // setData(body.data);
            //   // setTotalCountDuration(body.totalCount);
            // } else {
            //   Swal.fire("Error", body.msg, "error");
            // }
          }
          uploadUser(res.data.secure_url);
        });
      }
      fileUploadCloud();
    }
  }
  const onDateChange = async (e) => {
    setDateRange(e)
  }
  const setColor = async (id) => {
    const element = document.getElementById('fu' + id);
    if (!element.classList.contains('bg-dark')) {
      element.classList.add('bg-dark')
      for (let i = 0; i < element.children.length; i++) {
        element.children[i].classList.add('text-light')
        element.children[i].classList.remove('text-dark')
      }
    } else {
      element.classList.remove('bg-dark')
      for (let i = 0; i < element.children.length; i++) {
        element.children[i].classList.remove('text-light')
        element.children[i].classList.add('text-dark')
      }
    }
  };

  return (
    <div className='container d-flex flex-column justify-content-center align-items-center'>
      <h1 className='text-dark text-center'>HOT LEAD CALL REPORT</h1>
      <div className="form-group">
        <DateRangePicker className="form-control" onChange={(e) => { onDateChange(e) }} value={dateRange} />
      </div>
      <div className="col-md-6 login-form-2 m-2">
        <h3>Choose file</h3>
        <div className="form-group files m-2">
          <input id="excelfile" className='bg-light' type="file" onChange={(e) => handleFile(e)} />
        </div>
        <div className='container d-flex flex-column justify-content-center align-items-center'>
          <button className='btn btn-light btn-bright m-2 text-bold' onClick={handleUploadAndSave}>Upload And Show</button>
          <button className='btn btn-success btn-bright m-2 text-bold' onClick={handleUploadAndExport}>Upload And Export</button>
        </div>
      </div>
      <div className='container d-flex flex-column justify-content-center align-items-center m-2'>
        <button className="btn btn-dark btn-bright" onClick={handleReturn}>Return</button>
      </div>
      <h1 className='text-dark'>HOT LEADS LIST</h1>
      <h2 className='text-dark'>Total duration: {totalCountDuration} secs</h2>
      <h2 className='text-dark'>Total calls: {totalCount}</h2>
      {
        data.length > 0 &&
        <table className={isMobile ? "d-block overflow-scroll table table-sm table-bordered bg-light table-striped rounded rounded-3 overflow-hidden" : "table table-striped bg-light table-hover overflow-hidden"}>
          <thead className='bg-dark text-light align-middle'>
            <tr>
              <th className="text-center" scope="col">Name</th>
              <th className="text-center" scope="col">Phone Number</th>
              <th className="text-center" scope="col">Type</th>
              <th className="text-center" scope="col">Source</th>
              <th className="text-center" scope="col">State</th>
              <th className="text-center" scope="col">Amount calls</th>
              <th className="text-center" scope="col">Total duration (SECS)</th>
            </tr>
          </thead>

          {data.length > 0 && data.map((item, index) => {
            return (
              <tbody key={index}>
                <tr data-bs-toggle="collapse" onClick={() => { setColor(index) }} id={'fu' + index} href={'#' + 'u' + index} role="button" aria-expanded="false" aria-controls={'u' + index}>
                  <td className="text-center text-dark text-uppercase">{item.lead.name}</td>
                  <td className="text-center text-dark">{item.lead.phone_number}</td>
                  <td className="text-center text-dark">{item.lead.type}</td>
                  <td className="text-center text-dark">{item.lead.type_response}</td>
                  <td className="text-center text-dark">{item.lead.source}</td>
                  <td className="text-center text-dark">{item.amountCalls}</td>
                  <td className="text-center text-dark">{item.countDuration}</td>
                </tr>
                <tr>
                  <td colSpan="12" className="p-0 bg-dark">
                    <div className="collapse" id={'u' + index}>
                      <table className="table table-striped">
                        <thead className="bg-default text-light">
                          <tr className='align-middle'>
                            <th className="text-center small text-light">Date</th>
                            <th className="text-center small text-light">Call Status</th>
                            <th className="text-center small text-light">Duration</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item.calls.length > 0 && item.calls.map((call, index2) => {
                            return (
                              <tr key={index2} className='align-middle bg-light'>
                                <td className="text-center small text-dark">{format(new Date(call['Date & Time']), 'MM-dd-yy hh:mm')}</td>
                                <td className="text-center small text-dark text-uppercase">{call['Call Status']}</td>
                                <td className="text-center small text-dark">{call['Duration']}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      }
    </div>);
};
