import { useEffect } from 'react';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import useSWR from "swr";

export const AdminStatsTotal = ({ cancels }) => {
  const { data, isLoading } = useSWR(`userdata/usersmanualdata/month/${cancels}`);
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const date = new Date();
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  useEffect(() => {
    setTotal(data?.total)
  }, [data]);

  const goBack = () => {
    navigate('/statsdata');
  }

  return (
    <div className='bg-primary vh-110'>
      {/* <img className='position-absolute' src={'https://res.cloudinary.com/drholseqp/image/upload/v1697207405/blue_za5f17.jpg'} style={{ objectFit: "cover", width: "100vw", height: "100vh" }} alt="back" /> */}

      {/* <img className='position-absolute' src={'https://res.cloudinary.com/drholseqp/image/upload/v1697207405/blue_za5f17.jpg'} style={{ objectFit: "cover", width: "100vw", height: "100vh" }} alt="back" /> */}
      {
        isMobile
          ?
          <div className='position-relative'>
            <div className='d-flex justify-content-center align-items-center pt-2'>
              <button onClick={goBack} className='btn btn-light'><i className='fa fa-chevron-left'></i></button>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center text-center text-admin-stats'>
              <span className='font-special-total-deals m-5'>{monthNames[date.getMonth()]}</span>
              <span className='font-special-total-deals'>TOTAL SALES</span>
              {/* <div className='bg-primary-dark rounded-3 p-4 m-3'> */}
                <span className='text-light text-9 text-bold'>{total}</span>
              {/* </div> */}
            </div>
            <img className='logo-be-admin-stats' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694440370/images-logos/001_sc2glr.png'} style={{ objectFit: "contain" }} width={150} height={75} alt="back" />
            <img className='logo-be-admin-stats-2' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704224590/Asset_2_zo1wyw.png'} style={{ objectFit: "contain" }} width={200} height={100} alt="back" />
          </div>
          :
          <div className='w-100 text-center position-absolute'>
            <div className='d-flex flex-column justify-content-center align-items-center vh-100'>
              <div className='d-flex flex-column justify-content-center align-items-center position-absolute top-20'>
                <span className='font-special-total-deals m-5'>{monthNames[date.getMonth()]}</span>
                <span className='font-special-total-deals'>TOTAL SALES</span>
                <span className='font-special-total-deals-value'>{total}</span>
                {/* <span className='font-special-total-deals-supersmall'>Data cut-off time: 9:30 A.M. AND 6 P.M.</span> */}
              </div>
              <img className='position-absolute top-70' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694440370/images-logos/001_sc2glr.png'} style={{ objectFit: "contain" }} width={200} height={100} alt="back" />
              <div className='position-absolute top-90'>
                <button onClick={goBack} className='btn btn-light'><i className='fa fa-chevron-left'></i></button>
              </div>
            </div>
          </div>
      }
    </div>
  );
};