import { useEffect } from 'react';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { fetchConToken } from '../../../../helpers/fetch';
import { LoadingSpinnerWhite } from '../../../ui/LoadingSpinnerWhite';
import { CloserDealsAdminNew } from './CloserDealsAdminNew';
import { CanvasserDealsAdminNew } from './CanvasserDealsAdminNew';
import { CloserDealsAdminNewGroup } from './CloserDealsAdminNewGroup';
import { CloserDealsAdminNewGroup2 } from './CloserDealsAdminNewGroup2';
import { CloserDealsAdminNewGroup2Design } from './CloserDealsAdminNewGroup2Design';
import { CloserDealsAdminNewGroup2Design2 } from './CloserDealsAdminNewGroup2Design2';

export const AdminStatsDealsGeneralNewGroupDesignCanva = ({ cancels, id }) => {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const date = new Date();
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const goBack = () => {
    navigate('/statsdata');
  }

  const [totalCloser, setTotalCloser] = useState(0);
  const [othersCloser, setOthersCloser] = useState([]);
  const [firstsCloser, setFirstsCloser] = useState([]);
  const [totalCanva, setTotalCanva] = useState(0);
  const [othersCanva, setOthersCanva] = useState([]);
  const [firstsCanva, setFirstsCanva] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tops, setTops] = useState([]);
  const [othersList, setOthersList] = useState([]);
  const [topsCanva, setTopsCanva] = useState([]);
  const [othersListCanva, setOthersListCanva] = useState([]);
  useEffect(async () => {
    const todayDate = new Date();
    // const body = await fetchConToken(`userdata/usersmanualdata/custom/true?startDate=2024-06-01&endDate=${todayDate}`);
    // setTops(body.groupOrdened);
    // setOthersList(body.othersList);
    // setTotal(body.total);
    // setTotalCloser(body.total);
    // setFirstsCloser(body.firsts);
    // setOthersCloser(body.others);
    const bodi = await fetchConToken(`userdata/usersmanualdata/custom/canvasser/false?startDate=2024-06-01&endDate=${todayDate}`);
    setTopsCanva(bodi.groupOrdened);
    setOthersListCanva(bodi.othersList);
    setIsLoading(false);
  }, []);

  return (
    <div className={isMobile ? 'bg-design-2 vh-110' : 'bg-design-2'}>
      {
        isLoading
          ?
          <LoadingSpinnerWhite />
          :
          <>
            {
              isMobile
                ?
                <div className='position-relative'>
                  <div className='d-flex justify-content-center align-items-center pt-2'>
                    <button onClick={goBack} className='btn btn-light'><i className='fa fa-chevron-left'></i></button>
                  </div>
                  <div className='d-flex flex-column justify-content-center align-items-center text-center text-admin-stats'>
                    <span className='font-special-total-deals m-5'>{monthNames[date.getMonth()]}</span>
                    <span className='font-special-total-deals'>TOTAL SALES</span>
                  </div>
                  <img className='logo-be-admin-stats' src={'https://res.cloudinary.com/drholseqp/image/upload/v1718642704/COPA_AMERICA_skmiwu.jpg'} style={{ objectFit: "contain" }} width={200} height={100} alt="back" />
                  <img className='logo-be-admin-stats-2' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704224590/Asset_2_zo1wyw.png'} style={{ objectFit: "contain" }} width={200} height={100} alt="back" />
                </div>
                :
                <div className='w-100 text-center vh-110 position-relative pt-5'>
                  <div>
                    <img src='https://res.cloudinary.com/drholseqp/image/upload/v1718648234/Blanco_4x_yjniux.png' style={{ width: '20%', height: '7%' }} />
                    <div className='d-flex justify-content-center align-items-center gap-3'>
                      <span className='text-light text-design-new text-2-5 text-shadow'>JUNE</span>
                      <span className='text-info-light text-design-new text-2-5 text-shadow'>INCENTIVES</span>
                    </div>
                    <div>
                      <span className='text-light text-design-new text-5 text-notspace2 text-shadow'>CANVASSERS</span>
                    </div>
                    <div className='d-flex justify-content-center align-items-center gap-5 p-3'>
                      <div className='d-flex justify-content-center align-items-center gap-4'>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                          <span className='text-info-light text-design-new text-3 text-notspace2'>5</span>
                          <span className='text-info-light text-design-new text-2'>DEALS</span>
                        </div>
                        <span className='text-light text-design-new text-2'>$1000</span>
                      </div>
                      <div className='d-flex justify-content-center align-items-center gap-4'>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                          <span className='text-info-light text-design-new text-3 text-notspace2'>7</span>
                          <span className='text-info-light text-design-new text-2'>DEALS</span>
                        </div>
                        <span className='text-light text-design-new text-2'>$2000</span>
                      </div>
                      <div className='d-flex justify-content-center align-items-center gap-4'>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                          <span className='text-info-light text-design-new text-3 text-notspace2'>+10</span>
                          <span className='text-info-light text-design-new text-2'>DEALS</span>
                        </div>
                        <span className='text-light text-design-new text-2'>$3000</span>
                      </div>
                    </div>
                  </div>
                  {/* <img className='img-class-design' src={'https://res.cloudinary.com/drholseqp/image/upload/v1718642704/COPA_AMERICA_skmiwu.jpg'} style={{ objectFit: "cover" }} alt="back" /> */}
                  {/* <div className='col-12 px-5 py-4 d-flex justify-content-center align-items-center'>
                    <CloserDealsAdminNewGroup2Design2 tops={tops} othersList={othersList} title={'CLOSERS'} />
                  </div> */}
                  <div className='col-12 px-5 py-4 d-flex justify-content-center align-items-center'>
                    <CloserDealsAdminNewGroup2Design2 tops={topsCanva} othersList={othersListCanva} title={'CANVASSER'} />
                  </div>
                  {/* <div className='col-12 bg-canva-table px-5 py-4 d-flex justify-content-center align-items-center'>
                    <CanvasserDealsAdminNew date={date} myDeals={0} total={totalCanva} others={othersCanva} firsts={firstsCanva} loading={false} id={id} />
                  </div> */}
                  {/* <div className='col-12 bg-canva-table d-flex justify-content-center align-items-center'>
                    <span className='text-2 text-light text-justified text-bold'><b className='text-primary text-bold text-3'>{total}</b> deals</span>
                  </div>
                  <div className='col-12 bg-canva-table px-5 py-4 d-flex justify-content-center align-items-center'>
                    <img className='img-class-logo' src={'https://res.cloudinary.com/drholseqp/image/upload/v1707425511/output-onlinepngtools_i0seme.png'} style={{ objectFit: "contain" }} alt="back" />
                  </div> */}
                </div>
            }
          </>
      }
    </div>
  );
};