import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { fetchConToken } from '../../../../helpers/fetch';
import { LoadingSpinnerWhite } from '../../../ui/LoadingSpinnerWhite';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { TableRepcardOffice } from './TableRepcardOffice';
import { format } from 'date-fns';

export const AdminStatsRepcardOfficeSimpleAll = ({ cancels, id, officee, isAdmin }) => {
  const navigate = useNavigate();

  const [usersPercentageBR, setUsersPercentageBR] = useState([]);
  const [totalAppBR, setTotalAppBR] = useState(0);
  const [totalDKBR, setTotalDKBR] = useState(0);
  const [percentageBR, setPercentageBR] = useState(0);

  const [usersPercentageB, setUsersPercentageB] = useState([]);
  const [totalAppB, setTotalAppB] = useState(0);
  const [totalDKB, setTotalDKB] = useState(0);
  const [percentageB, setPercentageB] = useState(0);

  const [usersPercentageCC, setUsersPercentageCC] = useState([]);
  const [totalAppCC, setTotalAppCC] = useState(0);
  const [totalDKCC, setTotalDKCC] = useState(0);
  const [percentageCC, setPercentageCC] = useState(0);


  const [usersPercentageJ, setUsersPercentageJ] = useState([]);
  const [totalAppJ, setTotalAppJ] = useState(0);
  const [totalDKJ, setTotalDKJ] = useState(0);
  const [percentageJ, setPercentageJ] = useState(0);

  const [usersPercentageS, setUsersPercentageS] = useState([]);
  const [totalAppS, setTotalAppS] = useState(0);
  const [totalDKS, setTotalDKS] = useState(0);
  const [percentageS, setPercentageS] = useState(0);

  const [usersPercentageC, setUsersPercentageC] = useState([]);
  const [totalAppC, setTotalAppC] = useState(0);
  const [totalDKC, setTotalDKC] = useState(0);
  const [percentageC, setPercentageC] = useState(0);


  const [dateS, setDateS] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const date = new Date();
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const goBack = () => {
    navigate('/statsdata');
  }

  const handleDateChange = async (e) => {
    setDateS(e);
    setIsLoading(true);

    const bodu = await fetchConToken('userdata/usersdata/percentagesuccess/all/offices', { date: dateS }, 'POST')
    console.log(bodu)
    setUsersPercentageBR(bodu.leadsBR);
    setTotalDKBR(bodu.totalDKBR);
    setTotalAppBR(bodu.totalAppBR);
    setPercentageBR(bodu.totalPercentageBR);

    setUsersPercentageB(bodu.leadsB);
    setTotalDKB(bodu.totalDKB);
    setTotalAppB(bodu.totalAppB);
    setPercentageB(bodu.totalPercentageB);

    setUsersPercentageCC(bodu.leadsCC);
    setTotalDKCC(bodu.totalDKCC);
    setTotalAppCC(bodu.totalAppCC);
    setPercentageCC(bodu.totalPercentageCC);

    setUsersPercentageJ(bodu.leadsJ);
    setTotalDKJ(bodu.totalDKJ);
    setTotalAppJ(bodu.totalAppJ);
    setPercentageJ(bodu.totalPercentageJ);

    setUsersPercentageS(bodu.leadsS);
    setTotalDKS(bodu.totalDKS);
    setTotalAppS(bodu.totalAppS);
    setPercentageS(bodu.totalPercentageS);

    setUsersPercentageC(bodu.leadsC);
    setTotalDKC(bodu.totalDKC);
    setTotalAppC(bodu.totalAppC);
    setPercentageC(bodu.totalPercentageC);

    setIsLoading(false);
  };
  return (
    <div className={isMobile ? 'bg-primary-dark vh-110' : 'bg-primary-dark'}>
      {
        isLoading
          ?
          <LoadingSpinnerWhite />
          :
          <>
            {
              isMobile
                ? <></>
                //   <div className='position-relative'>
                //     <div className='d-flex justify-content-center align-items-center pt-2'>
                //       <button onClick={goBack} className='btn btn-light'><i className='fa fa-chevron-left'></i></button>
                //     </div>
                //     <div className='d-flex flex-column justify-content-center align-items-center text-center text-admin-stats'>
                //       <span className='font-special-total-deals m-5'>{monthNames[date.getMonth()]}</span>
                //       <span className='font-special-total-deals'>TOTAL SALES</span>
                //       {/* <div className='bg-primary-dark rounded-3 p-4 m-3'>
                //   <span className='font-special-total-deals-value'>{total}</span>
                // </div> */}
                //     </div>
                //     <img className='logo-be-admin-stats' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694440370/images-logos/001_sc2glr.png'} style={{ objectFit: "contain" }} width={200} height={100} alt="back" />
                //     <img className='logo-be-admin-stats-2' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704224590/Asset_2_zo1wyw.png'} style={{ objectFit: "contain" }} width={200} height={100} alt="back" />
                //   </div>
                :
                <div className='w-100 text-center position-relative'>
                  {/* <img className='logo-stats-decoration-2' src={'https://res.cloudinary.com/drholseqp/image/upload/v1705439657/Asset_1_1_qxqhqj.png'} style={{ objectFit: "contain" }} alt="back" /> */}
                  <DateRangePicker className={"bg-light rounded-3 mb-1"} onChange={(e) => { handleDateChange(e) }} value={dateS} />

                  {
                    dateS &&
                    <div className='w-100 d-flex justify-content-center align-items-center'>
                      <div className='bg-light p-2 rounded-3 d-flex flex-column align-items-center justify-content-center m-2 w-50'>
                        <span className='text-1-5 text-primary text-justified'>{monthNames[dateS[0].getMonth()]} - {monthNames[dateS[1].getMonth()]}</span>
                        <span className='text-1-5 text-primary text-justified'><b>{format(new Date(dateS[0]), 'yyyy-dd-MM')}</b> - <b>{format(new Date(dateS[1]), 'yyyy-dd-MM')}</b></span>
                      </div>
                    </div>
                  }
                  <div className='d-flex justify-content-center align-items-start row'>
                    {usersPercentageBR && usersPercentageBR.length > 0 && <TableRepcardOffice title={'Boca Raton'} users={usersPercentageBR} totalDK={totalDKBR} totalApp={totalAppBR} percentage={percentageBR} dateS={dateS} />}
                    {usersPercentageCC && usersPercentageCC.length > 0 && <TableRepcardOffice title={'Cape Coral'} users={usersPercentageCC} totalDK={totalDKCC} totalApp={totalAppCC} percentage={percentageCC} dateS={dateS} />}
                    {usersPercentageJ && usersPercentageJ.length > 0 && <TableRepcardOffice title={'Jacksonville'} users={usersPercentageJ} totalDK={totalDKJ} totalApp={totalAppJ} percentage={percentageJ} dateS={dateS} />}
                    {usersPercentageB && usersPercentageB.length > 0 && <TableRepcardOffice title={'Bradenton'} users={usersPercentageB} totalDK={totalDKB} totalApp={totalAppB} percentage={percentageB} dateS={dateS} />}
                    {usersPercentageS && usersPercentageS.length > 0 && <TableRepcardOffice title={'Strattonites'} users={usersPercentageS} totalDK={totalDKS} totalApp={totalAppS} percentage={percentageS} dateS={dateS} />}
                    {usersPercentageC && usersPercentageC.length > 0 && <TableRepcardOffice title={'Corporate Office'} users={usersPercentageC} totalDK={totalDKC} totalApp={totalAppC} percentage={percentageC} dateS={dateS} />}
                  </div>
                </div>
            }
          </>
      }
    </div>
  );
};