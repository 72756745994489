import * as d3 from 'd3';
import { useEffect, useRef } from 'react';

export const ProjectGraphBars = ({ data }) => {
   const chartRef = useRef();
   useEffect(() => {
      const width = 600;
      const height = 400;
      const margin = { top: 20, right: 30, bottom: 120, left: 40 };

      d3.select(chartRef.current).html("");

      const svg = d3.select(chartRef.current)
         .append("svg")
         .attr("viewBox", `0 0 ${width} ${height}`)
         .attr("width", width)
         .attr("height", height)
         .attr("style", "max-width: 100%; height: auto;")
         .append("g")
         .attr("transform", `translate(${margin.left},${margin.top})`);

      const x = d3.scaleBand()
         .domain(data.map(d => d.key))
         .range([0, width - margin.left - margin.right])
         .padding(0.1);

      const y = d3.scaleLinear()
         .domain([0, d3.max(data, d => d.value)])
         .nice()
         .range([height - margin.top - margin.bottom, 0]);

      svg.append("g")
         .attr("transform", `translate(0,${height - margin.top - margin.bottom})`)
         .call(d3.axisBottom(x))
         .selectAll("text")
         .style("text-anchor", "end")
         .attr("transform", "rotate(-45)")
         .attr("dx", "-0.5em")
         .attr("dy", "0.25em");

      svg.append("g")
         .call(d3.axisLeft(y));

      svg.selectAll("rect")
         .data(data)
         .enter()
         .append("rect")
         .attr("x", d => x(d.key))
         .attr("y", d => y(d.value))
         .attr("width", x.bandwidth())
         .attr("height", d => y(0) - y(d.value))
         .attr("fill", "steelblue");

      svg.selectAll("text.bar-label")
         .data(data)
         .enter()
         .append("text")
         .attr("class", "bar-label")
         .attr("x", d => x(d.key) + x.bandwidth() / 2)
         .attr("y", d => y(d.value) - 5) // Mueve el texto hacia arriba
         .attr("text-anchor", "middle")
         .text(d => d.value)
         .attr("fill", "black");

   }, [data]);

   return (
      <div ref={chartRef}></div>
   );
};
