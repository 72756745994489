import { fetchConToken } from '../../helpers/fetch';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ExportCsv = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleReturn = (e) => {
    navigate('/');
  };
  const handleUploadAndSave = () => {
    const uploadUser = async () => {
      const body = await fetchConToken(`userdata/exportcsv/closer`, {}, 'POST');
      if (body.ok) {
        Swal.fire(t('success.title'), body.msg, "success");
      } else {
        Swal.fire("Error", body.msg, "error");
      }
    }
    uploadUser();
  }
  return (
    <div className='container d-flex flex-column justify-content-center align-items-center'>
      <h1 className='text-light text-center'>Export csv</h1>
      <div className="col-md-6 login-form-2 m-2">

        <div className='container d-flex flex-column justify-content-center align-items-center'>
          <button className='btn btn-success btn-bright' onClick={handleUploadAndSave}>{t('buttons.upload')}</button>
        </div>
      </div>
      <div className='container d-flex flex-column justify-content-center align-items-center m-2'>
        <button className="btn btn-dark btn-bright" onClick={handleReturn}>{t('buttons.return')}</button>
      </div>
    </div>
    );
};