import { useState } from 'react';
import { fetchConToken } from '../../helpers/fetch';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Axios from "axios";
import './uploadexcel.css';
import { useTranslation } from 'react-i18next';

export const AddUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const handleFile = ({ target }) => {
    setFile(target.files[0]);
  };

  const handleReturn = (e) => {
    navigate('/');
  };
  const handleUploadAndSave = () => {
    if (file) {
      const fileUploadCloud = async () => {
        Swal.fire({
          title: 'Wait ...',
          onBeforeOpen() {
            Swal.showLoading()
          },
          onAfterClose() {
            Swal.hideLoading()
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false
        })
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "cardsapp");
        Axios.post("https://api.cloudinary.com/v1_1/drholseqp/upload", formData
        ).then((res) => {
          const uploadUser = async (file) => {
            const body = await fetchConToken("auth/uploadusers", { url: file }, 'POST');
            if (body.ok) {
              Swal.close();
              Swal.fire(t('success.title'), "Users upload", "success");
            } else {
              Swal.fire("Error", body.msg, "error");
            }
          }
          uploadUser(res.data.secure_url);
        });
      }
      fileUploadCloud();
    }
  }
  return (
    <div className='container d-flex flex-column justify-content-center align-items-center'>
      <h1 className='text-light text-center'>{t('upload.user')}</h1>
      <div className="col-md-6 login-form-2 m-2">
        <h3>{t('labels.choose_file')}</h3>
        <div className="form-group files m-2">
          <input id="excelfile" className='bg-light' type="file" onChange={(e) => handleFile(e)} />
        </div>
        <div className='container d-flex flex-column justify-content-center align-items-center'>
          <button className='btn btn-success btn-bright' onClick={handleUploadAndSave}>{t('buttons.upload')}</button>
        </div>
      </div>
      <div className='container d-flex flex-column justify-content-center align-items-center m-2'>
        <button className="btn btn-dark btn-bright" onClick={handleReturn}>{t('buttons.return')}</button>
      </div>
    </div>);
};
