import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';


import { useForm } from '../../hooks/useForm';
import { login, startGetUser } from '../../actions/auth';
import { fetchConToken } from '../../helpers/fetch';
import colourStyles from '../../helpers/selectStyles';
import { LoadingSpinnerWhite } from '../ui/LoadingSpinnerWhite';
import { format, isValid } from 'date-fns';
import Axios from 'axios';
import toPascalCase from '../../helpers/pascalCase';

export const EditUserCallCenter = () => {
  const { t } = useTranslation();
  const { userSelected } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [photo, setPhoto] = useState(null);
  const [file, setFile] = useState(null);
  const handleFile = ({ target }) => {
    setFile(target.files[0]);
  };
  const [formUserValues, handleUserInputChange, reset] = useForm({
    sName: userSelected ? userSelected.name : '',
    sLastname: userSelected ? userSelected.last_name : '',
    sEmail: userSelected ? userSelected.email : '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const roles = [
    { value: 'Canvasser', label: 'Canvasser' },
    { value: 'Closer', label: 'Closer' },
  ];
  const offices = [
    { value: 'Boca Raton', label: 'Boca Raton' },
    { value: 'Bradenton', label: 'Bradenton' },
    { value: 'Cape Coral', label: 'Cape Coral' },
    { value: 'Jacksonville', label: 'Jacksonville' },
  ];

  const datee = format(new Date(), 'MM-dd-yyyy HH:mm');

  useEffect(() => {
    if (isValid(datee)) {
      setBirthDate(datee)
      setStartDate(datee)
    }
  }, []);

  const [birthDate, setBirthDate] = useState("");
  const handleBirthDateChange = (e) => {
    setBirthDate(e);
  };

  const [startDate, setStartDate] = useState("");
  const handleStartDateChange = (e) => {
    setStartDate(e);
  };

  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true);
      const { user: userSelected } = await fetchConToken(`auth/${localStorage.getItem('userId')}`);
      reset({
        sName: userSelected ? userSelected.name : '',
        sLastname: userSelected ? userSelected.last_name : '',
        sEmail: userSelected ? userSelected.email : '',
      })
      setOffice(userSelected ? { value: userSelected.office, label: userSelected.office } : null);
      setRole(userSelected
        ?
        roles.filter((v) => {
          return userSelected.role === v.value;
        })[0]
        :
        null
      );
      setStatus(userSelected ? userSelected.status : true);
      setBirthDate(userSelected ? userSelected.birth_date : new Date());
      setStartDate(userSelected ? userSelected.date_started : new Date());
      setPhoto(userSelected ? userSelected.photo : '');
      setIsLoading(false);
    }
    initialize();
  }, [])

  const { sName, sLastname, sEmail } = formUserValues;
  const [status, setStatus] = useState(userSelected ? userSelected.status : true);
  const [office, setOffice] = useState(userSelected ? { value: userSelected.office, label: userSelected.office } : null);

  const handleOffice = (e) => {
    setOffice(e);
  };

  const [role, setRole] = useState(
    userSelected
      ?
      roles.filter((v) => {
        return userSelected.role === v.value;
      })
      :
      null
  );

  const handleRole = (e) => {
    setRole(e);
  };

  const handleEditUser = (e) => {
    e.preventDefault();
    if (sName === '' || sLastname === '' || sEmail === '') {
      return Swal.fire(
        "Error",
        t('errors.fields_required'),
        "error"
      );
    }
    const fileUploadCloud = async () => {
      Swal.fire({
        title: 'Wait ...',
        onBeforeOpen() {
          Swal.showLoading()
        },
        onAfterClose() {
          Swal.hideLoading()
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false
      })
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "cardsapp");
      Axios.post("https://api.cloudinary.com/v1_1/drholseqp/upload", formData
      ).then((res) => {
        setPhoto(res.data.secure_url);
        const startUpdateUserFromAdmin = async (emaill, namee, lastnamee, office, role, status, date_started, birth_date, photo) => {
          const email = emaill.toLowerCase();
          const last_name = toPascalCase(lastnamee.trim());
          const name = toPascalCase(namee.trim());
          const body = await fetchConToken(
            `auth/editadmin`,
            { email, name, last_name, office, role, status, date_started, birth_date, photo },
            "PUT"
          );
          if (body.ok) {
            Swal.fire("Success", "Updated successfully", "success");
            dispatch(login({ uid: body.uid, name: body.name, role: body.role, office: body.office, photo: body.photo, points: body.points }));
            dispatch(startGetUser(body.uid));
            navigate('/userscallcenter');
          } else {
            Swal.fire("Error", body.msg, "error");
          }
        };
        startUpdateUserFromAdmin(sEmail, sName, sLastname, office.value, role.value, status, startDate, birthDate, res.data.secure_url)

      });
    }
    if (file !== null) {
      fileUploadCloud();
    } else {
      const startUpdateUserFromAdmin = async (emaill, namee, lastnamee, office, role, status, date_started, birth_date, photo) => {
        const email = emaill.toLowerCase();
        const last_name = toPascalCase(lastnamee.trim());
        const name = toPascalCase(namee.trim());
        const body = await fetchConToken(
          `auth/editadmin`,
          { email, name, last_name, office, role, status, date_started, birth_date, photo },
          "PUT"
        );
        if (body.ok) {
          Swal.fire("Success", "Updated successfully", "success");
          dispatch(login({ uid: body.uid, name: body.name, role: body.role, office: body.office, photo: body.photo, points: body.points }));
          dispatch(startGetUser(body.uid));
          navigate('/userscallcenter');
        } else {
          Swal.fire("Error", body.msg, "error");
        }
      };
      startUpdateUserFromAdmin(sEmail, sName, sLastname, office.value, role.value, status, startDate, birthDate, photo)
    }

  };
  const handleReturn = () => {
    navigate('/userscallcenter');
  };
  const handleStatus = () => {
    setStatus(!status);
  };

  return (
    <>
      {isLoading
        ?
        <LoadingSpinnerWhite />
        :
        <>
          {
            isMobile
              ?
              <div className='d-flex flex-column justify-content-center align-items-center mt-4' data-aos="fade-up" data-aos-duration="1000">
                <h1 className='text-light h1'>{t('users.edit.title')}</h1>
                <div className="w-80 login-form-2 m-2">
                  <form onSubmit={handleEditUser}>
                    <div className="form-group">
                      <label className='text-light'>{t('labels.first_name')}</label>
                      <input
                        type="text"
                        className="form-control mb-2"
                        placeholder={t('labels.first_name')}
                        name="sName"
                        value={sName}
                        onChange={handleUserInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label className='text-light'>{t('labels.last_name')}</label>
                      <input
                        type="text"
                        className="form-control mb-2"
                        placeholder={t('labels.last_name')}
                        name="sLastname"
                        value={sLastname}
                        onChange={handleUserInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label className='text-light'>{t('labels.email')}</label>
                      <input
                        type="text"
                        className="form-control mb-2"
                        placeholder={t('labels.email')}
                        name="sEmail"
                        value={sEmail}
                        onChange={handleUserInputChange}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className='text-light'>{t('labels.office')}</label>
                      <div className="w-100">
                        <Select placeholder={t('select.placeholder')} styles={colourStyles} options={offices} value={office} onChange={handleOffice} />
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className='text-light'>{t('labels.role')}</label>
                      <div className="w-100">
                        <Select placeholder={t('select.placeholder')} styles={colourStyles} options={roles} value={role} onChange={handleRole} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className='text-light'>Birth date</label>
                      <DateTimePicker
                        onChange={handleBirthDateChange}
                        value={birthDate}
                        className="form-control"
                        format='MM-dd-yyyy HH:mm'
                      />
                    </div>
                    <div className="form-group">
                      <label className='text-light'>Start date</label>
                      <DateTimePicker
                        onChange={handleStartDateChange}
                        value={startDate}
                        className="form-control"
                        format='MM-dd-yyyy HH:mm'
                      />
                    </div>
                    <div className="form-group files m-2 text-center">
                      <label>Photo</label>
                      <img className="rounded-circle" alt="user" src={photo ? photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user_z1c8yd.webp'} style={{ objectFit: "cover" }} width={200} height={200} />
                      <input id="excelfile" className='bg-light' type="file" onChange={(e) => handleFile(e)} />
                    </div>
                    <div className="form-group mb-3">
                      <label className='form-label' style={{ color: "white" }}>{t('labels.status')}</label>
                      <div className="mb-2 d-flex justify-content-center align-items-center form-control w-25 form-check form-switch">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          value={status}
                          checked={status}
                          onChange={handleStatus}
                        />
                      </div>
                    </div>
                    <div className="form-group d-flex justify-content-center mb-1">
                      <button type="submit" className="btn btn-success btn-bright">
                        <i className="fa fa-floppy-disk"></i> {t('buttons.save')}
                      </button>
                    </div>
                  </form>
                  <div className="form-group d-flex flex-row justify-content-center">
                    <button className="btn btn-light mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleReturn}>
                      <i className="fa fa-arrow-rotate-left me-1"></i> {t('buttons.return')}
                    </button>
                  </div>
                </div>
              </div>
              :
              <div className='d-flex flex-column justify-content-center align-items-center mt-4' data-aos="fade-up" data-aos-duration="1000">
                <h1 className='text-light'>{t('users.edit.title')}</h1>
                <div className="col-md-6 login-form-2 m-4">
                  <form onSubmit={handleEditUser}>
                    <div className="form-group">
                      <label className='text-light'>{t('labels.first_name')}</label>
                      <input
                        type="text"
                        className="form-control mb-2"
                        placeholder={t('labels.first_name')}
                        name="sName"
                        value={sName}
                        onChange={handleUserInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label className='text-light'>{t('labels.last_name')}</label>
                      <input
                        type="text"
                        className="form-control mb-2"
                        placeholder={t('labels.last_name')}
                        name="sLastname"
                        value={sLastname}
                        onChange={handleUserInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label className='text-light'>{t('labels.email')}</label>
                      <input
                        type="text"
                        className="form-control mb-2"
                        placeholder={t('labels.email')}
                        name="sEmail"
                        value={sEmail}
                        onChange={handleUserInputChange}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className='text-light'>{t('labels.office')}</label>
                      <div className="w-100">
                        <Select placeholder={t('select.placeholder')} styles={colourStyles} options={offices} value={office} onChange={handleOffice} />
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className='text-light'>{t('labels.role')}</label>
                      <div className="w-100">
                        <Select placeholder={t('select.placeholder')} styles={colourStyles} options={roles} value={role} onChange={handleRole} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className='text-light'>Birth date</label>
                      <DateTimePicker
                        onChange={handleBirthDateChange}
                        value={birthDate}
                        className="form-control"
                        format='MM-dd-yyyy HH:mm'
                      />
                    </div>
                    <div className="form-group">
                      <label className='text-light'>Start date</label>
                      <DateTimePicker
                        onChange={handleStartDateChange}
                        value={startDate}
                        className="form-control"
                        format='MM-dd-yyyy HH:mm'
                      />
                    </div>

                    <div className='d-flex flex-column justify-content-center align-items-center'>
                      <label className='text-light'>Photo</label>
                      <img className="rounded-circle" alt="user" src={photo ? photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user_z1c8yd.webp'} style={{ objectFit: "cover" }} width={200} height={200} />
                    </div>
                    <div className="form-group files m-2 text-center">
                      <label className='text-light'>Change Photo</label>
                      <input id="excelfile" className='bg-light' type="file" onChange={(e) => handleFile(e)} />
                    </div>
                    <div className="form-group mb-3">
                      <label className='form-label text-light'>{t('labels.status')}</label>
                      <div className="mb-2 d-flex justify-content-center align-items-center form-control w-25 form-check form-switch">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={status}
                          value={status}
                          onChange={handleStatus}
                        />
                      </div>
                    </div>
                    <div className="form-group d-flex justify-content-center mb-1">
                      <button type="submit" className="btn btn-success btn-bright">
                        <i className="fa fa-floppy-disk"></i> {t('buttons.save')}
                      </button>
                    </div>
                  </form>
                  <div className="form-group d-flex flex-row justify-content-center">
                    <button className="btn btn-light mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleReturn}>
                      <i className="fa fa-arrow-rotate-left me-1"></i> {t('buttons.return')}
                    </button>
                  </div>
                </div>
              </div>
          }
        </>
      }
    </>
  );
};
