import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import Swal from "sweetalert2";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import { format, isValid } from "date-fns";

import { startClearUserAsoc, startLogout, startUpdateBirthDate } from "../../actions/auth";
import { uiOpenModal } from "../../actions/ui";
import { PhotoModal } from "./PhotoModal";
import { LoadingSpinnerWhite } from "../ui/LoadingSpinnerWhite";

export const ProfileGeneralScreen = ({ user }) => {
  const { t } = useTranslation();
  const { userAsoc } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const changePhoto = (e) => {
    dispatch(uiOpenModal());
  };
  const handleLogout = () => {
    dispatch(startLogout());
    dispatch(startClearUserAsoc());
  };

  const handleDateChange = (e) => {
    setDate(e);
  };

  const [date, setDate] = useState("");

  const datee = userAsoc.birth_date ? new Date(userAsoc.birth_date) : new Date();

  useEffect(() => {
    if (isValid(datee)) {
      setDate(datee)
    }
  }, []);

  const handleUpdate = (e) => {
    e.preventDefault();
    if (date === "") {
      return Swal.fire(
        "Error",
        "Please select a date",
        "error"
      );
    }
    dispatch(startUpdateBirthDate(userAsoc.id, date));
  };
  return (
    <>
      {user ?
        <div className="d-flex flex-column justify-content-center align-items-center" data-aos="fade-up" data-aos-duration="1000">
          <hr />
          <h3 className="text-light">{user.name + " " + user.last_name}</h3>
          <hr />
          <div className="m-2 d-flex flex-column justify-content-center align-items-center">
            <h3 className="mb-1 text-light text-center h4">{t('profile.photo')}</h3>
            <img className="rounded-circle" alt="user" src={user.photo ? user.photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user_z1c8yd.webp'} style={{ objectFit: "cover" }} width={200} height={200} />
            <button onClick={changePhoto} className="btn btn-primary btn-bright m-2"><i className="fa fa-edit"></i></button>
          </div>
          <hr />
          <form onSubmit={handleUpdate} className={isMobile ? "w-95" : "w-50"}>
            <h3 className="mb-1 text-light text-center h4">Birth Date</h3>
            <div className="form-group">
              <DateTimePicker
                onChange={handleDateChange}
                value={date}
                className="form-control mb-2"
                format='MM-dd-yyyy HH:mm'
              />
            </div>
            <div className="form-group d-flex justify-content-center mb-1">
              <button type="submit" className="btn btn-primary btn-bright">
                <i className="fa fa-floppy-disk"></i> {t('buttons.save')}
              </button>
            </div>
          </form>
          <hr />
          <div className="mb-2">
            <button className="btn btn-danger" onClick={handleLogout} role='button'><span className='font-speciallblue-2'>{t('buttons.logout')}</span></button>
          </div>
          <PhotoModal user={user} />
        </div>
        :
        <LoadingSpinnerWhite />
      }
    </>
  );
};
