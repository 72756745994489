import { useEffect, useState } from 'react';

import { GraphAllTimeTab } from './GraphAllTimeTab';
import { GraphPreviousWeekTab } from './GraphPreviousWeekTab';
import { GraphActualWeekTab } from './GraphActualWeekTab';
import { GraphActualMonthTab } from './GraphActualMonthTab';
import { GraphCustomTab } from './GraphCustomTab';

export const GraphCanvasser = ({ id }) => {
  const [active, setActive] = useState(0);
  useEffect(() => {
    document.getElementById('0').classList.add('active')
  }, []);

  const activate = (id) => {
    document.getElementById(`${active}`).classList.remove('active');
    document.getElementById(`${id}`).classList.add('active');
    setActive(id)
  }

  return (
    <div className="text-center mb-3 w-100">
      <div className='row'>
        <div className='col-3 d-flex flex-column justify-content-start align-items-center'>
          <button onClick={() => { activate(0) }} id="0" className='btn btn-primary rounded-pill m-1 w-50 ms-4'>All time</button>
          <button onClick={() => { activate(1) }} id="1" className='btn btn-primary rounded-pill m-1 w-50 ms-4'>Previous week</button>
          <button onClick={() => { activate(2) }} id="2" className='btn btn-primary rounded-pill m-1 w-50 ms-4'>Actual week</button>
          <button onClick={() => { activate(3) }} id="3" className='btn btn-primary rounded-pill m-1 w-50 ms-4'>Actual month</button>
          <button onClick={() => { activate(4) }} id="4" className='btn btn-primary rounded-pill m-1 w-50 ms-4'>Custom</button>
        </div>
        <div className='col-9 px-5'>
          {
            active === 0
              ?
              <GraphAllTimeTab id={id} />
              :
              active === 1
                ?
                <GraphPreviousWeekTab id={id} />
                :
                active === 2
                  ?
                  <GraphActualWeekTab id={id} />
                  :
                  active === 3
                    ?
                    <GraphActualMonthTab id={id} />
                    :
                    <GraphCustomTab id={id} />
          }
        </div>
      </div>
    </div>
  );
};
