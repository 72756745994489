import { useEffect } from 'react';
import { RowCardsMobile } from './RowCardsMobile';

export const RowsMobile = ({ cards }) => {
  useEffect(() => {
    setTimeout(() => {
      slice()
    }, 1000)

  }, [])
  const slice = (e) => {
    const cards = document.getElementsByClassName('card-stacks4-mobile');
    cards[0]?.classList?.toggle('transition');
  }
  return (
    <div className='text-center'>
      <div className='vw-100 d-flex flex-column justify-content-center align-items-center position-absolute  '>
        <ul className='card-stacks4-mobile animate__animated animate__backInLeft'>
          {
            cards.map((card, i) => {
              return (
                <RowCardsMobile key={i} cardRow={card} i={i + 1} />
              )
            })
          }
        </ul>
      </div>
    </div>
  );
};
