import { Link } from "react-router-dom";

export const AdminStatsData = () => {

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <hr />
      <div className="d-flex flex-column justify-content-center align-items-center">
        <Link to="/adminstats" className='m-2'>
          <button className="btn btn-light" title="Add users" style={{ width: "250px" }}><span className="text-1 text-bold">Stats and screenshots</span></button>
        </Link>
      </div>
      <hr />
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h2 className="text-light">ONLY DEALS CLOSED</h2>
        <Link to="/statstotal" className='m-2'>
          <button className="btn btn-light" title="Add users" style={{ width: "250px" }}>Total Deals</button>
        </Link>
        <Link to="/statscloser" className='m-2'>
          <button className="btn btn-light" title="Add users" style={{ width: "250px" }}>Closer Deals</button>
        </Link>
        <Link to="/statscanvasser" className='m-2'>
          <button className="btn btn-light" title="Add users" style={{ width: "250px" }}>Canvasser Deals</button>
        </Link>
        <Link to="/statsselfgen" className='m-2'>
          <button className="btn btn-light" title="Add users" style={{ width: "250px" }}>SelfGen Deals</button>
        </Link>
        <Link to="/statsoffices" className='m-2'>
          <button className="btn btn-light" title="Add users" style={{ width: "250px" }}>Deals by office</button>
        </Link>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h2 className="text-light">DEALS WITH CANCELLATIONS</h2>
        <Link to="/statstotalno" className='m-2'>
          <button className="btn btn-light" title="Add users" style={{ width: "250px" }}>Total Deals</button>
        </Link>
        <Link to="/statscloserno" className='m-2'>
          <button className="btn btn-light" title="Add users" style={{ width: "250px" }}>Closer Deals</button>
        </Link>
        <Link to="/statscanvasserno" className='m-2'>
          <button className="btn btn-light" title="Add users" style={{ width: "250px" }}>Canvasser Deals</button>
        </Link>
        <Link to="/statsselfgenno" className='m-2'>
          <button className="btn btn-light" title="Add users" style={{ width: "250px" }}>SelfGen Deals</button>
        </Link>
        <Link to="/statsofficesno" className='m-2'>
          <button className="btn btn-light" title="Add users" style={{ width: "250px" }}>Deals by office</button>
        </Link>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h2 className="text-light">LEADS OR APPOINTMENTS</h2>
        <Link to="/statsleads" className='m-2'>
          <button className="btn btn-light" title="Add users" style={{ width: "250px" }}>Leads by custom date</button>
        </Link>
        <Link to="/statsleadsoffice" className='m-2'>
          <button className="btn btn-light" title="Add users" style={{ width: "250px" }}>Leads of the month by office</button>
        </Link>
        <Link to="/statsappointments" className='m-2'>
          <button className="btn btn-light" title="Add users" style={{ width: "250px" }}>Appointments by custom date</button>
        </Link>
        <Link to="/statsappointmentsoffice" className='m-2'>
          <button className="btn btn-light" title="Add users" style={{ width: "250px" }}>Appointments of the month by office</button>
        </Link>
      </div>
      <Link to="/" className='m-4'>
        <button className="btn btn-dark" title="Add users" style={{ width: "250px" }}><i className='fa fa-chevron-left'></i></button>
      </Link>
    </div>
  );
};
