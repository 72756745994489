import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { format, set } from 'date-fns';

import { fetchConToken } from '../../helpers/fetch';

export const EditCallCenter = ({ isAdmin }) => {
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedHour, setSelectedHour] = useState(null);
  const [hours, setHours] = useState([]);

  //HOURS
  const [c8, setC8] = useState(false);
  const [c9, setC9] = useState(false);
  const [c10, setC10] = useState(false);
  const [c11, setC11] = useState(false);
  const [c12, setC12] = useState(false);
  const [c13, setC13] = useState(false);
  const [c14, setC14] = useState(false);
  const [c15, setC15] = useState(false);
  const [c16, setC16] = useState(false);
  const [c17, setC17] = useState(false);
  const [c18, setC18] = useState(false);
  const [c19, setC19] = useState(false);
  const [c20, setC20] = useState(false);
  const [c21, setC21] = useState(false);
  const [c83, setC83] = useState(false);
  const [c93, setC93] = useState(false);
  const [c103, setC103] = useState(false);
  const [c113, setC113] = useState(false);
  const [c123, setC123] = useState(false);
  const [c133, setC133] = useState(false);
  const [c143, setC143] = useState(false);
  const [c153, setC153] = useState(false);
  const [c163, setC163] = useState(false);
  const [c173, setC173] = useState(false);
  const [c183, setC183] = useState(false);
  const [c193, setC193] = useState(false);
  const [c203, setC203] = useState(false);

  useEffect(async () => {
    const body = await fetchConToken(`callcenter/detail/${+params.hours}`);
    setC8(body.userHours?.hour?.eight);
    setC9(body.userHours?.hour?.nine);
    setC10(body.userHours?.hour?.ten);
    setC11(body.userHours?.hour?.eleven);
    setC12(body.userHours?.hour?.twelve);
    setC13(body.userHours?.hour?.thirteen);
    setC14(body.userHours?.hour?.fourteen);
    setC15(body.userHours?.hour?.fifteen);
    setC16(body.userHours?.hour?.sixteen);
    setC17(body.userHours?.hour?.seventeen);
    setC18(body.userHours?.hour?.eighteen);
    setC19(body.userHours?.hour?.nineteen);
    setC20(body.userHours?.hour?.twenty);
    setC21(body.userHours?.hour?.twentyone);
    setC83(body.userHours?.hour?.eight3);
    setC93(body.userHours?.hour?.nine3);
    setC103(body.userHours?.hour?.ten3);
    setC113(body.userHours?.hour?.eleven3);
    setC123(body.userHours?.hour?.twelve3);
    setC133(body.userHours?.hour?.thirteen3);
    setC143(body.userHours?.hour?.fourteen3);
    setC153(body.userHours?.hour?.fifteen3);
    setC163(body.userHours?.hour?.sixteen3);
    setC173(body.userHours?.hour?.seventeen3);
    setC183(body.userHours?.hour?.eighteen3);
    setC193(body.userHours?.hour?.nineteen3);
    setC203(body.userHours?.hour?.twenty3);
    setSelectedHour(body.userHours);
    let totalHours = [];
    if (body.userHours?.hour?.eight) {
      totalHours.push(8);
    }
    if (body.userHours?.hour?.nine) {
      totalHours.push(9);
    }
    if (body.userHours?.hour?.ten) {
      totalHours.push(10);
    }
    if (body.userHours?.hour?.eleven) {
      totalHours.push(11);
    }
    if (body.userHours?.hour?.twelve) {
      totalHours.push(12);
    }
    if (body.userHours?.hour?.thirteen) {
      totalHours.push(13);
    }
    if (body.userHours?.hour?.fourteen) {
      totalHours.push(14);
    }
    if (body.userHours?.hour?.fifteen) {
      totalHours.push(15);
    }
    if (body.userHours?.hour?.sixteen) {
      totalHours.push(16);
    }
    if (body.userHours?.hour?.seventeen) {
      totalHours.push(17);
    }
    if (body.userHours?.hour?.eighteen) {
      totalHours.push(18);
    }
    if (body.userHours?.hour?.nineteen) {
      totalHours.push(19);
    }
    if (body.userHours?.hour?.twenty) {
      totalHours.push(20);
    }
    if (body.userHours?.hour?.twentyone) {
      totalHours.push(21);
    }
    if (body.userHours?.hour?.eight3) {
      totalHours.push(83);
    }
    if (body.userHours?.hour?.nine3) {
      totalHours.push(93);
    }
    if (body.userHours?.hour?.ten3) {
      totalHours.push(103);
    }
    if (body.userHours?.hour?.eleven3) {
      totalHours.push(113);
    }
    if (body.userHours?.hour?.twelve3) {
      totalHours.push(123);
    }
    if (body.userHours?.hour?.thirteen3) {
      totalHours.push(133);
    }
    if (body.userHours?.hour?.fourteen3) {
      totalHours.push(143);
    }
    if (body.userHours?.hour?.fifteen3) {
      totalHours.push(153);
    }
    if (body.userHours?.hour?.sixteen3) {
      totalHours.push(163);
    }
    if (body.userHours?.hour?.seventeen3) {
      totalHours.push(173);
    }
    if (body.userHours?.hour?.eighteen3) {
      totalHours.push(183);
    }
    if (body.userHours?.hour?.nineteen3) {
      totalHours.push(193);
    }
    if (body.userHours?.hour?.twenty3) {
      totalHours.push(203);
    }
    setHours(totalHours);
  }, []);



  const handleC8 = () => {
    setC8(!c8);
    if (!c8 === true) {
      setHours(prev => [...prev, 8])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 8
      })
      setHours(newPrev)
    }
  };
  const handleC9 = () => {
    setC9(!c9);
    if (!c9 === true) {
      setHours(prev => [...prev, 9])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 9
      })
      setHours(newPrev)
    }
  };
  const handleC10 = () => {
    setC10(!c10);
    if (!c10 === true) {
      setHours(prev => [...prev, 10])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 10
      })
      setHours(newPrev)
    }
  };
  const handleC11 = () => {
    setC11(!c11);
    if (!c11 === true) {
      setHours(prev => [...prev, 11])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 11
      })
      setHours(newPrev)
    }
  };
  const handleC12 = () => {
    setC12(!c12);
    if (!c12 === true) {
      setHours(prev => [...prev, 12])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 12
      })
      setHours(newPrev)
    }
  };
  const handleC13 = () => {
    setC13(!c13);
    if (!c13 === true) {
      setHours(prev => [...prev, 13])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 13
      })
      setHours(newPrev)
    }
  };
  const handleC14 = () => {
    setC14(!c14);
    if (!c14 === true) {
      setHours(prev => [...prev, 14])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 14
      })
      setHours(newPrev)
    }
  };
  const handleC15 = () => {
    setC15(!c15);
    if (!c15 === true) {
      setHours(prev => [...prev, 15])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 15
      })
      setHours(newPrev)
    }
  };
  const handleC16 = () => {
    setC16(!c16);
    if (!c16 === true) {
      setHours(prev => [...prev, 16])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 16
      })
      setHours(newPrev)
    }
  };
  const handleC17 = () => {
    setC17(!c17);
    if (!c17 === true) {
      setHours(prev => [...prev, 17])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 17
      })
      setHours(newPrev)
    }
  };
  const handleC18 = () => {
    setC18(!c18);
    if (!c18 === true) {
      setHours(prev => [...prev, 18])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 18
      })
      setHours(newPrev)
    }
  };
  const handleC19 = () => {
    setC19(!c19);
    if (!c19 === true) {
      setHours(prev => [...prev, 19])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 19
      })
      setHours(newPrev)
    }
  };
  const handleC20 = () => {
    setC20(!c20);
    if (!c20 === true) {
      setHours(prev => [...prev, 20])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 20
      })
      setHours(newPrev)
    }
  };
  const handleC21 = () => {
    setC21(!c21);
    if (!c21 === true) {
      setHours(prev => [...prev, 21])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 21
      })
      setHours(newPrev)
    }
  };
  const handleC83 = () => {
    setC83(!c83);
    if (!c83 === true) {
      setHours(prev => [...prev, 83])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 83
      })
      setHours(newPrev)
    }
  };
  const handleC93 = () => {
    setC93(!c93);
    if (!c93 === true) {
      setHours(prev => [...prev, 93])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 93
      })
      setHours(newPrev)
    }
  };
  const handleC103 = () => {
    setC103(!c103);
    if (!c103 === true) {
      setHours(prev => [...prev, 103])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 103
      })
      setHours(newPrev)
    }
  };
  const handleC113 = () => {
    setC113(!c113);
    if (!c113 === true) {
      setHours(prev => [...prev, 113])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 113
      })
      setHours(newPrev)
    }
  };
  const handleC123 = () => {
    setC123(!c123);
    if (!c123 === true) {
      setHours(prev => [...prev, 123])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 123
      })
      setHours(newPrev)
    }
  };
  const handleC133 = () => {
    setC133(!c133);
    if (!c133 === true) {
      setHours(prev => [...prev, 133])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 133
      })
      setHours(newPrev)
    }
  };
  const handleC143 = () => {
    setC143(!c143);
    if (!c143 === true) {
      setHours(prev => [...prev, 143])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 143
      })
      setHours(newPrev)
    }
  };
  const handleC153 = () => {
    setC153(!c153);
    if (!c153 === true) {
      setHours(prev => [...prev, 153])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 153
      })
      setHours(newPrev)
    }
  };
  const handleC163 = () => {
    setC163(!c163);
    if (!c163 === true) {
      setHours(prev => [...prev, 163])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 163
      })
      setHours(newPrev)
    }
  };
  const handleC173 = () => {
    setC173(!c173);
    if (!c173 === true) {
      setHours(prev => [...prev, 173])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 173
      })
      setHours(newPrev)
    }
  };
  const handleC183 = () => {
    setC183(!c183);
    if (!c183 === true) {
      setHours(prev => [...prev, 183])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 183
      })
      setHours(newPrev)
    }
  };
  const handleC193 = () => {
    setC193(!c193);
    if (!c193 === true) {
      setHours(prev => [...prev, 193])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 193
      })
      setHours(newPrev)
    }
  };
  const handleC203 = () => {
    setC203(!c203);
    if (!c203 === true) {
      setHours(prev => [...prev, 203])
    } else {
      const oldPrev = [...hours];
      const newPrev = oldPrev.filter((v) => {
        return v !== 203
      })
      setHours(newPrev)
    }
  };

  const handleAdd = (e) => {
    e.preventDefault();

    const startAdd = async (hours) => {
      const body = await fetchConToken(
        `callcenter/edit/${+params.hours}`,
        { hours },
        "PUT"
      );
      if (body.ok) {
        Swal.fire(t('success.title'), 'Hours edited', "success");
      } else {
        Swal.fire("Error", body.msg, "error");
      }
    };

    if (hours.length >= 1) {
      startAdd(hours);
    } else {
      Swal.fire("Error", 'You must select at least 1 hour', "error");
    }
  };
  const handleReturn = () => {
    navigate(isAdmin ? '/callcenter' : '/');
  };

  const formatDateTz = (date) => {
    const dt = new Date(date);
    const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
    return format(dtDateOnly, 'eee dd-MM-yy');
  };
  return (
    <>
      {
        selectedHour &&
        <>
          {
            isMobile
              ?
              <div className='d-flex flex-column justify-content-center align-items-center' data-aos="fade-up" data-aos-duration="1000">
                <span className='text-primary text-center text-uppercase fs-1'>Edit hours</span>
                <div className="col-md-6 rounded-3 border border-light p-3 m-3">
                  <form onSubmit={handleAdd}>
                    <div>
                      <div className="d-flex flex-column justify-content-center align-items-center text-center">
                        <span className='text-primary fw-bold'>{formatDateTz(selectedHour.date)}</span>
                      </div>
                      <div className='row m-2 text-center'>
                        <label className='text-primary fw-bold'>Select the hours</label>
                        <div className='col-6 d-flex flex-column justify-content-center align-items-center'>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c8'
                              checked={c8}
                              value={c8}
                              onChange={handleC8}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c8'>8:00</label>
                          </div>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c9'
                              checked={c9}
                              value={c9}
                              onChange={handleC9}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c9'>9:00</label>
                          </div>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c10'
                              checked={c10}
                              value={c10}
                              onChange={handleC10}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c10'>10:00</label>
                          </div>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c11'
                              checked={c11}
                              value={c11}
                              onChange={handleC11}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c11'>11:00</label>
                          </div>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c12'
                              checked={c12}
                              value={c12}
                              onChange={handleC12}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c12'>12:00</label>
                          </div>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c13'
                              checked={c13}
                              value={c13}
                              onChange={handleC13}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c13'>13:00</label>
                          </div>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c14'
                              checked={c14}
                              value={c14}
                              onChange={handleC14}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c14'>14:00</label>
                          </div>
                        </div>
                        <div className='col-6 d-flex flex-column justify-content-center align-items-center'>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c15'
                              checked={c15}
                              value={c15}
                              onChange={handleC15}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c15'>15:00</label>
                          </div>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c16'
                              checked={c16}
                              value={c16}
                              onChange={handleC16}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c16'>16:00</label>
                          </div>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c17'
                              checked={c17}
                              value={c17}
                              onChange={handleC17}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c17'>17:00</label>
                          </div>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c18'
                              checked={c18}
                              value={c18}
                              onChange={handleC18}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c18'>18:00</label>
                          </div>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c19'
                              checked={c19}
                              value={c19}
                              onChange={handleC19}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c19'>19:00</label>
                          </div>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c20'
                              checked={c20}
                              value={c20}
                              onChange={handleC20}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c20'>20:00</label>
                          </div>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c21'
                              checked={c21}
                              value={c21}
                              onChange={handleC21}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c21'>21:00</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group d-flex justify-content-center mb-1 mt-4">
                      <button type="submit" className="btn btn-success btn-bright">
                        <i className="fa fa-floppy-disk"></i> {t('buttons.save')}
                      </button>
                    </div>
                  </form>
                  <div className="form-group d-flex flex-row justify-content-center">
                    <button className="btn btn-light mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleReturn}>
                      <i className="fa fa-arrow-rotate-left me-1"></i> {t('buttons.return')}
                    </button>
                  </div>
                </div>
              </div>
              :
              <div className='d-flex flex-column justify-content-center align-items-center' data-aos="fade-up" data-aos-duration="1000">
                <span className='text-primary text-center text-uppercase fs-1'>Edit hours</span>
                <div className="col-md-6 rounded-3 border border-light p-3">
                  <form onSubmit={handleAdd}>
                    <div>
                      <div className="d-flex flex-column justify-content-center align-items-center text-center">
                        <span className='text-primary text-bold'>{formatDateTz(selectedHour.date)}</span>
                      </div>
                      <div className="d-flex flex-column justify-content-center align-items-center text-center">
                        <span className='text-primary text-bold'>Total hours: {hours.length / 2}</span>
                      </div>
                      {/* <div className='row m-2 text-center'>
                        <label className='text-primary fw-bold'>Select the hours</label>
                        <div className='col-6 d-flex flex-column justify-content-center align-items-center'>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c8'
                              checked={c8}
                              value={c8}
                              onChange={handleC8}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c8'>8:00</label>
                          </div>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c9'
                              checked={c9}
                              value={c9}
                              onChange={handleC9}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c9'>9:00</label>
                          </div>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c10'
                              checked={c10}
                              value={c10}
                              onChange={handleC10}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c10'>10:00</label>
                          </div>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c11'
                              checked={c11}
                              value={c11}
                              onChange={handleC11}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c11'>11:00</label>
                          </div>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c12'
                              checked={c12}
                              value={c12}
                              onChange={handleC12}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c12'>12:00</label>
                          </div>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c13'
                              checked={c13}
                              value={c13}
                              onChange={handleC13}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c13'>13:00</label>
                          </div>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c14'
                              checked={c14}
                              value={c14}
                              onChange={handleC14}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c14'>14:00</label>
                          </div>
                        </div>
                        <div className='col-6 d-flex flex-column justify-content-center align-items-center'>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c15'
                              checked={c15}
                              value={c15}
                              onChange={handleC15}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c15'>15:00</label>
                          </div>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c16'
                              checked={c16}
                              value={c16}
                              onChange={handleC16}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c16'>16:00</label>
                          </div>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c17'
                              checked={c17}
                              value={c17}
                              onChange={handleC17}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c17'>17:00</label>
                          </div>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c18'
                              checked={c18}
                              value={c18}
                              onChange={handleC18}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c18'>18:00</label>
                          </div>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c19'
                              checked={c19}
                              value={c19}
                              onChange={handleC19}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c19'>19:00</label>
                          </div>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c20'
                              checked={c20}
                              value={c20}
                              onChange={handleC20}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c20'>20:00</label>
                          </div>
                          <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                            <input
                              type="checkbox"
                              className="me-1 form-check-input p-3"
                              id='c21'
                              checked={c21}
                              value={c21}
                              onChange={handleC21}
                            />
                            <label className='text-light form-check-label font-special-total-deals-supersmall' htmlFor='c21'>21:00</label>
                          </div>
                        </div>
                      </div> */}
                      <div className='d-flex justify-content-center align-items-center'>
                        <div className='row m-2 text-center w-50 d-flex justify-content-center align-items-center'>
                          <label className='text-primary fw-bold'>Select the hours</label>
                          <div className='col-6 d-flex flex-column justify-content-start align-items-start'>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c8'
                                checked={c8}
                                value={c8}
                                onChange={handleC8}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c8'>8:00</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c83'
                                checked={c83}
                                value={c83}
                                onChange={handleC83}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c83'>8:30</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c9'
                                checked={c9}
                                value={c9}
                                onChange={handleC9}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c9'>9:00</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c93'
                                checked={c93}
                                value={c93}
                                onChange={handleC93}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c93'>9:30</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c10'
                                checked={c10}
                                value={c10}
                                onChange={handleC10}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c10'>10:00</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c103'
                                checked={c103}
                                value={c103}
                                onChange={handleC103}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c103'>10:30</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c11'
                                checked={c11}
                                value={c11}
                                onChange={handleC11}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c11'>11:00</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c113'
                                checked={c113}
                                value={c113}
                                onChange={handleC113}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c113'>11:30</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c12'
                                checked={c12}
                                value={c12}
                                onChange={handleC12}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c12'>12:00</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c123'
                                checked={c123}
                                value={c123}
                                onChange={handleC123}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c123'>12:30</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c13'
                                checked={c13}
                                value={c13}
                                onChange={handleC13}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c13'>13:00</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c133'
                                checked={c133}
                                value={c133}
                                onChange={handleC133}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c133'>13:30</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c14'
                                checked={c14}
                                value={c14}
                                onChange={handleC14}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c14'>14:00</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c143'
                                checked={c143}
                                value={c143}
                                onChange={handleC143}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c143'>14:30</label>
                            </div>
                          </div>
                          <div className='col-6 d-flex flex-column justify-content-start align-items-start'>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c15'
                                checked={c15}
                                value={c15}
                                onChange={handleC15}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c15'>15:00</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c153'
                                checked={c153}
                                value={c153}
                                onChange={handleC153}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c153'>15:30</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c16'
                                checked={c16}
                                value={c16}
                                onChange={handleC16}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c16'>16:00</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c163'
                                checked={c163}
                                value={c163}
                                onChange={handleC163}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c163'>16:30</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c17'
                                checked={c17}
                                value={c17}
                                onChange={handleC17}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c17'>17:00</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c173'
                                checked={c173}
                                value={c173}
                                onChange={handleC173}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c173'>17:30</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c18'
                                checked={c18}
                                value={c18}
                                onChange={handleC18}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c18'>18:00</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c183'
                                checked={c183}
                                value={c183}
                                onChange={handleC183}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c183'>18:30</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c19'
                                checked={c19}
                                value={c19}
                                onChange={handleC19}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c19'>19:00</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c193'
                                checked={c193}
                                value={c193}
                                onChange={handleC193}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c193'>19:30</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c20'
                                checked={c20}
                                value={c20}
                                onChange={handleC20}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c20'>20:00</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c203'
                                checked={c203}
                                value={c203}
                                onChange={handleC203}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c203'>20:30</label>
                            </div>
                            <div className='m-1 form-check form-switch-custom d-flex justify-content-center align-items-center'>
                              <input
                                type="checkbox"
                                className="me-1 form-check-input p-3"
                                id='c21'
                                checked={c21}
                                value={c21}
                                onChange={handleC21}
                              />
                              <label className='text-primary form-check-label font-special-total-deals-supersmall' htmlFor='c21'>21:00</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group d-flex justify-content-center mb-1 mt-4">
                      <button type="submit" className="btn btn-success btn-bright">
                        <i className="fa fa-floppy-disk"></i> {t('buttons.save')}
                      </button>
                    </div>
                  </form>
                  <div className="form-group d-flex flex-row justify-content-center">
                    <button className="btn btn-light mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleReturn}>
                      <i className="fa fa-arrow-rotate-left me-1"></i> {t('buttons.return')}
                    </button>
                  </div>
                </div>
              </div>
          }
        </>
      }
    </>
  );
};
