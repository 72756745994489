import { useState } from 'react';
import { fetchConToken } from '../../helpers/fetch';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Axios from "axios";
import { format } from 'date-fns';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import './uploadexcel.css';
import { useTranslation } from 'react-i18next';
import colourStyles from '../../helpers/selectStyles';
import Select from 'react-select';
import useSWR from "swr";
import { useForm } from '../../hooks/useForm';

export const AddManualUserData = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formUserValues, handleUserInputChange, reset] = useForm({
    sDeals: ''
  });
  const { sDeals } = formUserValues;
  const timeFrames = [
    { value: 'daily', label: `${t('cards.time_frames.daily')}` },
    { value: 'weekly', label: `${t('cards.time_frames.weekly')}` },
    { value: 'monthly', label: `${t('cards.time_frames.monthly')}` },
    { value: 'semiannual', label: `${t('cards.time_frames.semiannual')}` },
    { value: 'yearly', label: `${t('cards.time_frames.yearly')}` },
    { value: 'timeless', label: `${t('cards.time_frames.timeless')}` },
  ];
  const [timeFrame, setTimeFrame] = useState({ value: 'daily', label: `${t('cards.time_frames.daily')}` });
  const handleTimeFrame = (e) => {
    setTimeFrame(e);
  };

  const { data: usersApi } = useSWR("auth/users");
  const users = [];
  if (usersApi?.users) {
    const usersLen = usersApi?.users.length
    for (let i = 0; i < usersLen; i++) {
      const user = {
        value: usersApi?.users[i].id,
        label: usersApi?.users[i].name + ' ' + usersApi?.users[i].last_name
      }
      users.push(user);
    }
  }
  const [user, setUser] = useState(null);
  const handleUser = (e) => {
    setUser(e);
  };

  const [date, setDate] = useState(format(new Date(), 'MM-dd-yyyy HH:mm'));
  const handleDateChange = (e) => {
    setDate(e);
  };

  const handleReturn = (e) => {
    navigate('/');
  };
  const handleUploadAndSave = () => {
    const uploadUser = async (userId, deals) => {
      const body = await fetchConToken(`userdata/addusersmanualdata/${timeFrame.value}`, { date, userId, deals }, 'POST');
      if (body.ok) {
        Swal.close();
        Swal.fire(t('success.title'), body.msg, "success");
      } else {
        Swal.fire("Error", body.msg, "error");
      }
    }
    uploadUser(user.value, sDeals);
  }
  return (
    <div className='container d-flex flex-column justify-content-center align-items-center'>
      <h1 className='text-light text-center'>Add users data deals</h1>
      <div className="col-md-6 login-form-2 m-2">
        <div className="form-group mb-3">
          <label className='text-light'>{t('labels.time_frame')}</label>
          <div className="w-100">
            <Select placeholder={t('select.placeholder')} styles={colourStyles} options={timeFrames} value={timeFrame} onChange={handleTimeFrame} />
          </div>
        </div>

        {(timeFrame.value === 'daily' || timeFrame.value === 'weekly' || timeFrame.value === 'monthly') && <div className="form-group">
          <label className='text-light'>{t('labels.date')}</label>
          <DateTimePicker
            onChange={handleDateChange}
            value={date}
            className="form-control mb-2"
            format='MM-dd-yyyy HH:mm'
          />
        </div>}

        <div className="form-group mb-3">
          <label className='text-light'>User</label>
          <div className="w-100">
            <Select placeholder={t('select.placeholder')} styles={colourStyles} options={users} value={user} onChange={handleUser} />
          </div>
        </div>

        <div className="form-group">
          <label className='text-light'>Deals</label>
          <input
            type="number"
            className="form-control mb-2"
            placeholder='Deals'
            name="sDeals"
            value={sDeals}
            onChange={handleUserInputChange}
          />
        </div>
        <div className='container d-flex flex-column justify-content-center align-items-center'>
          <button className='btn btn-success btn-bright' onClick={handleUploadAndSave}>{t('buttons.upload')}</button>
        </div>
      </div>
      <div className='container d-flex flex-column justify-content-center align-items-center m-2'>
        <button className="btn btn-dark btn-bright" onClick={handleReturn}>{t('buttons.return')}</button>
      </div>
    </div>);
};