import { isMobile } from 'react-device-detect';
import Swal from 'sweetalert2';
import { fetchConToken } from '../../../helpers/fetch';
import { format } from 'date-fns';
import { LoadingSpinnerBlue } from '../../ui/LoadingSpinnerBlue';
import { AddNotesModal } from './AddNotesModal';
import { useDispatch } from 'react-redux';
import { setHotLeadId, setNotes, uiOpenModalAddNotes } from '../../../actions/ui';
import { useState } from 'react';


export const HotLeadsItems = ({ leads, isLoading, setLeads }) => {
  const dispatch = useDispatch();
  const [sortOrder, setSortOrder] = useState('DESC');
  const [sortName, setSortName] = useState('date');
  if (isLoading) {
    return <LoadingSpinnerBlue disableCenter={true} />
  }
  const updateType = async (id, type_response, id_calltools) => {
    const update = async () => {
      const body = await fetchConToken(`calltools/updatehotlead`, { id, type_response, id_calltools }, 'PUT');
      if (body.ok) {
        Swal.fire('Success', "Type changed", "success");
        setLeads(body.leads);
      } else {
        Swal.fire("Error", body.msg, "error");
      }
    }
    update();
  };
  const seeNotes = async (id, notes) => {

    dispatch(setHotLeadId(id));
    const getNotes = async () => {
      const body = await fetchConToken(`calltools/hotleads/notes/${id}`);
      dispatch(setNotes(body.data));
      dispatch(uiOpenModalAddNotes());
    }
    getNotes();
  };

  const setColor = async (id) => {
    const element = document.getElementById('fu' + id);
    if (!element.classList.contains('bg-dark')) {
      element.classList.add('bg-dark')
      for (let i = 0; i < element.children.length; i++) {
        element.children[i].classList.add('text-light')
        element.children[i].classList.remove('text-dark')
      }
    } else {
      element.classList.remove('bg-dark')
      for (let i = 0; i < element.children.length; i++) {
        element.children[i].classList.remove('text-light')
        element.children[i].classList.add('text-dark')
      }
    }
  };



  const sortDate = async (sortN) => {
    setSortName(sortN);
    let usersSorted = [];
    if (sortOrder === 'ASC') {
      setSortOrder('DESC');
      usersSorted = leads.sort((a, b) => {
        if (a.date < b.date) {
          return -1;
        }
        if (a.date > b.date) {
          return 1;
        }
        return 0;
      })
    } else {
      setSortOrder('ASC');
      usersSorted = leads.sort((a, b) => {
        if (b.date < a.date) {
          return -1;
        }
        if (b.date > a.date) {
          return 1;
        }
        return 0;
      })
    }
    setLeads(usersSorted);
  };

  const openZillow = (address) => {
    const formattedAddress = encodeURIComponent(address);
    window.open(`https://www.zillow.com/homes/${formattedAddress}_rb/`, '_blank');
  };

  const openGoogleMaps = (address) => {
    const formattedAddress = encodeURIComponent(address);
    window.open(`https://www.google.com/maps/search/?api=1&query=${formattedAddress}`, '_blank');
  };
  return (
    <div className='container-fluid table-responsive mb-2'>
      <>
        {leads && <table className={isMobile ? "d-block overflow-scroll table table-sm table-bordered bg-light table-striped rounded rounded-3 overflow-hidden" : "table table-striped bg-light table-hover overflow-hidden"}>
          <thead className='bg-dark text-light align-middle'>
            <tr>
              <th role='button' onClick={() => { sortDate('date') }} className="text-center" scope="col">
                {sortName === 'date' && <i className={sortOrder === 'DESC' ? 'fa fa-arrow-up-wide-short' : 'fa fa-arrow-down-short-wide'}></i>} Date
              </th>
              <th className="text-center" scope="col">Name</th>
              <th className="text-center" scope="col">Phone number</th>
              <th className="text-center" scope="col">Address</th>
              {/* <th className="text-center" scope="col">Utility provider</th> */}
              {/* <th className="text-center" scope="col">Utility bill</th> */}
              {/* <th className="text-center" scope="col">Credit score</th> */}
              {/* <th className="text-center" scope="col">Call hour</th> */}
              <th className="text-center" scope="col">Type</th>
              <th className="text-center" scope="col">Source</th>
              <th className="text-center" scope="col">Type response</th>
              <th className="text-center" scope="col">Notes</th>
              <th className="text-center" scope="col" colSpan={7}>Set type</th>
            </tr>
          </thead>

          {leads.length > 0 && leads.map(lead => {
            return (
              <tbody key={lead.id}>
                <tr data-bs-toggle="collapse" onClick={() => { setColor(lead.id) }} id={'fu' + lead.id} href={'#' + 'u' + lead.id} role="button" aria-expanded="false" aria-controls={'u' + lead.id}>
                  <td className="text-center text-dark text-nowrap">{lead.date ? format(new Date(lead.date), 'dd-MM-yyyy') : '-'}</td>
                  <td className="text-center text-dark text-uppercase">{lead.name}</td>
                  <td className="text-center text-dark">{lead.phone_number}</td>
                  <td className="text-center text-dark">{lead.address}</td>

                  <td className="text-center text-dark">{lead.type}</td>
                  <td className="text-center text-dark">{lead.source}</td>
                  <td className="text-center text-dark">{lead.type_response}</td>
                  <td className="text-center text-dark"><button className={lead.notes !== '-' && lead.notes !== '' && lead.notes !== ' ' ? 'btn btn-primary' : 'btn btn-outline-primary'} onClick={() => seeNotes(lead.id, lead.notes)}><i className='fa fa-note-sticky'></i></button></td>
                  <td className="text-center text-dark"><button className='btn btn-success' onClick={() => updateType(lead.id, "App", lead.id_calltools)}>App</button></td>
                  <td className="text-center text-dark"><button className='btn btn-warning' onClick={() => updateType(lead.id, "Ans", lead.id_calltools)}>Ans</button></td>
                  <td className="text-center text-dark"><button className='btn btn-danger' onClick={() => updateType(lead.id, "No Ans", lead.id_calltools)}>No Ans</button></td>
                  <td className="text-center text-dark"><button className='btn btn-dark' onClick={() => updateType(lead.id, "Rent", lead.id_calltools)}>Rent</button></td>
                  <td className="text-center text-dark"><button className='btn btn-light' onClick={() => updateType(lead.id, "LC", lead.id_calltools)}>LC</button></td>
                  <td className="text-center text-dark"><button className='btn btn-dark' onClick={() => updateType(lead.id, "Disq", lead.id_calltools)}>Disq</button></td>
                  <td className="text-center text-dark"><button className='btn btn-light' onClick={() => updateType(lead.id, "Msj", lead.id_calltools)}>Msj</button></td>
                </tr>
                <tr>
                  <td colSpan="20" className="p-0 bg-dark">
                    <div className="collapse" id={'u' + lead.id}>
                      <table className="table table-striped">
                        <thead className="bg-default text-light">
                          <tr className='align-middle'>
                            <th className="text-center small text-light">Utility Provider</th>
                            <th className="text-center small text-light">Utility Bill</th>
                            <th className="text-center small text-light">Credit Score</th>
                            <th className="text-center small text-light">Hour</th>
                            <th className="text-center small text-light">Campaign</th>
                            <th className="text-center small text-light">Posthog OS</th>
                            <th className="text-center small text-light">Posthog Location</th>
                            <th className="text-center small text-light">Address Location</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className='align-middle bg-light'>
                            <td className="text-center text-dark">{lead.utility_provider ? lead.utility_provider : '-'}</td>
                            <td className="text-center text-dark">{lead.utility_bill ? lead.utility_bill : '-'}</td>
                            <td className="text-center text-dark">{lead.credit_score ? lead.credit_score : '-'}</td>
                            <td className="text-center text-dark">{lead.hour ? lead.hour : '-'}</td>
                            <td className="text-center text-dark">{lead.utm_campaign ? lead.utm_campaign : '-'}</td>
                            <td className="text-center text-dark">{lead.os ? lead.os : '-'}</td>
                            <td className="text-center text-dark">{lead.longitude && lead.latitude ? (
                              <a
                                href={`https://www.google.com/maps?q=${lead.latitude},${lead.longitude}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-dark"
                              >
                                <button className="btn btn-primary"><i className='fa fa-location-dot'></i></button>
                              </a>
                            ) : (
                              '-'
                            )}</td>
                            <td className="text-center text-dark">
                              {
                                lead.address
                                  ?
                                  <div>
                                    <button
                                      className="btn btn-primary p-0 mx-2 px-1"
                                      onClick={(e) => {
                                        e.stopPropagation(); // Evita que se expanda la fila
                                        openZillow(lead.address);
                                      }}
                                      title="Zillow"
                                    >
                                      <i className="mx-1 fa fa-home"></i>
                                      Zillow
                                    </button>
                                    <button
                                      className="ms-2 btn btn-primary p-0 mx-2 px-1"
                                      onClick={(e) => {
                                        e.stopPropagation(); // Evita que se expanda la fila
                                        openGoogleMaps(lead.address);
                                      }}
                                      title="Google"
                                    >
                                      <i className="mx-1 fa-brands fa-google"></i>
                                      Maps
                                    </button>
                                  </div>
                                  :
                                  '-'
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
        }
      </>
      <AddNotesModal />
    </div>);
};
