export const types = {
  uiOpenModal: "[UI] Open Modal",
  uiCloseModal: "[UI] Close Modal",
  uiOpenModall: "[UI] Open Modall",
  uiCloseModall: "[UI] Close Modall",
  uiOpenModalll: "[UI] Open Modalll",
  uiCloseModalll: "[UI] Close Modalll",
  uiOpenModallll: "[UI] Open Modallll",
  uiCloseModallll: "[UI] Close Modallll",
  uiOpenModalllll: "[UI] Open Modalllll",
  uiCloseModalllll: "[UI] Close Modalllll",
  uiOpenModallllll: "[UI] Open Modallllll",
  uiCloseModallllll: "[UI] Close Modallllll",
  uiOpenModalBirth: "[UI] Open Modal birth",
  uiCloseModalBirth: "[UI] Close Modal birth",
  uiOpenModalBest: "[UI] Open Modal best",
  uiCloseModalBest: "[UI] Close Modal best",
  uiOpenModalTraining: "[UI] Open Modal training",
  uiCloseModalTraining: "[UI] Close Modal training",
  uiOpenModalResetPassword: "[UI] Open Modal reset password",
  uiCloseModalResetPassword: "[UI] Close Modal reset password",
  uiOpenModalAddDeal: "[UI] Open Modal add deal",
  uiCloseModalAddDeal: "[UI] Close Modal add deal",
  uiOpenModalAddNotes: "[UI] Open Modal add notes",
  uiCloseModalAddNotes: "[UI] Close Modal add notes",
  uiOpenModalEditDeal: "[UI] Open Modal edit deal",
  uiCloseModalEditDeal: "[UI] Close Modal edit deal",
  uiOpenModalDeleteDeal: "[UI] Open Modal delete deal",
  uiCloseModalDeleteDeal: "[UI] Close Modal delete deal",
  uiOpenModalAddSitDown: "[UI] Open Modal add sit down",
  uiCloseModalAddSitDown: "[UI] Close Modal add sit down",
  uiOpenModalPost: "[UI] Open Modal Post",
  uiCloseModalPost: "[UI] Close Modal Post",
  uiOpenModalMotivational: "[UI] Open Modal Motivational",
  uiCloseModalMotivational: "[UI] Close Modal Motivational",
  uiOpenModalVisionBoard: "[UI] Open Modal VisionBoard",
  uiCloseModalVisionBoard: "[UI] Close Modal VisionBoard",
  setCalendarDate: "[UI] Set calendar date",
  disableFixedBottom: "[UI] Disable fixed bottom",
  setSelectedCard: "[Card] Set selected card",
  clearSelectedCard: "[Card] Clear selected card",
  setSelectedFight: "[Fight] Set selected fight",
  clearSelectedFight: "[Fight] Clear selected fight",
  setUserDeal: "[Fight] Set user deal",
  clearUserDeal: "[Fight] Clear user deal",
  setPost: "[Post] Set post",
  clearPost: "[Post] Clear post",
  setNotes: "[Post] Set notes",
  setHotLeadId: "[Post] Set hot lead id",

  authChecking: "[Auth] Checking login state",
  authCheckingFinish: "[Auth] Finish checking login state",
  authStartLogin: "[Auth] Start login",
  authLogin: "[Auth] Login",
  authStartRegister: "[Auth] Start register",
  authStartTokenRenew: "[Auth] Start token renew",
  authLogout: "[Auth] Logout",

  userAsociar: "[Usuario] Listo para asociar",
  startClearUserAsoc: "[Usuario] Limpiando usuario",

  setLoading: "[UI] Recargando",
  setClearLoading: "[UI] Limpiando recarga",

  userSelected: "[User] Set user",

  sitDownSelected: "[Sit Down] Set sit down detail",

  setCloser: "[Closer] Set closer",
  setCanvasser: "[Canvasser] Set canvasser",

  showStory: "[UI] Show Story"
};
