import { useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import Select from 'react-select';
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

import { useForm } from "../../hooks/useForm";
import colourStyles from '../../helpers/selectStyles';
import Axios from "axios";
import { fetchSinToken } from "../../helpers/fetch";
import { format, isValid } from "date-fns";
import toPascalCase from "../../helpers/pascalCase";
import { useNavigate } from "react-router-dom";

export const AddUsersManual = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const handleFile = ({ target }) => {
    setFile(target.files[0]);
  };

  const datee = format(new Date(), 'MM-dd-yyyy HH:mm');

  useEffect(() => {
    if (isValid(datee)) {
      setBirthDate(datee)
    }
  }, []);

  const [formRegisterValues, handleRegisterInputChange, reset] = useForm({
    rName: '',
    rLastname: '',
    rEmail: ''
  });

  const { rEmail, rName, rLastname } = formRegisterValues;

  const [role, setRole] = useState(null);
  const roles = [
    { value: 'Closer', label: 'Closer' },
    { value: 'Canvasser', label: 'Canvasser' },
    { value: 'CallCenter', label: 'CallCenter' },
  ];
  const handleRole = (e) => {
    setRole(e);
  };

  const [office, setOffice] = useState(null);
  const offices = [
    { value: 'Boca Raton', label: 'Boca Raton' },
    { value: 'Bradenton', label: 'Bradenton' },
    { value: 'Cape Coral', label: 'Cape Coral' },
    { value: 'Jacksonville', label: 'Jacksonville' },
    { value: 'Argentina', label: 'Argentina' },
  ];

  const [birthDate, setBirthDate] = useState("");
  const handleBirthDateChange = (e) => {
    setBirthDate(e);
  };

  const [startDate, setStartDate] = useState("");
  const handleStartDateChange = (e) => {
    setStartDate(e);
  };

  const handleRegister = (e) => {
    e.preventDefault();
    if (rName === '' || rLastname === '' || rEmail === '') {
      return Swal.fire(
        "Error",
        t('errors.fields_required'),
        "error"
      );
    }

    const fileUploadCloud = async () => {
      Swal.fire({
        title: 'Wait ...',
        onBeforeOpen() {
          Swal.showLoading()
        },
        onAfterClose() {
          Swal.hideLoading()
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false
      })
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "cardsapp");
      Axios.post("https://api.cloudinary.com/v1_1/drholseqp/upload", formData
      ).then((res) => {
        const startRegister = async (emaill, namee, lastnamee, office, photo, birth_date, role, date_started) => {
          const email = emaill.toLowerCase().trim();
          const last_name = toPascalCase(lastnamee.trim());
          const name = toPascalCase(namee.trim());
          const password = "123456";
          const body = await fetchSinToken(
            "auth/new",
            { email, password, name, last_name, office, photo, birth_date, role, date_started },
            "POST"
          );
          if (body.ok) {
            Swal.fire("Success", "User created", "success");
            navigate('/userlist')
          } else {
            Swal.fire("Error", body.msg, "error");
          }
        }
        startRegister(rEmail, rName, rLastname, office.value, res.data.secure_url, birthDate, role.value, startDate);
      });
    }
    if (file !== null && office !== null && role !== null) {
      fileUploadCloud();
    } else {
      Swal.fire("Error", t('errors.fields_required'), "error");
    }
  };
  const handleOffice = (e) => {
    setOffice(e);
  };
  const handleReturn = () => {
    navigate('/userlist');
  };
  return (
    <>
      {isMobile ?
        <div className="container block-scroll">
          <div className="row">
            <div className="col-md-11 col-11 login-form-1 m-3">
              <h3>{t('auth.register.title')}</h3>
              <form className="text-center" onSubmit={handleRegister}>
                <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                  <div className="m-2 w-10">
                    <i className="fa-solid fa-envelope fa-lg"></i>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t('labels.email')}
                    name="rEmail"
                    autoComplete="off"
                    value={rEmail}
                    onChange={handleRegisterInputChange}
                  />
                </div>
                <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                  <div className="m-2 w-10">
                    <i className="fa-solid fa-user fa-lg"></i>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t('labels.first_name')}
                    name="rName"
                    value={rName}
                    onChange={handleRegisterInputChange}
                  />
                </div>
                <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                  <div className="m-2 w-10">
                    <i className="fa-solid fa-id-card fa-lg"></i>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t('labels.last_name')}
                    name="rLastname"
                    value={rLastname}
                    onChange={handleRegisterInputChange}
                  />
                </div>
                <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                  <div className="m-2 w-10">
                    <i className="fa-solid fa-building fa-lg"></i>
                  </div>
                  <div className="w-100">
                    <Select placeholder={t('select.placeholder')} menuPlacement="auto" menuPosition="fixed" styles={colourStyles} options={offices} value={office} onChange={handleOffice} />
                  </div>
                </div>
                <div className="form-group d-flex flex-row justify-content-center align-items-center">
                  <div className="m-2 w-10">
                    <i className="fa-solid fa-users-gear fa-lg"></i>
                  </div>
                  <div className="w-100">
                    <Select placeholder={t('select.placeholder')} menuPlacement="auto" menuPosition="fixed" styles={colourStyles} options={roles} value={role} onChange={handleRole} />
                  </div>
                </div>
                <div className="form-group text-center mt-2">
                  <label className='text-dark'>Birth date</label>
                  <DateTimePicker
                    onChange={handleBirthDateChange}
                    value={birthDate}
                    className="form-control"
                    format='MM-dd-yyyy HH:mm'
                  />
                </div>
                <div className="form-group">
                  <label className='text-dark'>Start date</label>
                  <DateTimePicker
                    onChange={handleStartDateChange}
                    value={startDate}
                    className="form-control"
                    format='MM-dd-yyyy HH:mm'
                  />
                </div>
                <div className="form-group files m-2">
                  <input id="excelfile" className='bg-light' type="file" onChange={(e) => handleFile(e)} />
                </div>
                <div className="form-group d-flex justify-content-center">
                  <input type="submit" className="btn btn-primary btn-bright" value={t('buttons.register')} />
                </div>
              </form>
            </div>
          </div >
        </div >
        :
        <div className="login-container ">
          <div className="row mt-2">
            <div className="col-md-6 col-6 me-5 login-form-3 bg-light">
              <h3>{t('auth.register.title')}</h3>
              <form className="p-1" onSubmit={handleRegister}>
                <div>
                  <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                    <div className="m-2 w-5">
                      <i className="fa-solid fa-envelope fa-lg"></i>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t('labels.email')}
                      name="rEmail"
                      autoComplete="off"
                      value={rEmail}
                      onChange={handleRegisterInputChange}
                    />
                  </div>
                  <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                    <div className="m-2 w-5">
                      <i className="fa-solid fa-user fa-lg"></i>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t('labels.first_name')}
                      name="rName"
                      value={rName}
                      onChange={handleRegisterInputChange}
                    />
                  </div>
                  <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                    <div className="m-2 w-5">
                      <i className="fa-solid fa-id-card fa-lg"></i>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t('labels.last_name')}
                      name="rLastname"
                      value={rLastname}
                      onChange={handleRegisterInputChange}
                    />
                  </div>
                  <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                    <div className="m-2 w-5">
                      <i className="fa-solid fa-building fa-lg"></i>
                    </div>
                    <div className="w-100">
                      <Select placeholder={t('select.placeholder')} menuPlacement="auto" menuPosition="fixed" styles={colourStyles} options={offices} value={office} onChange={handleOffice} />
                    </div>
                  </div>
                  <div className="form-group d-flex flex-row justify-content-center align-items-center">
                    <div className="m-2 w-10">
                      <i className="fa-solid fa-users-gear fa-lg"></i>
                    </div>
                    <div className="w-100">
                      <Select placeholder={t('select.placeholder')} menuPlacement="auto" menuPosition="fixed" styles={colourStyles} options={roles} value={role} onChange={handleRole} />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className='text-dark'>Birth date</label>
                    <DateTimePicker
                      onChange={handleBirthDateChange}
                      value={birthDate}
                      className="form-control"
                      format='MM-dd-yyyy HH:mm'
                    />
                  </div>
                  <div className="form-group">
                    <label className='text-dark'>Start date</label>
                    <DateTimePicker
                      onChange={handleStartDateChange}
                      value={startDate}
                      className="form-control"
                      format='MM-dd-yyyy HH:mm'
                    />
                  </div>
                  <div className="form-group files m-2 text-center">
                    <label>Photo</label>
                    <input id="excelfile" className='bg-light' type="file" onChange={(e) => handleFile(e)} />
                  </div>
                </div>
                <div className="form-group d-flex justify-content-center">
                  <input type="submit" className="btn btn-primary btn-bright" value={t('buttons.register')} />
                </div>
              </form>
              <div className="form-group d-flex justify-content-center">
                <button onClick={() => { handleReturn() }} type="button" className="btn btn-dark btn-bright">Go back</button>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};
