import useSWR from "swr";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { format } from "date-fns";

export const ClaimedScreen = ({ id }) => {
  const { t } = useTranslation();
  const { data: rewards, error, isLoading } = useSWR(`rewards/claimed/${id}`);
  return (
    <div className='text-center'>
      <h1 className="font-speciallblue mt-2">Rewards claimed</h1>
      <div className="mt-2">
        <Link className="text-decoration-none" to="/rewards"><span className='font-speciallblue-2 bg-light text-dark rounded p-2'>Rewards</span></Link>
      </div>
      <div className='d-flex flex-column justify-content-center align-items-center text-center text-light'>
        {
          rewards?.userRewards?.length > 0 && rewards?.userRewards?.map((reward, j) => {
            return (
              <div key={`key-reward-${j}`} className='d-flex  justify-content-center align-items-center m-3 w-100'>
                <div className="d-flex flex-column justify-content-start align-items-center w-100 p-3">
                  <div className="m-2 d-flex justify-content-center align-items-center">
                    <img className="rounded-3" alt="user" src={reward?.reward.url && reward?.reward.url} style={{ objectFit: "cover" }} width={200} height={200} />
                  </div>
                  <h3 className='m-1'><b>{reward?.reward.name}</b></h3>
                  {reward?.date && <span className='m-1'>Claim date: <b>{format(new Date(reward?.date), 'MM-dd-yyyy HH:mm')}</b></span>}
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
};
