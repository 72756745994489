import * as d3 from 'd3';
import { useEffect, useRef } from 'react';

export const ProjectGraphCircular = ({ data }) => {
   const chartRef = useRef();
   useEffect(() => {
      const width = 928;
      const height = Math.min(width, 500);

      d3.select(chartRef.current).html("");

      const color = d3.scaleOrdinal()
         .domain(data.map(d => d.key))
         .range(d3.quantize(t => d3.interpolateSpectral(t * 0.8 + 0.1), data.length).reverse())

      const pie = d3.pie()
         .sort(null)
         .value(d => d.value);

      const arc = d3.arc()
         .innerRadius(0)
         .outerRadius(Math.min(width, height) / 2 - 1);

      const labelRadius = arc.outerRadius()() * 0.8;

      const arcLabel = d3.arc()
         .innerRadius(labelRadius)
         .outerRadius(labelRadius);

      const arcs = pie(data);

      const svg = d3.select(chartRef.current)
         .append("svg")
         .attr("width", width)
         .attr("height", height)
         .attr("viewBox", [-width / 2, -height / 2, width, height])
         .attr("style", "max-width: 100%; height: auto; font: 10px sans-serif;");

      svg.append("g")
         .attr("stroke", "white")
         .selectAll()
         .data(arcs)
         .join("path")
         .attr("fill", d => color(d.data.key))
         .attr("d", arc)
         .append("title")
         .text(d => `${d.data.key}: ${d.data.value.toLocaleString("en-US")} %`);

      svg.append("g")
         .attr("text-anchor", "middle")
         .selectAll()
         .data(arcs)
         .join("text")
         .attr("transform", d => {
            const pos = arcLabel.centroid(d);
            return `translate(${pos})`;
         })
         .call(text => text.append("tspan")
            .attr("y", "-0.4em")
            .attr("font-weight", "bold")
            .text(d => d.data.key))
         .call(text => text.filter(d => (d.endAngle - d.startAngle) > 0.05) // Umbral de ángulo mínimo
            .append("tspan")
            .attr("x", 0)
            .attr("y", "0.7em")
            .attr("fill-opacity", 0.7)
            .text(d => `${d.data.value.toLocaleString("en-US")} %`));

   }, [data]);

   return (
      <div ref={chartRef}></div>
   );
};
