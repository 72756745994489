import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import useSWR from "swr";

import { fetchConToken } from '../../helpers/fetch';
import colourStyles from '../../helpers/selectStyles';

export const GiveCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: cardsApi } = useSWR("cards/timeless");
  const cards = [];
  if (cardsApi?.cards) {
    const cardsLen = cardsApi?.cards.length;
    for (let i = 0; i < cardsLen; i++) {
      const card = {
        value: cardsApi?.cards[i].id,
        label: cardsApi?.cards[i].description,
      }
      cards.push(card);
    }
  }
  const [card, setCard] = useState(null);
  const handleCard = (e) => {
    setCard(e);
  };

  const { data: usersApi } = useSWR("auth/users");
  const users = [];
  if (usersApi?.users) {
    const usersLen = usersApi?.users.length;
    for (let i = 0; i < usersLen; i++) {
      const user = {
        value: usersApi?.users[i].id,
        label: usersApi?.users[i].name + ' ' + usersApi?.users[i].last_name
      }
      users.push(user);
    }
  }
  const [user, setUser] = useState(null);
  const handleUser = (e) => {
    setUser(e);
  };

  const handleAddUserCard = (e) => {
    e.preventDefault();
    const startAddCard = async (user, card) => {
      const body = await fetchConToken(
        "usercards",
        { user, card },
        "POST"
      );
      if (body.ok) {
        Swal.fire(t('success.title'), t('success.card_assign'), "success");
      } else {
        Swal.fire("Error", body.msg, "error");
      }
    };
    startAddCard(user.value, card.value);
  };
  const handleReturn = () => {
    navigate('/');
  };
  return (
    <>
      {
        isMobile
          ?
          <div className='d-flex flex-column justify-content-center align-items-center mt-4' data-aos="fade-up" data-aos-duration="1000">
            <h1 className='text-light h1 text-center'>{t('user_cards.assign.title')}</h1>
            <div className="col-md-6 login-form-2 m-4">
              <form onSubmit={handleAddUserCard}>
                <div className="form-group mb-3">
                  <label className='text-light'>{t('labels.card')}</label>
                  <div className="w-100">
                    <Select placeholder={t('select.placeholder')} styles={colourStyles} options={cards} value={card} onChange={handleCard} />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className='text-light'>{t('labels.user')}</label>
                  <div className="w-100">
                    <Select placeholder={t('select.placeholder')} styles={colourStyles} options={users} value={user} onChange={handleUser} />
                  </div>
                </div>
                <div className="form-group d-flex justify-content-center mb-1">
                  <button type="submit" className="btn btn-success btn-bright">
                    <i className="fa fa-floppy-disk"></i> {t('buttons.save')}
                  </button>
                </div>
              </form>
              <div className="form-group d-flex flex-row justify-content-center">
                <button className="btn btn-light mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleReturn}>
                  <i className="fa fa-arrow-rotate-left me-1"></i> {t('buttons.return')}
                </button>
              </div>
            </div>
          </div>
          :
          <div className='d-flex flex-column justify-content-center align-items-center mt-4' data-aos="fade-up" data-aos-duration="1000">
            <h1 className='text-light'>{t('user_cards.assign.title')}</h1>
            <div className="col-md-6 login-form-2 m-4">
              <form onSubmit={handleAddUserCard}>
                <div className="form-group mb-3">
                  <label className='text-light'>{t('labels.card')}</label>
                  <div className="w-100">
                    <Select placeholder={t('select.placeholder')} styles={colourStyles} options={cards} value={card} onChange={handleCard} />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className='text-light'>{t('labels.user')}</label>
                  <div className="w-100">
                    <Select placeholder={t('select.placeholder')} styles={colourStyles} options={users} value={user} onChange={handleUser} />
                  </div>
                </div>
                <div className="form-group d-flex justify-content-center mb-1">
                  <button type="submit" className="btn btn-success btn-bright">
                    <i className="fa fa-floppy-disk"></i> {t('buttons.save')}
                  </button>
                </div>
              </form>
              <div className="form-group d-flex flex-row justify-content-center">
                <button className="btn btn-light mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleReturn}>
                  <i className="fa fa-arrow-rotate-left me-1"></i> {t('buttons.return')}
                </button>
              </div>
            </div>
          </div>
      }</>
  );
};
