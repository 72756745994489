import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import Axios from "axios";
import '../admin/uploadexcel.css';

import { useForm } from '../../hooks/useForm';
import { fetchConToken } from '../../helpers/fetch';
import { format } from 'date-fns';

export const AddEvent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const handleFile = ({ target }) => {
    setFile(target.files[0]);
  };

  const [formEventValues, handleEventInputChange] = useForm({
    sName: '',
    sDescription: '',
    sLocation: '',
    sPlace: '',
    sIndividual: '',
    sCompany: '',
  });
  const { sName, sDescription, sLocation, sPlace, sIndividual, sCompany } = formEventValues;

  const [date, setDate] = useState(format(new Date(), 'MM-dd-yyyy HH:mm'));
  const handleDateChange = (e) => {
    setDate(e);
  };
  const [startDate, setStartDate] = useState(format(new Date(), 'MM-dd-yyyy HH:mm'));
  const handleStartDateChange = (e) => {
    setStartDate(e);
  };


  const handleAddEvent = (e) => {
    e.preventDefault();
    if (sName === '' || sDescription === '' || sLocation === '' || sPlace === '' || sIndividual === '' || sCompany === '') {
      return Swal.fire(
        "Error",
        t('errors.fields_required'),
        "error"
      );
    }

    if (file) {
      const fileUploadCloud = async () => {
        Swal.fire({
          title: 'Wait ...',
          onBeforeOpen() {
            Swal.showLoading()
          },
          onAfterClose() {
            Swal.hideLoading()
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false
        })
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "cardsapp");
        Axios.post("https://api.cloudinary.com/v1_1/drholseqp/upload", formData
        ).then((res) => {
          const startAddEvent = async (name, description, place, location, individual_goal, company_goal, date, url, start_date) => {
            const body = await fetchConToken(
              "events",
              { name, description, place, location, individual_goal, company_goal, date, url, start_date },
              "POST"
            );
            if (body.ok) {
              Swal.close();
              Swal.fire(t('success.title'), t('success.event_created'), "success");
            } else {
              Swal.fire("Error", body.msg, "error");
            }
          };
          startAddEvent(sName, sDescription, sPlace, sLocation, sIndividual, sCompany, date, res.data.secure_url, startDate);
        });
      }
      fileUploadCloud();
    }
  };
  const handleReturn = () => {
    navigate('/');
  };
  return (
    <>{
      isMobile
        ?
        <div className='d-flex flex-column justify-content-center align-items-center mt-4' data-aos="fade-up" data-aos-duration="1000">
          <h1 className='text-light h1 text-center'>{t('events.register.title')}</h1>
          <div className="col-md-6 login-form-2 m-4">
            <form onSubmit={handleAddEvent}>
              <div className="form-group">
                <label className='text-light'>{t('labels.date')}</label>
                <DateTimePicker
                  onChange={handleDateChange}
                  value={date}
                  className="form-control mb-2"
                  format='MM-dd-yyyy HH:mm'
                />
              </div>
              <div className="form-group">
                <label className='text-light'>Start date</label>
                <DateTimePicker
                  onChange={handleStartDateChange}
                  value={startDate}
                  className="form-control mb-2"
                  format='MM-dd-yyyy HH:mm'
                />
              </div>
              <div className="form-group">
                <label className='text-light'>{t('labels.name')}</label>
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder={t('labels.name')}
                  name="sName"
                  value={sName}
                  onChange={handleEventInputChange}
                />
              </div>
              <div className="form-group">
                <label className='text-light'>{t('labels.description')}</label>
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder={t('labels.description')}
                  name="sDescription"
                  value={sDescription}
                  onChange={handleEventInputChange}
                />
              </div>
              <div className="form-group">
                <label className='text-light'>{t('labels.place')}</label>
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder={t('labels.place')}
                  name="sPlace"
                  value={sPlace}
                  onChange={handleEventInputChange}
                />
              </div>
              <div className="form-group">
                <label className='text-light'>{t('labels.location')}</label>
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder={t('labels.location')}
                  name="sLocation"
                  value={sLocation}
                  onChange={handleEventInputChange}
                />
              </div>
              <div className="form-group">
                <label className='text-light'>{t('labels.individual')}</label>
                <input
                  type="number"
                  className="form-control mb-2"
                  placeholder={t('labels.individual')}
                  name="sIndividual"
                  value={sIndividual}
                  onChange={handleEventInputChange}
                />
              </div>
              <div className="form-group">
                <label className='text-light'>{t('labels.company')}</label>
                <input
                  type="number"
                  className="form-control mb-2"
                  placeholder={t('labels.company')}
                  name="sCompany"
                  value={sCompany}
                  onChange={handleEventInputChange}
                />
              </div>
              <div>
                <h3>{t('labels.choose_file')}</h3>
                <div className="form-group files m-2">
                  <input id="excelfile" className='bg-light' type="file" onChange={(e) => handleFile(e)} />
                </div>
              </div>
              <div className="form-group d-flex justify-content-center mb-1">
                <button type="submit" className="btn btn-success btn-bright">
                  <i className="fa fa-floppy-disk"></i> {t('buttons.save')}
                </button>
              </div>
            </form>
            <div className="form-group d-flex flex-row justify-content-center">
              <button className="btn btn-light mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleReturn}>
                <i className="fa fa-arrow-rotate-left me-1"></i> {t('buttons.return')}
              </button>
            </div>
          </div>
        </div>
        :
        <div className='d-flex flex-column justify-content-center align-items-center mt-4' data-aos="fade-up" data-aos-duration="1000">
          <h1 className='text-light'>{t('events.register.title')}</h1>
          <div className="col-md-6 login-form-2 m-4">
            <form onSubmit={handleAddEvent}>
              <div className="form-group">
                <label className='text-light'>{t('labels.date')}</label>
                <DateTimePicker
                  onChange={handleDateChange}
                  value={date}
                  className="form-control mb-2"
                  format='MM-dd-yyyy HH:mm'
                />
              </div>
              <div className="form-group">
                <label className='text-light'>Start date</label>
                <DateTimePicker
                  onChange={handleStartDateChange}
                  value={startDate}
                  className="form-control mb-2"
                  format='MM-dd-yyyy HH:mm'
                />
              </div>
              <div className="form-group">
                <label className='text-light'>{t('labels.name')}</label>
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder={t('labels.name')}
                  name="sName"
                  value={sName}
                  onChange={handleEventInputChange}
                />
              </div>
              <div className="form-group">
                <label className='text-light'>{t('labels.description')}</label>
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder={t('labels.description')}
                  name="sDescription"
                  value={sDescription}
                  onChange={handleEventInputChange}
                />
              </div>
              <div className="form-group">
                <label className='text-light'>{t('labels.place')}</label>
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder={t('labels.place')}
                  name="sPlace"
                  value={sPlace}
                  onChange={handleEventInputChange}
                />
              </div>
              <div className="form-group">
                <label className='text-light'>{t('labels.location')}</label>
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder={t('labels.location')}
                  name="sLocation"
                  value={sLocation}
                  onChange={handleEventInputChange}
                />
              </div>
              <div className="form-group">
                <label className='text-light'>{t('labels.individual')}</label>
                <input
                  type="number"
                  className="form-control mb-2"
                  placeholder={t('labels.individual')}
                  name="sIndividual"
                  value={sIndividual}
                  onChange={handleEventInputChange}
                />
              </div>
              <div className="form-group">
                <label className='text-light'>{t('labels.company')}</label>
                <input
                  type="number"
                  className="form-control mb-2"
                  placeholder={t('labels.company')}
                  name="sCompany"
                  value={sCompany}
                  onChange={handleEventInputChange}
                />
              </div>
              <div>
                <h3>{t('labels.choose_file')}</h3>
                <div className="form-group files m-2">
                  <input id="excelfile" className='bg-light' type="file" onChange={(e) => handleFile(e)} />
                </div>
              </div>
              <div className="form-group d-flex justify-content-center mb-1">
                <button type="submit" className="btn btn-success btn-bright">
                  <i className="fa fa-floppy-disk"></i> {t('buttons.save')}
                </button>
              </div>
            </form>
            <div className="form-group d-flex flex-row justify-content-center">
              <button className="btn btn-light mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleReturn}>
                <i className="fa fa-arrow-rotate-left me-1"></i> {t('buttons.return')}
              </button>
            </div>
          </div>
        </div>
    }</>
  );
};
