
export const LoadingSpinnerBlue = ({ disableCenter = false }) => {
    return (
        <div className={disableCenter ? `bg-light d-flex justify-content-center align-items-center p-5` : `bg-light d-flex justify-content-center align-items-center p-5 center`}>
            <div className="central-dot-blue"></div>
            <div className="lds-default-blue">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}