import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Axios from "axios";
import '../admin/uploadexcel.css';

import { useForm } from '../../hooks/useForm';
import { fetchConToken } from '../../helpers/fetch';

export const AddPost = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const handleFile = ({ target }) => {
    setFile(target.files[0]);
  };

  const [formPostValues, handlePostInputChange] = useForm({
    sTitle: '',
    sDescription: ''
  });
  const { sTitle, sDescription } = formPostValues;

  const handleAddPost = (e) => {
    e.preventDefault();
    if (sTitle === '' || sDescription === '') {
      return Swal.fire(
        "Error",
        t('errors.fields_required'),
        "error"
      );
    }

    if (file) {
      const fileUploadCloud = async () => {
        Swal.fire({
          title: 'Wait ...',
          onBeforeOpen() {
            Swal.showLoading()
          },
          onAfterClose() {
            Swal.hideLoading()
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false
        })
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "cardsapp");
        Axios.post("https://api.cloudinary.com/v1_1/drholseqp/upload", formData
        ).then((res) => {
          const startAddPost = async (title, description, url) => {
            const body = await fetchConToken(
              "posts",
              { title, description, url, date: new Date() },
              "POST"
            );
            if (body.ok) {
              Swal.close();
              Swal.fire(t('success.title'), t('success.post_created'), "success");
            } else {
              Swal.fire("Error", body.msg, "error");
            }
          };
          startAddPost(sTitle, sDescription, res.data.secure_url);
        });
      }
      fileUploadCloud();
    }
  };
  const handleReturn = () => {
    navigate('/');
  };
  return (
    <>
      {
        isMobile
          ?
          <div className='d-flex flex-column justify-content-center align-items-center mt-4' data-aos="fade-up" data-aos-duration="1000">
            <h1 className='text-light h1 text-center'>Create Announcement</h1>
            <div className="col-md-6 login-form-2 m-4">
              <form onSubmit={handleAddPost}>
                <div className="form-group">
                  <label className='text-light'>{t('labels.title')}</label>
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder={t('labels.title')}
                    name="sTitle"
                    value={sTitle}
                    onChange={handlePostInputChange}
                  />
                </div>
                <div className="form-group">
                  <label className='text-light'>{t('labels.description')}</label>
                  <textarea
                    type="text"
                    className="form-control mb-2"
                    placeholder={t('labels.description')}
                    name="sDescription"
                    value={sDescription}
                    onChange={handlePostInputChange}
                  />
                </div>
                <div>
                  <h3>Announcement Image</h3>
                  <div className="form-group files m-2">
                    <input id="excelfile" className='bg-light' type="file" onChange={(e) => handleFile(e)} />
                  </div>
                </div>
                <div className="form-group d-flex justify-content-center mb-1">
                  <button type="submit" className="btn btn-success btn-bright">
                    <i className="fa fa-floppy-disk"></i> {t('buttons.save')}
                  </button>
                </div>
              </form>
              <div className="form-group d-flex flex-row justify-content-center">
                <button className="btn btn-light mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleReturn}>
                  <i className="fa fa-arrow-rotate-left me-1"></i> {t('buttons.return')}
                </button>
              </div>
            </div>
          </div>
          :
          <div className='d-flex flex-column justify-content-center align-items-center mt-4' data-aos="fade-up" data-aos-duration="1000">
            <h1 className='text-light'>Create Announcement</h1>
            <div className="col-md-6 login-form-2 m-4">
              <form onSubmit={handleAddPost}>
                <div className="form-group">
                  <label className='text-light'>{t('labels.title')}</label>
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder={t('labels.title')}
                    name="sTitle"
                    value={sTitle}
                    onChange={handlePostInputChange}
                  />
                </div>
                <div className="form-group">
                  <label className='text-light'>{t('labels.description')}</label>
                  <textarea
                    type="text"
                    className="form-control mb-2"
                    placeholder={t('labels.description')}
                    name="sDescription"
                    value={sDescription}
                    onChange={handlePostInputChange}
                  />
                </div>
                <div>
                  <h3>Announcement Image</h3>
                  <div className="form-group files m-2">
                    <input id="excelfile" className='bg-light' type="file" onChange={(e) => handleFile(e)} />
                  </div>
                </div>
                <div className="form-group d-flex justify-content-center mb-1">
                  <button type="submit" className="btn btn-success btn-bright">
                    <i className="fa fa-floppy-disk"></i> {t('buttons.save')}
                  </button>
                </div>
              </form>
              <div className="form-group d-flex flex-row justify-content-center">
                <button className="btn btn-light mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleReturn}>
                  <i className="fa fa-arrow-rotate-left me-1"></i> {t('buttons.return')}
                </button>
              </div>
            </div>
          </div>
      }
    </>
  );
};
