import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { fetchConToken } from '../../../../helpers/fetch';
import { LoadingSpinnerWhite } from '../../../ui/LoadingSpinnerWhite';
import Tilt from 'react-parallax-tilt';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import { format } from 'date-fns';

export const AdminStatsDealsGeneralOfficesCards = ({ cancels, id }) => {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const date = new Date();
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const goBack = () => {
    navigate('/statsdata');
  }

  const [dateS, setDateS] = useState(null);
  const [offices, setOffices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // useEffect(async () => {
  //   const todayDate = new Date();
  //   const body = await fetchConToken(`userdata/usersmanualdata/custom/offices/true?startDate=2024-03-01&endDate=${todayDate}`);
  //   setTotal(body.total);
  //   setOffices(body.offices);
  //   setIsLoading(false);
  // }, []);

  // let offices = [
  //   {
  //     av: 'br',
  //     name: 'Boca Raton',
  //     amount: 11,
  //     logo: 'https://res.cloudinary.com/drholseqp/image/upload/v1692899045/bocalogo_paxqkx.webp',
  //     sit: 15,
  //     app: 26,
  //     dk: 70
  //   },
  //   {
  //     av: 'ta',
  //     name: 'Tampa',
  //     amount: 4,
  //     logo: 'https://res.cloudinary.com/drholseqp/image/upload/v1710336018/tampasmall_juykti.png',
  //     sit: 10,
  //     app: 20,
  //     dk: 55
  //   },
  //   {
  //     av: 'cc',
  //     name: 'Cape Coral',
  //     amount: 15,
  //     logo: 'https://res.cloudinary.com/drholseqp/image/upload/v1692899045/capelogo_amfvbq.webp',
  //     sit: 15,
  //     app: 44,
  //     dk: 88
  //   },
  //   {
  //     av: 'jx',
  //     name: 'Jacksonville',
  //     amount: 14,
  //     logo: 'https://res.cloudinary.com/drholseqp/image/upload/v1692899045/jaxlogo_kqmla1.webp',
  //     sit: 20,
  //     app: 30,
  //     dk: 80
  //   },
  //   {
  //     av: 'cl',
  //     name: 'Call Center',
  //     amount: 2,
  //     logo: 'https://res.cloudinary.com/drholseqp/image/upload/v1710336044/Call_Center_4xsmall_pn1t5m.png',
  //     sit: 2,
  //     app: 10,
  //     dk: 24
  //   },
  //   {
  //     av: 'st',
  //     name: 'Strattonites',
  //     amount: 3,
  //     logo: 'https://res.cloudinary.com/drholseqp/image/upload/v1710336020/smallstratoni_eci1yk.png',
  //     sit: 5,
  //     app: 12,
  //     dk: 31
  //   },
  // ];

  const handleDateChange = async (e) => {
    setDateS(e);
    setIsLoading(true);

    const bodu = await fetchConToken('userdata/usersdata/custom/offices', { date: e }, 'POST')
    setOffices(bodu.offices);
    setTotal(bodu.total);

    setIsLoading(false);
  };

  return (
    <div className={isMobile ? 'bg-primary-dark vh-110' : 'bg-primary-dark'}>
      {
        isLoading
          ?
          <LoadingSpinnerWhite />
          :
          <>
            {
              isMobile
                ?
                <div className='position-relative'>
                  <div className='d-flex justify-content-center align-items-center pt-2'>
                    <button onClick={goBack} className='btn btn-light'><i className='fa fa-chevron-left'></i></button>
                  </div>
                  <div className='d-flex flex-column justify-content-center align-items-center text-center text-admin-stats'>
                    <span className='font-special-total-deals m-5'>{monthNames[date.getMonth()]}</span>
                    <span className='font-special-total-deals'>TOTAL SALES</span>
                    {/* <div className='bg-primary-dark rounded-3 p-4 m-3'>
                <span className='font-special-total-deals-value'>{total}</span>
              </div> */}
                  </div>
                  <img className='logo-be-admin-stats' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694440370/images-logos/001_sc2glr.png'} style={{ objectFit: "contain" }} width={200} height={100} alt="back" />
                  <img className='logo-be-admin-stats-2' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704224590/Asset_2_zo1wyw.png'} style={{ objectFit: "contain" }} width={200} height={100} alt="back" />
                </div>
                :
                <div className='w-100 text-center vh-100 position-relative pt-5 d-flex flex-column justify-content-center align-items-center'>
                  <img className='bg-offices-deals' src={'https://res.cloudinary.com/drholseqp/image/upload/v1709839195/FONDO_BLUENERGY_LOGOS_vx6tql.jpg'} style={{ objectFit: "cover" }} alt="back" />
                  <div className='position-absolute d-flex flex-column justify-content-center align-items-center'>
                    <div className='d-flex flex-column align-items-center justify-content-center pt-5 mt-5'>
                      <span className='text-light text-bold'>Select the range of dates</span>
                      <DateRangePicker className={"bg-light rounded-3 mb-5"} onChange={(e) => { handleDateChange(e) }} value={dateS} />
                    </div>
                    {
                      dateS &&
                      <>
                        <div className='col-12 d-flex justify-content-center align-items-center pb-5 pt-5'>
                          <img className='img-class-logo-offices' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694440370/images-logos/001_sc2glr.png'} style={{ objectFit: "cover" }} alt="back" />
                        </div>
                        <div className='col-12 d-flex justify-content-center align-items-center'>
                          <span className='text-light text-bold text-1-5'> {format(dateS[0], 'dd')} {monthNames[dateS[0].getMonth()]} - {format(dateS[1], 'dd')} {monthNames[dateS[1].getMonth()]}</span>
                        </div>
                        <div className='row mx-4'>
                          {
                            offices.map((office, i) => {
                              return (
                                <Tilt className={`col p-0 m-4 d-flex flex-column justify-content-center align-items-center bg-light rounded-3 shadow-lg`} style={{ width: 300 }} scale={1.2}>
                                  {/* <div className='col p-0 m-1 d-flex flex-column justify-content-center align-items-center bg-light rounded-3 shadow-lg'> */}
                                  <span className='text-bold rounded-top text-primary-light  text-bold text-1 bg-primary-dark w-100'>{office.name}</span>
                                  {/* <div className='box-flipable'> */}
                                  <img className={`img-logo-offices-2 flipableee`} src={office.logo} style={{ objectFit: "cover", backgroundColor: 'transparent' }} alt="back" />
                                  {/* <img id={i + "1"} className='img-logo-offices flipableee' src={office.logo} style={{ objectFit: "cover" }} alt="back" /> */}
                                  {/* <img id={i + "2"} className='img-logo-offices' src={office.logo} style={{ objectFit: "cover" }} alt="back" /> */}
                                  {/* </div> */}
                                  <div className='row w-100 d-flex justify-content-center align-items-center border border-dark'>
                                    <span className='col w-50 bg-dark text-light text-bold text-1'>Deals</span>
                                    <span className='col w-50 bg-light text-primary text-bold text-1'><b className='text-primary text-bold'>{office.deals}</b></span>
                                  </div>
                                  <div className='row w-100 d-flex justify-content-center align-items-center border border-dark'>
                                    <span className='col w-50 bg-dark text-light text-bold text-1'>Sits</span>
                                    <span className='col w-50 bg-light text-primary text-bold text-1'><b className='text-primary text-bold'>{office.sits}</b></span>
                                  </div>
                                  <div className='row w-100 d-flex justify-content-center align-items-center border border-dark'>
                                    <span className='col w-50 bg-dark text-light text-bold text-1'>App</span>
                                    <span className='col w-50 bg-light text-primary text-bold text-1'><b className='text-primary text-bold'>{office.apps}</b></span>
                                  </div>
                                  <div className='row w-100 d-flex justify-content-center align-items-center border border-dark'>
                                    <span className='col w-50 bg-dark text-light text-bold text-1'>DK</span>
                                    <span className='col w-50 bg-light text-primary text-bold text-1'><b className='text-primary text-bold'>{office.dk}</b></span>
                                  </div>
                                  <div className='row w-100 d-flex justify-content-center align-items-center border border-dark rounded-bottom bg-dark'>
                                    <span className='col w-50 bg-dark text-light text-bold text-0-5 border-lower-right'>Employees</span>
                                    <span className='col w-50 bg-light text-primary text-bold text-1 border-lower-right'><b className='text-primary text-bold'>{office.employees}</b></span>
                                  </div>

                                  {/* </div> */}
                                </Tilt>
                              )
                            })
                          }
                        </div>
                        <div className='col-12 d-flex justify-content-center align-items-center mt-5 pt-5'>
                          <span className='text-2 text-light text-justified me-5 text-line'>{monthNames[date.getMonth()].toUpperCase()}</span>
                          <span className='text-2 text-info-light text-justified text-bold text-line'><b className='text-bold me-3'>{total}</b> DEALS</span>
                        </div>
                      </>
                    }
                  </div>
                </div>
            }
          </>
      }
    </div>
  );
};