import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Axios from "axios";
import '../admin/uploadexcel.css';

import { useForm } from '../../hooks/useForm';
import { fetchConToken } from '../../helpers/fetch';
import colourStyles from '../../helpers/selectStyles';

export const AddCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const handleFile = ({ target }) => {
    setFile(target.files[0]);
  };

  const [formCardValues, handleCardInputChange] = useForm({
    sDescription: '',
    sValue: '',
  });
  const { sDescription, sValue } = formCardValues;

  const timeFrames = [
    { value: 'daily', label: `${t('cards.time_frames.daily')}` },
    { value: 'weekly', label: `${t('cards.time_frames.weekly')}` },
    { value: 'monthly', label: `${t('cards.time_frames.monthly')}` },
    { value: 'semiannual', label: `${t('cards.time_frames.semiannual')}` },
    { value: 'yearly', label: `${t('cards.time_frames.yearly')}` },
    { value: 'timeless', label: `${t('cards.time_frames.timeless')}` },
  ];
  const [timeFrame, setTimeFrame] = useState({ value: 'daily', label: `${t('cards.time_frames.daily')}` });
  const handleTimeFrame = (e) => {
    setTimeFrame(e);
  };

  const types = [
    { value: 'basic', label: `${t('cards.types.basic')}` },
    { value: 'pro', label: `${t('cards.types.pro')}` },
    { value: 'master', label: `${t('cards.types.master')}` },
    { value: 'grandmaster', label: `${t('cards.types.grandmaster')}` },
    { value: 'legendary', label: `${t('cards.types.legendary')}` },
    { value: 'goat', label: `${t('cards.types.goat')}` },
  ];
  const [type, setType] = useState({ value: 'basic', label: `${t('cards.types.basic')}` });
  const handleType = (e) => {
    setType(e);
  };

  const handleAddCard = (e) => {
    e.preventDefault();
    if (sDescription === '' || sValue === '') {
      return Swal.fire(
        "Error",
        t('errors.fields_required'),
        "error"
      );
    }

    if (file) {
      const fileUploadCloud = async () => {
        Swal.fire({
          title: 'Wait ...',
          onBeforeOpen() {
            Swal.showLoading()
          },
          onAfterClose() {
            Swal.hideLoading()
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false
        })
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "cardsapp");
        Axios.post("https://api.cloudinary.com/v1_1/drholseqp/upload", formData
        ).then((res) => {
          const startAddCard = async (description, value, time_frame, type, url) => {
            const body = await fetchConToken(
              "cards",
              { description, value, time_frame, type, url },
              "POST"
            );
            if (body.ok) {
              Swal.close();
              Swal.fire(t('success.title'), t('success.card_created'), "success");
            } else {
              Swal.fire("Error", body.msg, "error");
            }
          };
          startAddCard(sDescription, sValue, timeFrame.value, type.value, res.data.secure_url);
        });
      }
      fileUploadCloud();
    } else {
      const startAddCard = async (description, value, time_frame, type) => {
        const body = await fetchConToken(
          "cards",
          { description, value, time_frame, type },
          "POST"
        );
        if (body.ok) {
          Swal.close();
          Swal.fire(t('success.title'), t('success.card_created'), "success");
        } else {
          Swal.fire("Error", body.msg, "error");
        }
      };
      startAddCard(sDescription, sValue, timeFrame.value, type.value);
    }
  };
  const handleReturn = () => {
    navigate('/');
  };
  return (
    <>{
      isMobile
        ?
        <div className='d-flex flex-column justify-content-center align-items-center mt-4' data-aos="fade-up" data-aos-duration="1000">
          <h1 className='text-light h1 text-center'>{t('cards.register.title')}</h1>
          <div className="col-md-6 login-form-2 m-4">
            <form onSubmit={handleAddCard}>
              <div className="form-group mb-3">
                <label className='text-light'>{t('labels.type')}</label>
                <div className="w-100">
                  <Select placeholder={t('select.placeholder')} styles={colourStyles} options={types} value={type} onChange={handleType} />
                </div>
              </div>
              <div className="form-group">
                <label className='text-light'>{t('labels.description')}</label>
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder={t('labels.description')}
                  name="sDescription"
                  value={sDescription}
                  onChange={handleCardInputChange}
                />
              </div>
              <div className="form-group">
                <label className='text-light'>{t('labels.value')}</label>
                <input
                  type="number"
                  className="form-control mb-2"
                  placeholder={t('labels.value')}
                  name="sValue"
                  value={sValue}
                  onChange={handleCardInputChange}
                />
              </div>
              <div className="form-group mb-3">
                <label className='text-light'>{t('labels.time_frame')}</label>
                <div className="w-100">
                  <Select placeholder={t('select.placeholder')} styles={colourStyles} options={timeFrames} value={timeFrame} onChange={handleTimeFrame} />
                </div>
              </div>
              <div>
                <h3>{t('labels.choose_file')}</h3>
                <div className="form-group files m-2">
                  <input id="excelfile" className='bg-light' type="file" onChange={(e) => handleFile(e)} />
                </div>
              </div>
              <div className="form-group d-flex justify-content-center mb-1">
                <button type="submit" className="btn btn-success btn-bright">
                  <i className="fa fa-floppy-disk"></i> {t('buttons.save')}
                </button>
              </div>
            </form>
            <div className="form-group d-flex flex-row justify-content-center">
              <button className="btn btn-light mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleReturn}>
                <i className="fa fa-arrow-rotate-left me-1"></i> {t('buttons.return')}
              </button>
            </div>
          </div>
        </div>
        :
        <div className='d-flex flex-column justify-content-center align-items-center mt-4' data-aos="fade-up" data-aos-duration="1000">
          <h1 className='text-light'>{t('cards.register.title')}</h1>
          <div className="col-md-6 login-form-2 m-4">
            <form onSubmit={handleAddCard}>
              <div className="form-group mb-3">
                <label className='text-light'>{t('labels.type')}</label>
                <div className="w-100">
                  <Select placeholder={t('select.placeholder')} styles={colourStyles} options={types} value={type} onChange={handleType} />
                </div>
              </div>
              <div className="form-group">
                <label className='text-light'>{t('labels.description')}</label>
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder={t('labels.description')}
                  name="sDescription"
                  value={sDescription}
                  onChange={handleCardInputChange}
                />
              </div>
              <div className="form-group">
                <label className='text-light'>{t('labels.value')}</label>
                <input
                  type="number"
                  className="form-control mb-2"
                  placeholder={t('labels.value')}
                  name="sValue"
                  value={sValue}
                  onChange={handleCardInputChange}
                />
              </div>
              <div className="form-group mb-3">
                <label className='text-light'>{t('labels.time_frame')}</label>
                <div className="w-100">
                  <Select placeholder={t('select.placeholder')} styles={colourStyles} options={timeFrames} value={timeFrame} onChange={handleTimeFrame} />
                </div>
              </div>
              <div>
                <h3>{t('labels.choose_file')}</h3>
                <div className="form-group files m-2">
                  <input id="excelfile" className='bg-light' type="file" onChange={(e) => handleFile(e)} />
                </div>
              </div>
              <div className="form-group d-flex justify-content-center mb-1">
                <button type="submit" className="btn btn-success btn-bright">
                  <i className="fa fa-floppy-disk"></i> {t('buttons.save')}
                </button>
              </div>
            </form>
            <div className="form-group d-flex flex-row justify-content-center">
              <button className="btn btn-light mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleReturn}>
                <i className="fa fa-arrow-rotate-left me-1"></i> {t('buttons.return')}
              </button>
            </div>
          </div>
        </div>
    }</>
  );
};
