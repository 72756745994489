import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const TopLeaderboard = ({ date, myDeals, tierList, totalAmount }) => {
  const { role } = useSelector((state) => state.auth);
  // const [isAdmin, setisAdmin] = useState(false);
  // useEffect(() => {
  //   if (role === 'Admin') {
  //     setisAdmin(true);
  //   }
  // }, [role]);
  // const monthNames = ["January", "February", "March", "April", "May", "June",
  //   "July", "August", "September", "October", "November", "December"
  // ];
  return (
    <div>
      {/* <span className="d-flex justify-content-center align-items-center text-light mb-2">
        <span className="font-special-dash">Total {date.length === 2 ? 'Deals' : monthNames[date.getMonth()]}: </span>
        <b className="font-special-dash ms-1">{totalAmount}</b>
      </span>
      {!isAdmin && <span className="d-flex justify-content-center text-light mb-2">
        <span className="font-special-dash">My Deals: </span>
        <b className="font-special-dash ms-1">{myDeals}</b>
      </span>} */}
      <div className='d-flex justify-content-center align-items-start'>
        {tierList.map((leader, index, arr) => {
          return (
            <div className="col position-relative" key={"leader" + leader.user.id}>
              {
                index === 0
                  ?
                  <div>
                    <div className="d-flex flex-column justify-content-center align-items-center p-1">
                      <div className="crown">
                        <svg
                          id="crown1"
                          fill={arr[1].position === 1 ? '#fbb03b' : '#cccccc'}
                          data-name="Layer 1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 100 50"
                        >
                          <polygon
                            className="cls-1"
                            points="12.7 50 87.5 50 100 0 75 25 50 0 25.6 25 0 0 12.7 50"
                          />
                        </svg>
                      </div>
                      <img style={{ objectFit: "cover" }} className={arr[1].position === 1 ? 'imggs rounded-pill' : 'imgs rounded-pill'} width={75} height={75} loading="lazy" src={arr[1].user.photo ? arr[1].user.photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} />
                      <span className={arr[1].position === 1 ? 'position-1st' : 'position-2nd'}>{arr[1].position === 1 ? '1st.' : '2nd.'}</span>
                      <span className={arr[1].position === 1 ? 'position-logo-1st2' : 'position-logo-2nd'}>
                        {
                          arr[1].user?.office === 'Boca Raton' ?
                            <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462423/offices_logos/boca100x100_wzjla8.png'} width={35} height={35} />
                            :
                            arr[1].user?.office === 'Jacksonville' ?
                              <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462421/offices_logos/jax100x100_oqyqj7.png'} width={35} height={35} />
                              :
                              arr[1].user?.office === 'Bradenton' ?
                                <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462425/offices_logos/brad100x100_g0cxrl.png'} width={35} height={35} />
                                :
                                arr[1].user?.office === 'Cape Coral' ?
                                  <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462427/offices_logos/cape100x100_zsjjn4.png'} width={35} height={35} />
                                  :
                                  <></>
                        }
                      </span>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <span className="text-light"><b className="font-special-rank">{arr[1].amount} {arr[1].amount > 1 ? 'DLS' : 'DL'}</b></span>
                      <span style={{ wordSpacing: "99999px" }} className="text-light text-wrap  fw-bold">{arr[1].user?.name} {arr[1].user?.last_name}</span>
                    </div>
                  </div>
                  :
                  index === 1
                    ?
                    <div className="row">
                      <div className="col-12 d-flex flex-column justify-content-center align-items-center p-1 img-leader-1">
                        <div className="crown">
                          <svg
                            id="crown1"
                            fill="#fbb03b"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 100 50"
                          >
                            <polygon
                              className="cls-1"
                              points="12.7 50 87.5 50 100 0 75 25 50 0 25.6 25 0 0 12.7 50"
                            />
                          </svg>
                        </div>
                        <img style={{ objectFit: "cover" }} className="imgg rounded-pill" width={90} height={90} loading="lazy" src={arr[0].user.photo ? arr[0].user.photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} />
                        <span className="position-1st">1st.</span>
                        <span className={'position-logo-1st'}>
                          {
                            arr[0].user?.office === 'Boca Raton' ?
                              <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462423/offices_logos/boca100x100_wzjla8.png'} width={35} height={35} />
                              :
                              arr[0].user?.office === 'Jacksonville' ?
                                <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462421/offices_logos/jax100x100_oqyqj7.png'} width={35} height={35} />
                                :
                                arr[0].user?.office === 'Bradenton' ?
                                  <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462425/offices_logos/brad100x100_g0cxrl.png'} width={35} height={35} />
                                  :
                                  arr[0].user?.office === 'Cape Coral' ?
                                    <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462427/offices_logos/cape100x100_zsjjn4.png'} width={35} height={35} />
                                    :
                                    <></>
                          }
                        </span>
                      </div>
                      <div className="col-12 d-flex flex-column justify-content-center align-items-center text-leader-1">
                        <div className="d-flex justify-content-center align-items-center text-center">
                          <span className="text-light"><b className="font-special-rank">{arr[0].amount} {arr[0].amount > 1 ? 'DLS' : 'DL'}</b></span>
                        </div>
                        <span style={{ wordSpacing: "99999px" }} className="text-light text-wrap fw-bold">{arr[0].user?.name} {arr[0].user?.last_name}</span>
                      </div>
                    </div>
                    :
                    <div>
                      <div className="d-flex flex-column justify-content-center align-items-center p-1">
                        <div className="crown">
                          <svg
                            id="crown1"
                            fill={arr[2].position === 1 ? '#fbb03b' : arr[2].position === 2 ? '#cccccc' : '#cc670d'}
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 100 50"
                          >
                            <polygon
                              className="cls-1"
                              points="12.7 50 87.5 50 100 0 75 25 50 0 25.6 25 0 0 12.7 50"
                            />
                          </svg>
                        </div>
                        <img style={{ objectFit: "cover" }} className={arr[2].position === 1 ? 'imggc rounded-pill' : arr[2].position === 2 ? 'imgs rounded-pill' : 'imgc rounded-pill'} width={75} height={75} loading="lazy" src={leader.user.photo ? leader.user.photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} />
                        <span className={arr[2].position === 1 ? 'position-1st' : arr[2].position === 2 ? 'position-2nd' : 'position-3rd'}>{arr[2].position === 1 ? '1st.' : arr[2].position === 2 ? '2nd.' : '3rd.'}</span>
                        <span className={arr[2].position === 1 ? 'position-logo-1st3' : arr[2].position === 2 ? 'position-logo-2nd' : 'position-logo-3rd'}>
                          {
                            arr[2].user?.office === 'Boca Raton' ?
                              <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462423/offices_logos/boca100x100_wzjla8.png'} width={35} height={35} />
                              :
                              arr[2].user?.office === 'Jacksonville' ?
                                <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462421/offices_logos/jax100x100_oqyqj7.png'} width={35} height={35} />
                                :
                                arr[2].user?.office === 'Bradenton' ?
                                  <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462425/offices_logos/brad100x100_g0cxrl.png'} width={35} height={35} />
                                  :
                                  arr[2].user?.office === 'Cape Coral' ?
                                    <img style={{ objectFit: "cover" }} className='rounded-pill' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694462427/offices_logos/cape100x100_zsjjn4.png'} width={35} height={35} />
                                    :
                                    <></>
                          }
                        </span>
                      </div>
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="d-flex justify-content-center align-items-center">
                          <span className="text-light">
                            <b className="ms-2 font-special-rank">{arr[2].amount} {arr[2].amount > 1 ? 'DLS' : 'DL'}</b></span>
                        </div>
                        <span style={{ wordSpacing: "99999px" }} className="text-light text-wrap fw-bold">{leader.user?.name} {leader.user?.last_name}</span>
                      </div>
                    </div>
              }
            </div>
          )
        })}
      </div>
    </div>
  );
};
