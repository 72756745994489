import { Link, useNavigate } from "react-router-dom";
import useSWR from "swr"
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

import { fetchConToken } from "../../helpers/fetch";
import { UsersItems } from "./UsersItems";
import { isMobile } from "react-device-detect";

export const AdminUsersScreen = ({ isAdmin }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading } = useSWR(`auth/users/notadmin`);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data && !isLoading) {
      setUsers(data?.users);
      setLoading(false);
    }
  }, [data]);

  const handleReturn = (e) => {
    navigate('/');
  };
  const [values, setValues] = useState({ cSearch: "" });

  const { cSearch } = values;

  const handleSearchInputChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setLoading(true);
    if (cSearch.length > 0) {
      const search = async () => {
        const body = await fetchConToken(`auth/users/notadmin/search/${cSearch}`);
        setUsers(body.users);
        setLoading(false);
      }
      search();
    } else {
      const search = async () => {
        const body = await fetchConToken(`auth/users/notadmin`);
        setUsers(body.users);
        setLoading(false);
      }
      search();
    }
  };
  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center text-center bg-primary">
        <h1 className="text-light h1 mb-2 mt-2">Users</h1>
        <div className="d-flex justify-content-between align-items-center mb-3 w-100">
          <form className={isMobile ? 'form-group d-flex justify-content-center align-items-center w-70 m-2' : 'form-group d-flex justify-content-center align-items-center w-30 m-2'} onSubmit={handleSearch}>
            <input className='form-control me-2 text-center' title={t('filters.search_title')} placeholder='Search by name' type="text" name="cSearch" value={cSearch} onChange={handleSearchInputChange} />
            <button type="submit" className='btn btn-primary'><i className="fas fa-search"></i></button>
          </form>
          <div className="d-flex flex-row justify-content-center align-items-center">
            <Link to="/deletemasiveusers">
              <button className="btn btn-danger me-3" title="Delete users" style={{ width: "50px" }}>
                <i className="fas fa-trash fa-xl"></i>
              </button>
            </Link>
            <Link to="/addusersmanual">
              <button className="btn btn-success me-3" title="Add deal" style={{ width: "50px" }}>
                <i className="fas fa-plus-square fa-xl"></i>
              </button>
            </Link>
          </div>
        </div>
        <UsersItems users={users} setUsers={setUsers} loading={loading} />
        {
          isAdmin
          &&
          <>
            {
              !isLoading &&
              <div className="form-group d-flex flex-row justify-content-center">
                <button className="btn btn-light mt-2 mb-2 btn-bright d-flex flex-row justify-content-center align-items-center" onClick={handleReturn}>
                  <i className="fa fa-arrow-rotate-left me-1"></i> {t('buttons.return')}
                </button>
              </div>}
          </>
        }
      </div >
    </>
  );
};
