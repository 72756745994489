import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { clearPost, uiCloseModalPost } from "../../actions/ui";
import { format } from "date-fns";
import { fetchConToken } from "../../helpers/fetch";
import { isMobile } from "react-device-detect";

const customStyles = {
  // content: {
  //   top: "50%",
  //   left: "50%",
  //   right: "auto",
  //   bottom: "auto",
  //   marginRight: "-50%",
  //   transform: "translate(-50%, -50%)",
  // },
};
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

export const PostModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { modalOpenPost, post } = useSelector((state) => state.ui);

  const closeModal = () => {
    dispatch(uiCloseModalPost());
    dispatch(clearPost());
  };

  const addCount = async () => {
    const body = await fetchConToken('/');

  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Modal
        isOpen={modalOpenPost}
        onRequestClose={closeModal}
        style={customStyles}
        closeTimeoutMS={500}
        className={isMobile ? "postmodal-mobile" : "postmodal"}
        ariaHideApp={process.env.NODE_ENV === "test"}
      >
        <>
          {
            isMobile
              ?
              <>
                {
                  post &&
                  <div className="row g-0 container-modal-mobile">
                    <div className="col-4 rounded-semi-circle-left">
                      <img style={{ objectFit: "cover" }} className="w-100 h-100 rounded-semi-circle-left" src={post.url} width={100} height={100} />
                    </div>
                    <div className="col-8 rounded-semi-circle-right bg-primary p-4">
                      <span className='m-1 text-light'><b>{post.title.toUpperCase()}</b></span>
                      <p className='m-1 text-light' align="justify">{post.description}</p>
                      <div className="align-self-start align-bottom text-light">
                        <span style={{ fontSize: "12px" }}>{format(new Date(post.date), 'MM/dd/yyyy')}</span>
                      </div>
                    </div>
                    <div>
                      <button className="btn-post-modal1-mobile btn btn-2xl bg-light rounded-circle text-primary" onClick={() => { closeModal() }}><i className="fa fa-close"></i></button>
                    </div>
                    {/* <div>
                <button className="btn-post-modal2 btn btn-2xl bg-light text-bold text-primary" onClick={() => { addCount() }}>Count me in!</button>
              </div> */}
                  </div>
                }
              </>
              :
              <>
                {
                  post &&
                  <div className="row g-0 container-modal">
                    <div className="col-4 rounded-semi-circle-left">
                      <img style={{ objectFit: "cover" }} className="w-100 h-100 rounded-semi-circle-left" src={post.url} width={100} height={100} />
                    </div>
                    <div className="col-8 rounded-semi-circle-right bg-primary p-4">
                      <span className='m-1 text-light'><b>{post.title.toUpperCase()}</b></span>
                      <p className='m-1 text-light' align="justify">{post.description}</p>
                      <div className="align-self-start align-bottom text-light">
                        <span style={{ fontSize: "12px" }}>{format(new Date(post.date), 'MM/dd/yyyy')}</span>
                      </div>
                    </div>
                    <div>
                      <button className="btn-post-modal1 btn btn-2xl bg-light rounded-circle text-primary" onClick={() => { closeModal() }}><i className="fa fa-close"></i></button>
                    </div>
                    {/* <div>
                <button className="btn-post-modal2 btn btn-2xl bg-light text-bold text-primary" onClick={() => { addCount() }}>Count me in!</button>
              </div> */}
                  </div>
                }
              </>
          }
        </>
      </Modal>
    </div>
  );
};
