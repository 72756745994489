import Modal from "react-modal";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import { uiCloseModalBest } from "../../actions/ui";
import { fetchConToken } from "../../helpers/fetch";
import { useEffect, useRef } from "react";
import { useState } from "react";
import party from "party-js";
import { isMobile } from "react-device-detect";

const customStyles = {};
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

export const BestModal = ({ user, bests }) => {
  const dispatch = useDispatch();
  const { modalOpenBest } = useSelector((state) => state.ui);

  const partyy = useRef(null);
  const [closed, setClosed] = useState(false);
  const [intervalId, setIntervalId] = useState(0);
  const [hasBirthday, setHasBirthday] = useState(false);

  useEffect(() => {
    bests.find((b) => {
      if (b.birthday_boy_id !== null) {
        setHasBirthday(true);
      }
    })
  }, [])

  useEffect(() => {
    if (hasBirthday) {
      if (!closed) {
        const idInterval = setInterval(() => {
          party.confetti(partyy.current, {
            count: party.variation.range(30, 30),
            size: party.variation.range(0.8, 1.2),
            spread: party.variation.range(70, 80)
          });
        }, 2000)
        setIntervalId(idInterval);
      }
    }
  }, [closed, hasBirthday])

  const closeModal = (type) => {
    if (type === 'Birth') {
      const updateBest = async () => {
        const body = await fetchConToken(
          "userdata/bestoftheweek/update",
          { id: user.id },
          "PUT"
        );
        if (body.ok) {
        } else {
          Swal.fire("Error", body.msg, "error");
        }
      }
      updateBest();
      setClosed(true);
      if (intervalId) {
        clearInterval(intervalId)
      }
      dispatch(uiCloseModalBest());
    } else {
      const updateBest = async () => {
        const body = await fetchConToken(
          "userdata/bestoftheweek/update",
          { id: user.id },
          "PUT"
        );
        if (body.ok) {
        } else {
          Swal.fire("Error", body.msg, "error");
        }
      }
      updateBest();
      setClosed(true);
      if (intervalId) {
        clearInterval(intervalId)
      }
      dispatch(uiCloseModalBest());
    }

  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Modal
        isOpen={modalOpenBest}
        style={customStyles}
        closeTimeoutMS={500}
        className="bestmodal"
        ariaHideApp={process.env.NODE_ENV === "test"}
      >
        {
          isMobile
            ?
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div id="myCarouselbest" className="d-flex flex-column justify-content-center align-items-center carousel slide mb-3 text-center" data-bs-pause="true">
                <div className="carousel-indicators">
                  {bests.length > 0 && bests.map((best, i) => {
                    return (
                      <div key={best.id}>
                        {i === 0
                          ?
                          <button type="button" data-bs-target="#myCarouselbest" data-bs-slide-to={i} className="active" aria-current="true" aria-label="Slide 1"></button>
                          :
                          <button type="button" data-bs-target="#myCarouselbest" data-bs-slide-to={i} aria-current="true" aria-label={`Slide ${i}`}></button>
                        }
                      </div>
                    )
                  })
                  }
                </div>

                <div className="carousel-inner">
                  {
                    bests.length > 0 && bests.map((best, i) => {
                      return (
                        i === 0
                          ?
                          <div key={`best-${i}`} className="carousel-item active">
                            {
                              best.birthday_boy_name != null && best.birthday_boy_photo != null && best.birthday_boy_id != null
                                ?
                                <div className="vw-100 vh-80">
                                  <button ref={partyy} className="btn bg-invi"></button>
                                  <img className='img-bg-modal-blue-mobile' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819690/Asset_66_oqqvsi.png'} />
                                  <img className="mt-3 rounded-pill img-photo-modal-mobile" alt="user" src={best.birthday_boy_photo ? best.birthday_boy_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                  <div className="d-flex flex-column align-items-start img-name-modal-mobile">
                                    <span className="text-light text-thin text-3 text-justified">Happy Birthday</span>
                                    <span className="text-light text-bold text-4">{best.birthday_boy_name}!</span>
                                  </div>
                                  <button className="btn-modal-blue-mobile btn btn-2xl btn-big-mobile bg-light rounded-circle text-primary" onClick={() => { closeModal('Birth') }}><i className="fa fa-close"></i></button>
                                </div>
                                :
                                best.best_closer_name != null
                                  ?
                                  <div>
                                    {
                                      best.type === 'Week'
                                        ?
                                        <div className="vw-100 vh-100">
                                          <img className='img-bg-modal-mobile' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819689/Asset_65_gzxrzb.png'} />
                                          <img className="mt-3 rounded-pill img-photo-modal-mobile" alt="user" src={best.best_closer_photo ? best.best_closer_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                          <div className="d-flex flex-column align-items-center img-title-modal-mobile fs-3">
                                            <span className="text-thin text-justified text-brown">Closer of the <b className="text-bold">Week</b></span>
                                            {/* <span className="text-brown text-justified">of the <b className="text-bold">Week</b></span> */}
                                          </div>
                                          <div className="d-flex flex-column align-items-start img-name-modal-mobile">
                                            <span className="text-primary-dark text-thin text-3 text-justified">Congratulations</span>
                                            <span className="text-primary-dark text-bold text-4">{best.best_closer_name}!</span>
                                          </div>
                                          <button className="btn-modal-mobile btn btn-2xl btn-big-mobile bg-light rounded-circle text-primary" onClick={() => { closeModal('Best') }}><i className="fa fa-close"></i></button>
                                        </div>
                                        :
                                        <div className="vw-100 vh-100">
                                          <img className='img-bg-modal-mobile' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819689/Asset_65_gzxrzb.png'} />
                                          <img className="mt-3 rounded-pill img-photo-modal-mobile" alt="user" src={best.best_closer_photo ? best.best_closer_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                          <div className="d-flex flex-column align-items-center img-title-modal-mobile fs-3">
                                            <span className="text-thin text-justified text-brown">Closer of the <b className="text-bold">Month</b></span>
                                            {/* <span className="text-brown text-justified">of the <b className="text-bold">Month</b></span> */}
                                          </div>
                                          <div className="d-flex flex-column align-items-start img-name-modal-mobile">
                                            <span className="text-primary-dark text-thin text-3 text-justified">Congratulations</span>
                                            <span className="text-primary-dark text-bold text-4">{best.best_closer_name}!</span>
                                          </div>
                                          <button className="btn-modal-mobile btn btn-2xl btn-big-mobile bg-light rounded-circle text-primary" onClick={() => { closeModal('Best') }}><i className="fa fa-close"></i></button>
                                        </div>
                                    }
                                  </div>
                                  :
                                  best.best_canvasser_dk_name
                                    ?
                                    <div>
                                      {
                                        best.type === 'Week'
                                          ?
                                          <div className="vw-100 vh-100">
                                            <img className='img-bg-modal-mobile' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819689/Asset_65_gzxrzb.png'} />
                                            <img className="mt-3 rounded-pill img-photo-modal-mobile" alt="user" src={best.best_canvasser_dk_photo ? best.best_canvasser_dk_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                            <div className="d-flex flex-column align-items-center img-title-modal-mobile fs-3">
                                              <span className="text-thin text-justified text-brown">Canvasser of the <b className="text-bold">Week</b></span>
                                              {/* <span className="text-brown text-justified">of the <b className="text-bold">Week</b></span> */}
                                              <span className="text-brown text-justified text-bold">Most doors knocked</span>
                                            </div>
                                            <div className="d-flex flex-column align-items-start img-name-modal-mobile">
                                              <span className="text-primary-dark text-thin text-3 text-justified">Congratulations</span>
                                              <span className="text-primary-dark text-bold text-4">{best.best_canvasser_dk_name}!</span>
                                            </div>
                                            <button className="btn-modal-mobile btn btn-2xl btn-big-mobile bg-light rounded-circle text-primary" onClick={() => { closeModal('Best') }}><i className="fa fa-close"></i></button>
                                          </div>
                                          :
                                          <div className="vw-100 vh-100">
                                            <img className='img-bg-modal-mobile' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819689/Asset_65_gzxrzb.png'} />
                                            <img className="mt-3 rounded-pill img-photo-modal-mobile" alt="user" src={best.best_canvasser_dk_photo ? best.best_canvasser_dk_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                            <div className="d-flex flex-column align-items-center img-title-modal-mobile fs-3">
                                              <span className="text-thin text-justified text-brown">Canvasser of the <b className="text-bold">Month</b></span>
                                              {/* <span className="text-brown text-justified">of the <b className="text-bold">Month</b></span> */}
                                              <span className="text-brown text-justified text-bold">Most doors knocked</span>
                                            </div>
                                            <div className="d-flex flex-column align-items-start img-name-modal-mobile">
                                              <span className="text-primary-dark text-thin text-3 text-justified">Congratulations</span>
                                              <span className="text-primary-dark text-bold text-4">{best.best_canvasser_dk_name}!</span>
                                            </div>
                                            <button className="btn-modal-mobile btn btn-2xl btn-big-mobile bg-light rounded-circle text-primary" onClick={() => { closeModal('Best') }}><i className="fa fa-close"></i></button>
                                          </div>
                                      }
                                    </div>
                                    :
                                    <div>
                                      {
                                        best.type === 'Week'
                                          ?
                                          <div className="vw-100 vh-100">
                                            <img className='img-bg-modal-mobile' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819689/Asset_65_gzxrzb.png'} />
                                            <img className="mt-3 rounded-pill img-photo-modal-mobile" alt="user" src={best.best_canvasser_app_photo ? best.best_canvasser_app_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                            <div className="d-flex flex-column align-items-center img-title-modal-mobile fs-3">
                                              <span className="text-thin text-justified text-brown">Canvasser of the <b className="text-bold">Week</b></span>
                                              {/* <span className="text-brown text-justified">of the <b className="text-bold">Week</b></span> */}
                                              <span className="text-brown text-justified text-bold">Most appointments</span>
                                            </div>
                                            <div className="d-flex flex-column align-items-start img-name-modal-mobile">
                                              <span className="text-primary-dark text-thin text-3 text-justified">Congratulations</span>
                                              <span className="text-primary-dark text-bold text-4">{best.best_canvasser_app_name}!</span>
                                            </div>
                                            <button className="btn-modal-mobile btn btn-2xl btn-big-mobile bg-light rounded-circle text-primary" onClick={() => { closeModal('Best') }}><i className="fa fa-close"></i></button>
                                          </div>
                                          :
                                          <div className="vw-100 vh-100">
                                            <img className='img-bg-modal-mobile' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819689/Asset_65_gzxrzb.png'} />
                                            <img className="mt-3 rounded-pill img-photo-modal-mobile" alt="user" src={best.best_canvasser_app_photo ? best.best_canvasser_app_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                            <div className="d-flex flex-column align-items-center img-title-modal-mobile fs-3">
                                              <span className="text-thin text-justified text-brown">Canvasser of the <b className="text-bold">Month</b></span>
                                              {/* <span className="text-brown text-justified">of the <b className="text-bold">Month</b></span> */}
                                              <span className="text-brown text-justified text-bold">Most appointments</span>
                                            </div>
                                            <div className="d-flex flex-column align-items-start img-name-modal-mobile">
                                              <span className="text-primary-dark text-thin text-3 text-justified">Congratulations</span>
                                              <span className="text-primary-dark text-bold text-4">{best.best_canvasser_app_name}!</span>
                                            </div>
                                            <button className="btn-modal-mobile btn btn-2xl btn-big-mobile bg-light rounded-circle text-primary" onClick={() => { closeModal('Best') }}><i className="fa fa-close"></i></button>
                                          </div>
                                      }
                                    </div>
                            }
                          </div>
                          :
                          <div key={`best-${i}`} className="carousel-item">
                            {
                              best.birthday_boy_name != null && best.birthday_boy_photo != null && best.birthday_boy_id != null
                                ?
                                <div className="vw-100 vh-100">
                                  <button ref={partyy} className="btn bg-invi"></button>
                                  <img className='img-bg-modal-blue-mobile' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819690/Asset_66_oqqvsi.png'} />
                                  <img className="mt-3 rounded-pill img-photo-modal-mobile" alt="user" src={best.birthday_boy_photo ? best.birthday_boy_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                  <div className="d-flex flex-column align-items-start img-name-modal-mobile">
                                    <span className="text-light text-thin text-3 text-justified">Happy Birthday</span>
                                    <span className="text-light text-bold text-4">{best.birthday_boy_name}!</span>
                                  </div>
                                  <button className="btn-modal-blue-mobile btn btn-2xl btn-big-mobile bg-light rounded-circle text-primary" onClick={() => { closeModal('Birth') }}><i className="fa fa-close"></i></button>
                                </div>
                                :
                                best.best_closer_name != null
                                  ?
                                  <div>
                                    {
                                      best.type === 'Week'
                                        ?
                                        <div className="vw-100 vh-100">
                                          <img className='img-bg-modal-mobile' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819689/Asset_65_gzxrzb.png'} />
                                          <img className="mt-3 rounded-pill img-photo-modal-mobile" alt="user" src={best.best_closer_photo ? best.best_closer_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                          <div className="d-flex flex-column align-items-center img-title-modal-mobile fs-3">
                                            <span className="text-thin text-justified text-brown">Closer of the <b className="text-bold">Week</b></span>
                                            {/* <span className="text-brown text-justified">of the <b className="text-bold">Week</b></span> */}
                                          </div>
                                          <div className="d-flex flex-column align-items-start img-name-modal-mobile">
                                            <span className="text-primary-dark text-thin text-3 text-justified">Congratulations</span>
                                            <span className="text-primary-dark text-bold text-4">{best.best_closer_name}!</span>
                                          </div>
                                          <button className="btn-modal-mobile btn btn-2xl btn-big-mobile bg-light rounded-circle text-primary" onClick={() => { closeModal('Best') }}><i className="fa fa-close"></i></button>
                                        </div>
                                        :
                                        <div className="vw-100 vh-100">
                                          <img className='img-bg-modal-mobile' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819689/Asset_65_gzxrzb.png'} />
                                          <img className="mt-3 rounded-pill img-photo-modal-mobile" alt="user" src={best.best_closer_photo ? best.best_closer_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                          <div className="d-flex flex-column align-items-center img-title-modal-mobile fs-3">
                                            <span className="text-thin text-justified text-brown">Closer of the <b className="text-bold">Month</b></span>
                                            {/* <span className="text-brown text-justified">of the <b className="text-bold">Month</b></span> */}
                                          </div>
                                          <div className="d-flex flex-column align-items-start img-name-modal-mobile">
                                            <span className="text-primary-dark text-thin text-3 text-justified">Congratulations</span>
                                            <span className="text-primary-dark text-bold text-4">{best.best_closer_name}!</span>
                                          </div>
                                          <button className="btn-modal-mobile btn btn-2xl btn-big-mobile bg-light rounded-circle text-primary" onClick={() => { closeModal('Best') }}><i className="fa fa-close"></i></button>
                                        </div>
                                    }
                                  </div>
                                  :
                                  best.best_canvasser_dk_name
                                    ?
                                    <div>
                                      {
                                        best.type === 'Week'
                                          ?
                                          <div className="vw-100 vh-100">
                                            <img className='img-bg-modal-mobile' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819689/Asset_65_gzxrzb.png'} />
                                            <img className="mt-3 rounded-pill img-photo-modal-mobile" alt="user" src={best.best_canvasser_dk_photo ? best.best_canvasser_dk_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                            <div className="d-flex flex-column align-items-center img-title-modal-mobile fs-3">
                                              <span className="text-thin text-justified text-brown">Canvasser of the <b className="text-bold">Week</b></span>
                                              {/* <span className="text-brown text-justified">of the <b className="text-bold">Week</b></span> */}
                                              <span className="text-brown text-justified text-bold">Most doors knocked</span>
                                            </div>
                                            <div className="d-flex flex-column align-items-start img-name-modal-mobile">
                                              <span className="text-primary-dark text-thin text-3 text-justified">Congratulations</span>
                                              <span className="text-primary-dark text-bold text-4">{best.best_canvasser_dk_name}!</span>
                                            </div>
                                            <button className="btn-modal-mobile btn btn-2xl btn-big-mobile bg-light rounded-circle text-primary" onClick={() => { closeModal('Best') }}><i className="fa fa-close"></i></button>
                                          </div>
                                          :
                                          <div className="vw-100 vh-100">
                                            <img className='img-bg-modal-mobile' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819689/Asset_65_gzxrzb.png'} />
                                            <img className="mt-3 rounded-pill img-photo-modal-mobile" alt="user" src={best.best_canvasser_dk_photo ? best.best_canvasser_dk_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                            <div className="d-flex flex-column align-items-center img-title-modal-mobile fs-3">
                                              <span className="text-thin text-justified text-brown">Canvasser of the <b className="text-bold">Month</b></span>
                                              {/* <span className="text-brown text-justified">of the <b className="text-bold">Month</b></span> */}
                                              <span className="text-brown text-justified text-bold">Most doors knocked</span>
                                            </div>
                                            <div className="d-flex flex-column align-items-start img-name-modal-mobile">
                                              <span className="text-primary-dark text-thin text-3 text-justified">Congratulations</span>
                                              <span className="text-primary-dark text-bold text-4">{best.best_canvasser_dk_name}!</span>
                                            </div>
                                            <button className="btn-modal-mobile btn btn-2xl btn-big-mobile bg-light rounded-circle text-primary" onClick={() => { closeModal('Best') }}><i className="fa fa-close"></i></button>
                                          </div>
                                      }
                                    </div>
                                    :
                                    <div>
                                      {
                                        best.type === 'Week'
                                          ?
                                          <div className="vw-100 vh-100">
                                            <img className='img-bg-modal-mobile' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819689/Asset_65_gzxrzb.png'} />
                                            <img className="mt-3 rounded-pill img-photo-modal-mobile" alt="user" src={best.best_canvasser_app_photo ? best.best_canvasser_app_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                            <div className="d-flex flex-column align-items-center img-title-modal-mobile fs-3">
                                              <span className="text-thin text-justified text-brown">Canvasser of the <b className="text-bold">Week</b></span>
                                              {/* <span className="text-brown text-justified">of the <b className="text-bold">Week</b></span> */}
                                              <span className="text-brown text-justified text-bold">Most appointments</span>
                                            </div>
                                            <div className="d-flex flex-column align-items-start img-name-modal-mobile">
                                              <span className="text-primary-dark text-thin text-3 text-justified">Congratulations</span>
                                              <span className="text-primary-dark text-bold text-4">{best.best_canvasser_app_name}!</span>
                                            </div>
                                            <button className="btn-modal-mobile btn btn-2xl btn-big-mobile bg-light rounded-circle text-primary" onClick={() => { closeModal('Best') }}><i className="fa fa-close"></i></button>
                                          </div>
                                          :
                                          <div className="vw-100 vh-100">
                                            <img className='img-bg-modal-mobile' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819689/Asset_65_gzxrzb.png'} />
                                            <img className="mt-3 rounded-pill img-photo-modal-mobile" alt="user" src={best.best_canvasser_app_photo ? best.best_canvasser_app_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                            <div className="d-flex flex-column align-items-center img-title-modal-mobile fs-3">
                                              <span className="text-thin text-justified text-brown">Canvasser of the <b className="text-bold">Month</b></span>
                                              {/* <span className="text-brown text-justified">of the <b className="text-bold">Month</b></span> */}
                                              <span className="text-brown text-justified text-bold">Most appointments</span>
                                            </div>
                                            <div className="d-flex flex-column align-items-start img-name-modal-mobile">
                                              <span className="text-primary-dark text-thin text-3 text-justified">Congratulations</span>
                                              <span className="text-primary-dark text-bold text-4">{best.best_canvasser_app_name}!</span>
                                            </div>
                                            <button className="btn-modal-mobile btn btn-2xl btn-big-mobile bg-light rounded-circle text-primary" onClick={() => { closeModal('Best') }}><i className="fa fa-close"></i></button>
                                          </div>
                                      }
                                    </div>
                            }
                          </div>
                      )
                    })
                  }
                </div>
                <button aria-label="Anterior" className="carousel-control-prev" type="button" data-bs-target="#myCarouselbest" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true" />
                </button>
                <button id="apreta" aria-label="Siguiente" className="carousel-control-next" type="button" data-bs-target="#myCarouselbest" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true" />
                </button>
              </div>
            </div>
            :
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div id="myCarouselbest" className="d-flex flex-column justify-content-center align-items-center carousel slide mb-3 text-center" data-bs-pause="true">
                <div className="carousel-indicators">
                  {bests.length > 0 && bests.map((best, i) => {
                    return (
                      <div key={best.id}>
                        {i === 0
                          ?
                          <button type="button" data-bs-target="#myCarouselbest" data-bs-slide-to={i} className="active" aria-current="true" aria-label="Slide 1"></button>
                          :
                          <button type="button" data-bs-target="#myCarouselbest" data-bs-slide-to={i} aria-current="true" aria-label={`Slide ${i}`}></button>
                        }
                      </div>
                    )
                  })
                  }
                </div>

                <div className="carousel-inner">
                  {
                    bests.length > 0 && bests.map((best, i) => {
                      return (
                        i === 0
                          ?
                          <div key={`best-${i}`} className="carousel-item active">
                            {
                              best.birthday_boy_name != null && best.birthday_boy_photo != null && best.birthday_boy_id != null
                                ?
                                <div className="vw-100 vh-80">
                                  <button ref={partyy} className="btn bg-invi"></button>
                                  <img className='img-bg-modal-blue' style={{ width: "50%" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819690/Asset_66_oqqvsi.png'} />
                                  <img className="mt-3 rounded-pill img-photo-modal" alt="user" src={best.birthday_boy_photo ? best.birthday_boy_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                  <div className="d-flex flex-column align-items-start img-name-modal">
                                    <span className="text-light text-thin text-1 text-justified">Happy Birthday</span>
                                    <span className="text-light text-bold text-2">{best.birthday_boy_name}!</span>
                                  </div>
                                  <button className="btn-modal-blue btn btn-2xl btn-big bg-light rounded-circle text-primary" onClick={() => { closeModal('Birth') }}><i className="fa fa-close"></i></button>
                                </div>
                                :
                                best.best_closer_name != null
                                  ?
                                  <div>
                                    {
                                      best.type === 'Week'
                                        ?
                                        <div className="vw-100 vh-80">
                                          <img className='img-bg-modal' style={{ width: "50%" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819689/Asset_65_gzxrzb.png'} />
                                          <img className="mt-3 rounded-pill img-photo-modal" alt="user" src={best.best_closer_photo ? best.best_closer_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                          <div className="d-flex flex-column align-items-center img-title-modal fs-3">
                                            <span className="text-thin text-justified text-brown">Closer of the <b className="text-bold">Week</b></span>
                                            {/* <span className="text-brown text-justified">of the <b className="text-bold">Week</b></span> */}
                                          </div>
                                          <div className="d-flex flex-column align-items-start img-name-modal">
                                            <span className="text-primary-dark text-thin text-1 text-justified">Congratulations</span>
                                            <span className="text-primary-dark text-bold text-2">{best.best_closer_name}!</span>
                                          </div>
                                          <button className="btn-modal btn btn-2xl btn-big bg-light rounded-circle text-primary" onClick={() => { closeModal('Best') }}><i className="fa fa-close"></i></button>
                                        </div>
                                        :
                                        <div className="vw-100 vh-80">
                                          <img className='img-bg-modal' style={{ width: "50%" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819689/Asset_65_gzxrzb.png'} />
                                          <img className="mt-3 rounded-pill img-photo-modal" alt="user" src={best.best_closer_photo ? best.best_closer_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                          <div className="d-flex flex-column align-items-center img-title-modal fs-3">
                                            <span className="text-thin text-justified text-brown">Closer of the <b className="text-bold">Month</b></span>
                                            {/* <span className="text-brown text-justified">of the <b className="text-bold">Month</b></span> */}
                                          </div>
                                          <div className="d-flex flex-column align-items-start img-name-modal">
                                            <span className="text-primary-dark text-thin text-1 text-justified">Congratulations</span>
                                            <span className="text-primary-dark text-bold text-2">{best.best_closer_name}!</span>
                                          </div>
                                          <button className="btn-modal btn btn-2xl btn-big bg-light rounded-circle text-primary" onClick={() => { closeModal('Best') }}><i className="fa fa-close"></i></button>
                                        </div>
                                    }
                                  </div>
                                  :
                                  best.best_canvasser_dk_name
                                    ?
                                    <div>
                                      {
                                        best.type === 'Week'
                                          ?
                                          <div className="vw-100 vh-80">
                                            <img className='img-bg-modal' style={{ width: "50%" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819689/Asset_65_gzxrzb.png'} />
                                            <img className="mt-3 rounded-pill img-photo-modal" alt="user" src={best.best_canvasser_dk_photo ? best.best_canvasser_dk_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                            <div className="d-flex flex-column align-items-center img-title-modal fs-3">
                                              <span className="text-thin text-justified text-brown">Canvasser of the <b className="text-bold">Week</b></span>
                                              {/* <span className="text-brown text-justified">of the <b className="text-bold">Week</b></span> */}
                                              <span className="text-brown text-justified text-bold">Most doors knocked</span>
                                            </div>
                                            <div className="d-flex flex-column align-items-start img-name-modal">
                                              <span className="text-primary-dark text-thin text-1 text-justified">Congratulations</span>
                                              <span className="text-primary-dark text-bold text-2">{best.best_canvasser_dk_name}!</span>
                                            </div>
                                            <button className="btn-modal btn btn-2xl btn-big bg-light rounded-circle text-primary" onClick={() => { closeModal('Best') }}><i className="fa fa-close"></i></button>
                                          </div>
                                          :
                                          <div className="vw-100 vh-80">
                                            <img className='img-bg-modal' style={{ width: "50%" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819689/Asset_65_gzxrzb.png'} />
                                            <img className="mt-3 rounded-pill img-photo-modal" alt="user" src={best.best_canvasser_dk_photo ? best.best_canvasser_dk_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                            <div className="d-flex flex-column align-items-center img-title-modal fs-3">
                                              <span className="text-thin text-justified text-brown">Canvasser of the <b className="text-bold">Month</b></span>
                                              {/* <span className="text-brown text-justified">of the <b className="text-bold">Month</b></span> */}
                                              <span className="text-brown text-justified text-bold">Most doors knocked</span>
                                            </div>
                                            <div className="d-flex flex-column align-items-start img-name-modal">
                                              <span className="text-primary-dark text-thin text-1 text-justified">Congratulations</span>
                                              <span className="text-primary-dark text-bold text-2">{best.best_canvasser_dk_name}!</span>
                                            </div>
                                            <button className="btn-modal btn btn-2xl btn-big bg-light rounded-circle text-primary" onClick={() => { closeModal('Best') }}><i className="fa fa-close"></i></button>
                                          </div>
                                      }
                                    </div>
                                    :
                                    <div>
                                      {
                                        best.type === 'Week'
                                          ?
                                          <div className="vw-100 vh-80">
                                            <img className='img-bg-modal' style={{ width: "50%" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819689/Asset_65_gzxrzb.png'} />
                                            <img className="mt-3 rounded-pill img-photo-modal" alt="user" src={best.best_canvasser_app_photo ? best.best_canvasser_app_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                            <div className="d-flex flex-column align-items-center img-title-modal fs-3">
                                              <span className="text-thin text-justified text-brown">Canvasser of the <b className="text-bold">Week</b></span>
                                              {/* <span className="text-brown text-justified">of the <b className="text-bold">Week</b></span> */}
                                              <span className="text-brown text-justified text-bold">Most appointments</span>
                                            </div>
                                            <div className="d-flex flex-column align-items-start img-name-modal">
                                              <span className="text-primary-dark text-thin text-1 text-justified">Congratulations</span>
                                              <span className="text-primary-dark text-bold text-2">{best.best_canvasser_app_name}!</span>
                                            </div>
                                            <button className="btn-modal btn btn-2xl btn-big bg-light rounded-circle text-primary" onClick={() => { closeModal('Best') }}><i className="fa fa-close"></i></button>
                                          </div>
                                          :
                                          <div className="vw-100 vh-80">
                                            <img className='img-bg-modal' style={{ width: "50%" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819689/Asset_65_gzxrzb.png'} />
                                            <img className="mt-3 rounded-pill img-photo-modal" alt="user" src={best.best_canvasser_app_photo ? best.best_canvasser_app_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                            <div className="d-flex flex-column align-items-center img-title-modal fs-3">
                                              <span className="text-thin text-justified text-brown">Canvasser of the <b className="text-bold">Month</b></span>
                                              {/* <span className="text-brown text-justified">of the <b className="text-bold">Month</b></span> */}
                                              <span className="text-brown text-justified text-bold">Most appointments</span>
                                            </div>
                                            <div className="d-flex flex-column align-items-start img-name-modal">
                                              <span className="text-primary-dark text-thin text-1 text-justified">Congratulations</span>
                                              <span className="text-primary-dark text-bold text-2">{best.best_canvasser_app_name}!</span>
                                            </div>
                                            <button className="btn-modal btn btn-2xl btn-big bg-light rounded-circle text-primary" onClick={() => { closeModal('Best') }}><i className="fa fa-close"></i></button>
                                          </div>
                                      }
                                    </div>
                            }
                          </div>
                          :
                          <div key={`best-${i}`} className="carousel-item">
                            {
                              best.birthday_boy_name != null && best.birthday_boy_photo != null && best.birthday_boy_id != null
                                ?
                                <div className="vw-100 vh-80">
                                  <button ref={partyy} className="btn bg-invi"></button>
                                  <img className='img-bg-modal-blue' style={{ width: "50%" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819690/Asset_66_oqqvsi.png'} />
                                  <img className="mt-3 rounded-pill img-photo-modal" alt="user" src={best.birthday_boy_photo ? best.birthday_boy_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                  <div className="d-flex flex-column align-items-start img-name-modal">
                                    <span className="text-light text-thin text-1 text-justified">Happy Birthday</span>
                                    <span className="text-light text-bold text-2">{best.birthday_boy_name}!</span>
                                  </div>
                                  <button className="btn-modal-blue btn btn-2xl btn-big bg-light rounded-circle text-primary" onClick={() => { closeModal('Birth') }}><i className="fa fa-close"></i></button>
                                </div>
                                :
                                best.best_closer_name != null
                                  ?
                                  <div>
                                    {
                                      best.type === 'Week'
                                        ?
                                        <div className="vw-100 vh-80">
                                          <img className='img-bg-modal' style={{ width: "50%" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819689/Asset_65_gzxrzb.png'} />
                                          <img className="mt-3 rounded-pill img-photo-modal" alt="user" src={best.best_closer_photo ? best.best_closer_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                          <div className="d-flex flex-column align-items-center img-title-modal fs-3">
                                            <span className="text-thin text-justified text-brown">Closer of the <b className="text-bold">Week</b></span>
                                            {/* <span className="text-brown text-justified">of the <b className="text-bold">Week</b></span> */}
                                          </div>
                                          <div className="d-flex flex-column align-items-start img-name-modal">
                                            <span className="text-primary-dark text-thin text-1 text-justified">Congratulations</span>
                                            <span className="text-primary-dark text-bold text-2">{best.best_closer_name}!</span>
                                          </div>
                                          <button className="btn-modal btn btn-2xl btn-big bg-light rounded-circle text-primary" onClick={() => { closeModal('Best') }}><i className="fa fa-close"></i></button>
                                        </div>
                                        :
                                        <div className="vw-100 vh-80">
                                          <img className='img-bg-modal' style={{ width: "50%" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819689/Asset_65_gzxrzb.png'} />
                                          <img className="mt-3 rounded-pill img-photo-modal" alt="user" src={best.best_closer_photo ? best.best_closer_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                          <div className="d-flex flex-column align-items-center img-title-modal fs-3">
                                            <span className="text-thin text-justified text-brown">Closer of the <b className="text-bold">Month</b></span>
                                            {/* <span className="text-brown text-justified">of the <b className="text-bold">Month</b></span> */}
                                          </div>
                                          <div className="d-flex flex-column align-items-start img-name-modal">
                                            <span className="text-primary-dark text-thin text-1 text-justified">Congratulations</span>
                                            <span className="text-primary-dark text-bold text-2">{best.best_closer_name}!</span>
                                          </div>
                                          <button className="btn-modal btn btn-2xl btn-big bg-light rounded-circle text-primary" onClick={() => { closeModal('Best') }}><i className="fa fa-close"></i></button>
                                        </div>
                                    }
                                  </div>
                                  :
                                  best.best_canvasser_dk_name
                                    ?
                                    <div>
                                      {
                                        best.type === 'Week'
                                          ?
                                          <div className="vw-100 vh-80">
                                            <img className='img-bg-modal' style={{ width: "50%" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819689/Asset_65_gzxrzb.png'} />
                                            <img className="mt-3 rounded-pill img-photo-modal" alt="user" src={best.best_canvasser_dk_photo ? best.best_canvasser_dk_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                            <div className="d-flex flex-column align-items-center img-title-modal fs-3">
                                              <span className="text-thin text-justified text-brown">Canvasser of the <b className="text-bold">Week</b></span>
                                              {/* <span className="text-brown text-justified">of the <b className="text-bold">Week</b></span> */}
                                              <span className="text-brown text-justified text-bold">Most doors knocked</span>
                                            </div>
                                            <div className="d-flex flex-column align-items-start img-name-modal">
                                              <span className="text-primary-dark text-thin text-1 text-justified">Congratulations</span>
                                              <span className="text-primary-dark text-bold text-2">{best.best_canvasser_dk_name}!</span>
                                            </div>
                                            <button className="btn-modal btn btn-2xl btn-big bg-light rounded-circle text-primary" onClick={() => { closeModal('Best') }}><i className="fa fa-close"></i></button>
                                          </div>
                                          :
                                          <div className="vw-100 vh-80">
                                            <img className='img-bg-modal' style={{ width: "50%" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819689/Asset_65_gzxrzb.png'} />
                                            <img className="mt-3 rounded-pill img-photo-modal" alt="user" src={best.best_canvasser_dk_photo ? best.best_canvasser_dk_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                            <div className="d-flex flex-column align-items-center img-title-modal fs-3">
                                              <span className="text-thin text-justified text-brown">Canvasser of the <b className="text-bold">Month</b></span>
                                              {/* <span className="text-brown text-justified">of the <b className="text-bold">Month</b></span> */}
                                              <span className="text-brown text-justified text-bold">Most doors knocked</span>
                                            </div>
                                            <div className="d-flex flex-column align-items-start img-name-modal">
                                              <span className="text-primary-dark text-thin text-1 text-justified">Congratulations</span>
                                              <span className="text-primary-dark text-bold text-2">{best.best_canvasser_dk_name}!</span>
                                            </div>
                                            <button className="btn-modal btn btn-2xl btn-big bg-light rounded-circle text-primary" onClick={() => { closeModal('Best') }}><i className="fa fa-close"></i></button>
                                          </div>
                                      }
                                    </div>
                                    :
                                    <div>
                                      {
                                        best.type === 'Week'
                                          ?
                                          <div className="vw-100 vh-80">
                                            <img className='img-bg-modal' style={{ width: "50%" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819689/Asset_65_gzxrzb.png'} />
                                            <img className="mt-3 rounded-pill img-photo-modal" alt="user" src={best.best_canvasser_app_photo ? best.best_canvasser_app_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                            <div className="d-flex flex-column align-items-center img-title-modal fs-3">
                                              <span className="text-thin text-justified text-brown">Canvasser of the <b className="text-bold">Week</b></span>
                                              {/* <span className="text-brown text-justified">of the <b className="text-bold">Week</b></span> */}
                                              <span className="text-brown text-justified text-bold">Most appointments</span>
                                            </div>
                                            <div className="d-flex flex-column align-items-start img-name-modal">
                                              <span className="text-primary-dark text-thin text-1 text-justified">Congratulations</span>
                                              <span className="text-primary-dark text-bold text-2">{best.best_canvasser_app_name}!</span>
                                            </div>
                                            <button className="btn-modal btn btn-2xl btn-big bg-light rounded-circle text-primary" onClick={() => { closeModal('Best') }}><i className="fa fa-close"></i></button>
                                          </div>
                                          :
                                          <div className="vw-100 vh-80">
                                            <img className='img-bg-modal' style={{ width: "50%" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704819689/Asset_65_gzxrzb.png'} />
                                            <img className="mt-3 rounded-pill img-photo-modal" alt="user" src={best.best_canvasser_app_photo ? best.best_canvasser_app_photo : 'https://res.cloudinary.com/drholseqp/image/upload/v1692390843/images-logos/user-big_vmdeb6.webp'} style={{ objectFit: "cover" }} width={140} height={140} />
                                            <div className="d-flex flex-column align-items-center img-title-modal fs-3">
                                              <span className="text-thin text-justified text-brown">Canvasser of the <b className="text-bold">Month</b></span>
                                              {/* <span className="text-brown text-justified">of the <b className="text-bold">Month</b></span> */}
                                              <span className="text-brown text-justified text-bold">Most appointments</span>
                                            </div>
                                            <div className="d-flex flex-column align-items-start img-name-modal">
                                              <span className="text-primary-dark text-thin text-1 text-justified">Congratulations</span>
                                              <span className="text-primary-dark text-bold text-2">{best.best_canvasser_app_name}!</span>
                                            </div>
                                            <button className="btn-modal btn btn-2xl btn-big bg-light rounded-circle text-primary" onClick={() => { closeModal('Best') }}><i className="fa fa-close"></i></button>
                                          </div>
                                      }
                                    </div>
                            }
                          </div>
                      )
                    })
                  }
                </div>
                <button aria-label="Anterior" className="carousel-control-prev" type="button" data-bs-target="#myCarouselbest" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true" />
                </button>
                <button id="apreta" aria-label="Siguiente" className="carousel-control-next" type="button" data-bs-target="#myCarouselbest" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true" />
                </button>
              </div>
            </div>
        }

      </Modal>
    </div>
  );
};
