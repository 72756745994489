import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';


import { LoadingSpinnerWhite } from '../ui/LoadingSpinnerWhite';
import { fetchConToken } from '../../helpers/fetch';
import { useEffect } from 'react';
import { format, isValid } from 'date-fns';
import { LoadingSpinnerBlue } from '../ui/LoadingSpinnerBlue';

export const GraphCustomTab = ({ id }) => {
  const { t } = useTranslation();
  // const [date, setDate] = useState([new Date(), new Date()]);
  const [options, setOptions] = useState(null);
  const [options2, setOptions2] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [date, setDate] = useState(["", ""]);

  const datee = format(new Date(), 'MM-dd-yyyy HH:mm');

  useEffect(() => {
    if (isValid(datee)) {
      setDate([datee, datee])
    }
  }, []);

  const onDateChange = (e) => {
    setIsLoading(true);
    setDate(e)
    const getGraph = async () => {
      const body = await fetchConToken(
        `userdata/userdatahistory/custom/${id}`,
        { date: e },
        'POST'
      );
      setOptions({
        chart: {
          type: 'area',
        },
        title: {
          text: 'Custom doors knocked',
          style: {
            color: '#0d6dfd'
          }
        },
        series: [{
          data: body?.hcData
        }],
        plotOptions: {
          series: {
            pointStart: body?.hc && new Date(body?.hc[0]?.date).getTime(),
            pointInterval: 24 * 3600 * 1000
          }
        },
        legend: {
          enabled: false
        },
        yAxis: [{
          className: 'highcharts-color-0',
          title: {
            text: "Doors knocked",
            style: {
              color: 'black'
            }
          },
          labels: {
            style: {
              color: 'black'
            }
          }
        }],
        xAxis: [{
          className: 'highcharts-color-1',
          title: {
            text: "Time frame",
            style: {
              color: 'black'
            }
          },
          crosshair: {
            width: 1
          },
          type: 'datetime',
          labels: {
            format: "{value:%d %m }",
            style: {
              color: 'black'
            }
          },
        }]
      });
      setOptions2({
        chart: {
          type: 'area',
        },
        title: {
          text: 'Custom appointments',
          style: {
            color: '#0d6dfd'
          }
        },
        series: [{
          data: body?.hcDataApp
        }],
        plotOptions: {
          series: {
            pointStart: body?.hcApp && new Date(body?.hcApp[0]?.date).getTime(),
            pointInterval: 24 * 3600 * 1000
          }
        },
        legend: {
          enabled: false
        },
        yAxis: [{
          className: 'highcharts-color-0',
          title: {
            text: "Appointments",
            style: {
              color: 'black'
            }
          },
          labels: {
            style: {
              color: 'black'
            }
          }
        }],
        xAxis: [{
          className: 'highcharts-color-1',
          title: {
            text: "Time frame",
            style: {
              color: 'black'
            }
          },
          crosshair: {
            width: 1
          },
          type: 'datetime',
          labels: {
            format: "{value:%d %m }",
            style: {
              color: 'black'
            }
          },
        }]
      })
      setIsLoading(false);
    }
    if (e !== null) {
      getGraph()
    } else {
      setIsLoading(false);
    }
  }

  return (
    <Tabs selectedTabClassName="bg-primary-dark text-light rounded-pill">
      <TabList className={isMobile ? 'text-light font-speciallblue-tabmycards scrollable-container-mobile-2' : 'd-flex justify-content-center align-items-center text-light font-special-scroll scrollable-container-2'}>
        <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>Doors Knocked</Tab>
        <Tab className='bg-primary text-light rounded-pill py-1 px-4 list-unstyled m-1'>Appointments</Tab>
      </TabList>

      <TabPanel>
        <div className="text-center mb-3 w-100">
          <DateRangePicker className={"bg-light rounded-3 mb-3"} onChange={(e) => { onDateChange(e) }} value={date} />
          {isLoading
            ?
            <LoadingSpinnerBlue disableCenter={true} />
            :
            <div>
              {
                options &&
                <HighchartsReact
                  highcharts={Highcharts}
                  options={options}
                  containerProps={{ className: 'chart', style: { height: "330px" } }}
                />
              }
            </div>
          }
        </div>
      </TabPanel>
      <TabPanel>
        <div className="text-center mb-3 w-100">
          <DateRangePicker className={"bg-light rounded-3 mb-3"} onChange={(e) => { onDateChange(e) }} value={date} />
          {isLoading
            ?
            <LoadingSpinnerBlue disableCenter={true} />
            :
            <div>
              {
                options &&
                <HighchartsReact
                  highcharts={Highcharts}
                  options={options2}
                  containerProps={{ className: 'chart', style: { height: "330px" } }}
                />
              }
            </div>
          }
        </div>
      </TabPanel>
    </Tabs>
  );
};
