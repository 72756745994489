import { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
// import Select from 'react-select';

// import { uiCloseModalAddDeal } from "../../actions/ui";
// import { useForm } from "../../hooks/useForm";
// import useSWR from "swr";
import "../adddealmodal.css";
import { format, isValid } from "date-fns";
import { uiCloseModalAddSitDown } from "../../../actions/ui";
import { fetchConToken } from "../../../helpers/fetch";
import Select from 'react-select';
import colourStyles from "../../../helpers/selectStyles";
// import colourStyles from '../../helpers/selectStyles';

const customStyles = {};
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

export const AddSitDownModal = ({ setDeals, deals }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const { modalOpenAddSitDown, userDeal } = useSelector((state) => state.ui);
  // const [formUserValues, handleUserInputChange] = useForm({
  //   rName: '',
  //   rLastName: '',
  // });

  // const { rName, rLastName } = formUserValues;

  const datee = format(new Date(), 'MM-dd-yyyy HH:mm');

  const [date, setDate] = useState('');
  const handleDateChange = (e) => {
    setDate(e);
  };

  useEffect(() => {
    if (isValid(datee)) {
      setDate(datee)
    }
  }, []);

  // const { data: usersApi } = useSWR("auth/users/canvassersandclosers");
  // const users = [];
  // if (usersApi?.users) {
  //   const usersLen = usersApi?.users.length
  //   for (let i = 0; i < usersLen; i++) {
  //     const user = {
  //       value: usersApi?.users[i].id,
  //       label: usersApi?.users[i].name + ' ' + usersApi?.users[i].last_name
  //     }
  //     users.push(user);
  //   }
  // }
  // const [user, setUser] = useState(null);
  // const handleUser = (e) => {
  //   setUser(e);
  // };

  // const [isSelfGen, setIsSelfGen] = useState(false);
  // const handleIsSelfGen = (e) => {
  //   setIsSelfGen(!isSelfGen);
  // };

  const types = [
    { value: 'SD', label: `Sit Down` },
    { value: 'CD', label: `CD` },
    { value: 'FC', label: 'Fail Credit' },
  ];
  const [type, setType] = useState({ value: 'SD', label: `Sit Down` });
  const handleType = (e) => {
    setType(e);
  };




  const closeModal = useCallback(() => {
    dispatch(uiCloseModalAddSitDown());
  }, [dispatch]);

  const handleCreate = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    // if (rName === "" || rLastName === "") {
    //   setIsSaving(false);
    //   return Swal.fire(
    //     "Error",
    //     t('errors.fields_required'),
    //     "error"
    //   );
    // }
    // if (!isSelfGen) {
    //   if (!user) {
    //     setIsSaving(false);
    //     return Swal.fire(
    //       "Error",
    //       'You must select a canvasser',
    //       "error"
    //     );
    //   }
    // }
    if (!date) {
      setIsSaving(false);
      return Swal.fire(
        "Error",
        'You must select a date',
        "error"
      );
    }
    const createUserDeal = async (status, date, closerId) => {
      const body = await fetchConToken(`auth/users/sitdowns/create`, { status, date, closerId, type: type.value }, 'POST');
      if (body.ok) {
        closeModal();
        Swal.fire(t('success.title'), "Sit Down created", "success");

        //BUSCANDO
        const objectFinded = deals.find(x => x.user.id === closerId);
        //AUMENTANDO
        // if (is_selfgen) {
        //   objectFinded.user.deals = objectFinded.user.deals + 1;
        //   objectFinded.user.selfgens = objectFinded.user.selfgens + 1;
        // } else {
        objectFinded.user.sitdowns = objectFinded.user.sitdowns + 1;
        // }
        //AGREGANDO ITEM
        objectFinded.deals.push(body.userDeal);
        //CREATING NEW ARRAY
        const newDeals = deals.map((obj) => {
          return obj.user.id === closerId ? objectFinded : obj;
        });
        //REPLACING
        setDeals(newDeals);
        setIsSaving(false);
      } else {
        setIsSaving(false);
        Swal.fire("Error", body.msg, "error");
      }
    }
    createUserDeal('Closed', date, userDeal.id);
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Modal
        isOpen={modalOpenAddSitDown}
        onRequestClose={closeModal}
        style={customStyles}
        closeTimeoutMS={200}
        className="adddealmodal"
        ariaHideApp={process.env.NODE_ENV === "test"}
      >
        <div className="p-3">
          <div className="d-flex flex-column justify-content-center align-items-center text-center">
            <b style={{ fontSize: "30px" }}>Create sit down</b>
          </div>
          <hr className="border border-dark" />
          <div className="d-flex flex-column justify-content-center align-items-center w-100">
            <div className="w-100 d-flex flex-column align-items-center justify-content-center">
              <form onSubmit={handleCreate} className="w-95">
                <div className="form-group">
                  <label className='text-dark'>{t('labels.date')}</label>
                  <DateTimePicker
                    onChange={handleDateChange}
                    value={date}
                    className="form-control mb-2"
                    format='MM-dd-yyyy HH:mm'
                  />
                </div>
                <div className="form-group mb-3">
                  <label className='text-light'>Type</label>
                  <div className="w-100">
                    <Select styles={colourStyles} options={types} value={type} onChange={handleType} />
                  </div>
                </div>
                {/* <div className="form-group mb-2">
                  <label>Lead Name</label>
                  <input
                    type="text"
                    className="mb-2 form-control"
                    placeholder='Name'
                    name="rName"
                    value={rName}
                    onChange={handleUserInputChange}
                  />
                </div> */}
                {/* <div className="form-group mb-2">
                  <label>Lead Last Name</label>
                  <input
                    type="text"
                    className="mb-2 form-control"
                    placeholder='Last Name'
                    name="rLastName"
                    value={rLastName}
                    onChange={handleUserInputChange}
                  />
                </div> */}
                {/* <div className="form-group mb-3">
                  <label className='form-label text-dark'>Self gen?</label>
                  <div className="mb-2 d-flex justify-content-center align-items-center form-control w-25 form-check form-switch">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value={isSelfGen}
                      checked={isSelfGen}
                      onChange={handleIsSelfGen}
                    />
                  </div>
                </div>
                {!isSelfGen && <div className="form-group mb-2">
                  <label className='text-dark'>Canvasser</label>
                  <div className="w-100">
                    <Select placeholder={t('select.placeholder')} styles={colourStyles} options={users} value={user} onChange={handleUser} />
                  </div>
                </div>} */}
                <div className="form-group d-flex justify-content-center mb-1">
                  <button type="submit" className="btn btn-primary btn-bright" disabled={isSaving}>
                    <i className="fa fa-floppy-disk"></i> {t('buttons.save')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
