import useSWR from "swr";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { format } from "date-fns";
import { setPost, uiOpenModalPost } from "../../actions/ui";
import { useDispatch } from "react-redux";
import { PostModal } from "./PostModal";

export const PostScreen = ({ id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: posts, error, isLoading } = useSWR(`posts`);
  const date = new Date();
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const openModal = (post) => {
    dispatch(setPost(post));
    dispatch(uiOpenModalPost());
  }
  return (
    <div className='text-center'>
      {
        isMobile
          ?
          <div className={isMobile ? "w-100" : "w-100"}>
            <div className='w-100 bg-primary d-flex justify-content-start'>
              <span className='text-3 text-bold text-light ms-5'>Announcements</span>
            </div>
            <div className='w-100 d-flex justify-content-start mt-3'>
              <span className='text-2 text-bold text-light ms-5'>{monthNames[date.getMonth()]} / {new Date().getFullYear()}</span>
            </div>
            <img className='fixed-bottom' style={{ width: "20vw" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704470527/Asset_42_qdamfo.png'} />
            {/* <img className='fixed-bottom img-decorate-logo2' style={{ width: "20vw" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704397960/Asset_36_coa2gd.png'} /> */}
            <div className='d-flex flex-column justify-content-center align-items-center text-center text-light w-100 bg-invi-blur'>
              {
                posts?.posts?.length > 0 && posts?.posts?.map((post, j) => {
                  return (
                    <div role="button" onClick={() => { openModal(post) }} key={`key-post-${j}`} className='d-flex justify-content-center align-items-center w-80 m-2'>
                      <div className="d-flex flex-row justify-content-center align-items-center w-100 p-2 rounded-3">
                        {/* <div> */}
                        {/* <img style={{ objectFit: "cover" }} className="pre-img" src={'https://res.cloudinary.com/drholseqp/image/upload/v1704472720/qweqweqwe_grwjnh.png'} width={100} height={100} /> */}
                        <img style={{ objectFit: "cover" }} className="rounded-semi-circle-right post-img-mobile" src={post.url} width={100} height={100} />
                        {/* </div> */}
                        {/* <div className="d-flex flex-column align-items-start justify-content-center m-3">
                          <span className='m-1'><b>{post.title.toUpperCase()}</b></span>
                          <p className='m-1' align="justify">{post.description}</p>
                          <div className="align-self-start align-bottom">
                            <span style={{ fontSize: "12px" }}>{format(new Date(post.date), 'MM/dd/yyyy')}</span>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <PostModal />
          </div>
          :
          <div className={isMobile ? "w-100" : "w-100"}>
            <div className='w-100 bg-primary d-flex justify-content-start'>
              <span className='text-2 text-bold text-light ms-5'>Announcements</span>
            </div>
            <div className='w-100 d-flex justify-content-start mt-3'>
              <span className='text-1 text-bold text-light ms-5'>{monthNames[date.getMonth()]} / {new Date().getFullYear()}</span>
            </div>
            <img className='fixed-bottom' style={{ width: "10vw" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704470527/Asset_42_qdamfo.png'} />
            {/* <img className='fixed-bottom img-decorate-logo2' style={{ width: "20vw" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704397960/Asset_36_coa2gd.png'} /> */}
            <div className='d-flex flex-column justify-content-center align-items-center text-center text-light w-100 bg-invi-blur'>
              {
                posts?.posts?.length > 0 && posts?.posts?.map((post, j) => {
                  return (
                    <div role="button" onClick={() => { openModal(post) }} key={`key-post-${j}`} className='d-flex justify-content-center align-items-center w-50 m-2'>
                      <div className="d-flex flex-row justify-content-center align-items-center w-100 p-2 rounded-3">
                        {/* <div> */}
                        {/* <img style={{ objectFit: "cover" }} className="pre-img" src={'https://res.cloudinary.com/drholseqp/image/upload/v1704472720/qweqweqwe_grwjnh.png'} width={100} height={100} /> */}
                        <img style={{ objectFit: "cover" }} className="rounded-semi-circle-right post-img" src={post.url} width={100} height={100} />
                        {/* </div> */}
                        {/* <div className="d-flex flex-column align-items-start justify-content-center m-3">
                          <span className='m-1'><b>{post.title.toUpperCase()}</b></span>
                          <p className='m-1' align="justify">{post.description}</p>
                          <div className="align-self-start align-bottom">
                            <span style={{ fontSize: "12px" }}>{format(new Date(post.date), 'MM/dd/yyyy')}</span>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <PostModal />
          </div>
        // <div className="d-flex flex-column justify-content-center align-items-center">
        //   <h1 className="text-light h1">Announcements</h1>

        // </div>
      }
    </div>
  );
};
