import React from 'react';

export const Chip = ({ label, onDelete }) => {
    return (
        <div className="p-2 bg-primary-dark rounded-3 m-2 col d-flex justify-content-center align-items-center">
            <span className='text-light text-bold text-0-5'>{label}</span>
            {onDelete && (
                <button className="btn btn-danger btn-sm ms-2 text-0-5 text-bold" onClick={onDelete}>
                    <i className='fa fa-xmark'></i>
                </button>
            )}
        </div>
    );
}