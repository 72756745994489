import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

import { setUserDeal, uiOpenModalAddDeal } from '../../actions/ui';
import { LoadingSpinnerWhite } from '../ui/LoadingSpinnerWhite';

export const RewardAdminItems = ({ items, loading, firstDate, lastDate }) => {
  const dispatch = useDispatch();
  // const [blockButtons, setBlockButtons] = useState(false);
  if (loading) {
    return <LoadingSpinnerWhite />
  }
  const approve = async (user) => {
    dispatch(uiOpenModalAddDeal())
  };
  return (
    <div className='container-fluid table-responsive mb-2'>
      <>
        <div className='p-3'>
          <span className='text-light text-bold text-2'>Week: {firstDate} - {lastDate}</span>
        </div>
        {items && <table className={isMobile ? "d-block overflow-scroll table table-sm table-bordered bg-light table-striped rounded rounded-3 overflow-hidden" : "table table-striped bg-light table-hover overflow-hidden"}>
          <thead className='bg-dark text-light align-middle'>
            <tr>
              <th className="text-center" scope="col">User</th>
              <th className="text-center" scope="col">Office</th>
              <th className="text-center" scope="col">Role</th>
              <th className="text-center" scope="col">Deals</th>
              <th className="text-center" scope="col">Status</th>
              <th className="text-center" scope="col">Current Deals</th>
            </tr>
          </thead>

          {items.length > 0 && items.map(user => {
            return (
              <tbody key={user.id}>
                <tr>
                  <td className="text-center text-dark">{user.name} {user.last_name}</td>
                  <td className="text-center text-dark">{user.office}</td>
                  <td className="text-center text-dark">{user.role}</td>
                  <td className="text-center text-dark">{user.deals}</td>
                  <td className="text-center text-dark">{user.status === 'Verifying' ? <i className='fa fa-clock text-warning'></i> : <i className='fa fa-check text-success'></i>}</td>
                  <td className="text-center text-dark">{user.currentDeals}</td>
                </tr>
              </tbody>
            );
          })}
        </table>}
      </>
    </div>
  );
};
