import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { uiCloseModalMotivational } from "../../actions/ui";
import Typed from 'react-typed';
import { LoadingSpinnerWhite } from "../ui/LoadingSpinnerWhite";

const customStyles = {
  // content: {
  //   top: "50%",
  //   left: "50%",
  //   right: "auto",
  //   bottom: "auto",
  //   marginRight: "-50%",
  //   transform: "translate(-50%, -50%)",
  // },
};
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

export const MotivationModal = () => {
  const dispatch = useDispatch();
  const { modalOpenMotivational } = useSelector((state) => state.ui);

  const motivationalPhrases = [
    "No hagas lo que los demás hacen. Haz lo que los demás quisieran hacer y no se atreven.",
    "Si no puedes dejar de pensar en algo, no dejes de trabajar en ello.",
    "Lo importante no es lo que se promete, sino lo que se cumple.",
    "Cuando te despiertes cada mañana, piensa en qué preciado privilegio es estar vivo, respirar, pensar, disfrutar, amar.",
    "Un optimista entiende que la vida puede tener baches, pero que lleva a un lugar. Los optimistas aprenden de sus errores y fracasos y no tienen miedo de volver a fallar.",
    "Todo lo que necesitas para lograr tus objetivos ya está en ti.",
    "La gente con éxito tiene miedo. La gente con éxito tiene dudas. La gente con éxito se preocupa. Simplemente no permite que esas sensaciones les detengan.",
    "Cada día me despierto diciéndome que estoy vivo, y que eso es un milagro.",
    "Siempre sé la mejor versión de ti mismo, y la segunda mejor versión de todos los demás.",
    "Todos nuestros sueños se pueden hacer realidad si tenemos el coraje de perseguirlos.",
    "Si quieres ser feliz en esta vida tendrás que aprender a amar, a perdonar y a olvidar.",
    "Si no puedes volar, corre, si no puedes correr, camina, si no puedes caminar, gatea, pero hagas lo que hagas, sigue adelante.",
    "Podemos quejarnos de que los rosales tengan espinas o alegrarnos de que las espinas tengan rosas.",
    "La vida no se mide por tu aliento sino por los momentos que te han quitado el aliento.",
    "Pasas toda tu vida a tu lado así que aprovecha y disfruta de tu compañía.",
    "Cuanto mejor te sientas contigo mismo, menos necesidad tendrás de buscar validación.",
    "Recuerda que no solo tienes el derecho de ser un individuo: tienes la obligación de ser uno.",
    "Puedes tener todo lo que quieras si decides abandonar la creencia de que no puedes tener todo lo que quieras.",
    "Si te sientes inseguro, ¿sabes qué? Al resto del mundo le pasa igual.",
    "La confianza es un hábito que puede desarrollarse actuando como si tuvieras la confianza que deseas tener.",
    "Debes aprender las reglas del juego y luego jugar mejor que los demás.",
    "Debemos creer que tenemos un don en algo y que tenemos un objetivo por lograr.",
    "La vida nunca es fácil, pero, ¿y qué? Debemos tener perseverancia y creer en nosotros mismos.",
    "Las cosas siempre salen de la mejor manera posible para aquellas personas que se esmeran por conseguir el mejor resultado posible.",
    "En el momento que dejas de pensar en lo que puede pasar, empiezas a disfrutar de lo que está pasando.",
    "Ve con confianza en la dirección de tus sueños. Vive la vida como la has imaginado.",
    "La alegría suele entrar por esa puerta que se te había olvidado cerrar.",
    "Utiliza tu imaginación para inspirarte a lograr lo inimaginable.",
    "Debes hacer las cosas que crees que no puedes hacer.",
    "Deja marcado en tu corazón que cada día es el mejor día del año.",
    "La suerte de tu día depende de cómo lo afrontes desde primera hora de la mañana.",
    "El éxito en la vida no se mide por lo que logras sino por los obstáculos que superas.",
    "Si buscas resultados distintos, no hagas siempre lo mismo.",
    "Un viaje de diez mil kilómetros empieza por un solo paso.",
    "Todos tus sueños se pueden hacer realidad si tienes el coraje de perseguirlos.",
    "El mejor regalo que te puedes dar a ti mismo es un poco de tu propia atención.",
    "Si cambias el modo en que miras las cosas, las cosas que miras cambian.",
    "Cualquier hecho que enfrentes no es tan importante como tu actitud hacia él, ya que eso determina tu éxito o fracaso.",
    "Comienza a ser ahora lo que serás de ahora en adelante.",
    "Los retos son lo que hacen la vida interesante, y superarlos es lo que hace que la vida tenga un significado.",
    "Aquel que lo piensa mucho antes de dar un paso, se pasará toda su vida en un solo pie.",
    "Una persona que nunca se equivocó nunca intentó nada nuevo.",
    "El éxito no es la clave de la felicidad. La felicidad es la clave del éxito.",
    "La única diferencia entre un buen y un mal día es tu actitud.",
    "Pregúntate si lo que estás haciendo hoy te acerca al lugar en el que quieres estar mañana.",
    "Ser positiva en una situación negativa no es ser inocente, es ser líder.",
    "Si te sientes perdido en el mundo es porque todavía no has salido a buscarte.",
    "Todo error deja una enseñanza, toda enseñanza deja una experiencia, y toda experiencia deja una huella.",
    "Un pesimista ve dificultades en cada oportunidad: un optimista ve oportunidades en cada dificultad.",
    "Creo que no estoy dónde pretendía ir, pero he llegado al lugar donde necesita estar.",
    "El optimista ve la rosa y no las espinas, pero el pesimista se fija tanto en las espinas que no sabe que también hay una rosa.",
    "Si la montaña que subes parece cada vez más imponente es que la cima está cada vez más cerca.",
    "Sigue corriendo, no dejes que tus excusas te alcancen.",
    "Mientras más fuertes sean tus pruebas, más grandes serán tus victorias.",
    "El mejor momento para plantar un árbol era hace 20 años. El segundo mejor momento es AHORA.",
    "Si te ofrecen un asiento en un cohete no preguntes qué asiento. Solo súbete.",
    "En toda historia de éxito encontrarás a alguien que tomó una decisión valiente.",
    "La inteligencia sin ambición es como un pájaro sin sus alas.",
    "Fallar no arruina el éxito, sino que es parte de él. .",
    "Si no persigues lo que quieres, nunca lo tendrás. Si no preguntas, la respuesta siempre será no. Si no das un paso adelante, siempre estarás en el mismo lugar.",
    "Los problemas son como un martillo: si somos de vidrio, nos rompe. Si somos de hierro, nos forma.",
    "Si siempre te concentras en lo que te falta, nunca tendrás lo suficiente.",
    "Eres más fuerte de lo que crees. Más capaz de lo que alguna vez soñaste. Y a ti te aman más de lo que podrías imaginar.",
    "En toda historia de éxito encontrarás a alguien que tomó una decisión valiente.",
    "La tragedia no es no alcanzar tus objetivos, la tragedia es no tener objetivos que alcanzar.",
    "Los pesimistas solo ven un final sin esperanza. Los optimistas ven una esperanza sin final.",
    "La diferencia entre lo ordinario y lo extraordinario es un poquito de esfuerzo.",
    "No tienes que mirar toda la escalera. Para empezar, solo concéntrate en dar el primer paso.",
    "Los obstáculos son esas cosas espantosas que ves cuando quitas la mirada de tus metas.",
    "Si no haces que ocurran cosas, las cosas te ocurrirán a ti.",
    "El peor peligro para todos nosotros no es apuntar demasiado alto y no atinar, sino apuntar demasiado bajo y acertar.",
    "Las palabras convencen, el ejemplo arrastra.",
    "Céntrate hacia dónde quieres ir, no en lo que temes.",
    "Quien no arriesga, no gana.",
    "Piensa, sueña, cree y atrévete.",
    "Asegúrate de que colocas tus pies en el lugar correcto, y luego mantente firme.",
    "A veces a lo único que tienes que abrir la puerta es a una buena idea.",
    "La mejor manera para empezar es callándote y empezando a hacer.",
    "Transforma tus heridas en sabiduría.",
    "El secreto para salir adelante es comenzar.",
    "La edad no es barrera. Es una limitación que pones en tu mente.",
    "Con autodisciplina casi todo es posible.",
    "Tienes que esperar cosas de ti mismo antes de poder hacerlas.",
    "Sé el cambio que deseas ver en el mundo.",
    "Cuanto más sepas sobre quién eres, menos cosas te harán enfadar.",
    "La disciplina es el puente entre tus metas y tus logros.",
    "1.000 palabras jamás dejarán la misma marca que una sola promesa.",
    "Si la montaña que subes parece cada vez más imponente es que la cima está cada vez más cerca.",
    "Nuestra envidia siempre dura más que la felicidad de aquellos a quienes envidiamos.",
    "Los problemas no son señales de stop, son señalizaciones.",
    "Si el plan no funciona, cambia el plan, pero no cambies la meta.",
    "Las cometas se elevan contra y no a favor del viento.",
    "No es la montaña lo que debemos conquistar sino a nosotros mismos.",
    "Lo importante no es lo que se promete, sino lo que se cumple.",
    "El fracaso es otra piedra hacia la grandeza.",
    "La confianza viene no de tener siempre razón, sino de no temer equivocarse.",
    "El momento que da más miedo es siempre justo antes de empezar.",
    "Eres lo que haces, no lo que dices que harás.",
    "Quererte a ti mismo ahora mismo, tal y como eres, es darte ya el cielo.",
    "Ningún mar en calma hizo experto a un marinero.",
    "Tu mejor profesor es tu mayor error.",
    "Los grandes árboles de la naturaleza surgieron de indefensas semillas.",
    "Cualquier cosa que te plante la vida florécela con gracia.",
    "Queda terminantemente prohibido levantarse sin ilusiones.",
    "Si te cansas, aprende a descansar, no a renunciar.",
    "Cuanto más duramente trabajo, más suerte tengo.",
    "Cuéntamelo y me olvidaré. enséñamelo y lo recordaré.",
    "A veces la adversidad es lo que necesitas encarar para ser exitoso.",
    "Ejecuta tus conocimientos con la maestría del que sigue aprendiendo.",
    "Tu actitud, no tu aptitud, determinará tu altitud.",
    "Deja que cada hombre ejerza el arte que domina.",
    "El valor de una idea radica en su uso.",
    "Haz algo hoy que tu futuro yo te agradezca.",
    "Si no es ahora, ¿cuándo?",
    "Actúa como si marcaras la diferencia. Y es que, en realidad, la marcas.",
    "¿Tomas algo para ser feliz? Sí, decisiones.",
    "Cuando pierdas, no pierdas la lección.",
    "Deséalo, espéralo, suéñalo, pero por todos los medios… ¡Hazlo!",
    "Parece imposible hasta que se hace.",
    "Inténtalo y fracasa, pero no fracases en intentarlo.",
    "No hay árbol que el viento no haya sacudido.",
    "De todas las cosas que llevas puestas, tu actitud es la más importante.",
    "Si puedes soñarlo, puedes hacerlo.",
    "Hay algo en ti que el mundo necesita.",
    "Prohibido rendirse.",
    "Si te caes tres veces, levántate cuatro.",
    "Cree en ti misma y serás imparable.",
    "Inténtalo y fracasa, pero no fracases en intentarlo.",
    "No lo pienses. Házlo.",
    "El fracaso es éxito si aprendemos de él.",
    "El sufrimiento siempre nos enseña algo.",
    "En medio de la dificultad se esconde la oportunidad.",
    "Donde no hay lucha, no hay fuerza.",
    "No busques los errores, busca un remedio.",
    "Céntrate hacia dónde quieres ir, no en lo que temes.",
    "Si crees que puedes, ya estás a medio camino.",
    "El 80% del éxito se basa simplemente en insistir.",
    "Cuanto más violenta es una tormenta, más rápido pasa.",
    "No vas a dominar el resto de tu vida en un día. Relájate.",
    "Cambiar de respuesta es evolución. Cambiar de pregunta es revolución.",
    "Para ser irremplazable, uno debe buscar siempre ser diferente.",
    "Sé tú mismo: el resto de papeles ya están cogidos.",
    "La innovación distingue al líder del seguidor.",
    "No pares cuando estés cansado. Para cuando hayas terminado.",
    "Perder no es mi enemigo. El miedo a perder es mi enemigo.",
    "El mundo le aplaude a los ganadores. ¡Únete a ellos!",
    "Tú tienes el derecho de triunfar. Está en ti el ejercer ese derecho.",
    "Tú eres lo que piensas que eres. Positiviza tu diálogo interior y tu mundo cambiará.",
    "El primer paso para alcanzar el éxito es creer que puedes alcanzarlo.",
    "Si quieres que te traten como un ganador, intenta siempre verte como uno.",
    "Visualiza tu éxito futuro y toma acción ya para hacerlo realidad.",
    "Nada te acerca más al éxito que tu decisión inconmovible de alcanzarlo.",
    "“Nunca te rindas. Aun ante las adversidades, piensa que el éxito puede estar a la vuelta de la esquina.”",
    "Tal vez no puedas cambiar al mundo, pero sí tienes absoluto poder para cambiar tu mundo.",
    "No te compares con otra persona: compite, sí, pero contra tu propio potencial.",
    "Tu mente es la mejor computadora del mundo. ¡Prográmala para triunfar!",
    "“El conocimiento es sólo poder en potencia.” ¡Sácale provecho a ese potencial!",
    "Para triunfar en la vida, siempre necesitarás de los demás. Llevarse bien con la gente es una estrategia ganadora.",
    "Enfócate. No diluyas tu potencial. Ponte metas alcanzables y persíguelas constantemente.",
    "Cuida tu empaque personal. El mundo te juzgará primero por tu apariencia y tu presentación.",
    "La primera batalla del éxito es intracraneal. Tienes que convencerte de que puedes lograrlo.",
    "Para una mente triunfadora, cada situación trae siempre consigo una oportunidad.",
    "Los minutos cuentan: enfócate en las acciones cuyos resultados te traigan beneficios.",
    "El éxito no es cuestión de casualidades sino de “causalidades”. El éxito se basa en causa y efecto.",
    "Eres el producto de tus pensamientos y de tus acciones. Modifícalos y tu vida cambiará.",
    "“La ´suerte´ se da cuando la preparación y la oportunidad se juntan.” ¿Estás preparado para cosechar tu ´suerte´?",
    "No lo pospongas. ¡Hazlo ya! Recuerda que la vida se nos va en minutos perdidos.",
    "“Qué extraño: ¡entre más trabajo, más suerte tengo”. - Henry Ford.",
    "“El éxito es 1 por ciento inspiración y 99 por ciento transpiración”. - Thomas Alba Edisson.",
    "Si ves el mundo muy gris, puede ser que estás volando muy bajo. “Elévate: arriba de las nubes el cielo siempre es azul.”",
    "El 85% de tu éxito dependerá de una buena actitud. Todo lo demás representa sólo el 15 por ciento.",
    "Garantízate tu desarrollo en la empresa: contribuye diariamente con su éxito.",
    "¡Acción! El mundo pertenece a los que hacen, no a los observadores inactivos. No te quedes en la acera. ¡Únete al desfile!",
    "Sonríele a la vida, y la vida te sonreirá a ti.",
    "El cliente es realmente quien paga tu salario. Agradécele tratándolo con cortesía y respeto.",
    "Un rostro sonriente genera reacciones positivas en el cliente.",
    "Hacer contacto visual con tu cliente, te ayudará a proyectarte positivamente.",
    "Al dar la mano, hazlo con calidez y proyecta en ello tu ejecutividad.",
    "La gran diferencia ante nuestros clientes la hará la calidad del servicio que les brindemos.",
    "¡Corre la milla extra! Haz por el cliente más de lo que él espera que hagas.",
    "Servicio al Cliente también es “impacto visual”. ¡Cuida tu área de trabajo!",
    "Agilidad y dinamismo: el binomio clave para tu impacto personal ante el cliente.",
    "Ante ese cliente “especial”, enfócate en la solución. Eso es lo que cuenta para él.",
    "Las normas básicas de cortesía continúan plenamente vigentes en el Siglo XXI. ¡Úsalas continuamente!",
    "Apréndete y usa el nombre del cliente. Nada suena mejor a sus oídos.",
    "Hazte un experto en los productos y servicios de la empresa. Ello es crucial en el servicio.",
    "Escucha a tu cliente. Él es el experto en sus necesidades.",
    "Al hablar por teléfono, pon una sonrisa en tu voz. Realmente se siente la diferencia.",
    "Para el cliente, eres un asesor. Compórtate como tal.",
    "Personaliza tu atención al cliente tanto como puedas. Todos queremos ser tratados como personas.",
    "Empatía: ¡Bríncate el mostrador! Ponte en los zapatos del cliente.",
    "Proyecta la sensación de que trabajas en equipo. Eso también lo percibe el cliente.",
    "Proacciona. Ve un paso adelante del cliente.",
    "Mercadeo de Relaciones. No se trata de una sola venta, sino de hacer clientes de por vida.",
    "Si eres un líder, el cliente te seguirá. Comienza por ser el primero en sonreír.",
    "Hazte memorable para el cliente. Los pequeños detalles hacen que no te olviden.",
  ]

  function getRandomPhrase() {
    const randomIndex = Math.floor(Math.random() * motivationalPhrases.length);
    return motivationalPhrases[randomIndex];
  }

  const closeModal = () => {
    dispatch(uiCloseModalMotivational());
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Modal
        isOpen={modalOpenMotivational}
        onRequestClose={closeModal}
        style={customStyles}
        closeTimeoutMS={500}
        className={"postmodal"}
        ariaHideApp={process.env.NODE_ENV === "test"}
      >
        <div className="d-flex flex-column justify-content-start align-items-end">
          <button className="btn btn-2xl bg-light rounded-circle text-primary m-3" onClick={() => { closeModal() }}><i className="fa fa-close"></i></button>
          <div className="bg-primary-strong rounded-3 p-4 row border border-2 border-light shadow-lg">
            <div className="col">
              <Typed
                strings={[getRandomPhrase()]}
                typeSpeed={40}
                backSpeed={50}
                className="text-light text-1-8"
              />
              {/* <span className='m-1 text-light text-2 text-motivational'>{getRandomPhrase()}</span> */}
            </div>
            <div className="col-3 d-flex justify-content-center align-items-center animate__animated animate__fadeIn">
              {/* <LoadingSpinnerWhite disableCenter={true}/> */}
              <img src={"https://res.cloudinary.com/drholseqp/image/upload/v1707425511/output-onlinepngtools_i0seme.png"} width={105} height={100} />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
