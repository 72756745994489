import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { isMobile } from 'react-device-detect';
import { AdminStatsDealsGeneral } from './AdminStatsDealsGeneral';
import { AdminStatsDealsOffice } from './AdminStatsDealsOffice';
import { AdminStatsDealsAll } from './AdminStatsDealsAll';
import { AdminStatsDealsAllAutomatic } from './AdminStatsDealsAllAutomatic';
import { AdminStatsDealsAllAndCanvasser } from './AdminStatsDealsAllAndCanvasser';
import { AdminStatsDealsGeneralNew } from './AdminStatsDealsGeneralNew';
import { AdminStatsDealsGeneralOffices } from './AdminStatsDealsGeneralOffices';
import { AdminStatsDealsGeneralOfficesCards } from './AdminStatsDealsGeneralOfficesCards';
import { AdminStatsDealsGeneralNewGroup } from './AdminStatsDealsGeneralNewGroup';
import { AdminStatsDealsGeneralSelfGen } from './AdminStatsDealsGeneralSelfGen';
import { AdminStatsDealsGeneralSelfGenDesign } from './AdminStatsDealsGeneralSelfGenDesign';
import { AdminStatsDealsGeneralNewGroupDesign } from './AdminStatsDealsGeneralNewGroupDesign';
import { AdminStatsDealsGeneralNewGroupDesignCanva } from './AdminStatsDealsGeneralNewGroupDesignCanva';
import { AdminStatsDealsList } from './AdminStatsDealsList';

export const AdminStatsDeals = ({ cancels, id, office, isAdmin, isAutomatic }) => {

  return (
    <>
      {
        isAdmin
          ?
          <Tabs selectedTabClassName="bg-light text-dark rounded m-3">
            <TabList className={isMobile ? 'text-center text-light font-speciallblue-tabmycards' : 'text-center text-light font-special-scroll-3'}>
              <Tab>Manual Deals</Tab>
              <Tab>Manual Deals With Canvasser</Tab>
              <Tab>From Bonsai</Tab>
              <Tab>Screenshot</Tab>
              <Tab>Screenshot by offices</Tab>
              <Tab>Screenshot by offices cards</Tab>
              {/* <Tab>Screenshot group</Tab> */}
              <Tab>Screenshot group closer</Tab>
              <Tab>Screenshot group canva</Tab>
              <Tab>Screenshot group selfgen</Tab>
              {/* <Tab>By Office</Tab> */}
            </TabList>
            <TabPanel>
              <AdminStatsDealsAll cancels={cancels} id={id} officee={office} isAdmin={isAdmin} />
            </TabPanel>
            <TabPanel>
              <AdminStatsDealsList cancels={cancels} id={id} officee={office} isAdmin={isAdmin} />
              {/* <AdminStatsDealsAllAndCanvasser cancels={cancels} id={id} officee={office} isAdmin={isAdmin} /> */}
            </TabPanel>
            <TabPanel>
              <AdminStatsDealsAllAutomatic cancels={cancels} id={id} officee={office} isAdmin={isAdmin} />
            </TabPanel>
            <TabPanel>
              <AdminStatsDealsGeneralNew cancels={cancels} id={id} />
            </TabPanel>
            <TabPanel>
              <AdminStatsDealsGeneralOffices cancels={cancels} id={id} />
            </TabPanel>
            <TabPanel>
              <AdminStatsDealsGeneralOfficesCards cancels={cancels} id={id} />
            </TabPanel>
            <TabPanel>
              <AdminStatsDealsGeneralNewGroup cancels={cancels} id={id} />
            </TabPanel>
            {/* <TabPanel>
              <AdminStatsDealsGeneralNewGroupDesign cancels={cancels} id={id} />
            </TabPanel> */}
            <TabPanel>
              <AdminStatsDealsGeneralNewGroupDesignCanva cancels={cancels} id={id} />
            </TabPanel>
            <TabPanel>
              <AdminStatsDealsGeneralSelfGenDesign cancels={cancels} id={id} />
            </TabPanel>
            {/* <TabPanel>
              <AdminStatsDealsOffice cancels={cancels} id={id} />
            </TabPanel> */}
          </Tabs>
          :
          isAutomatic
            ?
            <AdminStatsDealsAllAutomatic cancels={cancels} id={id} officee={office} isAdmin={isAdmin} />
            :
            <AdminStatsDealsAll cancels={cancels} id={id} officee={office} isAdmin={isAdmin} />
        // <AdminStatsDealsOffice cancels={cancels} id={id} officee={office} isAdmin={isAdmin} />
      }
    </>
  );
};