import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { isMobile } from 'react-device-detect';
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

import { login, startGetUser } from "../../actions/auth";
import { useForm } from "../../hooks/useForm";
import { showStory, uiOpenModal, uiOpenModalBirth, uiOpenModalResetPassword } from "../../actions/ui";
import { fetchSinToken } from "../../helpers/fetch";
import { FirstModalModal } from "./FirstLoginModal";
import { Link } from "react-router-dom";
import { ModalPasswordReset } from "./ModalPasswordReset";

export const LoginScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formLoginValues, handleLoginInputChange] = useForm({
    lEmail: '',
    lPassword: '',
  });
  const { lEmail, lPassword } = formLoginValues;
  const [officeData, setOfficeData] = useState(null);

  const handleLogin = (e) => {
    e.preventDefault();
    if (lEmail === '' || lPassword === '') {
      return Swal.fire(
        "Error",
        t('errors.fields_required'),
        "error"
      );
    }

    const startLogin = async (emaill, password) => {
      const email = emaill.toLowerCase().trim();
      const body = await fetchSinToken("auth", { email, password }, "POST");
      if (body.ok) {
        // if (!body.new) {
          localStorage.setItem("token", body.token);
          localStorage.setItem("token-init-date", new Date().getTime());
          dispatch(login({ uid: body.uid, name: body.name, role: body.role, office: body.office, photo: body.photo, points: body.points }));
          dispatch(startGetUser(body.uid));
        // } else {
        //   setOfficeData(body);
        //   dispatch(uiOpenModal());
        //   dispatch(showStory(true));
          // Swal.fire("Attention", "The login is temporary disabled", "info");
        // }
      } else {
        Swal.fire("Error", body.msg, "error");
      }
    }
    startLogin(lEmail, lPassword);
  };

  const onSuccess = (res) => {
    const startGoogle = async (res) => {
      const bodi = await fetchSinToken(`auth/newgoogle`, { credential: res.credential }, "POST");
      if (bodi.isVerified) {
        if (bodi.created) {
          const startLoginGoogle = async (email, namee, lastnamee) => {
            const last_name = lastnamee.toUpperCase().trim();
            const name = namee.toUpperCase().trim();
            const role = 'office_manager';
            const body = await fetchSinToken("auth/google", { email, name, last_name, role }, "POST");
            if (body.ok) {
              localStorage.setItem("token", body.token);
              localStorage.setItem("token-init-date", new Date().getTime());
              dispatch(login({ uid: body.uid, name: body.name, role: body.role, office: body.office, photo: body.photo, points: body.points }));
              dispatch(startGetUser(body.uid));
            } else {
              Swal.fire("Error", body.msg, "error");
            }
          }
          startLoginGoogle(bodi.email, bodi.name, bodi.last_name);
        } else {
          setOfficeData(bodi);
          dispatch(uiOpenModal());
        }
      }
    }
    startGoogle(res);
  };
  const onError = (err) => {
    Swal.fire("Error", t('errors.google_login'), "error");
  };
  const resetPassword = (err) => {
    dispatch(uiOpenModalResetPassword())
  };

  const [isHidden, setIsHidden] = useState(true);

  const setHidden = () => {
    if (isHidden) {
      setIsHidden(false);
    } else {
      setIsHidden(true);
    }
  };

  const init = useRef(null);
  useEffect(() => {
    if (init) {
      init?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  return (
    <>
      {isMobile ?
        <div>
          <h1 className="d-flex align-items-center justify-content-center mb-3" style={{ color: "#000" }}><img style={{ objectFit: "cover" }} width={300} height={300} src={'https://res.cloudinary.com/drholseqp/image/upload/v1692714469/images-logos/logocolor_whitebg_kca71b.webp'} className="w-100" alt="Blue Energy Solar Logo" /></h1>
          <div className='w-100 position-absolute top-50 start-50 mt-5 pt-5 translate-middle d-flex flex-column justify-content-center align-items-center text-center' data-aos="fade-up" data-aos-duration="1000">
            <span className="d-flex align-items-center justify-content-center color-text-nav-bold mb-3" style={{ color: "#000" }}><img className="me-2" src={'https://res.cloudinary.com/drholseqp/image/upload/v1692714469/images-logos/logo-grey_mj34es.webp'} width={50} height={50} alt="Blue Energy Solar Logo" /> Please login.</span>
            <form onSubmit={handleLogin} autoComplete="new-password">
              <div className="form-group d-flex flex-column justify-content-start align-items-center mb-2">
                <label className="h4 w-20 color-dark">Email: </label>
                <input
                  type="text"
                  className="p-1 ms-1 border-0 rounded-3 bg-grey w-100"
                  name="lEmail"
                  autoComplete="new-password"
                  value={lEmail}
                  onChange={handleLoginInputChange}
                />
              </div>
              <div className="form-group d-flex flex-column justify-content-start align-items-center mb-3">
                <label className="h4 w-100 color-dark ">Password: </label>
                <div className="d-flex flex-row justify-content-start align-items-center">
                  <input
                    type={isHidden ? "password" : "text"}
                    className="p-1 ms-1 border-0 rounded-3 bg-grey"
                    name="lPassword"
                    autoComplete="new-password"
                    value={lPassword}
                    onChange={handleLoginInputChange}
                  />
                  <button style={{ width: "100%", height: "100%" }} className={isHidden ? 'btn btn-dark btn-sm ms-1' : 'btn btn-light btn-sm ms-1'} onClick={setHidden} type="button">{isHidden ? <i className="fa-solid fa-eye"></i> : <i className="fa-solid fa-eye-slash"></i>}</button>
                </div>
              </div>
              <div className="form-group d-flex justify-content-center">
                <input type="submit" className="btn btn-dark btn-bright" value={t('buttons.login')} />
              </div>
            </form>
            <div className="form-group d-flex justify-content-center mt-4">
              <h5>Forgot password? <b className="text-primary" onClick={resetPassword}>HERE</b></h5>
            </div>
            <div className="form-group d-flex justify-content-center mt-2">
              <h5>{t('auth.login.go_to_register')} <Link className='text-decoration-none text-primary' to='/register'><b>REGISTER</b></Link></h5>
            </div>

          </div>
          {officeData && <FirstModalModal data={officeData} />}
          <ModalPasswordReset />
        </div>
        :
        <div ref={init} className="overflow-hidden">
          {/* <img className='img-bea-login' style={{ width: "40%" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1703096690/g_bxfj43.webp'} /> */}
          <img className='img-rare-login animate__animated animate__slideInRight' style={{ width: "10%" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704224589/Asset_1_zwlgd6.png'} />
          <img className='img-rare-login2 animate__animated animate__slideInLeft' style={{ width: "10%" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704224590/Asset_2_zo1wyw.png'} />
          <img className='img-bg-login' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704224590/Asset_3_pm6wmk.png'} />
          <img className='img-bg-logo-login' style={{ width: "24%" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704232784/Asset_4_jzjvwn.png'} />
          <img className='main-title-login' style={{ width: "18%" }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1704224589/Asset_7_wkarqy.png'} />
          {/* <div id="titleUp" className={'d-flex flex-column fs-1 main-title-login text-primary animate__animated animate__slideInDown'}>
            <span className='text-bold bigger-login'>Let's <b className="text-primary-dark text-bold">go!</b></span>
          </div> */}
          {/* <div className="d-flex justify-content-center align-items-center">
            <img width={300} height={100} src={'https://res.cloudinary.com/drholseqp/image/upload/v1701717930/images-logos/beacademy_blue_bka1eg.png'} alt="Blue Energy Solar Logo" />
          </div> */}
          <div className='mt-5 d-flex flex-column justify-content-center align-items-center text-center main-form-login animate__animated animate__slideInRight'>
            <span className="d-flex align-items-center justify-content-center color-text-nav-bold mb-3 fs-1"><b className="text-light text-bold">Please login</b></span>
            <form onSubmit={handleLogin} autoComplete="new-password">
              <div className="form-group d-flex flex-column justify-content-start align-items-center mb-2">
                <label className="h4 w-20 text-light">Email: </label>
                <input
                  type="text"
                  className="p-1 ms-1 border-0 rounded-3 w-100"
                  name="lEmail"
                  autoComplete="new-password"
                  value={lEmail}
                  onChange={handleLoginInputChange}
                />
              </div>
              <div className="form-group d-flex flex-column justify-content-start align-items-center mb-3">
                <label className="h4 w-100 text-light">Password: </label>
                <div className="d-flex flex-row justify-content-start align-items-center">
                  <input
                    type={isHidden ? "password" : "text"}
                    className="p-1 ms-1 border-0 rounded-3"
                    name="lPassword"
                    autoComplete="new-password"
                    value={lPassword}
                    onChange={handleLoginInputChange}
                  />
                  <button style={{ width: "100%", height: "100%" }} className={isHidden ? 'btn btn-dark btn-sm ms-1' : 'btn btn-light btn-sm ms-1'} onClick={setHidden} type="button">{isHidden ? <i className="fa-solid fa-eye"></i> : <i className="fa-solid fa-eye-slash"></i>}</button>
                </div>
              </div>
              <div className="form-group d-flex justify-content-center">
                <input type="submit" className="btn btn-primary border-primary-dark bg-primary-dark text-light text-bold btn-bright" value="Login" />
              </div>
            </form>
            <div className="form-group d-flex justify-content-center mt-4 text-light">
              <h5>Forgot password? Click <b className="text-light" onClick={resetPassword}>HERE</b></h5>
            </div>
            <div className="form-group d-flex justify-content-center mt-2 text-light">
              <h5>Don't have an account? <Link className='text-decoration-none text-light' to='/register'><b>REGISTER</b></Link></h5>
            </div>
          </div>
          <ModalPasswordReset />
        </div>
      }
    </>
  );
};
